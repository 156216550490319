import { ChartBarIcon } from "@heroicons/react/24/outline";

export default function ListGroup({ data, handleClick }) {
  return (
    <div>
      {data.map((item) => (
        <div
          key={item.id}
          className={`p-4 m-1 border rounded-md ${
            handleClick ? "cursor-pointer hover:bg-gray-50" : ""
          }`}
          onClick={() => handleClick?.(item.id)}
        >
          {item && console.log("item", item)}
          <div className="flex items-center justify-start">
            <div className="mx-2">
              {item?.price ? (
                item?.price?.product?.image ? (
                  <img
                    src={item?.price?.product?.image}
                    className="w-10 h-10 rounded-md"
                    alt={item.name}
                  />
                ) : (
                  <ChartBarIcon className="w-10 h-10 rounded-md text-indigo-500" />
                )
              ) : null}
            </div>
            <div className="mx-1">
              <h1 className="font-medium">{item.name}</h1>
              {item?.price && (
                <p className="font-light text-gray-400">
                  ${item.price.amount} {item.price.currency.toUpperCase()}{" "}
                  monthly. {item.subscription_renewal_string}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
