import { useEffect, useState } from "react";

import SearchBrowseViewModel from "../../models/forFans/searchBrowseViewModel";
import ReleaseList from "../../components/forFans/artists/ReleaseList";
import ShowList from "../../components/forFans/artists/ShowList";
import ArtistTileList from "../../components/forFans/artists/ArtistTileList";
import { LoadingComponent } from "../../components/loading/LoadingScreen";
import ZantipiCat from "../../assets/images/Zantipi_Cat_no_zzz_screen_res.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BrowseSearch() {
  const token = localStorage.getItem("token");
  const [searchViewData, setSearchViewData] = useState();
  const [searchViewModel, setSearchViewModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    const viewModel = new SearchBrowseViewModel(
      token,
      searchViewData,
      (state) => setSearchViewData(state)
    );
    setSearchViewModel(viewModel);
  }, [token, searchViewData]);

  useEffect(() => {
    if (!searchViewData) {
      if (searchViewModel) {
        searchViewModel.fetchState().then(() => {
          setLoading(false); // Set loading to false after data is fetched
        });
      }
    }
  }, [searchViewModel]);

  return (
    <>
      {searchViewData?.data &&
        console.log("searchViewData", searchViewData.data)}
      {searchViewData?.data ? (
        searchViewData?.data?.releases && (
          <>
            {/* releases */}
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold text-gray-600 py-2">
                Releases
              </h2>
              <ReleaseList
                releases={searchViewData.data.releases}
                setLoading={setLoading}
                showArtist={true}
              />
            </div>

            {/* artists */}
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold text-gray-600 py-2">Artists</h2>
              {searchViewData?.data?.artists && (
                <ArtistTileList
                  artists={searchViewData.data.artists}
                  setLoading={setLoading}
                />
              )}
            </div>
          </>
        )
      ) : (
        <LoadingComponent />
      )}
    </>
  );
}
