import TableCell from "../../../../components/layout/tables/TableCell";
import TableRow from "../../../../components/layout/tables/TableRow";

const ProjectsTableRow = ({ project, cols, handleClick }) => {
  return (
    <TableRow cols={cols} handleClick={handleClick} divider={false}>
      <TableCell>
        <span className="font-medium text-gray-800">{project.name}</span>
      </TableCell>
      <TableCell>{project.artist?.name}</TableCell>
      <TableCell>{project.type.name}</TableCell>
    </TableRow>
  );
};

export default ProjectsTableRow;
