import { useState } from "react";

// generic
import { LoadingComponent } from "../../../../components/loading/LoadingScreen";

// feature specific
import ShowTableRow from "./ShowTableRow";
import HeaderItemSort from "../../../../components/layout/tables/HeaderItemSort";
import TableContainer from "../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../components/layout/tables/TableBody";

export default function ShowTable({
  shows,
  sortShows,
  users,
  taskCategories,
  showStatuses,
  selectShow,
  updateShow,
  fetchMoreShows,
  global = false,
}) {
  const [sortedHeader, setSortedHeader] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSort = (key) => {
    let prevKey = sortedHeader;

    sortShows(key);
    setSortedHeader(key);

    if (prevKey === key) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  const handleScroll = async (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      // if there are more shows to get
      if (shows.count > shows.results.length) {
        // only fetch more tasks if not already loading (avoid duplication)
        if (!isLoading) {
          setIsLoading(true);
          const res = await fetchMoreShows();
          if (res) {
            setIsLoading(false);
          } else {
            console.log("error getting more tasks");
            setIsLoading(false);
          }
        }
      }
    }
  };

  const headerItems = [
    {
      id: 1,
      name: "name",
      label: "Name",
      display: `w-3/4 md:w-1/2`,
    },
    {
      id: 2,
      name: "status",
      label: "Status",
      display: `hidden md:flex`,
    },
    {
      id: 3,
      name: "tour",
      label: "Tour",
      // display: `hidden md:flex`,
    },

    // {
    // id: 4,
    //   name: "venue",
    //   label: "Venue",
    //   display: `hidden lg:flex`,
    // },
    {
      id: 5,
      name: "show_date",
      label: "Show date",
      display: `hidden md:flex`,
    },
    // {
    // id: 6,
    //   name: "onSaleDate",
    //   label: "On sale date",
    //   display: `hidden xl:flex`,
    // },
    // {
    // id: 7,
    //   name: "capacity",
    //   label: "Capacity",
    //   display: `hidden xl:flex`,
    // },
    // {
    // id: 8,
    //   name: "link",
    //   label: "Ticket link",
    //   display: `hidden xl:flex`,
    // },
  ];

  return (
    <>
      <TableContainer fixed={true} handleScroll={handleScroll}>
        <TableHeader
          cols={headerItems}
          handleSort={handleSort}
          sortedHeader={sortedHeader}
          sortAscending={sortAscending}
        />
        <TableBody>
          {shows?.results &&
            shows.results.map((show, index) => {
              return (
                <ShowTableRow
                  key={show.id}
                  show={show}
                  selectShow={selectShow}
                  // users={users}
                  // taskCategories={taskCategories}
                  showStatuses={showStatuses}
                  updateShow={updateShow}
                  // global={global}
                />
              );
            })}
        </TableBody>
      </TableContainer>
      {isLoading && <LoadingComponent />}
    </>
  );
}
