import { useState, useEffect } from "react";
import { useParams, useOutletContext, useSearchParams } from "react-router-dom";

// generic
import { LoadingComponent } from "../../../../components/loading/LoadingScreen";
// feature specific
import ViewModel from "../../../../models/forArtists/main/projects/tasks/viewmodel";
import Header from "../../tasks/Header";
import TasksTable from "../../tasks/TasksTable";
import TaskSlideover from "../../tasks/TaskSlideover";
import TableContainer from "../../../../components/layout/tables/TableContainer";

export default function ProjectTasks() {
  const { token, project } = useOutletContext();
  let [searchParams, setSearchParams] = useSearchParams();
  let { projectId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, data, (state) => {
      setData(state);
    }),
    searchParams
  );

  useEffect(() => {
    viewModel.updateSearchParams(searchParams);
  }, [searchParams, setSearchParams, viewModel]);

  const [selectedTask, setSelectedTask] = useState();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Header
        setSearchParams={setSearchParams}
        addTask={async (taskData) => {
          await viewModel.addTask(taskData);
        }}
      />
      <div className="py-4">
        {data !== undefined ? (
          <TasksTable
            tasks={data?.tasks}
            sortTasks={(key) => viewModel.sortTasks(key)}
            taskCategories={data.categories}
            taskStatuses={data.statuses}
            selectTask={(task) => {
              setSelectedTask(task);
              setOpen(true);
            }}
            users={data.users}
            addTask={async (task) => {
              await viewModel.addTask(task);
            }}
            updateTask={async (task) => {
              return await viewModel.updateTask(task);
            }}
            deleteTask={async (task) => {
              await viewModel.deleteTask(task);
            }}
            fetchMoreTasks={async () => {
              return await viewModel.fetchMoreTasks();
            }}
          />
        ) : (
          <LoadingComponent />
        )}
        {selectedTask && data && (
          <TaskSlideover
            open={open}
            setOpen={setOpen}
            selectedTaskId={selectedTask?.id}
            deleteTask={async () => {
              await viewModel.deleteTask(selectedTask.id);
              setOpen(false);
            }}
            updateTask={async (task) => {
              return await viewModel.updateTask(task);
            }}
            categories={data.categories}
            statuses={data.statuses}
            users={data.users}
          />
        )}
      </div>
    </>
  );
}
