import { useState } from "react";

// generic
import TableContainer from "../../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../../components/layout/tables/TableBody";

// feature specific
import ModalRow from "./ModalRow";

const cols = [
  { id: 0, name: "", label: "" },
  { id: 1, name: "Ad", label: "Ad" },
  { id: 2, name: "Ad Type", label: "Ad Type" },
  { id: 3, name: "Asset Label", label: "Asset Label" },
];

export default function CreativeTable({
  ads,
  assetLabels,
  adTypes,
  sortAds,
  selected,
  handleSelectChange,
  createAssetLabel,
  addAssetLabelToAd,
  createAdType,
  addAdTypeToAd,
}) {
  const [sortedHeader, setSortedHeader] = useState("Ad");
  const [sortAscending, setSortAscending] = useState(true);

  const handleSort = (key) => {
    let prevKey = sortedHeader;

    setSortedHeader(key);

    if (prevKey === key) {
      sortAds(key, !sortAscending);
      setSortAscending(!sortAscending);
    } else {
      sortAds(key, true);
      setSortAscending(true);
    }
  };

  return (
    <div className="h-96 overflow-y-auto">
      <TableContainer>
        <TableHeader
          cols={cols}
          handleSort={handleSort}
          sortedHeader={sortedHeader}
          sortAscending={sortAscending}
        />
        <TableBody>
          {ads?.map((ad, index) => {
            return (
              <ModalRow
                key={ad.id}
                cols={cols}
                index={index}
                ad={ad}
                assetLabels={assetLabels}
                adTypes={adTypes}
                selected={selected}
                handleSelectChange={handleSelectChange}
                createAssetLabel={createAssetLabel}
                addAssetLabelToAd={addAssetLabelToAd}
                createAdType={createAdType}
                addAdTypeToAd={addAdTypeToAd}
              />
            );
          })}
        </TableBody>
      </TableContainer>
    </div>
  );
}
