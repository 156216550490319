import { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";

// generic
import { formatValue } from "../../../../../utils/analyticsUtils";
import { convertToDisplayDate } from "../../../../../utils/dateUtils";
import { sortData } from "../../../../../utils/datasetUtils";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/analytics/overviewViewModel";
// to be cleaned up as features
import ScorecardsOverview from "../../../../../components/analytics/scorecards/ScorecardsOverview";
import StatsTable from "../../../../../components/analytics/StatsTable";
import BarChartDynamic from "../../../../../components/analytics/charts/BarChartDynamic";
import ChartContainer from "../../../../../components/analytics/charts/ChartContainer";

export default function ProjectAnalyticsIndex() {
  const { token, project } = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  let { projectId } = useParams();
  const [analyticsOverviewViewData, setAnalyticsOverviewViewData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, analyticsOverviewViewData, (state) => {
      setAnalyticsOverviewViewData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchProjectState().then((data) => {
      setIsLoading(false);
    });
  }, [viewModel, token, projectId]);

  const hasMultipleShows =
    analyticsOverviewViewData?.data?.project_shows?.length > 1;

  // Function to determine the grid column class
  const getGridColumnClass = () => {
    const numItems =
      analyticsOverviewViewData?.data?.project_shows?.length ||
      analyticsOverviewViewData?.data?.project_releases?.length ||
      0;

    if (numItems > 4) {
      return "grid-cols-1";
    } else if (numItems > 2) {
      return "grid-cols-1 md:grid-cols-2";
    } else {
      return "grid-cols-1 md:grid-cols-2";
    }
  };

  const tourCols = [
    { header: "Show", key: "name" },
    {
      header: "Show date",
      key: "show_date",
      formatter: (value) => convertToDisplayDate(value),
    },
    { header: "Days until show", key: "days_until_show" },
    {
      header: "Capacity",
      key: "capacity",
      formatter: (value) => formatValue("capacity", value),
    },
    {
      header: "Budget",
      key: "budget",
      formatter: (value) => formatValue("budget", value),
    },
    {
      header: "Spent",
      key: "spent",
      formatter: (value) => formatValue("spent", value),
    },
    {
      header: "Spent (%)",
      key: "spent_percentage",
      formatter: (value) => formatValue("spent_percentage", value),
    },
    {
      header: "Tickets sold",
      key: "tickets_sold",
      formatter: (value) => formatValue("tickets_sold", value),
    },
    {
      header: "Tickets sold (%)",
      key: "tickets_sold_percentage",
      formatter: (value) => formatValue("tickets_sold_percentage", value),
    },
    {
      header: "CPT",
      key: "cpt",
      formatter: (value) => formatValue("cpt", value),
    },
    {
      header: "Avg Daily Tickets",
      key: "average_daily_tickets",
      formatter: (value) => formatValue("average_daily_tickets", value),
    },
    {
      header: "Required Avg Daily Tickets",
      key: "average_daily_tickets_required",
      formatter: (value) =>
        formatValue("average_daily_tickets_required", value),
    },
  ];

  const tourLiteCols = [
    { header: "Show", key: "name" },
    {
      header: "Show date",
      key: "show_date",
      formatter: (value) => convertToDisplayDate(value),
    },
    { header: "Days until show", key: "days_until_show" },
    {
      header: "Capacity",
      key: "capacity",
      formatter: (value) => formatValue("capacity", value),
    },
    {
      header: "Budget",
      key: "budget",
      formatter: (value) => formatValue("budget", value),
    },
    {
      header: "Spent",
      key: "spent",
      formatter: (value) => formatValue("spent", value),
    },
    {
      header: "Spent (%)",
      key: "spent_percentage",
      formatter: (value) => formatValue("spent_percentage", value),
    },
    {
      header: "Impressions",
      key: "impressions",
      formatter: (value) => formatValue("impressions", value),
    },
    {
      header: "CPM",
      key: "cpm",
      formatter: (value) => formatValue("cpm", value),
    },
    {
      header: "Clicks",
      key: "clicks",
      formatter: (value) => formatValue("clicks", value),
    },
    {
      header: "CTR",
      key: "ctr",
      formatter: (value) => formatValue("ctr", value),
    },
    {
      header: "CPC",
      key: "cpc",
      formatter: (value) => formatValue("cpc", value),
    },
  ];

  const releaseCols = [
    { header: "Release", key: "name" },
    {
      header: "Release date",
      key: "release_date",
      formatter: (value) => convertToDisplayDate(value),
    },
    {
      header: "Budget",
      key: "budget",
      formatter: (value) => formatValue("budget", value),
    },
    {
      header: "Spent",
      key: "spent",
      formatter: (value) => formatValue("spent", value),
    },
    {
      header: "Spent (%)",
      key: "spent_percentage",
      formatter: (value) => formatValue("spent_percentage", value),
    },
    {
      header: "Impressions",
      key: "impressions",
      formatter: (value) => formatValue("impressions", value),
    },
    {
      header: "CPM",
      key: "cpm",
      formatter: (value) => formatValue("cpm", value),
    },
    {
      header: "Clicks",
      key: "clicks",
      formatter: (value) => formatValue("clicks", value),
    },
    {
      header: "CTR",
      key: "ctr",
      formatter: (value) => formatValue("ctr", value),
    },
    {
      header: "CPC",
      key: "cpc",
      formatter: (value) => formatValue("cpc", value),
    },
  ];

  const merchCols = [
    { header: "Collection", key: "name" },
    {
      header: "Launch date",
      key: "release_date",
      formatter: (value) => convertToDisplayDate(value),
    },
    {
      header: "Budget",
      key: "budget",
      formatter: (value) => formatValue("budget", value),
    },
    {
      header: "Spent",
      key: "spent",
      formatter: (value) => formatValue("spent", value),
    },
    {
      header: "Spent (%)",
      key: "spent_percentage",
      formatter: (value) => formatValue("spent_percentage", value),
    },
    {
      header: "Impressions",
      key: "impressions",
      formatter: (value) => formatValue("impressions", value),
    },
    {
      header: "CPM",
      key: "cpm",
      formatter: (value) => formatValue("cpm", value),
    },
    {
      header: "Clicks",
      key: "clicks",
      formatter: (value) => formatValue("clicks", value),
    },
    {
      header: "CTR",
      key: "ctr",
      formatter: (value) => formatValue("ctr", value),
    },
    {
      header: "CPC",
      key: "cpc",
      formatter: (value) => formatValue("cpc", value),
    },
    {
      header: "Purchases",
      key: "purchases",
      formatter: (value) => formatValue("purchases", value),
    },
    {
      header: "ROAS",
      key: "roas",
      formatter: (value) => formatValue("roas", value),
    },
  ];

  console.log("analyticsOverviewViewData", analyticsOverviewViewData);
  console.log("project.has_ticket_reporting", project.has_ticket_reporting);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {/* scorecards */}
      <div className="py-2">
        {analyticsOverviewViewData?.data?.scorecards && (
          <ScorecardsOverview
            data={analyticsOverviewViewData.data.scorecards}
            projectType={analyticsOverviewViewData.data.project_type}
            hasTicketReporting={project.has_ticket_reporting}
          />
        )}
      </div>

      {/* charts */}
      {analyticsOverviewViewData?.data?.project_type === "Tour" &&
        project.has_ticket_reporting &&
        hasMultipleShows && (
          <div className={`py-4 my-4 grid ${getGridColumnClass()} gap-4`}>
            <ChartContainer title="Tickets sold">
              <BarChartDynamic
                data={
                  analyticsOverviewViewData?.data?.project_shows &&
                  sortData(
                    analyticsOverviewViewData?.data?.project_shows,
                    "tickets_sold",
                    false
                  )
                }
                title="Tickets sold (stacked)"
                x_label="name"
                y_label="tickets_sold"
                stackedLabel={"tickets_remaining"}
                showPercentage={true}
              />
            </ChartContainer>

            <ChartContainer title="Cost per Ticket">
              <BarChartDynamic
                data={
                  analyticsOverviewViewData?.data?.project_shows &&
                  sortData(
                    analyticsOverviewViewData?.data?.project_shows,
                    "cpt",
                    true
                  )
                }
                title="Cost per Ticket"
                x_label="name"
                y_label="cpt"
                // barColor="#e34a63"
              />
            </ChartContainer>
            <ChartContainer title="Avg Daily Tickets Required vs Actual">
              <BarChartDynamic
                data={
                  analyticsOverviewViewData?.data?.project_shows &&
                  sortData(
                    analyticsOverviewViewData?.data?.project_shows,
                    "average_daily_tickets",
                    false
                  )
                }
                title="Avg Daily Tickets Required vs Actual"
                x_label="name"
                y_label="average_daily_tickets"
                y1_label="average_daily_tickets_required"
                singleYAxis={true}
              />
            </ChartContainer>
          </div>
        )}

      {/* stats table */}
      {analyticsOverviewViewData?.data?.project_type === "Tour" &&
        project.has_ticket_reporting && (
          <div className="py-8">
            {analyticsOverviewViewData?.data?.project_shows && (
              <StatsTable
                data={analyticsOverviewViewData?.data?.project_shows}
                columns={tourCols}
                sortData={(key, sortAscending) =>
                  viewModel.sortData(
                    analyticsOverviewViewData?.data?.project_shows,
                    key,
                    sortAscending
                  )
                }
                footerData={analyticsOverviewViewData?.data?.totals}
                unallocatedData={analyticsOverviewViewData?.data?.unallocated}
              />
            )}
          </div>
        )}
      {analyticsOverviewViewData?.data?.project_type === "Tour" &&
        !project.has_ticket_reporting && (
          <div className="py-8">
            {analyticsOverviewViewData?.data?.project_shows && (
              <StatsTable
                data={analyticsOverviewViewData?.data?.project_shows}
                columns={tourLiteCols}
                sortData={(key, sortAscending) =>
                  viewModel.sortData(
                    analyticsOverviewViewData?.data?.project_shows,
                    key,
                    sortAscending
                  )
                }
                footerData={analyticsOverviewViewData?.data?.totals}
                unallocatedData={analyticsOverviewViewData?.data?.unallocated}
              />
            )}
          </div>
        )}
      {analyticsOverviewViewData?.data?.project_type === "Release" && (
        <div className="py-8">
          {analyticsOverviewViewData?.data?.project_releases && (
            <StatsTable
              data={analyticsOverviewViewData.data.project_releases}
              columns={releaseCols}
              sortData={(key, sortAscending) =>
                viewModel.sortData(
                  analyticsOverviewViewData?.data?.project_releases,
                  key,
                  sortAscending
                )
              }
              footerData={analyticsOverviewViewData?.data?.totals}
              unallocatedData={analyticsOverviewViewData?.data?.unallocated}
            />
          )}
        </div>
      )}
      {analyticsOverviewViewData?.data?.project_type === "Merch" && (
        <div className="py-4">
          {analyticsOverviewViewData?.data?.project_collections && (
            <StatsTable
              data={analyticsOverviewViewData.data.project_collections}
              columns={merchCols}
              sortData={(key, sortAscending) =>
                viewModel.sortData(
                  analyticsOverviewViewData?.data?.project_collections,
                  key,
                  sortAscending
                )
              }
              footerData={analyticsOverviewViewData?.data?.totals}
              unallocatedData={analyticsOverviewViewData?.data?.unallocated}
            />
          )}
        </div>
      )}
    </>
  );
}
