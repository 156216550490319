import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { BellIcon } from "@heroicons/react/24/outline";

import Navbar from "../../components/forFans/NavBar";
import Sidebar from "../../components/forFans/Sidebar";
import AudioPlayer from "../../components/forFans/AudioPlayer";
import AudioPlayerViewModel from "../../models/forFans/audioPlayerViewModel";
import SidebarViewModel from "../../models/forFans/sidebarViewModel";

export default function ProjectsRoot() {
  const token = localStorage.getItem("token");
  const [audioPlayerViewModel, setAudioPlayerViewModel] = useState(null);
  const [sidebarViewData, setSidebarTaskViewData] = useState();
  const [sidebarViewModel, setSidebarViewModel] = useState(
    new SidebarViewModel(token, sidebarViewData, (state) => {
      setSidebarTaskViewData(state);
    })
  );

  useEffect(() => {
    const viewModel = new AudioPlayerViewModel(token, (state) => {
      setAudioPlayerViewModel({ ...viewModel, ...state });
    });
    viewModel.fetchState();
    setAudioPlayerViewModel(viewModel);
  }, [token]);

  useEffect(() => {
    sidebarViewModel.fetchState();
  }, [sidebarViewModel, token]);

  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="sticky top-0 z-10">
          <Navbar />
        </div>
        <div className="flex h-[calc(100vh-8rem)]">
          <aside className="hidden w-64 lg:flex flex-grow">
            <Sidebar
              data={sidebarViewData}
              token={token}
              createPlaylist={sidebarViewModel.createPlaylist}
            />
          </aside>
          <main className="w-full flex-grow overflow-y-auto">
            {audioPlayerViewModel && (
              <Outlet
                context={{ token, audioPlayerViewModel, sidebarViewModel }}
              />
            )}
          </main>
        </div>
        {audioPlayerViewModel && (
          <AudioPlayer viewModel={audioPlayerViewModel} />
        )}
      </div>
    </>
  );
}
