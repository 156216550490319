import { Link } from "react-router-dom";
import Zantipi_Logo from "../../assets/images/Zantipi_Logo.png";

export default function NavBar() {
  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="mx-auto flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Zantipi</span>
            <img
              className="h-20 w-auto"
              src={Zantipi_Logo}
              alt="Zantipi Logo"
            />
          </Link>
        </div>
        <div className="flex flex-1 justify-end">
          <Link
            to="/login"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>
    </header>
  );
}
