import { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import ViewModel from "../../../../models/forArtists/main/artists/artistAccountViewModel";
import LoadingScreen from "../../../../components/loading/LoadingScreen";
import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";
import ProductForm from "../../../../components/forms/ProductForm";
import SlideOver from "../../../../components/SlideOver";
import ListGroup from "../../../../components/dataDisplay/lists/ListGroup";

export default function ArtistAccount() {
  const { token } = useOutletContext();
  let { artistId } = useParams();
  const [accountViewData, setAccountProductsViewData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, artistId, accountViewData, (state) => {
      setAccountProductsViewData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel, token, artistId]);

  if (!accountViewData) return <LoadingScreen />;

  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <h1 className="text-5xl font-bold">Account</h1>
      </div>
    </>
  );
}
