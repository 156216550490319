import SecondaryButton from "../../../../components/UI/buttons/SecondaryButton";
import SubtaskList from "./SubtaskList";

const Index = ({
  taskId,
  subtasks,
  addSubtask,
  updateSubtask,
  removeSubtask,
}) => {
  const handleAddSubtask = () => {
    const newSubtask = {
      title: "New subtask",
      task_id: taskId,
    };
    addSubtask(newSubtask);
  };

  return (
    <div className="py-2">
      <h3>Subtasks</h3>
      <SubtaskList
        subtasks={subtasks}
        updateSubtask={updateSubtask}
        removeSubtask={removeSubtask}
      />
      <div className="py-1">
        <SecondaryButton
          text={"+ Add subtask"}
          handleClick={handleAddSubtask}
          smallHeight={true}
        />
      </div>
    </div>
  );
};

export default Index;
