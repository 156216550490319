export default function ArtistIndex() {
  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <h1 className="text-5xl font-bold">Home</h1>
      </div>
      <div className="py-4">
        <p>Welcome to your artist home page! :)</p>
      </div>
    </>
  );
}
