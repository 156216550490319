import { Link, useParams } from "react-router-dom";

export default function Row({ ad }) {
  console.log("ad: ", ad);
  let { projectId } = useParams();

  return (
    <>
      <div>{ad.name}</div>
    </>
  );
}
