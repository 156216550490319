export const formatValue = (label, value) => {
  // Check for null, undefined, or non-numeric values
  if (value === null || value === undefined || isNaN(value)) {
    return 0; // or any other default/fallback value
  }

  const lowerCaseLabel = label.toLowerCase();

  switch (lowerCaseLabel) {
    case "spend":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "spent":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "budget":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "impressions":
      return value.toLocaleString();
    case "cpm":
      return `$${value.toFixed(2)}`;
    case "clicks":
      return value.toLocaleString();
    case "ctr":
      return `${value.toFixed(1)}%`;
    case "cpc":
      return `$${value.toFixed(2)}`;
    case "cpt":
      return `$${value.toFixed(2)}`;
    case "revenue":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "roas":
      // if (value > 0.2) {
      //   return `${value.toFixed(1)}`;
      // }
      // return `${value.toFixed(2)}`;
      return `${value.toFixed(1)}`;
    case "tickets_sold":
      return value.toLocaleString();
    case "tickets_remaining":
      return value.toLocaleString();
    case "average_daily_tickets":
      return `${value.toFixed(0)}`;
    case "capacity":
      return value.toLocaleString();
    case "average_daily_tickets_required":
      return `${value.toFixed(0)}`;
    case "tickets_sold_percentage":
      return `${value.toFixed(0)}%`;
    case "spent_percentage":
      return `${value.toFixed(0)}%`;
    default:
      return value;
  }
};

export const formatAxis = (label, value) => {
  switch (label) {
    case "spend":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "spent":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "budget":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "impressions":
      return value.toLocaleString();
    case "cpm":
      return `$${value.toFixed(2)}`;
    case "clicks":
      return value.toLocaleString();
    case "ctr":
      return `${value.toFixed(1)}%`;
    case "cpc":
      return `$${value.toFixed(2)}`;
    case "cpt":
      return `$${value.toFixed(2)}`;
    case "revenue":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: value > 100 ? 0 : 2,
      }).format(value);
    case "roas":
      if (value > 0.2) {
        return `${value.toFixed(1)}`;
      }
      return `${value.toFixed(2)}`;
    case "tickets_sold":
      return value.toLocaleString();
    case "tickets_remaining":
      return value.toLocaleString();
    case "average_daily_tickets":
      return `${value.toFixed(0)}`;
    case "capacity":
      return value.toLocaleString();
    case "average_daily_tickets_required":
      return `${value.toFixed(0)}`;
    case "tickets_sold_percentage":
      return `${value.toFixed(0)}%`;
    case "spent_percentage":
      return `${value.toFixed(0)}%`;
    default:
      return value;
  }
};

export const formatLegend = (value) => {
  switch (value) {
    case "spend":
      return "Spend";
    case "spent":
      return "Spent";
    case "impressions":
      return "Impressions";
    case "cpm":
      return "Cost per 1000 Impressions (CPM)";
    case "clicks":
      return "Clicks";
    case "ctr":
      return "Click Through Rate (CTR)";
    case "cpc":
      return "Cost per Click (CPC)";
    case "tickets_sold":
      return "Tickets Sold";
    case "tickets_sold_percentage":
      return "Tickets Sold %";
    case "tickets_remaining":
      return "Tickets Remaining";
    case "cpt":
      return "CPT (Cost per Ticket)";
    case "average_daily_tickets":
      return "Average daily tickets actual";
    case "average_daily_tickets_required":
      return "Average daily tickets required for sellout";
    case "purchases":
      return "Purchases";
    case "revenue":
      return "Revenue";
    case "roas":
      return "ROAS (Return on Ad Spend)";
    // Add more cases as needed
    default:
      return value;
  }
};

export const formatColor = (value) => {
  switch (value) {
    case "spend":
      return "#e34a63";
    case "spent":
      return "#e34a63";
    case "impressions":
      return "#662e91";
    case "cpm":
      return "#e34a63";
    case "clicks":
      return "#662e91";
    case "ctr":
      return "#4287f5";
    case "cpc":
      return "#e34a63";
    case "tickets_sold":
      return "#662e91";
    case "tickets_sold_percentage":
      return "#662e91";
    case "tickets_remaining":
      return "#e34a63";
    case "cpt":
      return "#e34a63";
    case "average_daily_tickets":
      return "#662e91";
    case "average_daily_tickets_required":
      return "#e34a63";
    case "purchases":
      return "#662e91";
    case "revenue":
      return "#662e91";
    case "roas":
      return "#662e91";
    // Add more cases as needed
    default:
      return "#e34a63";
  }
};

export const formatHoverColor = (value) => {
  switch (value) {
    case "#e34a63":
      return "rgba(227,74,99, 0.8)";
    case "#662e91":
      return "rgba(102,46,145, 0.8)";
    case "#4287f5":
      return "rgba(66,135,245, 0.8)";

    // Add more cases as needed
    default:
      return "#e34a63";
  }
};

export const displayAscending = (label) => {
  const lowerCaseLabel = label.toLowerCase();

  switch (lowerCaseLabel) {
    case "spend":
      return false;
    case "spent":
      return false;
    case "budget":
      return false;
    case "impressions":
      return false;
    case "cpm":
      return true;
    case "clicks":
      return false;
    case "ctr":
      return false;
    case "cpc":
      return true;
    case "cpt":
      return true;
    case "revenue":
      return false;
    case "roas":
      return false;
    case "tickets_sold":
      return false;
    case "tickets_remaining":
      return true;
    case "average_daily_tickets":
      return false;
    case "capacity":
      return false;
    case "average_daily_tickets_required":
      return true;
    case "tickets_sold_percentage":
      return false;
    case "spent_percentage":
      return true;
    default:
      return false;
  }
};
