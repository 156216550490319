export default class TimeseriesViewModel {
  constructor(token, projectId, state, updateState, searchParams) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    const queryParams = new URLSearchParams(this.searchParams).toString();
    return `${this.url_begin}${path}?${queryParams}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      //   cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/analytics/views/timeseries/`,
      true
    );
  };

  _fetchInitialProjectCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchProjectState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialProjectCalls();
      this._callUpdateState();
    }
    console.log("tis state: ", this.state);
    return this.state;
  };

  updateSearchParams = async (newSearchParams) => {
    this.searchParams = newSearchParams;
    this.state = await this._fetchInitialProjectCalls();
    this._callUpdateState();
  };
}
