const FormInput = ({ name, type, value, onChange, onBlur, errorMessage }) => {
  return (
    <div className="w-full h-full">
      <input
        name={name}
        type={type}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
        className="block w-full h-full bg-transparent border-none placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 hover:cursor-pointer"
      />
      <span className="error">{errorMessage}</span>
    </div>
  );
};

export default FormInput;
