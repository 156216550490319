import { toTitleCase } from "../../utils/stringUtils";

const ToggleButtonGroup = ({ buttons, activeButton, onToggle }) => {
  const isActive = (button) => activeButton === button.toLowerCase();

  return (
    <div className="p-0 grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-8">
      {buttons.map((button) => (
        <button
          key={button}
          onClick={() => onToggle(button)}
          className={
            isActive(button)
              ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          }
        >
          {button}
        </button>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;
