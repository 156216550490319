import TableCell from "../../../../../components/layout/tables/TableCell";
import TableRow from "../../../../../components/layout/tables/TableRow";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

const CollectionsModalRow = ({ collection, cols, addCollectionToProject }) => {
  return (
    <TableRow cols={cols}>
      <TableCell>{collection.name}</TableCell>
      <TableCell>
        {collection.is_added_to_project ? (
          "added"
        ) : (
          <PrimaryButton
            text={"Add to project"}
            handleClick={() => addCollectionToProject(collection.id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default CollectionsModalRow;
