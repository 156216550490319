import { createBrowserRouter, RouterProvider } from "react-router-dom";
// M4A Sales site
import Sales from "./pages/forArtists/sales/Sales";
import SalesRoot from "./pages/forArtists/sales/SalesRoot";
// M4A auth site
import Login from "./pages/forArtists/auth/Login";
import VerifyOTP from "./pages/forArtists/auth/VerifyOTP";
// M4A Main site
import MainLayoutContext from "./pages/forArtists/MainLayoutContext";
import Root from "./pages/forArtists/main/Root";
import Index from "./pages/forArtists/main/Index";
import ProjectsIndex from "./pages/forArtists/main/projects/ProjectsIndex";
import ProjectRoot from "./pages/forArtists/main/projects/detail/Root";
import ProjectIndex from "./pages/forArtists/main/projects/detail/Index";
import ProjectAnalytics from "./pages/forArtists/main/projects/ProjectAnalytics";
import ProjectAnalyticsIndex from "./pages/forArtists/main/projects/analytics/ProjectAnalyticsIndex";
import ProjectAnalyticsTimeseries from "./pages/forArtists/main/projects/analytics/ProjectAnalyticsTimeseries";
import ProjectAnalyticsPlatform from "./pages/forArtists/main/projects/analytics/ProjectAnalyticsPlatform";
import ProjectAnalyticsAudience from "./pages/forArtists/main/projects/analytics/ProjectAnalyticsAudience";
import ProjectAnalyticsCreative from "./pages/forArtists/main/projects/analytics/ProjectAnalyticsCreative";
import ProjectTasks from "./pages/forArtists/main/projects/tasks/Index";
import ProjectBudgetRoot from "./pages/forArtists/main/projects/adcampaigns/Root";
import ProjectCampaigns from "./pages/forArtists/main/projects/adcampaigns/Campaigns";
import ProjectAdgroups from "./pages/forArtists/main/projects/adcampaigns/Adgroups";
import ProjectAds from "./pages/forArtists/main/projects/adcampaigns/Ads";
import ProjectDataIndex from "./pages/forArtists/main/projects/data/Index";
import ProjectUsers from "./pages/forArtists/main/projects/users/Index";
// M4A Artist site
import ArtistsIndex from "./pages/forArtists/main/artists/ArtistsIndex";
import ArtistRoot from "./pages/forArtists/main/artists/detail/Root";
import ArtistIndex from "./pages/forArtists/main/artists/detail/Index";
import ArtistShows from "./pages/forArtists/main/artists/shows/Index";
import ArtistReleases from "./pages/forArtists/main/artists/releases/Index";
import ArtistProducts from "./pages/forArtists/main/artists/products/Index";
import ArtistAccount from "./pages/forArtists/main/artists/ArtistAccount";
// M4A Organization site
import OrganizationsRoot from "./pages/forArtists/main/organizations/OrganizationsRoot";
import OrganizationsIndex from "./pages/forArtists/main/organizations/OrganizationsIndex";
import OrganizationRoot from "./pages/forArtists/main/organizations/detail/Root";
import OrganizationIndex from "./pages/forArtists/main/organizations/detail/Index";
import OrganizationTasks from "./pages/forArtists/main/organizations/tasks/Index";
import OrganizationUsers from "./pages/forArtists/main/organizations/users/Index";
import OrganizationBilling from "./pages/forArtists/main/organizations/billing/Index";
import OrganizationData from "./pages/forArtists/main/organizations/data/Index";
// M4A Staff site
import StaffRoot from "./pages/forArtists/main/staff/Root";
import StaffIndex from "./pages/forArtists/main/staff/Index";
import Royalties from "./pages/forArtists/main/staff/Royalties";
import Ingestion from "./pages/forArtists/main/staff/Ingestion";
import ReleaseFormPage from "./pages/forArtists/main/staff/ReleaseFormPage";
// M4A Data site
import DataConnections from "./pages/forArtists/main/dataSources/Index";
import DataTiktok from "./pages/forArtists/main/dataSources/tiktok/Index";
import DataGoogle from "./pages/forArtists/main/dataSources/google/Index";
// M Main site
import ErrorPage from "./pages/ErrorPage";
// fonts
import "./assets/fonts/fonts.css";
import ProjectsForm from "./pages/forArtists/main/projects/ProjectFormPage";
// utils
import StaffOnlyRoute from "./components/utils/StaffOnlyRoute";
import ScrollToTop from "./components/utils/ScrollToTop";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/verify",
    element: <VerifyOTP />,
    errorElement: <ErrorPage />,
  },
  {
    // business app
    element: <MainLayoutContext />,
    children: [
      {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Index /> },
          {
            path: "projects/",
            element: <ProjectsIndex />,
          },
          {
            path: "projects/create/",
            element: <ProjectsForm />,
          },
          {
            path: "artists/",
            element: <ArtistsIndex />,
          },
          {
            path: "organizations/",
            element: <OrganizationsRoot />,
            children: [
              {
                index: true,
                element: <OrganizationsIndex />,
              },
            ],
          },
          {
            path: "data/",
            element: <DataConnections />,
          },
          {
            path: "data/tiktok/",
            element: <DataTiktok />,
          },
          {
            path: "data/google/",
            element: <DataGoogle />,
          },
          {
            path: "staff/",
            element: (
              <StaffOnlyRoute>
                <StaffRoot />
              </StaffOnlyRoute>
            ),
            children: [
              {
                index: true,
                element: <StaffIndex />,
              },
              {
                path: "royalties/",
                element: <Royalties />,
              },
              {
                path: "ingestion/",
                element: <Ingestion />,
              },
              {
                path: "ingestion/releases/:releaseId/",
                element: <ReleaseFormPage />,
              },
            ],
          },
        ],
      },

      {
        path: "organizations/:organizationId/",
        element: <OrganizationRoot />,
        children: [
          {
            index: true,
            element: <OrganizationIndex />,
          },
          { path: "tasks/", element: <OrganizationTasks /> },
          { path: "users/", element: <OrganizationUsers /> },
          { path: "billing/", element: <OrganizationBilling /> },
          {
            path: "data/",
            element: <OrganizationData />,
          },
        ],
      },

      {
        path: "projects/:projectId/",
        element: <ProjectRoot />,
        children: [
          { index: true, element: <ProjectIndex /> },
          {
            path: "edit/",
            element: <ProjectsForm />,
          },
          { path: "tasks/", element: <ProjectTasks /> },
          {
            path: "analytics/",
            element: <ProjectAnalytics />,
            children: [
              { index: true, element: <ProjectAnalyticsIndex /> },
              {
                path: "timeseries/",
                element: <ProjectAnalyticsTimeseries />,
              },
              { path: "platform/", element: <ProjectAnalyticsPlatform /> },
              { path: "audience/", element: <ProjectAnalyticsAudience /> },
              { path: "creative/", element: <ProjectAnalyticsCreative /> },
            ],
          },
          {
            element: <ProjectDataIndex />,
            path: "data/",
          },
          { path: "users/", element: <ProjectUsers /> },
          {
            element: <ProjectBudgetRoot />,
            children: [
              { path: "campaigns/", element: <ProjectCampaigns /> },
              { path: "adgroups/", element: <ProjectAdgroups /> },
              { path: "ads/", element: <ProjectAds /> },
            ],
          },
        ],
      },
      {
        path: "artists/:artistId/",
        element: <ArtistRoot />,
        children: [
          { index: true, element: <ArtistIndex /> },
          { path: "shows/", element: <ArtistShows /> },
          { path: "releases/", element: <ArtistReleases /> },
          { path: "products/", element: <ArtistProducts /> },
          { path: "account/", element: <ArtistAccount /> },
        ],
      },
    ],
  },
  {
    // sales app (non logged in view)
    path: "/sales",
    element: <SalesRoot />,
    errorElement: <ErrorPage />,
    children: [{ index: true, element: <Sales /> }],
  },
  {
    path: "*", // Catch-all route
    element: <ErrorPage />, // Render ErrorPage for unmatched routes
  },
]);

export default function ArtistsApp() {
  return (
    <>
      <RouterProvider router={router}>
        <ScrollToTop />
      </RouterProvider>
    </>
  );
}
