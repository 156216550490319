import { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";

// generic
import PageHeading from "../../../../../components/layout/PageHeading";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/organizations/users/viewmodel.js";
import InviteUserForm from "../../../../../features/forArtists/organizations/users/InviteUserForm";
import UserListGroup from "../../../../../features/forArtists/organizations/users/UserListGroup";

export default function Index() {
  const { token } = useOutletContext();
  let { organizationId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, organizationId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel, token, organizationId]);

  const [openAddForm, setOpenAddForm] = useState(false);

  const handleClick = () => {
    setOpenAddForm(!openAddForm);
  };

  console.log("data: ", data);

  return (
    <>
      <div className="p-10 justify-center">
        <PageHeading
          title="Users"
          subtitle={"Invite your team members to join your organization."}
          buttonText={"+ Add"}
          handleClick={handleClick}
        />

        <div className="py-2">
          <div className="p-2 flex flex-col gap-2">
            {openAddForm ? (
              <InviteUserForm
                inviteUser={async (email) => {
                  return await viewModel.inviteUser(email);
                }}
                setOpenAddForm={setOpenAddForm}
              />
            ) : null}
          </div>
          {data?.users && (
            <UserListGroup
              users={data.users}
              removeUser={async (userId) => {
                await viewModel.removeUser(userId);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
