import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

// generic
import Checkbox from "../../../../../components/UI/checkboxes/Index";

export default function Row({ campaign, handleSelect, selectedCampaigns }) {
  let { projectId } = useParams();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    handleSelect(campaign.id, event.target.checked);
  };

  useEffect(() => {
    console.log("isChecked: ", isChecked);
  }, [isChecked]);

  const handleClick = () => {
    navigate(`/projects/${projectId}/adgroups`);
    setIsChecked(!isChecked);
  };

  return (
    <>
      <tr
      // onClick={() => onClick(item.id)}
      // className="hover:bg-gray-50 cursor-pointer"
      >
        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <Checkbox
            isChecked={selectedCampaigns.includes(campaign.id.toString())}
            onChange={handleCheckboxChange}
            id={campaign.id}
          />
        </td>

        <td
          onClick={handleClick}
          className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 hover:text-blue-400"
        >
          <Link
            to={`/projects/${projectId}/adgroups`}
            className="hover:text-blue-400"
          >
            {campaign.name}
          </Link>
        </td>
      </tr>
    </>
  );
}
