import { useState } from "react";

import { PencilIcon } from "@heroicons/react/20/solid";

export default function FormTile({ imageUrl, alt, onImageChange }) {
  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_API_URL;
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior (Prevent file from being opened)
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      setImagePreviewUrl(URL.createObjectURL(file));
      const response = await fetch(
        `${baseURL}api/v1/images/generate-signed-url/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json", // Ensure content type is set for JSON payload
          },
          body: JSON.stringify({ filename: file.name }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      console.log("signed url data", data);

      // Perform the actual file upload to the signed URL
      const putResponse = await fetch(data.signedUrl, {
        method: "PUT",
        body: file, // Put the file itself without any additional headers
      });

      if (!putResponse.ok) {
        throw new Error(`Upload failed: ${putResponse.status}`);
      }

      console.log("File uploaded successfully.");
      console.log("putResponse", putResponse);

      const res = await saveToDatabase(file.name, data.fullUrl);
      console.log("res", res);
    } catch (error) {
      console.error("Error during file upload", error);
    }
  };

  const saveToDatabase = async (fileName, URL) => {
    try {
      const response = await fetch(
        `${baseURL}api/v1/images/save-to-database/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileName: fileName,
            fullUrl: URL,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Selected/uploaded image id", data.imageId);
        onImageChange(data.imageId); // Invoke the handler with the image data
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Error during file upload", error);
    }
  };

  return (
    <div
      className="relative md:flex md:items-start h-60 w-60 rounded-lg bg-gray-300"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        accept="image/*"
        onChange={(e) => onImageChange(e.target.files[0])}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      {imagePreviewUrl ? (
        <img
          className="object-cover rounded-lg"
          src={imagePreviewUrl}
          alt={"preview of new upload"}
        />
      ) : imageUrl ? (
        <img className="object-cover rounded-lg" src={imageUrl} alt={alt} />
      ) : (
        <div className="object-cover" alt="Upload image here" />
      )}
      <div className="absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-60 opacity-0 hover:opacity-100">
        <PencilIcon className="text-white h-8 w-8" />
        <span className="text-white text-lg font-semibold">Change</span>
      </div>
    </div>
  );
}
