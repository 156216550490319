import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import Avatar from "../../Avatar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchDropdown() {
  const token = localStorage.getItem("token");
  const [query, setQuery] = useState("");
  const [artists, setArtists] = useState(1);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (query.length > 0) {
      fetch(`${baseURL}api/v1/artists/fans/?q=${query}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Log the error message body
            response.json().then((json) => console.error(json));
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          // console.log(data);
          setArtists(data.results);
        });
    }
  }, [token, query]);

  const handleArtistSelect = (artist) => {
    // Navigate to the selected project's page
    navigate(`/artists/${artist.id}`);
  };

  return (
    <Combobox as="div" value={null} onChange={handleArtistSelect}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 sr-only">
        Search artists
      </Combobox.Label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <Combobox.Input
          className="w-full rounded-full border-0 bg-white py-1.5  pl-10 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(project) => project?.name}
          placeholder="Search artists..."
        />

        {artists && artists.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {artists.map((artist) => (
              <Combobox.Option
                key={artist.id}
                value={artist}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center gap-2">
                      {/* Avatar */}
                      <Avatar
                        imageUrl={artist.spotify_profile_pic_url}
                        size={"6"}
                      />

                      {/* Artist Name */}
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {artist.name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
