import { Link } from "react-router-dom";

// generic
import Zantipi_Logo from "../../../../assets/images/Zantipi_Logo_Purple.png";

export default function SiteLogo() {
  return (
    <>
      <div className="flex px-2 lg:px-0">
        <div className="flex flex-shrink-0 items-center">
          <Link to="/">
            <img className="h-8 w-auto" src={Zantipi_Logo} alt="Zantipi" />
          </Link>
        </div>
      </div>
    </>
  );
}
