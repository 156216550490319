import { useState } from "react";

// feature specific
import SubtaskListItem from "./SubtaskListItem";

export default function SubtaskList({
  subtasks,
  updateSubtask,
  removeSubtask,
}) {
  return (
    <>
      <div className="flex flex-col w-full text-sm">
        <div className="divide-y divide-gray-200">
          {subtasks &&
            subtasks.map((subtask, index) => {
              return (
                <SubtaskListItem
                  key={subtask.id}
                  subtask={subtask}
                  updateSubtask={updateSubtask}
                  removeSubtask={removeSubtask}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
