// generic
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import TableRow from "../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../components/layout/tables/TableCell";

export default function Row({ adaccount, setOpenModal, setSelectedAdaccount }) {
  const handleButtonClick = (adaccount) => {
    setOpenModal(true);
    setSelectedAdaccount(adaccount);
  };

  return (
    <>
      <TableRow key={adaccount.id}>
        <TableCell>
          <div className="flex-col">
            <p className="text-sm font-normal leading-6 text-gray-900">
              {adaccount.name}
            </p>
            {adaccount.gads_manager && (
              <p className="text-sm font-normal leading-6 text-gray-400">
                Manager
              </p>
            )}
          </div>
        </TableCell>
        <TableCell>
          <SecondaryButton
            text={"remove"}
            handleClick={() => handleButtonClick(adaccount)}
          />
        </TableCell>
      </TableRow>
    </>
  );
}
