import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "../UI/buttons/PrimaryButton";
import InputField from "./InputField";

export default function OrganizationForm({
  addOrganization,
  updateOrganization,
  data = null,
  setOpen = null,
}) {
  const navigate = useNavigate();
  const initialFormData = {
    name: "",
    // link: "",
    // Add more fields here if necessary, e.g., date, location, etc.
    // date: '',
    // location: '',
    // ...
  };
  const [formData, setFormData] = useState(data || initialFormData);

  useEffect(() => {
    // Update the form state if the data prop changes
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("we here in onSubmit");
    let requestBody = {
      ...formData,
    };
    // requestBody.artist = artistId;
    //   if (formData.assigned_to)
    //     requestBody.assigned_to = formData.assigned_to.id;
    //   if (formData.category) requestBody.category = formData.category.id;
    //   if (formData.status) requestBody.status = formData.status.id;
    let res = data
      ? await updateOrganization(requestBody)
      : await addOrganization(requestBody);
    console.log("res: ", res);
    if (setOpen) setOpen(false);
  };

  return (
    <div className="m-1 px-4 py-2 border rounded-md">
      <form onSubmit={handleSubmit}>
        {/* <h2 className="m-4 font-bold text-gray-700">Create a new show</h2> */}
        <div className="py-2">
          <InputField
            label="Name"
            type="text"
            name="name"
            value={formData.name || ""}
            onChange={handleChange}
          />
          {/* <InputField
            label="Link"
            type="text"
            name="link"
            value={formData.link || ""}
            onChange={handleChange}
          /> */}
          {/* Include additional input fields here if needed */}
          <div className="pt-4">
            <PrimaryButton text="Save" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
}
