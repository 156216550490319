import { useContext, Fragment, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/20/solid";
import { HeartIcon } from "@heroicons/react/24/outline";

import { UserContext } from "../../pages/forFans/FanLayoutContext";
import SidebarList from "./SidebarList";
import TabContainer from "./TabContainer";
import TabBubble from "./TabBubble";
import Tooltip from "../Tooltip";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { HomeIcon } from "@heroicons/react/24/outline";
import PrimaryButton from "../UI/buttons/PrimaryButton";
import SecondaryButton from "../UI/buttons/SecondaryButton";
import SidebarContextList from "./SidebarContextList";

const tabItems = [
  { id: 1, name: "Artists" },
  { id: 2, name: "Releases" },
  { id: 3, name: "Playlists" },
];

export default function Sidebar({ data, token, createPlaylist }) {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("Artists");

  const handleButtonClick = (type) => {
    setSelectedType(type);
  };

  const handlePlaylistCreateClick = async (e) => {
    e.stopPropagation();

    const playlist = await createPlaylist();
    console.log("playlist", playlist);
    if (playlist?.id) {
      navigate(`/playlists/${playlist.id}`);
    }
  };

  return (
    <>
      <div className="w-full border-e">
        {/* sidebar heading */}
        <div className="flex flex-col gap-1 pt-2 pb-4">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `flex justify-start items-center gap-2 px-2 py-2 font-bold transition duration-300 underline-offset-2 hover:text-indigo-600 hover:underline ${
                isActive ? "text-indigo-600" : "text-gray-600"
              }`
            }
            end
          >
            <HomeIcon className="h-6 w-6 " />
            <h2 className="text-md font-medium">home</h2>
          </NavLink>

          <NavLink
            to="/search/"
            className={({ isActive }) =>
              `flex justify-start items-center gap-2 px-2 py-2 font-bold transition duration-300 underline-offset-2 hover:text-indigo-600 hover:underline ${
                isActive ? "text-indigo-600" : "text-gray-600"
              }`
            }
            end
          >
            <MagnifyingGlassIcon className="h-6 w-6" />
            <h2 className="text-md font-medium">search</h2>
          </NavLink>
          <div className="flex justify-between items-center pb-2">
            <NavLink
              to="/library/"
              className={({ isActive }) =>
                `flex justify-start items-center gap-2 px-2 py-2 font-bold transition duration-300 underline-offset-2 hover:text-indigo-600 hover:underline ${
                  isActive ? "text-indigo-600" : "text-gray-600"
                }`
              }
            >
              <HeartIcon className="h-6 w-6" />
              <h3 className="text-md">my collection</h3>
            </NavLink>
            <div className="px-1">
              <Tooltip text="Create a new playlist">
                <button type="button" onClick={handlePlaylistCreateClick}>
                  <PlusIcon className="h-6 w-6 text-gray-800 rounded-sm hover:bg-indigo-200" />
                </button>
              </Tooltip>
            </div>
          </div>

          <hr className="py-2" />

          <div
            className={
              "flex flex-row justify-start items-center gap-2 px-2 font-medium"
            }
          >
            {/* <MagnifyingGlassIcon className="h-6 w-6" /> */}
            <h2 className="text-sm text-gray-500">recently played</h2>
          </div>
        </div>

        {/* my collection */}
        <div className="flex flex-col">
          {/* <div className="flex justify-between items-center">
            <NavLink
              to="/library/"
              className={({ isActive }) =>
                `flex justify-start items-center gap-2 px-2 py-2 font-bold transition duration-300 underline-offset-2 hover:text-indigo-600 hover:underline ${
                  isActive ? "text-indigo-600" : "text-gray-600"
                }`
              }
            >
              <HeartIcon className="h-6 w-6" />
              <h3 className="text-md">my collection</h3>
            </NavLink>
            <div className="px-1">
              <Tooltip text="Create a new playlist">
                <button type="button" onClick={handlePlaylistCreateClick}>
                  <PlusIcon className="h-6 w-6 text-gray-800 rounded-sm hover:bg-indigo-200" />
                </button>
              </Tooltip>
            </div>
          </div> */}
          {/* tabs */}
          {/* <div className="px-2 pt-2">
            <TabContainer
              items={tabItems}
              selected={selectedType}
              handleButtonClick={handleButtonClick}
            >
              {tabItems &&
                tabItems.map((item) => (
                  <TabBubble
                    key={item.id}
                    item={item}
                    selected={selectedType}
                    handleButtonClick={handleButtonClick}
                  />
                ))}
            </TabContainer>
          </div> */}

          <div className="flex flex-grow h-full">
            <div className="flex flex-col w-full h-[28rem] px-2 overflow-y-auto">
              {data?.data?.recents?.length > 0 ? (
                <SidebarContextList contexts={data.data.recents} />
              ) : null}
              {/* {selectedType === "Artists" ? (
                data?.data?.artists?.length > 0 ? (
                  <SidebarList data={data.data.artists} type="artists" />
                ) : (
                  <p className="text-sm text-gray-500">
                    No artists favorited yet
                  </p>
                )
              ) : null}

              {selectedType === "Playlists" ? (
                data?.data?.playlists?.length > 0 ? (
                  <SidebarList data={data.data.playlists} type="playlists" />
                ) : (
                  <p className="text-sm text-gray-500">
                    No playlists favorited yet
                  </p>
                )
              ) : null}

              {selectedType === "Releases" ? (
                data?.data?.releases?.length > 0 ? (
                  <SidebarList data={data.data.releases} type="releases" />
                ) : (
                  <p className="text-sm text-gray-500">
                    No releases favorited yet
                  </p>
                )
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
