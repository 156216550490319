export default class ProjectHomeViewModel {
  constructor(token, projectId, state, updateState) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchProject = async () => {
    return await this._get(`api/v1/projects/${this.projectId}`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchProject()]).then((values) => {
      return {
        project: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  deleteProject = async (project) => {
    try {
      const response = await fetch(
        this._createUrl(`api/v1/projects/${project.id}/`),
        {
          method: "DELETE",
          headers: this._standardActionHeaders(),
        }
      );

      if (!response.status === 204) {
        // If the response is not ok, throw an error
        throw new Error(
          `Failed to delete task, status code: ${response.status}`
        );
      }

      this._callUpdateState();
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error deleting task", error);
      return false; // Return false to indicate failure
    }
  };
}
