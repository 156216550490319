import TableCell from "../../../../../components/layout/tables/TableCell";
import TableRow from "../../../../../components/layout/tables/TableRow";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

const AdaccountsModalRow = ({ adaccount, cols, addAdaccountToProject }) => {
  return (
    <TableRow cols={cols}>
      <TableCell>{adaccount.name}</TableCell>
      <TableCell>{adaccount.platform_parent_name}</TableCell>
      <TableCell>
        {adaccount.is_added_to_project ? (
          "added"
        ) : (
          <PrimaryButton
            text={"Add to project"}
            handleClick={() => addAdaccountToProject(adaccount.id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default AdaccountsModalRow;
