import { useState, useEffect } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Pagination from "../../../../components/Pagination";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Artists() {
  const { token } = useOutletContext();
  const [artists, setArtists] = useState([]);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;
  console.log("baseURL", baseURL);

  const handleChange = (event) => {
    setQuery(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetch(
      `${baseURL}api/v1/artists/?q=${query}&page=${currentPage}&page_size=${pageSize}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Log the error message body
          response.json().then((json) => console.error(json));
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        console.log(data);
        setArtists(data.results); // Assuming 'results' contains the list of projects
        setTotalPages(Math.ceil(data.count / pageSize));
      });
  }, [token, query, currentPage, pageSize]);

  const handleClick = (artistId) => {
    navigate(`/artists/${artistId}`);
  };

  return (
    <div className="container mx-auto px-4 py-20">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Artists
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              The full list of your artists.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {/* <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            + Create
          </button> */}
          </div>
        </div>

        {/* search bar */}
        <div className="flex items-center justify-center py-4">
          <div className="w-full">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                id="search"
                onChange={handleChange}
                name="q"
                className="block w-full rounded-full border-0 bg-white py-2 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Search artists"
                type="search"
              />
            </div>
          </div>
        </div>

        {/* artists table */}
        <div className="-mx-4 mt-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
                {/* <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Artist
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Type
                </th> */}
                {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {artists &&
                artists.map((artist, artistIdx) => (
                  <tr
                    key={artist.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleClick(artist.id)}
                  >
                    <td
                      className={classNames(
                        artistIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                      )}
                    >
                      <div className="flex items-center gap-4 font-medium text-gray-900">
                        {artist?.spotify_profile_pic_url ? (
                          <img
                            src={artist.spotify_profile_pic_url}
                            alt=""
                            className="h-8 w-8 rounded-full"
                          />
                        ) : (
                          <div className="h-8 w-8 rounded-full bg-gray-100"></div>
                        )}

                        {artist.name}
                      </div>

                      {artistIdx !== 0 ? (
                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                      ) : null}
                    </td>
                    {/* <td
                      className={classNames(
                        artistIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {artist.name}
                    </td>
                    <td
                      className={classNames(
                        projectIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {project.type_name}
                    </td> */}
                    {/* <td
                      className={classNames(
                        artistIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                      )}
                    >
                      <Link
                        to={`/artists/${artist.id}`}
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      >
                        Select<span className="sr-only">, {artist.name}</span>
                      </Link> */}
                    {/* <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    disabled={project.isCurrent}
                  >
                    Select<span className="sr-only">, {project.name}</span>
                  </button> */}
                    {/* {artistIdx !== 0 ? (
                        <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                      ) : null}
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* pagination component */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}
