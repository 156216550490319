export default function Avatar({ imageUrl, size = 6 }) {
  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt=""
          className={`h-${size} w-${size} flex-shrink-0 rounded-full`}
        />
      ) : (
        <div
          className={`h-${size} w-${size} flex-shrink-0 rounded-full bg-gray-300`}
        ></div>
      )}
    </>
  );
}
