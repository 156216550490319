export default class OverviewViewModel {
  constructor(token, projectId, state, updateState, searchParams) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    const queryParams = new URLSearchParams(this.searchParams).toString();
    return `${this.url_begin}${path}?${queryParams}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      //   cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/analytics/views/creative/`,
      true
    );
  };

  _fetchInitialProjectCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchProjectState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialProjectCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  updateSearchParams = async (newSearchParams) => {
    this.searchParams = newSearchParams;
    this.state = await this._fetchInitialProjectCalls();
    this._callUpdateState();
    this.fetchAds();
  };

  sortData = (data, key, ascending = true) => {
    return data.slice().sort((a, b) => {
      if (a[key] < b[key]) return ascending ? -1 : 1;
      if (a[key] > b[key]) return ascending ? 1 : -1;
      return 0;
    });
  };

  // sort below (and delete?)

  fetchAds = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/get-ads-labels/`,
      false
    )
      .then(async (response) => {
        let res = await response;

        // update asset_label and ad_type key names to camelCase
        res.ads = res.ads.map((ad) => {
          ad.assetLabel = ad.asset_label;
          ad.adType = ad.ad_type;
          const { asset_label, ad_type, ...adTrimmed } = ad;
          return adTrimmed;
        });

        this.state.data.ads = res.ads;
        this.state.data.assetLabels = res.asset_labels;
        this.state.data.adTypes = res.ad_types;
        this._callUpdateState();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _post = async (path, body) => {
    return await fetch(this._createUrl(path), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createAssetLabel = async (adId, assetLabelName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-asset-label/`,
      {
        ad_id: adId,
        asset_label_name: assetLabelName,
      }
    )
      .then((res) => {
        let assetLabel = res;

        // Add the new audience to the state
        this.state.data.assetLabels.push(assetLabel);

        // Update the adgroup with the new audience
        this.state.data.ads = this.state.data.ads.map((ad) => {
          if (ad.id === adId) {
            ad.assetLabel = assetLabel;
          }
          return ad;
        });
        this._callUpdateState();
        return assetLabel;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addAssetLabelToAd = async (adId, assetLabelId) => {
    return await this._post(`api/v1/ads/${adId}/add-asset-label/`, {
      asset_label_id: assetLabelId,
    })
      .then((res) => {
        let ad = res;
        this.state.data.ads = this.state.data.ads.map((a) => {
          if (a.id === ad) {
            a = ad;
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createAdType = async (adId, adTypeName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-ad-type/`,
      {
        ad_id: adId,
        ad_type_name: adTypeName,
      }
    )
      .then((res) => {
        let adType = res;

        // Add the new audience to the state
        this.state.data.adTypes.push(adType);

        // Update the adgroup with the new audience
        this.state.data.ads = this.state.data.ads.map((ad) => {
          if (ad.id === adId) {
            ad.adType = adType;
          }
          return ad;
        });
        this._callUpdateState();
        return adType;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addAdTypeToAd = async (adId, adTypeId) => {
    return await this._post(`api/v1/ads/${adId}/add-ad-type/`, {
      ad_type_id: adTypeId,
    })
      .then((res) => {
        let ad = res;
        this.state.data.ads = this.state.data.ads.map((a) => {
          if (a.id === ad) {
            a = ad;
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
}
