import { useState } from "react";

// generic
import TabsNonNav from "../../../../../components/TabsNonNav";
// feature specific
import Meta from "../../../../../features/forArtists/organizations/data/meta/Index";
import Tiktok from "../../../../../features/forArtists/organizations/data/tiktok/Index";
import GAds from "../../../../../features/forArtists/organizations/data/gads/Index";

export default function Index() {
  const [selectedTab, setSelectedTab] = useState("Meta Ads");

  const tabs = [
    {
      name: "Meta Ads",
      component: <Meta />,
    },
    {
      name: "Google Ads",
      component: <GAds />,
    },
    {
      name: "Tiktok Ads",
      component: <Tiktok />,
    },
  ];

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col gap-2 py-4">
          <h1 className="text-5xl font-bold">Data</h1>
          <p className="font-light text-gray-500">
            Connect your data sources here to make it available for use in
            projects.
          </p>
        </div>

        <div className="py-4">
          <TabsNonNav
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        <div>
          {tabs
            .filter((tab) => tab.name === selectedTab)
            .map((tab) => (
              <div key={tab.name}>{tab.component}</div>
            ))}
        </div>
      </div>
    </>
  );
}
