import Tile from "../../images/Tile";
import PlayButton from "../releases/PlayButton";
import FavoriteIcon from "../releases/FavoriteIcon";
import ReleaseMenu from "../releases/ReleaseMenu";
import PlaylistMenu from "./PlaylistMenu";

export default function PlaylistHeader({
  playlist,
  handleEditClick,
  handleClick,
  isPlaying,
  contextURI,
  playingContextURI,
  isFavorite,
  favoriteRelease,
}) {
  const isLongName = playlist.name.length > 30;

  return (
    <>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:grid md:grid-cols-[auto_1fr] gap-4 items-center">
          <Tile
            imageUrl={playlist?.artwork || null}
            handleClick={handleEditClick}
            alt={playlist.name}
          />
          <div className="w-80 md:w-4/5 md:mx-4 md:mt-0 md:p-4">
            {/* <p className="text-gray-400">{data.release.type.name}</p> */}
            <h1
              onClick={handleEditClick}
              className={`text-2xl md:text-6xl font-bold text-gray-800 hover:cursor-pointer ${
                isLongName ? "text-xl md:text-4xl" : ""
              }`}
            >
              {playlist.name}
            </h1>
            <p className="text-gray-400">
              created by: {playlist.is_mine ? "me" : playlist.created_by}
            </p>

            <div className="flex items-center gap-3 pt-1">
              <PlayButton
                handleClick={handleClick}
                isPlaying={isPlaying}
                contextURI={contextURI}
                playingContextURI={playingContextURI}
              />
              {!playlist.is_mine && (
                <FavoriteIcon
                  isFavorite={isFavorite}
                  favoriteRelease={favoriteRelease}
                />
              )}

              <PlaylistMenu playlistId={playlist.id} darkStyle={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
