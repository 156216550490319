import { useEffect, useState } from "react";
import { CheckCircleIcon as CheckCircleFilled } from "@heroicons/react/24/solid";
import {
  XCircleIcon,
  CheckCircleIcon as CheckCircleOutline,
} from "@heroicons/react/24/outline";

// feature specific
import TaskListCell from "../TaskListCell";

export default function SubtaskListItem({
  subtask,
  updateSubtask,
  removeSubtask,
}) {
  //   let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isCompleted, setIsCompleted] = useState(subtask.completed);
  const [formData, setFormData] = useState({
    id: subtask.id,
    title: subtask.title,
    task: subtask.task,
    completed: subtask.completed,
  });

  useEffect(() => {
    setFormData({
      id: subtask.id,
      title: subtask.title,
      task: subtask.task,
      completed: subtask.completed,
    });
    setIsCompleted(subtask.completed);
  }, [subtask]);

  // hover state for the complete icon
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdate = async () => {
    console.log("formData: ", formData);
    const taskData = {
      ...formData,
      //   assigned_to_id: formData.assigned_to?.id,
    };
    const updated = await updateSubtask(taskData);
    if (updated) {
      console.log("Task updated successfully");
    }
  };

  const handleTaskCompletion = async (e) => {
    e.stopPropagation();

    // Toggle the completed state
    const newCompletedState = !isCompleted;
    const updatedTaskValue = { ...formData, completed: newCompletedState };

    setFormData(updatedTaskValue);

    // Use updatedTaskValue for the update
    const updated = await updateSubtask(updatedTaskValue);
    if (updated) {
      console.log("Task updated successfully");
      setIsCompleted(newCompletedState);
    }
  };

  return (
    <>
      <div
        className={`grid grid-cols-1 items-center ${
          isCompleted ? "bg-gray-50" : ""
        } text-gray-800 group`}
      >
        <TaskListCell colClass={`col-span-1`}>
          <div className="flex items-center gap-2 w-full px-2">
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleTaskCompletion}
              className="cursor-pointer h-5 w-5"
            >
              {isHovered || isCompleted ? (
                <CheckCircleFilled className="text-green-600" />
              ) : (
                <CheckCircleOutline className="text-gray-500" />
              )}
            </div>
            <input
              name="title"
              value={formData.title}
              onClick={(e) => e.stopPropagation()}
              onChange={handleChange}
              onBlur={handleUpdate}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              className="block w-full my-1 px-2 py-1 bg-transparent rounded-sm border border-transparent group-hover:bg-white group-hover:cursor-text"
            />
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => removeSubtask(subtask.id)}
              className="cursor-pointer h-5 w-5"
            >
              {isHovered ? (
                <XCircleIcon className="text-red-600" />
              ) : (
                <XCircleIcon className="text-gray-500" />
              )}
            </div>
          </div>
        </TaskListCell>
      </div>
    </>
  );
}
