import { useOutletContext } from "react-router-dom";

// feature specific
import Tour from "./projectTypes/Tour";
import Release from "./projectTypes/Release";
import Merch from "./projectTypes/Merch";

export default function ProjectDataRoot() {
  const { project } = useOutletContext();
  let projectType = project?.type_name;

  if (projectType === "Tour") {
    return <Tour typeObj={"Show"} />;
  } else if (projectType === "Release") {
    return <Release typeObj={"Release"} />;
  } else if (projectType === "Merch") {
    return <Merch typeObj={"Collection"} />;
  } else {
    return <div>Project type not found</div>;
  }
}
