export default class ReleaseIngestionViewModel {
  constructor(token, releaseId, state, updateState) {
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.releaseId = releaseId;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      `api/v1/ingestion/releases/${this.releaseId}`,
      false
    );
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  updateRelease = async (release) => {
    console.log("release", release);
    return await fetch(
      this._createUrl(`api/v1/releases/${this.releaseId}/ingestion/`),
      {
        method: "PATCH",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(release),
      }
    )
      .then(async (response) => {
        console.log(response);
        let data = await response.json();
        console.log("data: ", data);
        this.state.data.release = data;
        this._callUpdateState();
        return data;
      })
      .catch((error) => {
        console.error("Error updating release", error);
        return false;
      });
  };

  updateReleaseTracks = async (tracks) => {
    console.log("tracks in the model view method", tracks);
    return await fetch(
      this._createUrl(`api/v1/releases/${this.releaseId}/tracks/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(tracks),
      }
    )
      .then(async (response) => {
        console.log(response);
        let data = await response.json();
        console.log("data: ", data);
        this.state.data.tracks = data;
        this._callUpdateState();
        return data;
      })
      .catch((error) => {
        console.error("Error updating release", error);
        return false;
      });
  };
}
