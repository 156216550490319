import { useState } from "react";

// generic
import { LoadingComponent } from "../../../components/loading/LoadingScreen";

// feature specific
import TaskTableRow from "./TaskTableRow";
import HeaderItemSort from "../../../components/layout/tables/HeaderItemSort";
import TableContainer from "../../../components/layout/tables/TableContainer";
import TableHeader from "../../../components/layout/tables/TableHeader";
import TableBody from "../../../components/layout/tables/TableBody";
import TableRow from "../../../components/layout/tables/TableRow";
import TableCell from "../../../components/layout/tables/TableCell";

export default function TasksTable({
  tasks,
  sortTasks,
  users,
  taskCategories,
  taskStatuses,
  selectTask,
  updateTask,
  deleteTask,
  fetchMoreTasks,
  global = false,
}) {
  const [sortedHeader, setSortedHeader] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSort = (key) => {
    let prevKey = sortedHeader;

    sortTasks(key);
    setSortedHeader(key);

    if (prevKey === key) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  const handleScroll = async (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      // if there are more tasks to get
      if (tasks.count > tasks.results.length) {
        // only fetch more tasks if not already loading (avoid duplication)
        if (!isLoading) {
          setIsLoading(true);
          const res = await fetchMoreTasks();
          if (res) {
            setIsLoading(false);
          } else {
            console.log("error getting more tasks");
            setIsLoading(false);
          }
        }
      }
    }
  };

  const headerItems = [
    {
      id: 1,
      name: "title",
      label: "Task name",
      display: `w-3/4 md:w-1/2`,
    },
    {
      id: 2,
      name: "project",
      label: "Project",
      display: `hidden ${global && "md:table-cell"}`,
    },
    {
      id: 3,
      name: "assigned_to",
      label: "Assigned to",
      display: "hidden sm:table-cell",
    },
    {
      id: 4,
      name: "category",
      label: "Category",
      display: "hidden lg:table-cell",
    },
    {
      id: 5,
      name: "status",
      label: "Status",
      display: "hidden lg:table-cell",
    },
    {
      id: 6,
      name: "due_datetime",
      label: "Due",
      display: "hidden md:table-cell",
    },
  ];

  return (
    <>
      <TableContainer fixed={true} handleScroll={handleScroll}>
        <TableHeader
          cols={headerItems}
          handleSort={handleSort}
          sortedHeader={sortedHeader}
          sortAscending={sortAscending}
        />
        <TableBody>
          {tasks?.results?.length > 0 &&
            tasks.results.map((task, index) => {
              return (
                <TaskTableRow
                  key={task.id}
                  task={task}
                  selectTask={selectTask}
                  users={users}
                  taskCategories={taskCategories}
                  taskStatuses={taskStatuses}
                  updateTask={updateTask}
                  deleteTask={deleteTask}
                  global={global}
                />
              );
            })}
        </TableBody>
      </TableContainer>
      {isLoading && <LoadingComponent />}
    </>
  );
}
