import { useEffect, useState } from "react";

import ViewModel from "../../models/forFans/profile/libraryReleasesViewModel";
import ListGroup from "../../components/dataDisplay/lists/ListGroup";
import LoadingScreen from "../../components/loading/LoadingScreen";
import ReleaseList from "../../components/forFans/artists/ReleaseList";

export default function LibraryReleases() {
  const token = localStorage.getItem("token");
  const [libraryReleaseViewData, setLibraryReleasesViewData] = useState();
  const [libraryViewModel, setLibraryViewModel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const viewModel = new ViewModel(token, libraryReleaseViewData, (state) =>
      setLibraryReleasesViewData(state)
    );
    setLibraryViewModel(viewModel);
  }, [token, libraryReleaseViewData]);

  useEffect(() => {
    if (!libraryReleaseViewData) {
      if (libraryViewModel) {
        libraryViewModel.fetchState();
      }
    }
    setLoading(false);
  }, [libraryViewModel, libraryReleaseViewData]);

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <>
      {libraryReleaseViewData?.data?.releases &&
        console.log(libraryReleaseViewData.data.releases)}
      {libraryReleaseViewData?.data?.releases && (
        <ReleaseList
          releases={libraryReleaseViewData.data.releases}
          showArtist={true}
          setLoading={setLoading}
        />
      )}
    </>
  );
}
