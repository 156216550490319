import { Fragment, useState } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import { XMarkIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";

export default function SlideOver({
  open,
  setOpen,
  children,
  object,
  objectName,
  handleDelete,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex flex-row items-center justify-between">
                        <Dialog.Title className="items-center text-base font-semibold leading-6 text-gray-900">
                          {object?.name || objectName}
                        </Dialog.Title>
                        <div className="flex items-center space-x-3">
                          {handleDelete && (
                            <Menu
                              as="div"
                              className="items-center relative inline-block text-left"
                            >
                              <Menu.Button
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                              >
                                <EllipsisVerticalIcon className="h-6 w-6 text-gray-400 hover:text-gray-500" />
                              </Menu.Button>
                              <Transition
                                show={isMenuOpen}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          className={`${
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                          onClick={async () => {
                                            if (handleDelete) {
                                              await handleDelete();
                                              setIsMenuOpen(false);
                                              setOpen(false);
                                            }
                                          }}
                                        >
                                          Delete {objectName}
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          )}
                          <button
                            type="button"
                            className="items-center relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
