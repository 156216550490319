export default function PageSubHeading({
  title,
  handleClick,
  buttonText = "+ Create",
}) {
  return (
    <div className=" pb-5 sm:flex sm:items-center sm:justify-between">
      <div className="flex flex-col gap-y-6">
        <h2 className="text-2xl font-bold leading-6 text-gray-900">{title}</h2>
      </div>
      <div className="mt-3 sm:ml-4 sm:mt-0">
        <button
          type="button"
          onClick={handleClick}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}
