// generic
import StackedListItem from "../../../../../components/dataDisplay/lists/StackedListItem";
import StackedListContainer from "../../../../../components/dataDisplay/lists/StackedListContainer";
import Modal from "../../../../../components/UI/modals/Modal";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";

export default function AdaccountModal({
  title,
  isLoading,
  openModal,
  setOpenModal,
  adaccountOptions,
  addAdaccount,
}) {
  const handleButtonClick = async (adaccount) => {
    await addAdaccount(adaccount);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Modal open={openModal} setOpen={setOpenModal}>
      <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      <StackedListContainer>
        {adaccountOptions ? (
          adaccountOptions?.map((item) => (
            <StackedListItem
              key={item.id}
              item={item}
              btnPrimaryText={"+ Add"}
              btnPrimaryAction={handleButtonClick}
            />
          ))
        ) : (
          <div>No adaccounts available.</div>
        )}
      </StackedListContainer>
    </Modal>
  );
}
