import { useEffect, useState } from "react";

import ViewModel from "../../../models/forFans/profile/libraryPlaylistsViewModel";
import ListGroup from "../../../components/dataDisplay/lists/ListGroup";
import LoadingScreen from "../../../components/loading/LoadingScreen";
import ReleaseList from "../../../components/forFans/artists/ReleaseList";
import PrimaryButton from "../../../components/UI/buttons/PrimaryButton";

export default function LibraryPlaylists() {
  const token = localStorage.getItem("token");
  const [libraryPlaylistsViewData, setLibraryPlaylistsViewData] = useState();
  const [libraryViewModel, setLibraryViewModel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const viewModel = new ViewModel(token, libraryPlaylistsViewData, (state) =>
      setLibraryPlaylistsViewData(state)
    );
    setLibraryViewModel(viewModel);
  }, [token, libraryPlaylistsViewData]);

  useEffect(() => {
    if (!libraryPlaylistsViewData) {
      if (libraryViewModel) {
        libraryViewModel.fetchState();
      }
    }
    setLoading(false);
  }, [libraryViewModel, libraryPlaylistsViewData]);

  const handleCreateClick = async () => {
    const playlist = await libraryViewModel.createPlaylist();
    if (playlist?.id) {
      window.location.href = `/playlists/${playlist.id}`;
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <PrimaryButton text={"+ Create new"} handleClick={handleCreateClick} />
      {libraryPlaylistsViewData?.data && (
        <ReleaseList
          releases={libraryPlaylistsViewData.data}
          setLoading={setLoading}
          isRelease={false}
        />
      )}
    </>
  );
}
