import { useState, useEffect } from "react";
import { useSearchParams, useOutletContext, useParams } from "react-router-dom";

// generic
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/adcampaigns/adgroups/viewmodel";
import Table from "../../../../../features/forArtists/projects/adcampaigns/adgroups/Table";

export default function ProjectAdgroups() {
  const { token } = useOutletContext();
  let { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaign_id");
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState(campaignId).then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token, projectId, campaignId]);

  console.log("data: ", data);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return <>{data && <Table data={data} />}</>;
}
