import { useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Sector } from "recharts";
import { customizedDonutLabel } from "./CustomizedDonutLabel";

const COLORS = ["#662e91", "#eeeeff"];
const HIGHLIGHT_COLORS = ["#885faa", "#f2f5ff"];

export default function DonutCompletedScorecard({ data, format }) {
  const [activeIndex, setActiveIndex] = useState(-1); // Track the hovered segment

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
  };

  // Extract the first value for the central label
  const firstValue = data && data.length > 0 ? data[0].value : 0;
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);
  const firstPercentage =
    totalValue > 0 ? ((firstValue / totalValue) * 100).toFixed(1) : 0;

  return (
    <div style={{ position: "relative" }}>
      <PieChart
        width={500}
        height={200}
        margin={{ top: 10, right: 5, bottom: 10, left: 5 }}
      >
        <Pie
          data={data}
          activeShape={renderActiveShape}
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          activeIndex={activeIndex}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                index === activeIndex
                  ? HIGHLIGHT_COLORS[index % HIGHLIGHT_COLORS.length]
                  : COLORS[index % COLORS.length]
              }
            />
          ))}
        </Pie>
      </PieChart>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span className="text-lg text-gray-700">{format(firstValue)}</span>
      </div>
    </div>
  );
}

function getTooltipPosition(data, activeIndex, chartWidth, chartHeight) {
  if (activeIndex === -1) return { top: 0, left: 0 };

  const RADIAN = Math.PI / 180;
  const radius = 80; // Same as your outerRadius in Pie
  const { startAngle, endAngle } = data[activeIndex];
  const midAngle = (startAngle + endAngle) / 2; // Mid point of the active segment
  const x = chartWidth / 2 + radius * Math.cos(-midAngle * RADIAN);
  const y = chartHeight / 2 + radius * Math.sin(-midAngle * RADIAN);

  return { left: x, top: y };
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;

  // Adjust the angle slightly for the start point
  const adjustedAngle = midAngle + 50; // Example adjustment, could be more complex based on desired effect
  const adjustedCos = Math.cos(-RADIAN * adjustedAngle);
  const adjustedSin = Math.sin(-RADIAN * adjustedAngle);

  // start of the line
  const sx = cx + (outerRadius + 10) * adjustedCos;
  const sy = cy + (outerRadius + 10) * adjustedSin;

  // end of the line
  const ex = sx + (adjustedCos >= 0 ? 1 : -1) * 25;
  const ey = sy;

  // this is the text anchor
  const textAnchor = adjustedCos >= 0 ? "start" : "end";

  return (
    <g>
      {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (adjustedCos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${payload.name}`}</text>
      <text
        x={ex + (adjustedCos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};
