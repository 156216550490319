// feature specific
import OrganizationTasksView from "../../../../../features/forArtists/organizations/tasks/Index";

export default function ProjectTasks() {
  return (
    <>
      <div>
        <OrganizationTasksView />
      </div>
    </>
  );
}
