// generic
import TableRow from "../../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../../components/layout/tables/TableCell";
import SelectSearchOrCreate from "../../../../../../components/forms/SelectSearchOrCreate";

export default function ModalRow({
  cols,
  index,
  adgroup,
  audiences,
  selected,
  handleSelectChange,
  createAudience,
  addAudienceToAdgroup,
}) {
  return (
    <TableRow cols={cols}>
      <TableCell>
        <input
          id={adgroup.id}
          name={`selected-${index}`}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          checked={selected.includes(adgroup.id.toString())}
          onChange={(e) => handleSelectChange(e, index)} // Use `onChange` here
        />
      </TableCell>

      <TableCell>{adgroup.name}</TableCell>
      <TableCell>
        <SelectSearchOrCreate
          initialOption={adgroup.audience}
          options={audiences}
          createNew={(audienceName) => {
            createAudience(adgroup.id, audienceName);
          }}
          updateObject={(audience) => {
            addAudienceToAdgroup(adgroup.id, audience);
          }}
        />
      </TableCell>
    </TableRow>
  );
}
