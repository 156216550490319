import { useState, useEffect } from "react";
import { useSearchParams, useOutletContext, useParams } from "react-router-dom";

// generic
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/adcampaigns/campaigns/viewmodel";
import Table from "../../../../../features/forArtists/projects/adcampaigns/campaigns/Table";

export default function ProjectCampaigns() {
  const { token, selectedCampaigns, setSelectedCampaigns } = useOutletContext();
  let { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token, projectId]);

  const handleSelect = (campaignId, checked) => {
    console.log("campaignId: ", campaignId);
    console.log("checked: ", checked);

    if (!checked) {
      setSelectedCampaigns(
        selectedCampaigns.filter((campaign) => campaign !== campaignId)
      );
      return;
    }
    setSelectedCampaigns([...selectedCampaigns, campaignId]);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="-mx-4 mt-8 sm:-mx-0">
          {data && (
            <Table
              data={data}
              handleSelect={handleSelect}
              selectedCampaigns={selectedCampaigns}
            />
          )}
        </div>
      </div>
    </>
  );
}
