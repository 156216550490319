import { useOutletContext, useNavigate } from "react-router-dom";

import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";

export default function Ingestion() {
  const { token } = useOutletContext();
  const baseURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleReleaseCreate = () => {
    console.log("clicked");

    fetch(`${baseURL}api/v1/releases/create-new-default/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Log the error message body
          response.json().then((json) => console.error(json));
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        navigate(`releases/${data.id}`);
      });
  };

  return (
    <>
      <div className="lg:p-10 justify-center">
        <div className="flex items-center justify-start gap-2">
          <h1 className="text-5xl font-bold">Ingestion</h1>
        </div>
        <div className="py-4">
          <p>Create new releases here:</p>
        </div>
        <PrimaryButton handleClick={handleReleaseCreate} />
      </div>
    </>
  );
}
