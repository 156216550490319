import { useEffect, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export default function SimpleSearchBar({
  label = "Search",
  placeholder = "Search...",
  handleChange,
  debounceDelay = 300,
}) {
  const [typedValue, setTypedValue] = useState("");

  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      handleChange(typedValue);
    }, debounceDelay);

    return () => clearTimeout(handler);
  }, [typedValue, debounceDelay, handleChange]);

  const handleInputChange = (event) => {
    setTypedValue(event.target.value);
  };

  return (
    <>
      <div className="flex items-center justify-center py-4">
        <div className="w-full">
          <label htmlFor="search" className="sr-only">
            {label}
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              onChange={handleInputChange}
              name="q"
              className="block w-full rounded-full border-0 bg-white py-2 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={placeholder}
              type="search"
            />
          </div>
        </div>
      </div>
    </>
  );
}
