import { useState } from "react";
import { Link, useOutletContext, useNavigate } from "react-router-dom";

import PlayButton from "../releases/PlayButton";
import ReleaseTile from "../releases/ReleaseTile";

export default function ReleaseList({
  releases,
  setLoading,
  showArtist = false,
  isRelease = true,
}) {
  const { audioPlayerViewModel } = useOutletContext();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(audioPlayerViewModel.isPlaying);

  const handleClick = (id) => {
    console.log("handleClick");
    setLoading(true);
    window.scrollTo(0, 0);
    isRelease ? navigate(`/releases/${id}`) : navigate(`/playlists/${id}`);
  };

  // Add a function to handle play/pause click (Update as per your logic)
  const handlePlayPauseClick = async (e, id) => {
    e.stopPropagation(); // Prevent triggering the navigation
    // Play/pause logic here
    if (isRelease) {
      if (id !== audioPlayerViewModel.releaseId) {
        await audioPlayerViewModel.loadReleaseTracks(id);
      } else {
        audioPlayerViewModel.togglePlayPause();
      }
    } else {
      if (id !== audioPlayerViewModel.playlistId) {
        await audioPlayerViewModel.loadPlaylistTracks(id);
      } else {
        audioPlayerViewModel.togglePlayPause();
      }
    }
  };

  const handleArtistClick = (event, artistId) => {
    event.stopPropagation();
    window.scrollTo(0, 0);
    navigate(`/artists/${artistId}`);
  };

  return (
    <>
      {releases && releases.length > 0 && (
        <div className="">
          <ul className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {releases.map((release) => (
              <ReleaseTile
                key={release.id}
                release={release}
                setLoading={setLoading}
                showArtist={showArtist}
                isRelease={isRelease}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
