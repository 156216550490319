import { useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import {
  formatValue,
  formatLegend,
  formatColor,
  formatHoverColor,
} from "../../../utils/analyticsUtils";
import CustomizedBarLabel from "../CustomBarLabel";
import CustomTooltip from "../CustomTooltip";

export default function BarChartHorizontal({
  data,
  x_label,
  y_label,
  y1_label = null,
  domain,
  singleYAxis = false,
  stackedLabel = null,
  showPercentage = false,
}) {
  const [focusBar, setFocusBar] = useState(null);
  const [mouseLeave, setMouseLeave] = useState(true);

  return (
    <>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart
          // width={500}
          // height={300}
          data={data}
          barGap={0}
          barSize={60}
          // barCategoryGap={30}
          margin={{
            top: 20,
            right: 30,
            left: 25,
            bottom: 5,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
              setMouseLeave(false);
            } else {
              setFocusBar(null);
              setMouseLeave(true);
            }
          }}
        >
          <XAxis dataKey={x_label} />
          <YAxis
            yAxisId="left"
            orientation="left"
            //   stroke="#e34a63"
            minTickGap={50}
            tickFormatter={(tick) => formatValue(y_label, tick)}
            domain={domain}
          />
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                colors={{
                  [y_label]: formatColor(y_label),
                  [y1_label]: formatColor(y1_label),
                  [stackedLabel]: formatColor(stackedLabel),
                }}
                showPercentage={showPercentage}
              />
            }
          />
          <Legend
            verticalAlign="bottom"
            height={1}
            layout="vertical"
            formatter={(value) => formatLegend(value)}
          />
          <Bar
            yAxisId="left"
            dataKey={y_label}
            fill={formatColor(y_label)}
            label={
              stackedLabel ? null : (
                <CustomizedBarLabel
                  layout={"horizontal"}
                  format={(value) => formatValue(y_label, value)}
                />
              )
            }
            // minPointSize={5}
            stackId={y_label}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  focusBar === index || mouseLeave
                    ? formatColor(y_label)
                    : formatHoverColor(formatColor(y_label))
                }
                // for this, we make the hovered colour #2B5CE7, else its opacity decreases to 20%
              />
            ))}
          </Bar>

          {stackedLabel && (
            <Bar
              yAxisId="left"
              dataKey={stackedLabel}
              fill={formatColor(stackedLabel)}
              // label={
              //   <CustomizedBarLabel
              //     layout={"horizontal"}
              //     format={(value) => formatValue(stackedLabel, value)}
              //   />
              // }
              // minPointSize={5}
              stackId={y_label}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index || mouseLeave
                      ? formatColor(stackedLabel)
                      : formatColor(stackedLabel) === "#662e91"
                      ? "rgba(102,46,145, 0.8)"
                      : "rgba(227,74,99, 0.8)"
                  }
                  // for this, we make the hovered colour #2B5CE7, else its opacity decreases to 20%
                />
              ))}
            </Bar>
          )}

          {/* if y1_label exists */}
          {y1_label && (
            <Bar
              yAxisId={singleYAxis ? "left" : "right"}
              dataKey={y1_label}
              fill={formatColor(y1_label)}
              label={
                <CustomizedBarLabel
                  layout={"horizontal"}
                  format={(value) => formatValue(y1_label, value)}
                />
              }
              // minPointSize={5}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index || mouseLeave
                      ? formatColor(y1_label)
                      : formatColor(y1_label) === "#662e91"
                      ? "rgba(102,46,145, 0.8)"
                      : "rgba(227,74,99, 0.8)"
                  }
                />
              ))}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
