// generic
import SecondaryButton from "../../../../../../components/UI/buttons/SecondaryButton";
import SelectSearchOrCreate from "../../../../../../components/forms/SelectSearchOrCreate";

export default function Controls({
  selected,
  setSelected,
  audiences,
  createBulkAudience,
  addBulkAudienceToAdgroup,
}) {
  return (
    <div className="flex justify-start items-center gap-2">
      <h3 className="text-lg ps-10 pt-4 text-gray-700 font-bold">
        {selected.length} selected to update:
      </h3>
      <SelectSearchOrCreate
        options={audiences}
        createNew={createBulkAudience}
        updateObject={addBulkAudienceToAdgroup}
      />
      <SecondaryButton
        text={"Clear selected"}
        handleClick={() => setSelected([])}
      />
    </div>
  );
}
