import { useState, useEffect } from "react";
import { useParams, useOutletContext, useSearchParams } from "react-router-dom";

// generic
import BarChartHorizontal from "../../../../../components/analytics/charts/BarChartHorizontal";
import ToggleButtonGroup from "../../../../../components/analytics/ToggleButtonGroup";
import CreativeTable from "../../../../../components/analytics/CreativeTable";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import {
  displayAscending,
  formatValue,
} from "../../../../../utils/analyticsUtils";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/analytics/creativeViewModel";
import CreativeModal from "../../../../../features/forArtists/projects/analytics/labelling/creatives/CreativeModal";

const buttonLabels = [
  "Spend",
  "Impressions",
  "CPM",
  "Clicks",
  "CTR",
  "CPC",
  "Revenue",
  "ROAS",
];

const releaseButtonLabels = [
  "Spend",
  "Impressions",
  "CPM",
  "Clicks",
  "CTR",
  "CPC",
  // "Revenue",
  // "ROAS",
];

export default function ProjectAnalyticsCreative() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeButton, setActiveButton] = useState("ctr");

  // view model
  const { token, project } = useOutletContext();
  let { projectId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(
      token,
      projectId,
      data,
      (state) => {
        setData(state);
      },
      searchParams
    )
  );

  // for the data labelling
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Update search params to reflect active buttons
    setSearchParams({
      field: activeButton,
    });
  }, [activeButton, setSearchParams]);

  const handleButtonClick = (field) => {
    setActiveButton(field.toLowerCase());
  };

  const cols = [
    { header: "Name", key: "asset" },
    {
      header: "Spend",
      key: "spend",
      formatter: (value) => formatValue("spend", value),
    },
    {
      header: "Impressions",
      key: "impressions",
      formatter: (value) => formatValue("impressions", value),
    },
    {
      header: "CPM",
      key: "cpm",
      formatter: (value) => formatValue("cpm", value),
    },
    {
      header: "Clicks",
      key: "clicks",
      formatter: (value) => formatValue("clicks", value),
    },
    {
      header: "CTR",
      key: "ctr",
      formatter: (value) => formatValue("ctr", value),
    },
    {
      header: "CPC",
      key: "cpc",
      formatter: (value) => formatValue("cpc", value),
    },
    {
      header: "Revenue",
      key: "revenue",
      formatter: (value) => formatValue("revenue", value),
    },
    {
      header: "ROAS",
      key: "roas",
      formatter: (value) => formatValue("roas", value),
    },
  ];

  const releaseCols = [
    { header: "Name", key: "asset" },
    {
      header: "Spend",
      key: "spend",
      formatter: (value) => formatValue("spend", value),
    },
    {
      header: "Impressions",
      key: "impressions",
      formatter: (value) => formatValue("impressions", value),
    },
    {
      header: "CPM",
      key: "cpm",
      formatter: (value) => formatValue("cpm", value),
    },
    {
      header: "Clicks",
      key: "clicks",
      formatter: (value) => formatValue("clicks", value),
    },
    {
      header: "CTR",
      key: "ctr",
      formatter: (value) => formatValue("ctr", value),
    },
    {
      header: "CPC",
      key: "cpc",
      formatter: (value) => formatValue("cpc", value),
    },
    // {
    //   header: "Revenue",
    //   key: "revenue",
    //   formatter: (value) => formatValue("revenue", value),
    // },
    // {
    //   header: "ROAS",
    //   key: "roas",
    //   formatter: (value) => formatValue("roas", value),
    // },
  ];

  // Use useEffect to detect when the modal is being closed
  useEffect(() => {
    if (!isModalOpen) {
      viewModel.updateSearchParams(searchParams);
    }
  }, [isModalOpen, viewModel, searchParams]);

  const handleOpenModalClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {/* data labelling button */}
      <div className="py-2">
        <SecondaryButton text={""} handleClick={handleOpenModalClick}>
          Label ad data
        </SecondaryButton>
        <p className="text-sm text-gray-500 py-2">
          Add labels to your ad data to group and compare performance.
        </p>
      </div>

      <hr className="py-4" />

      <ToggleButtonGroup
        buttons={
          project.type_name === "Release" ? releaseButtonLabels : buttonLabels
        }
        activeButton={activeButton}
        onToggle={handleButtonClick}
      />

      <div
        className={`grid grid-cols-1 sm:grid-cols-8 gap-4 ${
          data?.data?.ad_assets?.length > 4 ? "" : ""
        }`}
      >
        <div className="sm:col-span-3 rounded-lg mt-8">
          <h2 className="py-4 text-2xl font-medium text-gray-600">
            Asset Type
          </h2>
          {/* chart */}
          {data?.data?.ad_types && (
            <BarChartHorizontal
              data={
                data.data.ad_types &&
                viewModel.sortData(
                  data.data.ad_types,
                  activeButton,
                  displayAscending(activeButton)
                )
              }
              x_label={"ad_type"}
              y_label={activeButton}
              // y1_label={activeButtons[1]}
            />
          )}
        </div>
        <div className="sm:col-span-5 rounded-lg mt-8">
          <h2 className="py-4 text-2xl font-medium text-gray-600">
            Top Assets
          </h2>
          {/* chart */}
          {data?.data?.ad_assets && (
            <BarChartHorizontal
              data={
                data.data.ad_assets &&
                viewModel
                  .sortData(
                    data.data.ad_assets,
                    activeButton,
                    displayAscending(activeButton)
                  )
                  .slice(0, 5) // only shows top 5 results
              }
              x_label={"asset"}
              y_label={activeButton}
              // y1_label={activeButtons[1]}
            />
          )}
        </div>
      </div>

      {/* stats table */}
      {data?.data?.ad_assets && (
        <div className="py-8">
          {data?.data?.ad_assets && (
            <CreativeTable
              data={data?.data?.ad_assets}
              columns={project.type_name === "Release" ? releaseCols : cols}
              sortData={(key, sortAscending) =>
                viewModel.sortData(data?.data?.ad_assets, key, sortAscending)
              }
              footerData={data?.data?.totals}
            />
          )}
        </div>
      )}

      <CreativeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}
