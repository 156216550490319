import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

// generic
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";
// feature specific
import ViewModel from "../../../../../models/forArtists/main/organizations/data/gads/viewmodel";
import Adaccounts from "../adaccounts/Index";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

export default function Index() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useOutletContext();
  const { organizationId } = useParams();

  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, organizationId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState().then(() => setIsLoading(false));
  }, [viewModel, token]);

  const handleRedirect = () => {
    window.open("/data/", "_blank");
  };

  const handleRefreshAssets = async () => {
    setIsLoading(true);
    await viewModel.refreshAssets();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!data?.googleUser?.google_user) {
    return (
      <div className="my-2">
        <p className="text-sm text-gray-500">
          (Re)Authenticate with your Google Ads User account to connect or make
          changes to adaccounts (refresh when done)
        </p>
        <SecondaryButton
          text="Connect to Google Ads"
          handleClick={handleRedirect}
        />
      </div>
    );
  }

  return (
    <>
      {data?.googleUser?.google_user && (
        <div className="pt-8">
          <PrimaryButton
            text="Refresh assets"
            handleClick={handleRefreshAssets}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      )}

      <div className="my-8">
        <Adaccounts
          adaccounts={data?.data?.google_adaccounts}
          adaccountOptions={data?.data?.gads_accounts_options}
          addAdaccount={async (adaccount) => {
            return await viewModel.addGadsAdaccount(adaccount);
          }}
          removeAdaccount={async (adaccount) => {
            return await viewModel.removeGadsAdaccount(adaccount);
          }}
          fetchAdaccountOptions={async () => {
            await viewModel.fetchGadsAdaccountOptions();
          }}
        />
      </div>
    </>
  );
}
