import { useEffect, useState } from "react";

import ViewModel from "../../models/forFans/profile/libraryArtistsViewModel";
import ListGroup from "../../components/dataDisplay/lists/ListGroup";
import LoadingScreen from "../../components/loading/LoadingScreen";
import ArtistTileList from "../../components/forFans/artists/ArtistTileList";

export default function Library() {
  const token = localStorage.getItem("token");
  const [libraryArtistsViewData, setLibraryArtistsViewData] = useState();
  const [libraryViewModel, setLibraryViewModel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const viewModel = new ViewModel(token, libraryArtistsViewData, (state) =>
      setLibraryArtistsViewData(state)
    );
    setLibraryViewModel(viewModel);
  }, [token, libraryArtistsViewData]);

  useEffect(() => {
    if (!libraryArtistsViewData) {
      if (libraryViewModel) {
        libraryViewModel.fetchState();
      }
    }
    setLoading(false);
  }, [libraryViewModel, libraryArtistsViewData]);

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <>
      {libraryArtistsViewData?.data?.artists && (
        <ArtistTileList
          artists={libraryArtistsViewData.data.artists}
          setLoading={setLoading}
        />
      )}
    </>
  );
}
