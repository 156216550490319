export default class AudioPlayerViewModel {
  constructor(token, updateState, defaultTracks = []) {
    this.token = token;
    this.updateState = updateState;
    this.contextURI = null;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.tracks = defaultTracks;
    this.currentSongIndex = 0;
    this.isPlaying = false;
    this.duration = 0;
    this.currentTime = 0;
    this.audioRef = new Audio();
    this._initializeAudioEvents();
    this.releaseId = null;
    this.artistId = null;
    this.playlistId = null;
    this.playTimeout = null;
    this.currentVolume = 0.8; // Default volume level (0 to 1)
    this.audioRef.volume = this.currentVolume; // Set the initial volume
    // Binding methods
    this.togglePlayPause = this.togglePlayPause.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleTimeSliderChange = this.handleTimeSliderChange.bind(this);
    this.loadNewTracks = this.loadNewTracks.bind(this);
    this.loadTracks = this.loadTracks.bind(this);
    this.startPlay = this.startPlay.bind(this);
    this.handleTrackClick = this.handleTrackClick.bind(this);
    this.changeTrack = this.changeTrack.bind(this);
    this.handleVolumeChange = this.handleVolumeChange.bind(this);
  }

  _initializeAudioEvents() {
    this.audioRef.addEventListener("loadedmetadata", () => {
      this.duration = this.audioRef.duration;
      this._callUpdateState();
    });
    this.audioRef.addEventListener("timeupdate", () => {
      this.currentTime = this.audioRef.currentTime;
      this._callUpdateState();
    });

    // Add event listener for when a track ends
    this.audioRef.addEventListener("ended", this.handleTrackEnd);
  }

  onStateChange = null;

  _callUpdateState = () => {
    this.updateState({
      tracks: this.tracks,
      currentSongIndex: this.currentSongIndex,
      isPlaying: this.isPlaying,
      duration: this.duration,
      currentTime: this.currentTime,
      currentVolume: this.currentVolume,
    });

    // Call the onStateChange function if it's defined
    if (this.onStateChange) {
      this.onStateChange();
    }
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchInitialData = async () => {
    const tracks = await this._get(
      `api/v1/artists/6ac6d95e-7530-4d5a-a39c-bf44ba160fdd/tracks/`,
      true
    );
    if (tracks) {
      this.loadDefaultTracks(tracks);
    }
  };

  async loadTracksFromContextURI(contextURI, trackIndex = 0) {
    let data;
    if (contextURI.startsWith("artist:")) {
      const artistId = contextURI.substring("artist:".length);
      data = await this.loadArtistTracks(artistId, contextURI, trackIndex);
    } else if (contextURI.startsWith("release:")) {
      const releaseId = contextURI.substring("release:".length);
      data = await this.loadReleaseTracks(releaseId, contextURI, trackIndex);
    } else if (contextURI.startsWith("playlist:")) {
      const playlistId = contextURI.substring("playlist:".length);
      data = await this.loadPlaylistTracks(playlistId, contextURI, trackIndex);
    } else if (contextURI.startsWith("userTracks:")) {
      const userId = contextURI.substring("userTracks:".length);
      data = await this.loadUserTracks(userId, contextURI, trackIndex);
    }
    // Add more conditions if there are other types of contexts like 'artist-' or 'user-'

    if (data?.tracks) {
      this.loadTracks(data.tracks, trackIndex, contextURI);
    }
  }

  async retrieveStateFromLocalStorage() {
    const savedState = localStorage.getItem("audioPlayerState");
    if (savedState) {
      const { contextURI, currentSongIndex } = JSON.parse(savedState);
      this.contextURI = contextURI;
      await this.loadTracksFromContextURI(contextURI, currentSongIndex);
    }
  }

  // Call this method when the application starts
  async initializePlayer() {
    this.isPlaying = false;
    await this.retrieveStateFromLocalStorage();
    // Other initialization logic
  }

  fetchState = async () => {
    await this.initializePlayer();
    // if (!this.tracks.length) {
    //   await this._fetchInitialData();
    // }
    this._callUpdateState();
  };

  loadTracks(tracks, trackIndex = 0, contextURI, startPlaying = false) {
    this.contextURI = contextURI;
    this.tracks = tracks;
    this.changeTrack(trackIndex);
    if (this.isPlaying || startPlaying) {
      this.startPlay();
    }
    this._recordContext();
  }

  loadArtistTracks = async (artistId, contextURI, trackIndex = 0) => {
    const data = await this._get(`api/v1/artists/${artistId}/tracks/`, false);
    if (data?.tracks) {
      this.loadTracks(data.tracks, trackIndex, contextURI);
    }
  };

  loadReleaseTracks = async (
    releaseId,
    contextURI,
    trackIndex = 0,
    startPlaying = false
  ) => {
    const data = await this._get(
      `api/v1/releases/${releaseId}/load-tracks/`,
      false
    );
    if (data?.tracks) {
      this.loadTracks(data.tracks, trackIndex, contextURI, startPlaying);
    }
  };

  loadPlaylistTracks = async (
    playlistId,
    contextURI,
    trackIndex = 0,
    startPlaying = false
  ) => {
    const data = await this._get(`api/v1/playlists/${playlistId}/`, false);
    if (data?.tracks) {
      this.loadTracks(data.tracks, trackIndex, contextURI, startPlaying);
    }
  };

  loadUserTracks = async (userId, contextURI, trackIndex = 0) => {
    const data = await this._get(
      `api/v1/users/${userId}/release-tracks/`,
      false
    );
    if (data?.release_tracks) {
      this.loadTracks(data.release_tracks, trackIndex, contextURI);
    }
  };

  loadDefaultTracks(tracks) {
    // this.tracks = tracks;
    // this.changeTrack(0);
    this._callUpdateState();
  }

  // delete?
  loadNewTracks(tracks, trackIndex = 0) {
    this.tracks = tracks;
    this.changeTrack(trackIndex);
    this.startPlay();
  }

  //  delete?
  handleReleaseClick(tracks, trackIndex = 0, releaseId = null) {
    if (releaseId) {
      // if the releaseId is different from the current one, update the releaseId and tracks
      if (releaseId !== this.releaseId) {
        this.releaseId = releaseId;
        this.tracks = tracks;
        this.currentSongIndex = trackIndex;

        // Ensure the audio source is updated to the new track
        this.changeTrack(this.currentSongIndex);

        // Automatically start playing the new track
        this.startPlay();
      } else {
        // if the releaseId is the same, just toggle play/pause
        this.togglePlayPause();
      }
    }
  }

  //  delete?
  handleTrackClick(releaseId, trackIndex) {
    // if it's the same release
    if (releaseId === this.releaseId) {
      // if it's the same track, toggle play/pause
      if (trackIndex === this.currentSongIndex) {
        this.togglePlayPause();
      } else {
        // if it's a different track, change the track
        this.changeTrack(trackIndex);
      }
    } else {
      // if it's a different release, load the new release
      this.loadNewRelease(releaseId, trackIndex);
    }
  }

  //  delete?
  loadNewRelease = async (releaseId, trackIndex = 0) => {
    const data = await this._get(`api/v1/releases/${releaseId}/`, true);
    if (data?.tracks) {
      this.releaseId = data.releaseId;
      this.loadNewTracks(data.tracks, trackIndex);
    }
  };

  triggerTrackRecordTimer() {
    // Clear any existing timeout
    this._clearPlayTimeout();

    // Set a new timeout to record the play after 30 seconds
    this.playTimeout = setTimeout(() => {
      if (this.isPlaying) {
        this._recordPlay();
      }
    }, 2000); // 30000 = 30 seconds
  }

  startPlay() {
    this.isPlaying = true;
    this.audioRef.play();
    this._callUpdateState();
    this.triggerTrackRecordTimer();
  }

  _clearPlayTimeout() {
    if (this.playTimeout) {
      clearTimeout(this.playTimeout);
      this.playTimeout = null;
    }
  }

  togglePlayPause() {
    if (this.tracks?.length > 0) {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.audioRef.play();
        this.triggerTrackRecordTimer();
      } else {
        this.audioRef.pause();
        this._clearPlayTimeout();
      }
      this._callUpdateState();
    }
  }

  changeTrack(index) {
    if (index >= 0 && index < this.tracks.length) {
      // Clear any existing play timeout before changing the track
      this._clearPlayTimeout();

      this.currentSongIndex = index;
      this.audioRef.src = this.tracks[index].audio_file;
      this.audioRef.load();
      if (this.isPlaying) {
        this.audioRef.play();
      }
      // Save the new state to local storage
      this.saveCurrentStateToLocalStorage();
    }
    this._callUpdateState();
  }

  handleTrackEnd = () => {
    this.handleNext();
  };

  handleNext() {
    if (this.tracks?.length > 0) {
      const nextIndex =
        this.currentSongIndex < this.tracks.length - 1
          ? this.currentSongIndex + 1
          : 0;
      this.changeTrack(nextIndex);
      if (this.isPlaying) {
        this.triggerTrackRecordTimer(); // Restart the timer for the next track
      }
    }
  }

  handleBack() {
    if (this.tracks?.length > 0) {
      const prevIndex =
        this.currentSongIndex > 0
          ? this.currentSongIndex - 1
          : this.tracks.length - 1;
      this.changeTrack(prevIndex);
      if (this.isPlaying) {
        this.triggerTrackRecordTimer(); // Restart the timer for the next track
      }
    }
  }

  handleTimeSliderChange(time) {
    this.audioRef.currentTime = time;
    this._callUpdateState();
  }

  _recordPlay = async () => {
    console.log("this.contextURI = ", this.contextURI);
    const trackId = this.tracks[this.currentSongIndex].id; // assuming each track has an 'id' field
    fetch(this._createUrl(`api/v1/release-tracks/${trackId}/record-play/`), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify({ context_uri: this.contextURI }), // adjust as needed based on your backend requirements
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Play recorded:", data);
      })
      .catch((error) => {
        console.error("Error recording play:", error);
      });
  };

  _recordContext = async () => {
    fetch(this._createUrl(`api/v1/user/record-context/`), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify({ context_uri: this.contextURI }), // adjust as needed based on your backend requirements
    })
      .then((response) => response.json())
      // .then((data) => {
      //   console.log("Context recorded:", data);
      // })
      .catch((error) => {
        console.error("Error recording context:", error);
      });
  };

  handleVolumeChange = (volume) => {
    this.currentVolume = volume;
    this.audioRef.volume = volume;
    this._callUpdateState();
  };

  saveCurrentStateToLocalStorage() {
    const currentState = {
      contextURI: this.contextURI,
      currentSongIndex: this.currentSongIndex,
    };
    localStorage.setItem("audioPlayerState", JSON.stringify(currentState));
  }
}
