import { useState } from "react";
import { useNavigate } from "react-router-dom";

// generic
import EllipsisMenu from "../../../../components/UI/menus/ellipsisMenu/Index";
import DeleteModal from "../../../../components/UI/modals/DeleteModal";

const ProjectMenu = ({ deleteProject }) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    setOpenModal(true);
  };

  const completeDeleteProject = async () => {
    const res = await deleteProject();
    if (res) {
      navigate("/projects");
    }
  };

  const items = [
    {
      name: "Delete",
      handleClick: handleDelete,
    },
  ];

  return (
    <>
      <EllipsisMenu items={items} />
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        onConfirm={completeDeleteProject}
        onCancel={() => console.log("canceled")}
        title="Delete project"
        message="Are you sure you want to delete this project?"
        confirmBtnText="Delete"
      >
        Hey
      </DeleteModal>
    </>
  );
};

export default ProjectMenu;
