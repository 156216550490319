import { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useParams,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import {
  CircleStackIcon,
  UserGroupIcon,
  ChartPieIcon,
  HomeIcon,
  CheckCircleIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";

// generic
import { UserContext } from "../../../MainLayoutContext.jsx";
import LoadingScreen from "../../../../../components/loading/LoadingScreen.jsx";
import Navbar from "../../../../../components/layout/navigation/navbar/Index.jsx";
import Sidebar from "../../../../../components/layout/navigation/sidebar/Index.jsx";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/viewmodel.js";

const navItems = [
  { name: "Home", href: "", icon: HomeIcon, end: true },
  {
    name: "Tasks",
    href: "tasks/",
    icon: CheckCircleIcon,
    end: false,
  },
  { name: "Analytics", href: "analytics", icon: ChartPieIcon, end: false },
  {
    name: "Data",
    href: "data/",
    icon: CircleStackIcon,
  },
  // {
  //   name: "Adcampaigns",
  //   href: "campaigns/",
  //   icon: FolderIcon,
  // },
  {
    name: "Users",
    href: "users/",
    icon: UserGroupIcon,
  },
];

const avatarItems = [{ id: 1, name: "Connect my data", href: "/data/" }];

export default function ProjectRoot() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { token, appItems } = useOutletContext();
  const user = useContext(UserContext);

  let { projectId } = useParams();
  const [project, setProject] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, project, (state) => {
      setProject(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token, projectId]);

  const handleSelect = async (project) => {
    setIsLoading(true);
    await viewModel.refreshState(project.id);
    navigate(`/projects/${project.id}`);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="flex flex-col h-screen">
        <Navbar
          user={user}
          navItems={navItems}
          appItems={appItems}
          avatarItems={avatarItems}
          searchOptions={project?.projects}
          fetchSearchOptions={(query) => viewModel.searchProjects(query)}
          handleSelect={handleSelect}
          searchPlaceholder="Search projects..."
        />

        <div className="flex h-[calc(100vh-4rem)]">
          {/* Sidebar */}
          <aside className="hidden lg:flex flex-grow">
            <Sidebar navItems={navItems} />
          </aside>

          {/* Main Content */}
          <main className="w-full flex-grow overflow-y-auto p-4">
            {/* project title for mobile */}
            <div className="p-2 mb-2">
              <div className="flex items-center justify-center">
                <span className="text-gray-400">{project.name} — </span>
                <span className="text-gray-300 ms-2">
                  ({project.artist_name})
                </span>
              </div>
            </div>
            <div className="flex-1 py-20">
              <Outlet context={{ token, project }} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
