import { useState } from "react";

import SimpleSearchBar from "../../search/SimpleSearchBar";
import TrackResults from "./TrackResults";

export default function AddPlaylistTracks({ addPlaylistTrack }) {
  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_API_URL;
  const [trackResults, setTrackResults] = useState([]);

  // Debounce function
  const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // Debounced search function
  const debouncedSearch = debounce((query) => {
    fetch(`${baseURL}api/v1/release-tracks/?q=${query}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setTrackResults(data.results);
      })
      .catch((error) => {
        console.error("Error in search:", error);
      });
  }, 100); // 100 milliseconds debounce time

  // Handle input change
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    debouncedSearch(query);
  };

  return (
    <>
      <div className="px-6">
        <SimpleSearchBar
          label={"Search Tracks"}
          placeholder={"Search tracks..."}
          handleChange={handleSearchInputChange}
        />
      </div>
      {trackResults?.length > 0 && (
        <TrackResults
          tracks={trackResults}
          addPlaylistTrack={addPlaylistTrack}
        />
      )}
    </>
  );
}
