import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import FacebookLogin from "@greatsumini/react-facebook-login";
import {
  ArrowDownRightIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

const GridItemMeta = ({ text, handleClick, isConnected }) => {
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={`group col-span-1 divide-y divide-gray-200 rounded-md  shadow duration-300  hover:shadow-md ${
          isConnected
            ? " bg-indigo-300 text-gray-50 hover:bg-indigo-400"
            : "bg-white text-gray-600 hover:bg-indigo-50"
        }`}
      >
        <div className="flex items-center justify-between space-x-6 p-6">
          <div className="flex justify-start items-center gap-4">
            <ArrowDownRightIcon
              className="h-10 w-10 flex-shrink-0 duration-300 "
              aria-hidden="true"
            />

            <div className="flex-1">
              <div className="flex items-center space-x-3">
                <h2 className="truncate text-sm font-bold">{text}</h2>
              </div>
              {isConnected && (
                <p className="text-sm font-light text-gray-200 hover:underline underline-offset-4">
                  Reconnect
                </p>
              )}
            </div>
          </div>
          <div className="flex items-top justify-end w-full p-2">
            {isConnected && <CheckCircleIcon className="h-6 w-6" />}
          </div>
        </div>
      </button>
    </>
  );
};

export default GridItemMeta;
