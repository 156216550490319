export default class ProjectCampaignsViewModel {
  constructor(token, projectId, state, updateState) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchCampaigns = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/campaigns/`,
      false
    );
  };

  // _fetchAdgroups = async () => {
  //   return await this._get(`api/v1/projects/${this.projectId}/adgroups`, false);
  // };

  // _fetchAds = async () => {
  //   return await this._get(`api/v1/projects/${this.projectId}/ads`, false);
  // };

  _fetchProjectPlatforms = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/project_platforms`,
      false
    );
  };

  // _fetchAdgroups = async () => {
  //   return await this._get(`api/v1/projects/${this.projectId}/adgroups`, true);
  //   //   return await this._get(`api/v1/projects/${this.projectId}/adgroups?campaign_ids=${campaignIds}`, true);
  // };

  _fetchInitialCalls = async () => {
    return Promise.all([
      this._fetchCampaigns(),
      // this._fetchProjectPlatforms(),
    ]).then((values) => {
      return {
        campaigns: values[0],
        // projectPlatforms: values[3],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  updateCampaignStatus = async (campaign) => {
    console.log(campaign);
    return await fetch(
      this._createUrl(`api/v1/campaigns/${campaign.id}/update-status/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(campaign),
      }
    )
      .then(async (response) => {
        console.log(response);
        let campaign = await response.json();
        console.log("campaign res: ", campaign);
        console.log("this.state: ", this.state);
        this.state.data.campaigns = this.state.data.campaigns.map((c) => {
          if (c.id === campaign.id) {
            return campaign;
          }
          return c;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating campaign", error);
        return false;
      });
  };

  // updateCampaign = async (campaign) => {
  //   console.log(campaign);
  //   return await fetch(this._createUrl(`api/v1/campaign/${task.id}/`), {
  //     method: "PATCH",
  //     headers: this._standardActionHeaders(),
  //     body: JSON.stringify(task),
  //   })
  //     .then(async (response) => {
  //       console.log(response);
  //       let task = await response.json();
  //       console.log("task: ", task);
  //       this.state.tasks = this.state.tasks.map((t) => {
  //         if (t.id === task.id) {
  //           return task;
  //         }
  //         return t;
  //       });
  //       this._callUpdateState();
  //       return true;
  //     })
  //     .catch((error) => {
  //       console.error("Error updating task", error);
  //       return false;
  //     });
  // };

  updateAdgroupStatus = async (adgroup) => {
    console.log(adgroup);
    return await fetch(
      this._createUrl(`api/v1/adgroups/${adgroup.id}/update-status/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(adgroup),
      }
    )
      .then(async (response) => {
        console.log(response);
        let adgroup = await response.json();
        console.log("adgroup res: ", adgroup);
        console.log("this.state: ", this.state);
        this.state.data.adgroups = this.state.data.adgroups.map((a) => {
          if (a.id === adgroup.id) {
            return adgroup;
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating adgroup", error);
        return false;
      });
  };

  updateAdStatus = async (ad) => {
    console.log(ad);
    return await fetch(this._createUrl(`api/v1/ads/${ad.id}/update-status/`), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(ad),
    })
      .then(async (response) => {
        console.log(response);
        let ad = await response.json();
        console.log("ad res: ", ad);
        console.log("this.state: ", this.state);
        this.state.data.ads = this.state.data.ads.map((a) => {
          if (a.id === ad.id) {
            return ad;
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating ad", error);
        return false;
      });
  };
}
