import { Link } from "react-router-dom";
import AddToBagBtn from "./AddToBagBtn";

export default function ProductHeader({ product }) {
  return (
    <>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          <div className="flex justify-center items-center">
            {product.image ? (
              <img
                className="h-100 object-cover rounded-lg bg-gray-300"
                src={product.image}
                alt={product.name}
              />
            ) : (
              <div
                className="h-80 object-cover rounded-lg bg-gray-300"
                alt={product.name}
              />
            )}
          </div>

          <div className="md:py-10">
            <div className="flex justify-between">
              <h1 className="text-2xl font-medium">{product.name}</h1>
              <p className="text-gray-400">${product.price}</p>
            </div>
            <h2 className="text-md text-gray-400 hover:underline pb-6">
              <Link to={`/artists/${product.artist}`}>{product.name}</Link>
            </h2>
            <hr />
            <div className="py-4">
              <AddToBagBtn />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
