import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";

export default function Index({ open, setOpen }) {
  return (
    <span
      onClick={() => setOpen(!open)}
      className={`flex ${
        open ? "justify-end" : "justify-start"
      } items-center p-2 text-gray-700 hover:text-indigo-600 hover:cursor-pointer`}
    >
      {open ? (
        <ArrowLeftCircleIcon className="h-6 w-6 shrink-0" />
      ) : (
        <ArrowRightCircleIcon className="h-6 w-6 shrink-0" />
      )}
    </span>
  );
}
