import { useNavigate } from "react-router-dom";

export default function SidebarList({ item, type, onClick }) {
  const navigate = useNavigate();

  const handleArtistClick = (event, artistId) => {
    event.stopPropagation();
    window.scrollTo(0, 0);
    navigate(`/artists/${artistId}`);
  };

  return (
    <>
      <li
        className="px-2 py-2 cursor-pointer truncate text-ellipsis hover:bg-gray-100"
        key={item.id}
        onClick={onClick}
      >
        <div className="flex justify-start items-center gap-2">
          <img
            src={
              item.spotify_profile_pic_url
                ? item.spotify_profile_pic_url
                : item.artwork
            }
            alt={`${type} profile`}
            className={`h-10 w-10 ${
              type === "artist" ? "rounded-full" : "rounded-sm"
            }`}
          />

          <div className="flex flex-col">
            <span className="text-sm text-gray-700 font-medium">
              {item.name}
            </span>
            <div className="text-sm font-light text-gray-500">
              {item.artists &&
                item.artists.map((artist, index) => (
                  <span
                    key={artist.id}
                    className="hover:underline cursor-pointer"
                    onClick={(e) => handleArtistClick(e, artist.id)}
                  >
                    {artist.name}
                    {index < item.artists.length - 1 && ", "}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </li>
    </>
  );
}
