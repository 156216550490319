export default function SecondaryButton({
  handleClick,
  text = "+ Create",
  type = "button",
  disabled = false,
  children,
  widthFull = false,
  smallHeight = false,
}) {
  return (
    <button
      type={type}
      onClick={!disabled && handleClick ? handleClick : null}
      disabled={disabled}
      className={`flex items-center ${
        widthFull ? "justify-center w-full" : "justify-between"
      } rounded-md px-4 py-2 text-sm font-normal shadow-sm ring-1 ring-inset 
        ${
          disabled
            ? "bg-gray-200 text-gray-500 cursor-not-allowed" // Disabled styles
            : "bg-white text-gray-900 hover:bg-gray-50" // Default styles
        } ring-gray-300 ${smallHeight ? "h-8" : ""}`}
    >
      {text}
      {children}
    </button>
  );
}
