// generic
import SecondaryButton from "../../../../../../components/UI/buttons/SecondaryButton";
import SelectSearchOrCreate from "../../../../../../components/forms/SelectSearchOrCreate";

export default function Controls({
  selected,
  setSelected,
  assetLabels,
  createBulkAssetLabel,
  addBulkAssetLabelToAd,
  adTypes,
  createBulkAdType,
  addBulkAdTypeToAd,
}) {
  return (
    <div className="flex justify-start items-center gap-2">
      <h3 className="text-lg ps-10 pt-4 text-gray-700 font-bold">
        {selected.length} selected to update:
      </h3>
      <SelectSearchOrCreate
        options={adTypes}
        createNew={createBulkAdType}
        updateObject={addBulkAdTypeToAd}
      />
      <SelectSearchOrCreate
        options={assetLabels}
        createNew={createBulkAssetLabel}
        updateObject={addBulkAssetLabelToAd}
      />
      <SecondaryButton
        text={"Clear selected"}
        handleClick={() => setSelected([])}
      />
    </div>
  );
}
