import { NavLink, useLocation, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Function to determine if the tab is currently active based on the location
  const isTabActive = (tabHref) => {
    // Check if the tab's href is an empty string and compare it with the root path
    if (tabHref === "" && location.pathname === "/") {
      return true;
    }
    // Otherwise, compare normally
    return location.pathname.includes(tabHref);
  };

  // Find the active tab based on the current location
  const activeTab = tabs.find((tab) => isTabActive(tab.href)) || tabs[0];

  const handleSelectChange = (event) => {
    const selectedTab = tabs.find((tab) => tab.name === event.target.value);
    if (selectedTab) {
      navigate(selectedTab.href);
    }
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={activeTab.name}
          onChange={handleSelectChange}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex" aria-label="Tabs">
          {tabs.map((tab) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              className={({ isActive }) =>
                isActive
                  ? "font-bold underline underline-offset-8 pb-4 me-3 sm:me-8 md:me-16"
                  : "font-light text-gray-600 me-3 sm:me-8 md:me-16"
              }
              end={tab.end}
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
}
