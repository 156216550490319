export default class MetaViewModel {
  constructor(token, organizationId, state, updateState) {
    this.token = token;
    this.organizationId = organizationId;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.site_url = process.env.REACT_APP_SITE_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      `api/v1/organizations/${this.organizationId}/data-sources/`,
      false
    );
  };

  _fetchOrganization = async () => {
    return await this._get(
      `api/v1/organizations/${this.organizationId}/`,
      false
    );
  };

  _fetchFbUser = async () => {
    return await this._get(`api/v1/user/fb-user/`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([
      this._fetchData(),
      this._fetchOrganization(),
      this._fetchFbUser(),
    ]).then((values) => {
      return {
        data: values[0],
        organization: values[1],
        fb_user: values[2],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  addBusinessManagerToOrg = async (businessManager) => {
    console.log("running addBusinessManagerToOrg");
    try {
      const response = await fetch(
        `${this.url_begin}api/v1/organizations/${this.organizationId}/add-meta-business-manager/`,
        {
          method: "POST",
          headers: this._standardActionHeaders(),
          body: JSON.stringify({
            businessManager,
          }),
        }
      );

      if (response.ok) {
        const busManager = await response.json();
        this.state.data.meta.business_manager = busManager;
        this.state.organization.meta_business_manager = busManager;
        this._callUpdateState();
        this.refreshBusinessManagerAssets();
      } else {
        // Handle error response
        console.error("Error saving Business Manager");
      }
    } catch (error) {
      console.error("Error in saving Business Manager:", error);
    }
  };

  refreshBusinessManagerAssets = async () => {
    try {
      const response = await fetch(
        `${this.url_begin}api/v1/organizations/${this.organizationId}/refresh-business-manager-assets/`,
        {
          method: "GET",
          headers: this._standardHeaders(),
        }
      );
      if (response.ok) {
        console.log("Refreshed Business Manager assets");
      } else {
        // Handle error response
        console.error("Error refreshing Business Manager assets");
      }
    } catch (error) {
      console.error("Error in refreshing Business Manager assets:", error);
    }
  };

  updateOrganization = async (organization) => {
    return await fetch(
      this._createUrl(`api/v1/organizations/${this.organizationId}/`),
      {
        method: "PATCH",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(organization),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        this.state.organization = res;
        this._callUpdateState();
        return;
      })
      .catch((error) => {
        console.error("Error updating organization", error);
      });
  };

  fetchMetaAdaccountOptions = async () => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/get-bm-meta-adaccounts/`
      ),
      {
        method: "GET",
        headers: this._standardHeaders(),
      }
    )
      .then(async (response) => {
        let options = await response.json();
        this.state.data.meta.adaccounts_options = options;
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error fetching meta ads accounts", error);
      });
  };

  addMetaAdaccount = async (adaccount) => {
    if (
      this.state.data?.meta?.adaccounts &&
      this.state.data.meta.adaccounts.find((item) => item.id === adaccount.id)
    ) {
      return;
    }
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/add-meta-adaccount/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ adaccount }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        if (this.state.data.meta?.adaccounts) {
          this.state.data.meta.adaccounts.push(adaccount);
        } else {
          this.state.data.meta.adaccounts = [adaccount];
        }

        // update adaccount options item with the updated adaccount
        this.state.data.meta.adaccounts_options =
          this.state.data.meta.adaccounts_options.filter(
            (item) => item.id !== adaccount.id
          );

        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error adding meta adaccount", error);
      });
  };

  removeMetaAdaccount = async (adaccount) => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/remove-meta-adaccount/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ adaccount }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        this.state.data.meta.adaccounts =
          this.state.data.meta.adaccounts.filter(
            (item) => item.id !== adaccount.id
          );
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error removing meta ads account", error);
      });
  };

  fetchMetaPageOptions = async () => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/get-bm-meta-pages/`
      ),
      {
        method: "GET",
        headers: this._standardHeaders(),
      }
    )
      .then(async (response) => {
        let options = await response.json();
        this.state.data.meta.page_options = options;
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error fetching meta ads accounts", error);
      });
  };

  addMetaPage = async (page) => {
    if (
      this.state.data?.meta?.pages &&
      this.state.data.meta.pages.find((item) => item.id === page.id)
    ) {
      return;
    }
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/add-meta-page/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ page }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        if (this.state.data.meta?.pages) {
          this.state.data.meta.pages.push(page);
        } else {
          this.state.data.meta.pages = [page];
        }

        // update page options item with the updated page
        this.state.data.meta.page_options =
          this.state.data.meta.page_options.map((item) => {
            if (item.id === page.id) {
              // Found the matching item, update its is_added_to_org field
              return { ...item, is_added_to_org: true };
            }
            return item; // Return unmodified item
          });

        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error adding meta page", error);
      });
  };

  // to sort

  removeBusinessManagerFromOrg = async () => {
    try {
      const response = await fetch(
        `${this.url_begin}api/v1/organizations/${this.organizationId}/remove-meta-business-manager/`,
        {
          method: "POST",
          headers: this._standardHeaders(),
        }
      );

      if (response.ok) {
        this.state.data.meta.business_manager = null;
        this._callUpdateState();
      } else {
        // Handle error response
        console.error("Error removing Business Manager");
      }
    } catch (error) {
      console.error("Error in removing Business Manager:", error);
    }
  };

  removeMetaPage = async (page) => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/remove-meta-page/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ page }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        this.state.data.meta.pages = this.state.data.meta.pages.filter(
          (item) => item.id !== page.id
        );
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error removing meta ads account", error);
      });
  };

  fetchMetaPixelOptions = async () => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/get-bm-meta-pixels/`
      ),
      {
        method: "GET",
        headers: this._standardHeaders(),
      }
    )
      .then(async (response) => {
        let options = await response.json();
        console.log("options", options);
        this.state.data.meta.pixels_options = options;
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error fetching meta ads accounts", error);
      });
  };

  addMetaPixel = async (pixel) => {
    if (
      this.state.data?.meta?.pixels &&
      this.state.data.meta.pixels.find((item) => item.id === pixel.id)
    ) {
      return;
    }
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/add-meta-pixel/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ pixel }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        if (this.state.data.meta?.pixels) {
          this.state.data.meta.pixels.push(pixel);
        } else {
          this.state.data.meta.pixels = [pixel];
        }
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error adding meta pixel", error);
      });
  };

  removeMetaPixel = async (pixel) => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/remove-meta-pixel/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ pixel }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        this.state.data.meta.pixels = this.state.data.meta.pixels.filter(
          (item) => item.id !== pixel.id
        );
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error removing meta ads account", error);
      });
  };
}
