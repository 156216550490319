import { useEffect, useState } from "react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchDropdown({
  options,
  fetchOptions,
  handleSelect,
  placeholder,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [query, setQuery] = useState("");

  const handleSelectOption = (option) => {
    setSelectedItem(option);
    handleSelect(option);
    setQuery("");
  };

  // When search query is updated, fetch projects from the API
  useEffect(() => {
    if (query.length > 0) {
      fetchOptions(query);
    }
  }, [query, fetchOptions]);

  return (
    <Combobox as="div" value={selectedItem} onChange={handleSelectOption}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 sr-only">
        {placeholder}
      </Combobox.Label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <Combobox.Input
          className="w-full rounded-full border-0 bg-white py-1.5  pl-10 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(project) => project?.name}
          placeholder={placeholder}
        />

        {options?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      {option.profile_pic && (
                        <img
                          src={option.profile_pic}
                          alt={option.name}
                          className="me-3 h-6 w-6 flex-shrink-0 rounded-full"
                        />
                      )}
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {option.name}
                      </span>
                      {option.artist_name && (
                        <span
                          className={classNames(
                            "ml-2 truncate text-gray-500",
                            active ? "text-indigo-200" : "text-gray-500"
                          )}
                        >
                          {option.artist_name}
                        </span>
                      )}
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
