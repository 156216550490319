export default function TrackResults({ tracks, addPlaylistTrack }) {
  return (
    <>
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="flex flex-col">
            {tracks.map((track) => (
              <div
                key={track.id}
                className="flex flex-row justify-between items-center py-2 hover:bg-gray-100"
              >
                <div className=" px-2 flex flex-row items-center">
                  {track.artwork ? (
                    <img
                      src={track.artwork}
                      className="h-10 w-10 rounded-sm me-4"
                      alt={`${track.name} artwork`}
                    />
                  ) : (
                    <div className="h-10 w-10 bg-gray-300 rounded-sm me-4"></div>
                  )}
                  <div className="flex flex-col">
                    <div className="text-sm font-medium text-gray-900">
                      {track.name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {track.artists.map((artist) => (
                        <span key={artist.id}>{artist.name} </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="px-2 flex flex-row items-center">
                  <button
                    onClick={() => addPlaylistTrack(track.id)}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
