import { useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";

export default function ImageUploadTile({ releaseArtworkUrl, onUpload }) {
  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_API_URL;
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    console.log("file changed event", event);
    const file = event.target.files[0];
    console.log("file", file);
    if (file) {
      setImage(file);
      setImagePreviewUrl(URL.createObjectURL(file));
      uploadFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    console.log("event", event);
    const file = event.dataTransfer.files[0];
    console.log("file", file);
    if (file) {
      setImage(file);
      setImagePreviewUrl(URL.createObjectURL(file));
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      const response = await fetch(
        `${baseURL}api/v1/images/generate-signed-url/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json", // Ensure content type is set for JSON payload
          },
          body: JSON.stringify({ filename: file.name }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      console.log("signed url data", data);

      // Perform the actual file upload to the signed URL
      const putResponse = await fetch(data.signedUrl, {
        method: "PUT",
        body: file, // Put the file itself without any additional headers
      });

      if (!putResponse.ok) {
        throw new Error(`Upload failed: ${putResponse.status}`);
      }

      console.log("File uploaded successfully.");
      console.log("putResponse", putResponse);

      const res = await saveToDatabase(file.name, data.fullUrl);
      console.log("res", res);
    } catch (error) {
      console.error("Error during file upload", error);
    }
  };

  const saveToDatabase = async (fileName, URL) => {
    try {
      const response = await fetch(
        `${baseURL}api/v1/images/save-to-database/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileName: fileName,
            fullUrl: URL,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Selected/uploaded image id", data.imageId);
        onUpload(data); // Invoke the handler with the image data
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Error during file upload", error);
    }
  };

  return (
    <>
      <div className="col-span-full">
        <div
          className={`flex justify-center rounded-lg ${
            !imagePreviewUrl &&
            !releaseArtworkUrl &&
            "mt-2 border border-dashed border-gray-900/25 px-6 py-10"
          }`}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <div className="text-center">
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                alt="Preview"
                className="mx-auto h-60 rounded-md"
              />
            ) : releaseArtworkUrl ? (
              <img
                src={releaseArtworkUrl}
                alt="Preview"
                className="mx-auto h-60 rounded-md"
              />
            ) : (
              <PhotoIcon
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
            )}

            {!imagePreviewUrl && !releaseArtworkUrl && (
              <>
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG, GIF up to 10MB
                </p>
              </>
            )}

            {uploadProgress > 0 && (
              <div className="mt-4">
                <label>Uploading:</label>
                <progress value={uploadProgress} max="100">
                  {uploadProgress}%
                </progress>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
