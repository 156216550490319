import { useEffect, useState } from "react";
import TableHeaderSticky from "../layout/tables/TableHeaderSticky";

export default function CreativeTable({ data, columns, sortData, footerData }) {
  const [sortedData, setSortedData] = useState([...data]);
  const [sortedHeader, setSortedHeader] = useState(columns[1].key);
  const [sortAscending, setSortAscending] = useState(true);

  const handleSort = (key) => {
    let prevKey = sortedHeader;

    setSortedHeader(key);

    if (prevKey === key) {
      sortData(key, !sortAscending);
      setSortAscending(!sortAscending);
    } else {
      sortData(key, true);
      setSortAscending(true);
    }
  };

  useEffect(() => {
    setSortedData(sortData(sortedHeader, sortAscending));
  }, [sortedHeader, sortAscending, sortData]);

  return (
    <div className="overflow-x-auto text-md">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <TableHeaderSticky
                key={column.key}
                name={column.key}
                label={column.header}
                handleSort={handleSort}
                sortedHeader={sortedHeader}
                sortAscending={sortAscending}
                index={index}
              />
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {sortedData.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td
                  key={column.key}
                  className={
                    colIndex === 0
                      ? "sticky left-0 bg-white whitespace-nowrap py-4 pl-1 pr-1 font-medium text-gray-900"
                      : "whitespace-nowrap px-3 py-4 text-gray-500"
                  }
                >
                  {column.formatter
                    ? column.formatter(item[column.key])
                    : item[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot className="divide-y divide-gray-200 bg-white">
          <tr>
            {footerData &&
              columns.map((column) => (
                <th
                  key={column.key}
                  className="text-left whitespace-nowrap px-3 py-4 text-gray-500 bg-white"
                >
                  {column.formatter
                    ? column.formatter(footerData[column.key])
                    : footerData[column.key]}
                </th>
              ))}
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
