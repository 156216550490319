import { Link } from "react-router-dom";

import addAdaccounts from "../../../assets/images/sales/addAdaccounts.png";
import addCampaignsScreenshot from "../../../assets/images/sales/addCampaignsScreenshot.png";
import connectMyData from "../../../assets/images/sales/connectMyData.png";
import dashboardOverviewPage from "../../../assets/images/sales/dashboardOverviewPage.png";
import timeseriesScreenshot from "../../../assets/images/sales/timeseriesScreenshot.png";

export default function About() {
  return (
    <>
      <div className="relative isolate">
        <svg
          className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
          />
        </svg>
        <div
          className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
            }}
          />
        </div>

        {/* hero block */}
        <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
              <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  We love great music.
                </h1>
                <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Owned and created by me, musician and technologist, Will
                  Cuming (ie. LANKS) with help from my sister, Lou. I believe in
                  empowering artist teams to use analytics to build smarter,
                  sustainable businesses.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to="/login/"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started
                  </Link>
                  <Link
                    to="/login/"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Log in <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
              <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                  <div className="relative">
                    <img
                      src="https://storage.googleapis.com/cma-production-bucket/site/img/boyandbear_1080x1080.png"
                      alt=""
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                  <div className="relative">
                    <img
                      src="https://storage.googleapis.com/cma-production-bucket/site/img/tash_sultana_press_pic.jpg"
                      alt=""
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <img
                      src="https://storage.googleapis.com/cma-production-bucket/site/img/LANKS_square_press_shot_z_website.jpg"
                      alt=""
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                  <div className="relative">
                    <img
                      src="https://storage.googleapis.com/cma-production-bucket/site/img/diplo_1080x1080.png"
                      alt=""
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <img
                      src="https://storage.googleapis.com/cma-production-bucket/site/img/mts_1080x1080.png"
                      alt=""
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="flex justify-center items-center  pb-32 pt-16">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold tracking-tight text-indigo-700">
              Automate the boring stuff, so you can focus on insights and
              growing music revenue.
            </h1>
          </div>
        </div>

        {/* steps block 1 */}
        <div className="sm:flex justify-between items-center md:mx-16">
          <div className="max-w-3xl mx-auto p-12">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Easily add your Meta, Google Ads, Tiktok ads and other data
              sources.
            </h1>
          </div>
          <div className="max-w-3xl mx-auto p-12">
            <div className="border border-gray-200 rounded-lg shadow-lg">
              <img
                className="h-auto w-auto"
                src={connectMyData}
                alt="Zantipi Logo"
              />
            </div>
          </div>
        </div>

        {/* steps block 2 */}
        <div className="sm:flex justify-between items-center md:mx-16">
          <div className="max-w-3xl mx-auto p-12">
            <div className="border border-gray-200 rounded-lg shadow-lg">
              <img
                className="h-auto w-auto"
                src={addCampaignsScreenshot}
                alt="Zantipi Logo"
              />
            </div>
          </div>
          <div className="max-w-3xl mx-auto p-12">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Select the ad accounts and campaigns for your project.
            </h1>
          </div>
        </div>

        {/* steps block 3 */}
        <div className="sm:flex justify-between items-center md:mx-16">
          <div className="max-w-3xl mx-auto p-12">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Automatically get bespoke <span className="italic">music</span>{" "}
              dashboard overview of your ad performance.
            </h1>
          </div>
          <div className="max-w-3xl mx-auto p-12">
            <div className="border border-gray-200 rounded-lg shadow-lg">
              <img
                className="h-auto w-auto"
                src={dashboardOverviewPage}
                alt="Zantipi Logo"
              />
            </div>
          </div>
        </div>

        {/* steps block 4 */}
        <div className="sm:flex justify-between items-center md:mx-16">
          <div className="max-w-3xl mx-auto p-12">
            <div className="border border-gray-200 rounded-lg shadow-lg">
              <img
                className="h-auto w-auto"
                src={timeseriesScreenshot}
                alt="Zantipi Logo"
              />
            </div>
          </div>
          <div className="max-w-3xl mx-auto p-12">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Share your tour, release and merch campaigns with the rest of the
              team or your clients.
            </h1>
          </div>
        </div>

        {/*  */}
        <div className="flex justify-center items-center pb-16 pt-16">
          <div className="max-w-3xl mx-auto">
            <Link
              to="/login/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </Link>
          </div>
        </div>

        {/* footer privacy policy link */}
        <div className="flex justify-center items-center pb-20">
          <div className="max-w-3xl mx-auto">
            <Link
              to="https://zantipi.com/privacy-policy/"
              target="_blank"
              className="font-light tracking-tight text-indigo-600 hover:text-indigo-400 hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Privacy policy
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
