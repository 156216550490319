import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { format, parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerWidget = ({ selected, onChange, label, name }) => {
  // selected must be a string in ISO format ie. "YYYY-MM-DD"
  const [dateValue, setDateValue] = useState(
    selected ? parseISO(selected) : null
  );

  useEffect(() => {
    // Ensure component reacts appropriately to prop changes
    setDateValue(selected ? parseISO(selected) : null);
  }, [selected]);

  const handleDateChange = (date) => {
    setDateValue(date);
    onChange(date ? format(date, "yyyy-MM-dd") : null); // Ensure you pass a formatted date or null back to the parent
  };

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md">
        <DatePicker
          selected={dateValue}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          icon={
            <CalendarDaysIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          }
          showIcon={true} // Hides the calendar icon
          className="rounded-md border-0 py-1.5 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:text-sm sm:leading-6"
          isClearable={true} // Allows clearing the date
          placeholderText="Select date" // Placeholder text for empty input
          fixedHeight
        />
      </div>
    </div>
  );
};
