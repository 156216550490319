import { useRouteError } from "react-router-dom";

import ZantipiCat from "../assets/images/zantipi-cat.png";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex flex-col items-center justify-center p-20 mx-auto w-full max-w-2xl">
      <img src={ZantipiCat} alt="Zantipi Cat" className="w-1/2" />
      <h1
        style={{ fontFamily: "CentraNo1" }}
        className="font-bold text-6xl py-2 text-center text-[#662e91]"
      >
        Meow!
      </h1>
      <p className="text-gray-500">Can't find that page, sorry!</p>
    </div>
  );
}
