import { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import LoadingScreen from "../../components/loading/LoadingScreen";
import ShowViewModel from "../../models/forFans/shows/showViewModel";
import ShowHeader from "../../components/forFans/shows/ShowHeader";
import ErrorPage from "../ErrorPage";

export default function ShowIndex() {
  const token = localStorage.getItem("token");
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  // view model
  let { showId } = useParams();
  const [showViewData, setShowViewData] = useState();
  const [showViewModel, setShowViewModel] = useState(null);

  useEffect(() => {
    setLoading(true);
    const viewModel = new ShowViewModel(
      token,
      showId,
      showViewData,
      (state) => setShowViewData(state),
      searchParams
    );
    setShowViewModel(viewModel);
  }, [token, showId, searchParams]);

  useEffect(() => {
    if (showViewModel) {
      showViewModel.fetchShowState().then(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
    }
  }, [showViewModel]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!showViewData || !showViewData.data) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className="container-lg mb-20">
        <div className="flex flex-col items-center justify-center">
          {showViewData?.data?.show && (
            <ShowHeader show={showViewData.data.show} />
          )}
        </div>
      </div>
    </>
  );
}
