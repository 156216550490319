import { useState } from "react";

import { CheckCircleIcon as CheckCircleFilled } from "@heroicons/react/24/solid";
import { CheckCircleIcon as CheckCircleOutline } from "@heroicons/react/24/outline";

export default function CheckedCircle({ isCompleted, handleClick }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
        className="cursor-pointer h-5 w-5"
      >
        {isHovered || isCompleted ? (
          <CheckCircleFilled className="text-green-600" />
        ) : (
          <CheckCircleOutline className="text-gray-500" />
        )}
      </div>
    </>
  );
}
