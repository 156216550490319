import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

// generic
import Modal from "../../../../../components/UI/modals/Modal";
import TableBody from "../../../../../components/layout/tables/TableBody";
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/data/releases/viewmodel";
import ReleasesModalRow from "./ReleasesModalRow";
import ReleasesSearch from "./ReleasesSearch";
import ReleasesPagination from "./ReleasesPagination";

const ReleasesModal = ({ isModalOpen, setIsLoading, handleClosingModal }) => {
  const { token, project } = useOutletContext();
  let { projectId } = useParams();
  let artistId = project?.artist?.id;

  // view model
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, artistId, data, (state) => {
      setData(state);
    })
  );
  // set state with adaccount campaigns
  useEffect(() => {
    viewModel.fetchState();
    setIsLoading(false);
  }, [viewModel, token, projectId]);

  const cols = [
    { id: 1, name: "Release", label: "Name" },
    { id: 2, name: "Status", label: "Status" },
    // { id: 3, name: "Project", label: "Project" },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  return (
    <Modal open={isModalOpen} setOpen={handleClosingModal} fullWidth={true}>
      <h2 className="text-2xl ps-10 pt-4 py-1 text-gray-700 font-bold">
        Add Releases to Project
      </h2>
      <ReleasesSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        refreshState={async (page, searchQuery) => {
          await viewModel.refreshState(page, searchQuery);
        }}
        setPage={setPage}
      />
      <div className="h-96 overflow-y-auto">
        <TableContainer>
          <TableHeader cols={cols} />
          <TableBody>
            {data?.releases?.results?.map((release, index) => {
              return (
                <ReleasesModalRow
                  key={index}
                  release={release}
                  cols={cols}
                  addReleaseToProject={async (releaseId) => {
                    await viewModel.addReleaseToProject(releaseId);
                  }}
                />
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
      <ReleasesPagination
        count={data?.releases?.count}
        page={page}
        setPage={setPage}
        refreshState={async (page) => {
          await viewModel.refreshState(page, searchQuery);
        }}
      />
    </Modal>
  );
};

export default ReleasesModal;
