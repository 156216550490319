import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// generic
import { LoadingComponent } from "../../../../components/loading/LoadingScreen";

// feature specific
import ViewModel from "../../../../models/forArtists/main/projects/projectList/viewmodel";
import ProjectsTable from "./ProjectsTable";
import ProjectSearch from "./ProjectSearch";
import ProjectsPagination from "./ProjectsPagination";

export default function Projects() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useOutletContext();
  const navigate = useNavigate();

  // view model
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, data, (state) => {
      setData(state);
    })
  );
  useEffect(() => {
    viewModel.fetchState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token]);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  const handleClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className="">
      {data?.projects?.results && (
        <ProjectSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          refreshState={async (page, searchQuery) => {
            await viewModel.refreshState(page, searchQuery);
          }}
          setPage={setPage}
        />
      )}

      {data?.projects?.results?.length > 0 && (
        <div className="-mx-4 mt-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <ProjectsTable
            projects={data.projects.results}
            handleClick={(projectId) => handleClick(projectId)}
          />
        </div>
      )}

      <ProjectsPagination
        count={data?.projects?.count}
        page={page}
        setPage={setPage}
        refreshState={async (page) => {
          await viewModel.refreshState(page, searchQuery);
        }}
      />
    </div>
  );
}
