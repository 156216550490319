import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import FacebookLogin from "@greatsumini/react-facebook-login";

// generic
import GridContainer from "../../../../components/layout/grids/GridContainer";
import GridItem from "../../../../components/layout/grids/GridItem";

// feature specific
import ViewModel from "../../../../models/forArtists/main/dataSources/viewmodel";

const Index = () => {
  const { token } = useOutletContext();
  const fbAppId = process.env.REACT_APP_FB_APP_ID;

  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel, token]);

  const handleGadsConnect = async () => {
    await viewModel.openGadsDialog();
  };

  const handleTiktokConnect = async () => {
    await viewModel.openTiktokDialog();
  };

  const handleSuccess = async (fbUser) => {
    console.log("fbUser: ", fbUser);
    await viewModel.saveFbAuthToBackend(fbUser);
  };

  return (
    <>
      {data && console.log("data: ", data)}
      <div className="container mx-auto px-4 py-20">
        <div className="py-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-3xl">Connect my data</h1>
            </div>
          </div>
          <p className="py-2 text-gray-500">
            To get data for reporting, select one of the app integrations below
            and connect accounts.
          </p>
        </div>
        <div className="py-4">
          <GridContainer>
            <FacebookLogin
              appId={fbAppId}
              initParams={{
                cookie: false,
                localStorage: false,
                version: "v18.0",
                xfbml: true,
              }}
              scope="public_profile,email,business_management,pages_read_engagement,catalog_management,instagram_basic,instagram_manage_insights"
              onSuccess={handleSuccess}
              onFail={(error) => {
                console.log("Login Failed!", error);
              }}
              onProfileSuccess={(response) => {
                console.log("Get Profile Success!", response);
              }}
              render={({ onClick }) => (
                <GridItem
                  text="Meta Ads"
                  handleClick={onClick}
                  isConnected={data?.data?.meta}
                />
              )}
            />
            <GridItem
              text="Google Ads"
              handleClick={handleGadsConnect}
              isConnected={data?.data?.google}
            />
            <GridItem
              text="Tiktok Ads"
              handleClick={handleTiktokConnect}
              isConnected={data?.data?.tiktok}
            />
          </GridContainer>
        </div>
      </div>
    </>
  );
};

export default Index;
