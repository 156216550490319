import { useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectSearchOrCreate({
  label = "",
  initialOption,
  options,
  updateObject,
  createNew,
}) {
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    // Check if initialOption is not undefined before setting it
    if (initialOption !== undefined) {
      setSelected(initialOption);
      setQuery(initialOption ? initialOption.name : "");
    } else {
      // Handle the case when initialOption is undefined to avoid uncontrolled to controlled switch
      setSelected(null); // Ensure this is consistent with the component's expected state structure
      setQuery(""); // Reset or maintain initial state
    }
  }, [initialOption]);

  console.log("query: ", query);

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.name.toLowerCase().includes(query.toLowerCase())
        );

  const handleSelectChange = async (selection) => {
    console.log("selection: ", selection);
    // Determine if the selection is a new creation
    if (selection === query) {
      console.log("create new: ", query);
      const res = await createNew(query);
      if (res) {
        setSelected(res);
        setQuery(res.name); // Update the query to reflect the name of the newly created item
      }
    } else {
      updateObject(selection);
      setSelected(selection);
      setQuery(selection.name); // Update the query to reflect the selected item's name
    }
  };

  return (
    <>
      {/* {console.log("query", query)} */}
      <Combobox as="div" value={selected} onChange={handleSelectChange}>
        {label && (
          <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </Combobox.Label>
        )}
        <div className="relative">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            value={query} // Control the input with the query state
            displayValue={(item) => (item ? item.name : "")}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {(filteredOptions?.length > 0 || query?.length > 0) && (
            <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.id}
                  value={option}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {option.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}

              {/* create new option */}
              <Combobox.Option
                key={"newObjectToCreate123"}
                value={query}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {`Create new: "${query}"`}
                    </span>
                  </>
                )}
              </Combobox.Option>
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  );
}
