export default class ArtistAccountViewModel {
  constructor(token, artistId, state, updateState) {
    this.artistId = artistId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchTypes = async () => {
    return await this._get("api/v1/products/types/", true);
  };

  _fetchData = async () => {
    return await this._get(`api/v1/artists/${this.artistId}/products/`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData(), this._fetchTypes()]).then(
      (values) => {
        return {
          data: values[0],
          types: values[1],
        };
      }
    );
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  addProduct = async (product) => {
    return await fetch(this._createUrl("api/v1/products/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(product),
    })
      .then(async (response) => {
        let newProduct = await response.json();
        console.log(newProduct);
        this.state.data.products.unshift(newProduct);
        this._callUpdateState();
        return newProduct;
      })
      .catch((error) => {
        console.error("Error updating product", error);
      });
  };

  updateProduct = async (product) => {
    console.log(product);
    return await fetch(this._createUrl(`api/v1/products/${product.id}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(product),
    })
      .then(async (response) => {
        console.log(response);
        let product = await response.json();
        console.log("product: ", product);
        console.log("this.state: ", this.state);
        this.state.data.products = this.state.data.products.map((p) => {
          if (p.id === product.id) {
            return product;
          }
          return p;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating product", error);
        return false;
      });
  };

  // Deletes an existing task from the artist and updates the state
  deleteTask = async (taskId) => {
    console.log(taskId);
    try {
      const response = await fetch(this._createUrl(`api/v1/tasks/${taskId}/`), {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      });

      console.log(response);

      if (!response.status === 204) {
        console.log("not ok");
        // If the response is not ok, throw an error
        throw new Error(
          `Failed to delete task, status code: ${response.status}`
        );
      }

      // Remove the task from the local state
      this.state.tasks = this.state.tasks.filter((task) => task.id !== taskId);
      this._callUpdateState();
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error deleting task", error);
      return false; // Return false to indicate failure
    }
  };
}
