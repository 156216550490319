import { Outlet, useOutletContext } from "react-router-dom";
import {
  ChartBarIcon,
  MusicalNoteIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import Sidebar from "../../../../components/layout/navigation/sidebar/Index";

const navItems = [
  { name: "Home", href: "", icon: HomeIcon, end: true },
  {
    name: "Royalties",
    href: "royalties/",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Ingestion",
    href: "ingestion/",
    icon: MusicalNoteIcon,
    end: false,
  },
];

export default function StaffRoot() {
  const { token } = useOutletContext();

  return (
    <>
      <div className="flex h-[calc(100vh-4rem)]">
        {/* Sidebar */}
        <aside className="hidden lg:flex flex-grow">
          <Sidebar navItems={navItems} />
        </aside>

        {/* Main Content */}
        <main className="w-full flex-grow overflow-y-auto p-4">
          <div className="flex-1 py-20">
            <Outlet context={{ token }} />
          </div>
        </main>
      </div>
    </>
  );
}
