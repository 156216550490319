export default class UserViewModel {
  constructor(token, state, updateState) {
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _post = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "POST",
      headers: this._standardActionHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _verifyToken = async () => {
    return await this._post(`api/v1/otp/token/verify/`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._verifyToken()]).then((values) => {
      return {
        user: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  // In ProjectsViewModel.js
  logout = async () => {
    return await this._post(`api/v1/otp/logout/`, false)
      .then((response) => {
        localStorage.removeItem("token"); // Clear token on successful logout
        return response;
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        throw error; // Rethrow to handle it in the component
      });
  };
}
