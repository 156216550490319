const TableCell = ({ display, children, handleClick, hover = false }) => {
  return (
    <td
      onClick={handleClick}
      className={`border-b border-gray-200 whitespace-nowrap p-2 text-sm text-gray-500 ${display} ${
        hover && "hover:bg-gray-50"
      } ${handleClick && "cursor-pointer"}`}
    >
      {children}
    </td>
  );
};

export default TableCell;
