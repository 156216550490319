import { useState, useEffect } from "react";

// generic
import { DatePickerWidget } from "../../../../components/forms/DatePickerWidget";
import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";
import InputField from "../../../../components/forms/InputField";
import SelectSearchOrCreate from "../../../../components/forms/SelectSearchOrCreate";
import SelectSearch from "../../../../components/forms/SelectSearch";
import { formatDate, createDateAsUTC } from "../../../../utils/dateUtils";
import SelectField from "../../../../components/forms/SelectField";

export default function ShowForm({
  addShow,
  updateShow,
  artistId,
  tours,
  venues,
  show = null,
  setOpen,
  setShowForm,
  createTour,
  showStatuses,
}) {
  // Initialize form state with either an empty form or the provided show data
  const initialFormData = {
    name: "",
    show_date: null,
    on_sale_date: null,
    link: "",
    artist_id: artistId,
    // Add more fields as necessary
  };

  const [formData, setFormData] = useState(
    show
      ? {
          ...show,
          show_date: show.show_date ? show.show_date : null,
          on_sale_date: show.on_sale_date ? show.on_sale_date : null,
        }
      : initialFormData
  );

  // Generic handler for form inputs
  const handleChange = (value, name) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateTour = async (name) => {
    const newTour = await createTour(name);
    console.log("newTour: ", newTour);
    if (newTour) {
      setFormData((prev) => ({ ...prev, tour: newTour }));
      return newTour;
    }
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    let requestBody = {
      ...formData,
      artist_id: artistId,
      status_id: formData.status ? formData.status.id : null,
      tour_id: formData.tour ? formData.tour.id : null,
      show_date: formData.show_date ? formData.show_date : null,
      on_sale_date: formData.on_sale_date ? formData.on_sale_date : null,
    };

    console.log("requestBody: ", requestBody);

    const response = show
      ? await updateShow(requestBody)
      : await addShow(requestBody);
    console.log("Response: ", response);

    if (setOpen) setOpen(false);
    if (setShowForm) setShowForm(false);
    setFormData(initialFormData); // Reset the form
  };

  // Specialized handler for date changes
  const handleDateChange = (date, name) => handleChange(date, name);

  return (
    <div className="m-1 px-4 py-2 border rounded-md">
      <form onSubmit={handleSubmit}>
        <InputField
          label="Name"
          type="text"
          name="name"
          value={formData.name || ""}
          onChange={(e) => handleChange(e.target.value, e.target.name)}
        />
        <SelectField
          label="Status"
          options={showStatuses}
          selected={formData.status}
          handleChange={(value) => handleChange(value, "status")}
        />
        <SelectSearchOrCreate
          label="Tour"
          initialOption={formData.tour}
          options={tours}
          updateObject={(value) => handleChange(value, "tour")}
          createNew={handleCreateTour}
        />
        <SelectSearch
          label="Venue"
          initialOption={formData.venue}
          options={venues}
          onSelectChange={(value) => handleChange(value, "venue")}
        />
        <DatePickerWidget
          label="Show date"
          name="show_date"
          selected={formData.show_date}
          onChange={(date) => handleDateChange(date, "show_date")}
        />
        <DatePickerWidget
          label="On sale date"
          name="on_sale_date"
          selected={formData.on_sale_date}
          onChange={(date) => handleDateChange(date, "on_sale_date")}
        />
        <InputField
          label="Capacity"
          type="number"
          name="capacity"
          value={formData.capacity || ""}
          onChange={(e) => handleChange(e.target.value, e.target.name)}
        />
        <InputField
          label="Link"
          type="text"
          name="link"
          value={formData.link || ""}
          onChange={(e) => handleChange(e.target.value, e.target.name)}
        />
        <div className="p-2 pt-4 flex justify-between">
          <PrimaryButton text="Save" type="submit" />
        </div>
      </form>
    </div>
  );
}
