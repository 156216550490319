export default function HeadingEditable({ name, value, onChange }) {
  return (
    <>
      <input
        onChange={onChange}
        id={name}
        name={name}
        type="text"
        value={value}
        className="text-5xl font-bold cursor-pointer hover:bg-indigo-50"
      />
    </>
  );
}
