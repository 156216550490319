import { useEffect, useState } from "react";

import HomeViewModel from "../../models/forFans/homeViewModel";
import ReleaseList from "../../components/forFans/artists/ReleaseList";
import ContextList from "../../components/forFans/ContextList";
import ShowList from "../../components/forFans/artists/ShowList";
import ArtistTileList from "../../components/forFans/artists/ArtistTileList";
import { LoadingComponent } from "../../components/loading/LoadingScreen";
import ZantipiCat from "../../assets/images/Zantipi_Cat_no_zzz_screen_res.png";

export default function FansRoot() {
  const token = localStorage.getItem("token");
  const [homeViewData, setHomeViewData] = useState();
  const [homeViewModel, setHomeViewModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    const viewModel = new HomeViewModel(token, homeViewData, (state) =>
      setHomeViewData(state)
    );
    setHomeViewModel(viewModel);
  }, [token, homeViewData]);

  useEffect(() => {
    if (!homeViewData) {
      if (homeViewModel) {
        homeViewModel.fetchState().then(() => {
          setLoading(false); // Set loading to false after data is fetched
        });
      }
    }
  }, [homeViewModel, homeViewData]);

  if (!homeViewData) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center px-20 pt-10 pb-18 mx-auto w-full max-w-2xl">
        <img
          src={ZantipiCat}
          alt="Zantipi Cat"
          className="w-3/5 sm:w-1/2 object-cover rounded-lg"
        />
      </div>

      {homeViewData?.data?.recent_contexts?.length > 1 && (
        <>
          {/* listen again */}
          <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <h2 className="text-xl font-bold text-gray-600 px-3">
              listen again
            </h2>
            <ContextList
              contexts={homeViewData.data.recent_contexts}
              setLoading={setLoading}
            />
          </div>
        </>
      )}

      {homeViewData?.data?.top_releases && (
        <>
          {/* top releases */}
          <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <h2 className="text-xl font-bold text-gray-600 px-3">
              top releases
            </h2>
            <ReleaseList
              releases={homeViewData.data.top_releases}
              setLoading={setLoading}
              showArtist={true}
            />
          </div>
        </>
      )}

      {homeViewData?.data?.never_played_releases && (
        <>
          {/* never played releases */}
          <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <h2 className="text-xl font-bold text-gray-600 px-3">
              something you've never heard before :)
            </h2>
            <ReleaseList
              releases={homeViewData.data.never_played_releases}
              setLoading={setLoading}
              showArtist={true}
            />
          </div>
        </>
      )}

      {homeViewData?.data?.artists && (
        <>
          {/* artists */}
          <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <h2 className="text-xl font-bold text-gray-600 px-3">
              top artists
            </h2>
            {homeViewData?.data?.artists && (
              <ArtistTileList
                artists={homeViewData.data.artists}
                setLoading={setLoading}
              />
            )}
          </div>
        </>
      )}

      {homeViewData?.data?.shows && (
        <>
          {/* shows */}
          {/* <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold text-gray-600 py-2">
                Shows near you
              </h2>
              <ShowList
                shows={homeViewData.data.shows}
                setLoading={setLoading}
              />
            </div> */}
        </>
      )}
    </>
  );
}
