import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import ViewModel from "../../../../models/forArtists/main/staff/royaltiesViewModel.js";
import ScorecardsOverview from "../../../../components/analytics/scorecards/ScorecardsOverview";
import BarChartDynamic from "../../../../components/analytics/charts/BarChartDynamic";

export default function Royalties() {
  const { token } = useOutletContext();

  const [royaltyViewData, setRoyaltyViewData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, royaltyViewData, (state) => {
      setRoyaltyViewData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel]);

  return (
    <>
      <div className="p-10 justify-center w-1/2">
        <div className="flex items-center justify-start gap-2">
          <h1 className="text-5xl font-bold">Royalties</h1>
        </div>
        <div className="flex items-center justify-start gap-2">
          <p className="font-light text-gray-500">
            Questions that need answering:
          </p>
          <ul>
            <li>Total subscribers</li>
            <li>Total subscriber revenue</li>
            <li>Total payout owed for the month</li>
            <li>Zantipi total, artist revenue total</li>
            <li>DAUs / MAUs</li>
            <li>New subscribers</li>
            <li>Cost per new subscriber</li>
          </ul>
        </div>

        {/* scorecards */}
        <div className="pb-10">
          {/* {royaltyViewData?.data?.scorecards && (
            <ScorecardsOverview data={royaltyViewData.data.scorecards} />
          )} */}
        </div>

        {/* Top tracks */}
        <div className="py-4">
          {royaltyViewData && console.log(royaltyViewData)}
          {royaltyViewData?.data?.results && (
            <BarChartDynamic
              data={royaltyViewData.data.results}
              title="Top tracks"
              x_label="name"
              y_label="play_count"
            />
          )}
        </div>
      </div>
    </>
  );
}
