import { useEffect } from "react";

import SimpleSearchBar from "../../../../../components/search/SimpleSearchBar";

const CampaignsSearch = ({
  searchQuery,
  setSearchQuery,
  refreshState,
  setPage,
}) => {
  useEffect(() => {
    refreshState(1, searchQuery);
    setPage(1);
  }, [searchQuery]);

  const handleChange = (query) => {
    setSearchQuery(query);
  };

  return (
    <SimpleSearchBar placeholder={"Search..."} handleChange={handleChange} />
  );
};

export default CampaignsSearch;
