import { useState } from "react";
import { useOutletContext } from "react-router-dom";

// generic
import TableRow from "../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../components/layout/tables/TableCell";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import SelectFieldStealth from "../../../../../components/forms/SelectFieldStealth";

const CampaignTableRow = ({
  campaign,
  setSelectedCampaign,
  removeCampaign,
  updateCampaign,
  projectShows,
  projectReleases,
  projectCollections,
  projectPlatforms,
}) => {
  const [formData, setFormData] = useState({
    project_show: campaign.project_show,
    project_release: campaign.project_release,
    project_collection: campaign.project_collection,
    project_platform: campaign.project_platform,
  });

  const handleSelectChange = async (selectedValue, fieldName) => {
    const updatedFormData = { ...formData, [fieldName]: selectedValue };
    setFormData(updatedFormData);

    // Prepare the data for the updateCampaign function
    const campaignData = {
      project_show_id: updatedFormData.project_show?.id,
      project_release_id: updatedFormData.project_release?.id,
      project_collection_id: updatedFormData.project_collection?.id,
      project_platform_id: updatedFormData.project_platform?.id,
    };

    // Call the updateCampaign function with the new data
    const updated = await updateCampaign(campaign, campaignData);
    if (updated) {
      console.log("Campaign updated successfully");
    }
  };

  return (
    <>
      <TableRow>
        <TableCell
          handleClick={() => setSelectedCampaign(campaign)}
          hover={true}
        >
          {campaign.name}
        </TableCell>

        <TableCell display={"hidden sm:table-cell hover:bg-gray-50"}>
          {projectPlatforms && (
            <SelectFieldStealth
              options={projectPlatforms}
              selected={formData.project_platform || null}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "project_platform")
              }
            />
          )}
        </TableCell>

        {campaign?.has_adgroup_project_show ? (
          <TableCell display="hidden sm:table-cell" hover={false}>
            Set at adgroup level
          </TableCell>
        ) : projectShows ? (
          <TableCell display="hidden sm:table-cell" hover={true}>
            <SelectFieldStealth
              options={projectShows}
              selected={formData.project_show || null}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "project_show")
              }
            />
          </TableCell>
        ) : null}

        {campaign?.has_adgroup_project_release ? (
          <TableCell display="hidden sm:table-cell" hover={false}>
            Set at adgroup level
          </TableCell>
        ) : projectReleases ? (
          <TableCell display="hidden sm:table-cell" hover={true}>
            <SelectFieldStealth
              options={projectReleases}
              selected={formData.project_release || null}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "project_release")
              }
            />
          </TableCell>
        ) : null}

        {campaign?.has_adgroup_project_collection ? (
          <TableCell display="hidden sm:table-cell" hover={false}>
            Set at adgroup level
          </TableCell>
        ) : projectCollections ? (
          <TableCell display="hidden sm:table-cell" hover={true}>
            <SelectFieldStealth
              options={projectCollections}
              selected={formData.project_collection || null}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "project_collection")
              }
            />
          </TableCell>
        ) : null}

        <TableCell>
          <SecondaryButton
            text={"remove"}
            handleClick={() => {
              removeCampaign(campaign.id);
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default CampaignTableRow;
