import { useState } from "react";
import { PlusCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";

export default function FavoriteIcon({
  isFavorite,
  favoriteRelease,
  favoriteReleaseTrack,
  releaseTrackId,
}) {
  const [isChecked, setIsChecked] = useState(isFavorite);

  const handleClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    console.log("clicked");
    if (favoriteReleaseTrack) {
      favoriteReleaseTrack(!isFavorite, releaseTrackId);
    } else if (favoriteRelease) {
      favoriteRelease(!isFavorite);
    }

    setIsChecked(!isChecked);
  };

  return (
    <>
      <button onClick={handleClick}>
        {isChecked ? (
          <CheckCircleIcon
            className={`h-7 text-green-600 hover:text-green-400`}
          />
        ) : (
          <PlusCircleIcon className={`h-7 text-gray-600 hover:text-gray-400`} />
        )}
      </button>
    </>
  );
}
