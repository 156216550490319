import { PencilIcon } from "@heroicons/react/20/solid";

export default function Tile({ imageUrl, alt, handleClick }) {
  return (
    <div
      onClick={handleClick}
      className={`relative md:flex md:items-start border rounded-md ${
        handleClick ? "cursor-pointer" : ""
      } h-80 w-80 md:h-60 md:w-60 rounded-lg`}
    >
      {imageUrl ? (
        <img className="object-cover rounded-lg" src={imageUrl} alt={alt} />
      ) : (
        <div className="object-cover" alt="upload image to here" />
      )}
      <div className="absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-60 opacity-0 hover:opacity-100">
        <PencilIcon className="text-white h-8 w-8" />
        <span className="text-white text-lg font-semibold">Change</span>
      </div>
    </div>
  );
}
