import { useState } from "react";

import PrimaryButton from "../../UI/buttons/PrimaryButton";
import SecondaryButton from "../../UI/buttons/SecondaryButton";

export default function StackedListItem({
  item,
  btnPrimaryText,
  btnSecondaryText,
  btnPrimaryAction,
  btnSecondaryAction,
}) {
  console.log("item", item);

  const [isActioned, setIsActioned] = useState(false);
  const handlePrimaryClick = () => {
    btnPrimaryAction(item);
    setIsActioned(true);
  };

  const handleSecondaryClick = () => {
    btnSecondaryAction(item);
    // setIsActioned(true);
  };
  return (
    <li className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex-col justify-center items-start gap-x-3">
          <p className="text-sm font-normal leading-6 text-gray-900">
            {item?.name}
          </p>
          {item.gads_manager && (
            <p className="text-sm text-gray-400">Manager</p>
          )}
        </div>
      </div>
      <div className="flex flex-none justify-center items-center gap-x-4">
        {item?.is_added_to_org ? (
          <>
            {btnSecondaryText ? (
              <SecondaryButton
                text={btnSecondaryText}
                handleClick={handleSecondaryClick}
              />
            ) : (
              "added"
            )}
          </>
        ) : (
          <>
            {btnPrimaryText && (
              <PrimaryButton
                text={btnPrimaryText}
                handleClick={handlePrimaryClick}
              />
            )}
          </>
        )}
      </div>
    </li>
  );
}
