import { useState, useEffect, createContext } from "react";
import { useNavigate, Outlet, ScrollRestoration } from "react-router-dom";
import LoadingScreen from "../../components/loading/LoadingScreen";

export const UserContext = createContext(null);

export default function MainLayoutContext() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (token) {
      fetch(`${baseURL}api/v1/otp/token/verify/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to verify token");
          }
          return response.json();
        })
        .then((data) => {
          setUser(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error verifying token:", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token, baseURL, navigate, setUser, setLoading]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <UserContext.Provider value={user}>
        <ScrollRestoration />
        <Outlet context={token} />
      </UserContext.Provider>
    </>
  );
}
