export default class dataSourcesViewModel {
  constructor(token, state, updateState) {
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.site_url = process.env.REACT_APP_ARTISTS_SITE_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    console.log("this.token", this.token);
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(`api/v1/user/data-sources/`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  openTiktokDialog = async () => {
    const redirectUri = encodeURIComponent(`${this.site_url}data/tiktok/`);
    const appId = "7159401351615610882";
    const url = `https://business-api.tiktok.com/portal/auth?app_id=${appId}&state=your_custom_params&redirect_uri=${redirectUri}`;
    window.location.href = url;
  };

  openGadsDialog = async () => {
    const clientId =
      "169079934069-33tfemohbvfo3olki8ifl5ig42m2mf6i.apps.googleusercontent.com";
    const redirectUri = encodeURIComponent(`${this.site_url}data/google/`);
    const scopes = [
      "https://www.googleapis.com/auth/adwords",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "openid",
    ];
    const scopeString = encodeURIComponent(scopes.join(" "));

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&scope=${scopeString}&redirect_uri=${redirectUri}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
  };

  saveFbAuthToBackend = async (fbUser) => {
    console.log("fbUser", fbUser);
    return await fetch(this._createUrl("api/v1/meta/save-auth-to-db/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify({ res: fbUser }),
    })
      .then(async (response) => {
        await response.json();
        this.state.data.hasAuth = true;
        this._callUpdateState();
        return;
      })
      .catch((error) => {
        console.error("Error saving user fb auth", error);
      });
  };

  disconnectAuth = async () => {
    console.log("disconnecting from Facebook yeah");
    try {
      const response = await fetch(
        `${this.url_begin}api/v1/meta/disconnect-auth/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      );
      if (response.ok) {
        console.log("Disconnected from Facebook didn't i");
        this.state.data.hasAuth = false;
        this.state.data.access_token = null;
        console.log("this.state.data", this.state.data);
        this._callUpdateState();
        return true;
      }
    } catch (error) {
      console.error("Error disconnecting from Facebook:", error);
    }
  };

  sendTokenToBackend = async (accessToken) => {
    return await fetch(this._createUrl("api/v1/meta/auth/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify({ accessToken }),
    })
      .then(async (response) => {
        let res = await response.json();
        console.log(res);
        return;
      })
      .catch((error) => {
        console.error("Error updating task", error);
      });
  };
}
