const TableContainer = ({
  fixed = false,
  handleScroll,
  children,
  fixedHeight = true,
}) => {
  return (
    <div
      onScroll={handleScroll}
      className={`flow-root ${fixedHeight && "mt-8 h-96 overflow-y-auto"}`}
    >
      <div className="inline-block min-w-full py-2 align-middle">
        <table
          className={`min-w-full border-separate border-spacing-0 ${
            fixed && "table-fixed w-full"
          }`}
        >
          {children}
        </table>
      </div>
    </div>
  );
};

export default TableContainer;
