export default function Checkbox({ isChecked, onChange, id }) {
  return (
    <input
      id={`checkbox-${id}`}
      type="checkbox"
      className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      checked={isChecked}
      onChange={onChange}
      aria-describedby={`checkbox-${id}`}
      name={`checkbox-${id}`}
    />
  );
}
