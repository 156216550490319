import { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";

// generic
import Modal from "../../../../../../components/UI/modals/Modal";
import { useShiftKey } from "../../../../../../components/hooks/useShiftKey";
import { LoadingComponent } from "../../../../../../components/loading/LoadingScreen";

// feature specific
import ViewModel from "../../../../../../models/forArtists/main/projects/analytics/labelling/creatives/viewmodel";
import Controls from "./Controls";
import CreativeSearch from "./CreativeSearch";
import CreativeTable from "./CreativeTable";
import CreativePagination from "./CreativePagination";

export default function CreativeModal({ isModalOpen, setIsModalOpen }) {
  const { token } = useOutletContext();
  let { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchProjectState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, projectId]);

  const [selected, setSelected] = useState([]);
  const [lastChecked, setLastChecked] = useState(null);
  const isShiftPressed = useShiftKey();

  const handleSelectChange = (e, index) => {
    const { id, checked } = e.target;
    let newSelected = [...selected];
    const adgroupId = id.toString();

    if (checked) {
      if (!newSelected.includes(adgroupId)) {
        newSelected.push(adgroupId);
      }
    } else {
      newSelected = newSelected.filter((item) => item !== adgroupId);
    }

    // Adjusted logic to use isShiftPressed state
    if (isShiftPressed && lastChecked !== null && lastChecked !== index) {
      const start = Math.min(lastChecked, index);
      const end = Math.max(lastChecked, index);
      const allIds = data.ads.results
        .slice(start, end + 1)
        .map((ad) => ad.id.toString());

      if (checked) {
        newSelected = [...new Set([...newSelected, ...allIds])];
      } else {
        newSelected = newSelected.filter((id) => !allIds.includes(id));
      }
    }

    setSelected(newSelected);
    setLastChecked(index);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  if (isLoading) {
    return <LoadingComponent />;
  }

  console.log("data in creative modal: ", data);

  return (
    <Modal open={isModalOpen} setOpen={setIsModalOpen} fullWidth={true}>
      <h2 className="text-2xl ps-10 pt-4 py-1 text-gray-700 font-bold">
        Label Creatives
      </h2>

      <CreativeSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        refreshState={async (page, searchQuery) => {
          await viewModel.refreshAds(page, searchQuery);
        }}
        setPage={setPage}
      />

      <Controls
        selected={selected}
        setSelected={setSelected}
        assetLabels={data?.assetLabels}
        createBulkAssetLabel={async (assetLabelName) => {
          await viewModel.createBulkAssetLabel(selected, assetLabelName);
        }}
        addBulkAssetLabelToAd={async (assetLabel) => {
          await viewModel.addBulkAssetLabelToAd(selected, assetLabel.id);
        }}
        adTypes={data?.adTypes}
        createBulkAdType={async (adTypeName) => {
          await viewModel.createBulkAdType(selected, adTypeName);
        }}
        addBulkAdTypeToAd={async (adType) => {
          await viewModel.addBulkAdTypeToAd(selected, adType.id);
        }}
      />

      <CreativeTable
        ads={data?.ads?.results}
        assetLabels={data?.assetLabels}
        adTypes={data?.adTypes}
        sortAds={async (key, sortAscending) => {
          await viewModel.sortAds(key, sortAscending);
        }}
        selected={selected}
        handleSelectChange={handleSelectChange}
        createAssetLabel={async (adId, assetLabelName) => {
          await viewModel.createAssetLabel(adId, assetLabelName);
        }}
        addAssetLabelToAd={async (adId, assetLabel) => {
          await viewModel.addAssetLabelToAd(adId, assetLabel.id);
        }}
        createAdType={async (adId, adTypeName) => {
          await viewModel.createAdType(adId, adTypeName);
        }}
        addAdTypeToAd={async (adId, adType) => {
          await viewModel.addAdTypeToAd(adId, adType.id);
        }}
      />

      <CreativePagination
        count={data?.ads?.count}
        page={page}
        setPage={setPage}
        refreshState={async (page) => {
          await viewModel.refreshAds(page, searchQuery);
        }}
      />
    </Modal>
  );
}
