export default class ProjectsViewModel {
  constructor(token, projectId, state, updateState, searchParams) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchCategories = async () => {
    return await this._get("api/v1/tasks/categories/", true);
  };

  _fetchStatuses = async () => {
    return await this._get("api/v1/tasks/statuses/", true);
  };

  _fetchTasks = async () => {
    let url = `api/v1/tasks/?project_id=${this.projectId}`;

    // Check if there are search params and append them if they exist
    if (this.searchParams) {
      url += `&${this.searchParams}`;
    }

    return await this._get(url, false);
  };

  _fetchProject = async () => {
    return await this._get(`api/v1/projects/${this.projectId}`, true);
  };

  _fetchUsers = async () => {
    return await this._get(`api/v1/projects/${this.projectId}/users/`, true);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([
      this._fetchCategories(),
      this._fetchStatuses(),
      this._fetchTasks(),
      this._fetchProject(),
      this._fetchUsers(),
    ]).then((values) => {
      return {
        categories: values[0],
        statuses: values[1],
        tasks: values[2],
        project: values[3],
        users: values[4],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  updateSearchParams = async (newSearchParams) => {
    this.searchParams = newSearchParams;
    this.state = await this._fetchInitialCalls();
    this._callUpdateState();
  };

  fetchMoreTasks = async () => {
    if (!this.state || !this.state.tasks.next) {
      return false;
    }

    const url = this.state.tasks.next;

    return await fetch(url, {
      method: "GET",
      headers: this._standardHeaders(),
    })
      .then(async (response) => {
        let moreTasks = await response.json();
        // Use concat or spread operator to create a new array with the additional tasks
        const updatedResults = [
          ...this.state.tasks.results,
          ...moreTasks.results,
        ];
        // Update the state indirectly, assuming _callUpdateState manages this
        this.state.tasks.results = updatedResults;
        this.state.tasks.next = moreTasks.next;
        this._callUpdateState(); // Ensure this method updates the component's state as needed
        return true;
      })
      .catch((error) => {
        console.error("Error fetching more tasks", error);
        return false;
      });
  };

  sortTasks = (key) => {
    if (!this.state || !this.state.tasks.results) {
      return;
    }

    let sortedTasks;
    const isAscending = this.sortKey === key && !this.isAscending;

    if (key === "title") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        let valueA = a[key].toLowerCase(); // Assuming titles are strings
        let valueB = b[key].toLowerCase();

        if (valueA < valueB) return isAscending ? -1 : 1;
        if (valueA > valueB) return isAscending ? 1 : -1;
        return 0;
      });
    } else if (key === "due_datetime") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        // Check if either value is an empty string and handle sorting explicitly
        if (
          (a[key] === "" || a[key] === null) &&
          (b[key] !== "" || b[key] !== null)
        ) {
          return isAscending ? 1 : -1; // For ascending, empty strings come first; for descending, they come last.
        } else if (
          (a[key] !== "" || a[key] !== null) &&
          (b[key] === "" || b[key] === null)
        ) {
          return isAscending ? -1 : 1; // Inverse of the above
        } else if (
          (a[key] === "" || a[key] === null) &&
          (b[key] === "" || b[key] === null)
        ) {
          return 0; // Both are empty, keep original order
        }

        // Both have values, compare as dates
        let valueA = new Date(a[key]).getTime();
        let valueB = new Date(b[key]).getTime();
        return isAscending ? valueA - valueB : valueB - valueA;
      });
    } else if (key === "project") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        const emailA = a.project?.name.toLowerCase() || "";
        const emailB = b.project?.name.toLowerCase() || "";

        if (emailA < emailB) return isAscending ? -1 : 1;
        if (emailA > emailB) return isAscending ? 1 : -1;
        return 0;
      });
    } else if (key === "assigned_to") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        const emailA = a.assigned_to?.email.toLowerCase() || "";
        const emailB = b.assigned_to?.email.toLowerCase() || "";

        if (emailA < emailB) return isAscending ? -1 : 1;
        if (emailA > emailB) return isAscending ? 1 : -1;
        return 0;
      });
    } else if (key === "category" || key === "status") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        const nameA = a[key]?.name.toLowerCase() || "";
        const nameB = b[key]?.name.toLowerCase() || "";

        if (nameA < nameB) return isAscending ? -1 : 1;
        if (nameA > nameB) return isAscending ? 1 : -1;
        return 0;
      });
    }

    this.state.tasks.results = sortedTasks;
    this.sortKey = key;
    this.isAscending = isAscending;
    this._callUpdateState();
  };

  addTask = async (task) => {
    return await fetch(this._createUrl("api/v1/tasks/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(task),
    })
      .then(async (response) => {
        let newTask = await response.json();
        this.state.tasks.results.unshift(newTask);
        this._callUpdateState();
        return newTask;
      })
      .catch((error) => {
        console.error("Error updating task", error);
      });
  };

  updateTask = async (task) => {
    return await fetch(this._createUrl(`api/v1/tasks/${task.id}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(task),
    })
      .then(async (response) => {
        let task = await response.json();
        this.state.tasks.results = this.state.tasks.results.map((t) => {
          if (t.id === task.id) {
            return task;
          }
          return t;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating task", error);
        return false;
      });
  };

  // Deletes an existing task from the project and updates the state
  deleteTask = async (taskId) => {
    try {
      const response = await fetch(this._createUrl(`api/v1/tasks/${taskId}/`), {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      });

      if (!response.status === 204) {
        // If the response is not ok, throw an error
        throw new Error(
          `Failed to delete task, status code: ${response.status}`
        );
      }

      // Remove the task from the local state
      this.state.tasks.results = this.state.tasks.results.filter(
        (task) => task.id !== taskId
      );
      this._callUpdateState();
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error deleting task", error);
      return false; // Return false to indicate failure
    }
  };
}
