import { useState } from "react";
import { Link, useOutletContext, useNavigate } from "react-router-dom";

import ReleaseTile from "./releases/ReleaseTile";
import ArtistTile from "./artists/ArtistTile";

export default function ContextList({ contexts, setLoading }) {
  const { audioPlayerViewModel } = useOutletContext();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(audioPlayerViewModel.isPlaying);

  const handleClick = (id, type) => {
    console.log("handleClick");
    setLoading(true);
    window.scrollTo(0, 0);
    navigate(`/${type}/${id}`);
  };

  // Add a function to handle play/pause click
  const handlePlayPauseClick = async (e, id, type) => {
    e.stopPropagation(); // Prevent triggering the navigation
    console.log("clicked");
    // Update logic based on type and id
  };

  const handleArtistClick = (artistId) => {
    // event.stopPropagation();
    window.scrollTo(0, 0);
    navigate(`/artists/${artistId}`);
  };

  return (
    <>
      {contexts && contexts.length > 0 && (
        <div className="">
          <ul className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {contexts.map((context) => {
              if (context.context_type === "release") {
                return (
                  <ReleaseTile
                    key={context.id}
                    release={context.context_data}
                    setLoading={setLoading}
                    onClick={() => handleClick(context.id, "releases")}
                    // Other props as needed
                  />
                );
              } else if (context.context_type === "artist") {
                return (
                  <ArtistTile
                    key={context.id}
                    artist={context.context_data}
                    handleClick={handleArtistClick}
                    // Other props as needed
                  />
                );
              } else if (context.context_type === "playlist") {
                return (
                  <ReleaseTile
                    key={context.id}
                    isRelease={false}
                    release={context.context_data}
                    setLoading={setLoading}
                    // onClick or other handlers as needed
                    // Other props as needed
                  />
                );
              }
            })}
          </ul>
        </div>
      )}
    </>
  );
}
