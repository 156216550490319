const Tooltip = ({ children, text }) => {
  return (
    <div className="group relative flex items-center">
      {children}
      <span className="absolute bottom-full left-1/2 mb-2 hidden min-w-[150px] w-auto p-2 text-sm text-white bg-indigo-600 rounded-md shadow-lg group-hover:block z-30 whitespace-normal -translate-x-1/2">
        {text}
      </span>
    </div>
  );
};

export default Tooltip;
