export default class ProjectDataViewModel {
  constructor(token, projectId, state, updateState) {
    this.projectId = projectId;
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.pagination = {
      next: null,
      previous: null, // Added to store the previous URL for pagination
    };
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(`api/v1/projects/${this.projectId}/data/`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return values[0];
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  refreshState = async () => {
    this.state = await this._fetchInitialCalls();
    this._callUpdateState();
    return this.state;
  };

  fetchAdgroups = async (campaignId) => {
    return await fetch(
      this._createUrl(
        `api/v1/projects/${this.projectId}/adgroups/?campaign_id=${campaignId}`
      ),
      {
        method: "GET",
        headers: this._standardHeaders(),
      }
    )
      .then(async (response) => {
        let adgroups = await response.json();
        this.state.adgroups = adgroups;
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error fetching adgroups", error);
        return false;
      });
  };

  removeAdaccountFromProject = async (projectAdaccountId) => {
    const response = await fetch(
      this._createUrl(`api/v1/project-adaccounts/${projectAdaccountId}/`),
      {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      }
    );

    if (response.status === 204) {
      // update the project_adaccounts state
      this.state.project_adaccounts = this.state.project_adaccounts.filter(
        (a) => a.id !== projectAdaccountId
      );

      this._callUpdateState();
    }
  };

  removeCampaignFromProject = async (campaignId) => {
    const response = await fetch(
      this._createUrl(`api/v1/projects/${this.projectId}/remove-campaign/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ campaign_id: campaignId }),
      }
    );

    if (response.ok) {
      let res = await response.json();
      let campaign = res.campaign;

      // update the campaigns state
      this.state.campaigns = this.state.campaigns.filter(
        (c) => c.id !== campaign.id
      );

      this._callUpdateState();
    }
  };

  removeShowFromProject = async (projectShowId) => {
    const response = await fetch(
      this._createUrl(`api/v1/project-shows/${projectShowId}/`),
      {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      }
    );

    if (response.status === 204) {
      // update the projectShows state
      this.state.project_shows = this.state.project_shows.filter(
        (s) => s.id !== projectShowId
      );

      this._callUpdateState();
    }
  };

  removeReleaseFromProject = async (projectReleaseId) => {
    const response = await fetch(
      this._createUrl(`api/v1/project-releases/${projectReleaseId}/`),
      {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      }
    );

    if (response.status === 204) {
      // update the projectReleases state
      this.state.project_releases = this.state.project_releases.filter(
        (r) => r.id !== projectReleaseId
      );

      this._callUpdateState();
    }
  };

  removeCollectionFromProject = async (projectCollectionId) => {
    const response = await fetch(
      this._createUrl(`api/v1/project-collections/${projectCollectionId}/`),
      {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      }
    );

    if (response.status === 204) {
      // update the projectCollections state
      this.state.project_collections = this.state.project_collections.filter(
        (c) => c.id !== projectCollectionId
      );

      this._callUpdateState();
    }
  };

  updateCampaign = async (campaign, formData) => {
    return await fetch(this._createUrl(`api/v1/campaigns/${campaign.id}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        let campaign = await response.json();
        this.state.campaigns = this.state.campaigns.map((c) => {
          if (c.id === campaign.id) {
            return campaign;
          }
          return c;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating campaign", error);
        return false;
      });
  };

  updateAdgroup = async (adgroup, formData) => {
    return await fetch(this._createUrl(`api/v1/adgroups/${adgroup.id}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        let adgroup = await response.json();
        console.log(adgroup);
        this.state.adgroups = this.state.adgroups.map((a) => {
          if (a.id === adgroup.id) {
            return adgroup;
          }
          return a;
        });
        this._callUpdateState();
        this._refreshCampaign(adgroup.campaign_id);
        return true;
      })
      .catch((error) => {
        console.error("Error updating adgroup", error);
        return false;
      });
  };

  _refreshCampaign = async (campaignId) => {
    return await fetch(this._createUrl(`api/v1/campaigns/${campaignId}/`), {
      method: "GET",
      headers: this._standardHeaders(),
    })
      .then(async (response) => {
        let campaign = await response.json();
        this.state.campaigns = this.state.campaigns.map((c) => {
          if (c.id === campaign.id) {
            return campaign;
          }
          return c;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error refreshing campaign", error);
        return false;
      });
  };
}
