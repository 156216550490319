import { useState, useEffect, createContext, useCallback } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import {
  FolderIcon,
  MoonIcon,
  UserGroupIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import ViewModel from "../../models/forArtists/userViewModel";
import LoadingScreen from "../../components/loading/LoadingScreen";

let appItems = [
  {
    id: 1,
    name: "Projects",
    description: "Manage ads and view analytics",
    path: "/projects",
    icon: FolderIcon,
  },
  {
    id: 2,
    name: "Artists",
    description: "Add shows, releases and merch to your profile",
    path: "/artists",
    icon: MoonIcon,
  },
  {
    id: 3,
    name: "Organizations",
    description: "Manage your organizations, users and billing",
    path: "/organizations",
    icon: UserGroupIcon,
  },
  {
    id: 4,
    name: "Staff",
    description: "Only for staff",
    path: "/staff",
    icon: XCircleIcon,
  },
];

export const UserContext = createContext(null);

export default function MainLayoutContext() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [userData, setUserData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, userData, (state) => {
      setUserData(state);
    })
  );

  useEffect(() => {
    // If there is no token, navigate to the sales page
    if (!token) {
      navigate("/sales");
      return;
    }

    // Fetch the user data
    viewModel
      .fetchState()
      .then((data) => {
        if (!data.user) {
          navigate("/sales");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        navigate("/sales");
      });
  }, [viewModel, token, userData, navigate]);

  const handleLogout = useCallback(() => {
    viewModel
      .logout()
      .then(() => {
        navigate("/sales"); // Navigate on successful logout
      })
      .catch((error) => {
        console.error("Error during logout:", error);
        // Handle any logout errors here
        navigate("/sales"); // Optionally navigate on error
      });
  }, [viewModel, navigate]); // Dependencies

  if (userData) {
    appItems = appItems.filter(
      (item) => userData.user.is_superuser || item.id !== 4
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <UserContext.Provider value={userData.user}>
        <Outlet context={{ token, handleLogout, appItems }} />
      </UserContext.Provider>
    </>
  );
}
