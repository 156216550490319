export default class ProjectViewModel {
  constructor(token, projectId, state, updateState) {
    this.projectId = projectId;
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.timeout = null;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(`api/v1/projects/${this.projectId}`);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return values[0];
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  refreshState = async (projectId) => {
    this.projectId = projectId;
    this.state = await this._fetchInitialCalls();
    this._callUpdateState();
    return this.state;
  };

  searchProjects = (query) => {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      const res = await this._get(`api/v1/projects/search/?q=${query}`);

      if (!res || res.error) {
        console.error(res ? res.error : "Failed to fetch projects");
        return;
      }

      // Update the state with the search results
      this.state.projects = res.results;
      this._callUpdateState();
    }, 300);
  };
}
