import { useEffect, useState } from "react";

// generic
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import SelectField from "../../../../../components/forms/SelectField";
import DeleteModal from "../../../../../components/UI/modals/DeleteModal";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../components/layout/tables/TableBody";
import TableRow from "../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../components/layout/tables/TableCell";

const cols = [
  { id: 1, name: "meta_business_manager", label: "Business Manager" },
  { id: 2, name: "" },
];

export default function BusinessManager({
  isLoading,
  org,
  fbUser,
  options,
  updateOrganization,
  addBusinessManagerToOrg,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    meta_business_manager: null,
  });

  useEffect(() => {
    setFormData({
      meta_business_manager: org.meta_business_manager,
    });
  }, [org]);

  const handleRedirect = () => {
    window.open("/data/", "_blank");
  };

  const handleSelect = async (item) => {
    setFormData({ meta_business_manager: item });
  };

  const handleSubmit = async () => {
    await addBusinessManagerToOrg(formData.meta_business_manager);
  };

  const handleRemoveBusManager = async () => {
    await updateOrganization({
      meta_business_manager_id: null,
    });
    setFormData({
      meta_business_manager: null,
    });
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!fbUser) {
    return (
      <div className="my-2">
        <p className="text-sm text-gray-500">
          (Re)Authenticate with Meta to connect your Business Manager or make
          changes to adaccounts (refresh when done)
        </p>
        <SecondaryButton text="Connect to Meta" handleClick={handleRedirect} />
      </div>
    );
  }

  if (!org.meta_business_manager) {
    return (
      <>
        <div className="w-1/2">
          <SelectField
            label={"Business Manager"}
            options={options}
            selected={formData.meta_business_manager}
            handleChange={handleSelect}
          />
          <div className="my-2">
            <PrimaryButton text="Save" handleClick={handleSubmit} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <TableContainer fixedHeight={false}>
        <TableHeader cols={cols} />
        <TableBody>
          <TableRow>
            <TableCell>{org?.meta_business_manager?.name}</TableCell>
            <TableCell>
              <SecondaryButton
                text={"remove"}
                handleClick={() => setOpenModal(true)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </TableContainer>

      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        onConfirm={handleRemoveBusManager}
        onCancel={() => setOpenModal(false)}
        title={"Remove Business Manager"}
        message={
          "Are you sure you want to remove? This will stop any data being pulled for reports."
        }
        confirmBtnText={"Remove"}
      />
    </>
  );
}
