import { Link, useOutletContext } from "react-router-dom";

// generic
import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";

// feature specific
import ProjectList from "../../../../features/forArtists/projects/projectList/Index";

export default function Projects() {
  const { user } = useOutletContext();

  console.log("user:", user);

  return (
    <div className="container mx-auto px-4 py-20">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Projects
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              The full list of your projects.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {user.has_active_organization ? (
              <Link
                to="/projects/create/"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                + Create
              </Link>
            ) : (
              <PrimaryButton text={"+ Create"} disabled={true} />
            )}
          </div>
        </div>

        <ProjectList />
      </div>
    </div>
  );
}
