export default class AudienceLabellingViewModel {
  constructor(token, projectId, state, updateState, searchParams) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      //   cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchAdgroups = async (page = 1, searchQuery = "") => {
    return await this._get(
      `api/v1/projects/${this.projectId}/get-adgroups/?page=${page}&q=${searchQuery}`,
      true
    );
  };

  _fetchAudiences = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/get-audiences/`,
      true
    );
  };

  _fetchInitialProjectCalls = async () => {
    return Promise.all([this._fetchAdgroups(), this._fetchAudiences()]).then(
      (values) => {
        return {
          adgroups: values[0],
          audiences: values[1],
        };
      }
    );
  };

  fetchProjectState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialProjectCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  refreshAdgroups = async (page = 1, searchQuery = "") => {
    this.state.adgroups = await this._fetchAdgroups(page, searchQuery);
    this._callUpdateState();
  };

  sortData = (data, key, ascending = true) => {
    console.log("data: ", data, "key: ", key, "ascending: ", ascending);

    return data.slice().sort((a, b) => {
      // Use slice() to create a shallow copy and avoid modifying the original array
      // Determine the values to compare, handling special keys "Adgroups" and "audience"
      let aValue, bValue;
      if (key === "Adgroup") {
        aValue = a.name;
        bValue = b.name;
      } else if (key === "Audience") {
        aValue = a.audience?.name || ""; // Use optional chaining to safely access name and provide a fallback
        bValue = b.audience?.name || "";
      } else {
        aValue = a[key];
        bValue = b[key];
      }

      // Compare the determined values for sorting
      if (aValue < bValue) {
        return ascending ? -1 : 1;
      }
      if (aValue > bValue) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  };

  sortAdgroups = async (key, ascending = true) => {
    this.state.adgroups.results = this.sortData(
      this.state.adgroups.results,
      key,
      ascending
    );
    this._callUpdateState();
  };

  _post = async (path, body) => {
    return await fetch(this._createUrl(path), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createAudience = async (adgroupId, audienceName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-audience/`,
      {
        adgroup_id: adgroupId,
        audience_name: audienceName,
      }
    )
      .then((res) => {
        let audience = res;

        // Add the new audience to the state
        this.state.audiences.push(audience);

        // Update the adgroup with the new audience
        this.state.adgroups.results = this.state.adgroups.results.map(
          (adgroup) => {
            if (adgroup.id === adgroupId) {
              adgroup.audience = audience;
            }
            return adgroup;
          }
        );
        this._callUpdateState();
        return audience;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createBulkAudience = async (adgroupIds, audienceName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-bulk-audience/`,
      {
        adgroup_ids: adgroupIds,
        audience_name: audienceName,
      }
    )
      .then((res) => {
        let audience = res;

        // Add the new audience to the state
        this.state.audiences.push(audience);

        // Update the adgroup with the new audience
        this.state.adgroups.results = this.state.adgroups.results.map(
          (adgroup) => {
            if (adgroupIds.includes(adgroup.id)) {
              adgroup.audience = audience;
            }
            return adgroup;
          }
        );
        this._callUpdateState();
        return audience;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addAudienceToAdgroup = async (adgroupId, audienceId) => {
    console.log("adgroupId: ", adgroupId, "audienceId: ", audienceId);
    return await this._post(`api/v1/adgroups/${adgroupId}/add-audience/`, {
      audience_id: audienceId,
    })
      .then((res) => {
        let adgroup = res;
        this.state.adgroups.results = this.state.adgroups.results.map((a) => {
          if (a.id === adgroup) {
            a = adgroup;
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addBulkAudienceToAdgroup = async (adgroupIds, audienceId) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/add-bulk-audience/`,
      {
        adgroup_ids: adgroupIds,
        audience_id: audienceId,
      }
    )
      .then((res) => {
        let adgroups = res;
        this.state.adgroups.results = this.state.adgroups.results.map((a) => {
          if (adgroupIds.includes(a.id)) {
            a = adgroups.find((ad) => ad.id === a.id);
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
}
