export default class TaskDetailViewModel {
  constructor(
    token,
    projectId = null,
    organizationId = null,
    taskId,
    state,
    updateState,
    searchParams
  ) {
    this.projectId = projectId;
    this.organizationId = organizationId;
    this.taskId = taskId;
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchTask = async () => {
    return await this._get(`api/v1/tasks/${this.taskId}/`, false);
  };

  _fetchSubtasks = async () => {
    return await this._get(`api/v1/tasks/${this.taskId}/subtasks/`, false);
  };

  _fetchCategories = async () => {
    return await this._get("api/v1/tasks/categories/", true);
  };

  _fetchStatuses = async () => {
    return await this._get("api/v1/tasks/statuses/", true);
  };

  _fetchUsers = async () => {
    if (this.projectId === null) {
      return await this._get(
        `api/v1/organizations/${this.organizationId}/users/`,
        false
      );
    }
    return await this._get(`api/v1/projects/${this.projectId}/users/`, false);
  };

  _fetchOrgProjects = async () => {
    return await this._get(
      `api/v1/organizations/${this.organizationId}/project-search/`,
      false
    );
  };

  _fetchInitialCalls = async () => {
    return Promise.all([
      this._fetchTask(),
      this._fetchSubtasks(),
      this._fetchCategories(),
      this._fetchStatuses(),
      this._fetchUsers(),
    ]).then((values) => {
      return {
        task: values[0],
        subtasks: values[1],
        categories: values[2],
        statuses: values[3],
        users: values[4],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      if (this.organizationId) {
        this.state.projects = await this._fetchOrgProjects();
      }
      this._callUpdateState();
    }
    return this.state;
  };

  refreshState = async (taskId) => {
    this.taskId = taskId;
    this.state = await this._fetchInitialCalls();
    if (this.organizationId) {
      this.state.projects = await this._fetchOrgProjects();
    }
    this._callUpdateState();
  };

  updateSearchParams = async (newSearchParams) => {
    this.searchParams = newSearchParams;
    this.state = await this._fetchInitialProjectCalls();
    this._callUpdateState();
  };

  sortTasks = (key) => {
    if (!this.state || !this.state.tasks.results) {
      return;
    }

    let sortedTasks;
    const isAscending = this.sortKey === key && !this.isAscending;

    // If sorting by title or a simple key
    if (key === "title" || key === "due_datetime") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        if (a[key] < b[key]) {
          return isAscending ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return isAscending ? 1 : -1;
        }
        return 0;
      });
    }
    // Special case for sorting by assignedTo
    else if (key === "assigned_to") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        // Compare email attributes
        const emailA = a.assigned_to?.email || "";
        const emailB = b.assigned_to?.email || "";

        if (emailA < emailB) {
          return isAscending ? -1 : 1;
        }
        if (emailA > emailB) {
          return isAscending ? 1 : -1;
        }
        return 0;
      });
    } else if (key === "category") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        // Compare email attributes
        const A = a.category?.name || "";
        const B = b.category?.name || "";

        if (A < B) {
          return isAscending ? -1 : 1;
        }
        if (A > B) {
          return isAscending ? 1 : -1;
        }
        return 0;
      });
    } else if (key === "status") {
      sortedTasks = [...this.state.tasks.results].sort((a, b) => {
        // Compare email attributes
        const A = a.status?.name || "";
        const B = b.status?.name || "";

        if (A < B) {
          return isAscending ? -1 : 1;
        }
        if (A > B) {
          return isAscending ? 1 : -1;
        }
        return 0;
      });
    }

    this.state.tasks.results = sortedTasks;
    this.sortKey = key;
    this.isAscending = isAscending;
    this._callUpdateState();
  };

  addTask = async (task) => {
    return await fetch(this._createUrl("api/v1/tasks/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(task),
    })
      .then(async (response) => {
        let newTask = await response.json();
        this.state.tasks.results.unshift(newTask);
        this._callUpdateState();
        return newTask;
      })
      .catch((error) => {
        console.error("Error updating task", error);
      });
  };

  updateTask = async (task) => {
    return await fetch(this._createUrl(`api/v1/tasks/${task.id}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(task),
    })
      .then(async (response) => {
        let task = await response.json();
        this.state.tasks.results = this.state.tasks.results.map((t) => {
          if (t.id === task.id) {
            return task;
          }
          return t;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating task", error);
        return false;
      });
  };

  // Deletes an existing task from the project and updates the state
  deleteTask = async (taskId) => {
    try {
      const response = await fetch(this._createUrl(`api/v1/tasks/${taskId}/`), {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      });

      if (!response.status === 204) {
        // If the response is not ok, throw an error
        throw new Error(
          `Failed to delete task, status code: ${response.status}`
        );
      }

      // Remove the task from the local state
      this.state.tasks.results = this.state.tasks.results.filter(
        (task) => task.id !== taskId
      );
      this._callUpdateState();
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error deleting task", error);
      return false; // Return false to indicate failure
    }
  };

  addSubtask = async (subtask) => {
    return await fetch(
      this._createUrl(`api/v1/tasks/${this.taskId}/create-subtask/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(subtask),
      }
    )
      .then(async (response) => {
        let newSubtask = await response.json();
        this.state.subtasks.unshift(newSubtask);
        this._callUpdateState();
        return newSubtask;
      })
      .catch((error) => {
        console.error("Error updating subtask", error);
      });
  };

  updateSubtask = async (subtask) => {
    return await fetch(this._createUrl(`api/v1/subtasks/${subtask.id}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(subtask),
    })
      .then(async (response) => {
        let subtask = await response.json();
        this.state.subtasks = this.state.subtasks.map((t) => {
          if (t.id === subtask.id) {
            return subtask;
          }
          return t;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating subtask", error);
        return false;
      });
  };

  removeSubtask = async (subtaskId) => {
    try {
      const response = await fetch(
        this._createUrl(`api/v1/subtasks/${subtaskId}/`),
        {
          method: "DELETE",
          headers: this._standardActionHeaders(),
        }
      );

      if (!response.status === 204) {
        // If the response is not ok, throw an error
        throw new Error(
          `Failed to delete subtask, status code: ${response.status}`
        );
      }

      // Remove the subtask from the local state
      this.state.subtasks = this.state.subtasks.filter(
        (subtask) => subtask.id !== subtaskId
      );
      this._callUpdateState();
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error deleting subtask", error);
      return false; // Return false to indicate failure
    }
  };
}
