export default class OrganizationsViewModel {
  constructor(token, userId, state, updateState) {
    this.userId = userId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.query = "";
    this.currentPage = 1;
    this.pageSize = 10;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    const path = `api/v1/user/organizations/?q=${this.query}&page=${this.currentPage}&page_size=${this.pageSize}`;
    return await this._get(path, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  updateSearchParameters = async (newQuery, newPage) => {
    this.query = newQuery;
    this.currentPage = newPage;
    this.state = await this._fetchInitialCalls();
    this._callUpdateState();
  };

  addOrganization = async (organization) => {
    return await fetch(this._createUrl(`api/v1/organizations/create/`), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify({ organization: organization }),
    })
      .then(async (response) => {
        let newOrg = await response.json();
        console.log("newOrgUser: ", newOrg);
        console.log("this.state: ", this.state);
        this.state.data.results.unshift(newOrg);
        this._callUpdateState();
        return newOrg;
      })
      .catch((error) => {
        console.error("Error creating new organization", error);
      });
  };
}
