import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../pages/forArtists/MainLayoutContext";

export default function StaffOnlyRoute({ children }) {
  const user = useContext(UserContext);

  // Redirect to a different page if not a staff member
  if (!user.is_staff) {
    // Redirect to the home page or a 'not authorized' page
    return <Navigate to="/" />;
  }

  // If the user is a staff member, render the children components (the protected route)
  return children;
}
