import Row from "./Row";

export default function Table({ data, handleSelect, selectedCampaigns }) {
  return (
    <>
      <table className="p-2 min-w-full divide-y divide-gray-300">
        <tbody>
          {data?.campaigns?.map((campaign) => (
            <Row
              key={campaign.id}
              campaign={campaign}
              handleSelect={handleSelect}
              selectedCampaigns={selectedCampaigns}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
