import { useState, useEffect } from "react";
import {
  NavLink,
  useParams,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/solid";
import { ShareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

import PlayButton from "../../../components/forFans/releases/PlayButton";
import LoadingScreen from "../../../components/loading/LoadingScreen";
import { useTracks } from "../../../components/contexts/TracksContext";

import ArtistHeader from "../../../components/forFans/artists/ArtistHeader";
import TrackList from "../../../components/forFans/tracks/TrackList";
import ReleaseList from "../../../components/forFans/artists/ReleaseList";
import ArtistViewModel from "../../../models/forFans/artists/artistViewModel";
import ShowList from "../../../components/forFans/artists/ShowList";
import ProductList from "../../../components/forFans/artists/ProductList";
import ErrorPage from "../../ErrorPage";
import TabContainer from "../../../components/forFans/TabContainer";
import TabBubble from "../../../components/forFans/TabBubble";
import Tab from "../../../components/forFans/Tab";

const tabs = [
  { name: "Music", href: "music/", current: true },
  { name: "Merch", href: "merch/", current: false },
  { name: "Tour", href: "#", current: false },
  // { name: "Billing", href: "#", current: false },
];

const tabItems = [
  { id: 1, name: "Music" },
  { id: 2, name: "Tour" },
  { id: 3, name: "Merch" },
  { id: 4, name: "Profile" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FanArtistIndex() {
  const { audioPlayerViewModel, sidebarViewModel } = useOutletContext();
  const token = localStorage.getItem("token");
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Music");

  // view model
  let { artistId } = useParams();
  let contextURI = `artist:${artistId}`;
  const [artistProfileViewData, setArtistProfileViewData] = useState();
  const [artistViewModel, setArtistViewModel] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [artistId]);

  const handleTabClick = (type) => {
    setSelectedTab(type);
  };

  useEffect(() => {
    if (audioPlayerViewModel) {
      // Set the initial state
      setIsPlaying(audioPlayerViewModel.isPlaying);

      // Define a function to update state when ViewModel changes
      const handleViewModelChange = () => {
        setIsPlaying(audioPlayerViewModel.isPlaying);
      };

      // Attach the listener to the ViewModel
      audioPlayerViewModel.onStateChange = handleViewModelChange;

      // Clean up the listener when the component unmounts
      return () => {
        audioPlayerViewModel.onStateChange = null;
      };
    }
  }, [audioPlayerViewModel]);

  const handleClick = async () => {
    console.log("clicked play");
    if (contextURI === audioPlayerViewModel.contextURI) {
      console.log("same context");
      audioPlayerViewModel.togglePlayPause();
    } else {
      console.log("different context - load tracks");
      audioPlayerViewModel.loadTracks(
        artistProfileViewData.data.top_tracks,
        0,
        contextURI,
        true
      );
    }
  };

  const handleTrackClick = (trackId, trackIndex) => {
    if (contextURI === audioPlayerViewModel.contextURI) {
      if (currentTrackIndex === trackIndex) {
        audioPlayerViewModel.togglePlayPause();
      } else {
        audioPlayerViewModel.changeTrack(trackIndex);
        if (!isPlaying) {
          audioPlayerViewModel.togglePlayPause();
        }
      }
    } else {
      audioPlayerViewModel.loadTracks(
        artistProfileViewData.data.top_tracks,
        trackIndex,
        contextURI
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    const viewModel = new ArtistViewModel(
      token,
      artistId,
      artistProfileViewData,
      (state) => {
        setArtistProfileViewData(state);
      },
      searchParams
    );
    setArtistViewModel(viewModel);
  }, [token, artistId, searchParams]);

  useEffect(() => {
    if (artistViewModel) {
      artistViewModel.updateSearchParams(searchParams).then(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
    }
  }, [artistViewModel]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!artistProfileViewData || !artistProfileViewData.data) {
    return <ErrorPage />;
  }

  return (
    <div className="">
      {artistProfileViewData?.data?.artist && (
        <ArtistHeader
          artist={artistProfileViewData.data.artist}
          handlePlayClick={handleClick}
          isPlaying={isPlaying}
          contextURI={contextURI}
          playingContextURI={audioPlayerViewModel.contextURI}
          toggleArtistFollow={async (isFollowing) => {
            await artistViewModel.toggleArtistFollow(isFollowing);
          }}
          refreshSidebarData={async () => {
            await sidebarViewModel.refreshState();
          }}
        />
      )}
      <div className="max-w-7xl mx-auto py-4 px-2 sm:px-6 lg:px-8">
        {/* tabs */}
        {/* <TabContainer>
          {tabItems &&
            tabItems.map((item) => (
              <Tab
                key={item.id}
                item={item}
                selected={selectedTab}
                handleButtonClick={handleTabClick}
                xPadding={"px-6"}
              />
            ))}
        </TabContainer> */}
        {/* top tracks */}
        <h2 className="text-3xl font-bold text-gray-600 py-2">Top tracks</h2>
        {artistProfileViewData?.data?.top_tracks && (
          <TrackList
            tracks={artistProfileViewData.data.top_tracks}
            handleTrackClick={handleTrackClick}
            audioPlayerViewModel={audioPlayerViewModel}
            contextURI={contextURI}
            playingContextURI={audioPlayerViewModel.contextURI}
            isPlaying={isPlaying}
            currentTrackIndex={currentTrackIndex}
            setCurrentTrackIndex={setCurrentTrackIndex}
            // releaseId={releaseId}
            favoriteReleaseTrack={async (isFavorite, releaseTrackId) => {
              await artistViewModel.favoriteReleaseTrack(
                isFavorite,
                releaseTrackId
              );
            }}
            currentArtistId={audioPlayerViewModel.artistId}
            pageArtistId={artistId}
            showArtist={false}
          />
        )}

        {/* releases */}
        <h2 className="text-3xl font-bold text-gray-600 py-2">Releases</h2>
        {artistProfileViewData?.data?.releases && (
          <ReleaseList
            releases={artistProfileViewData.data.releases}
            setLoading={setLoading}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            currentReleaseId={audioPlayerViewModel.releaseId}
          />
        )}
        {/* <h2 className="text-3xl font-bold text-gray-600 py-2">Live Shows</h2>
        <p>Request Artist to play in your city</p>
        {artistProfileViewData?.data?.shows && (
          <ShowList
            shows={artistProfileViewData.data.shows}
            setLoading={setLoading}
          />
        )} */}

        {/* {artistProfileViewData?.data?.products?.length > 0 && (
          <>
            <h2 className="text-3xl font-bold text-gray-600 py-2">Merch</h2>
            <ProductList
              products={artistProfileViewData.data.products}
              setLoading={setLoading}
            />
          </>
        )} */}
      </div>
    </div>
  );
}
