import { useEffect, useState, useRef } from "react";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export default function MultiSelect({ label, selectedArtists, onChange }) {
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);

  useEffect(() => {
    setSelected(selectedArtists || []);
  }, [selectedArtists]);

  const inputRef = useRef(null);
  const dropdownRef = useRef(false);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleBlur = () => {
    if (!dropdownRef.current) {
      setDisplayDropdown(false);
    }
  };

  const handleDropdownMouseEnter = () => {
    dropdownRef.current = true;
  };

  const handleDropdownMouseLeave = () => {
    dropdownRef.current = false;
    setDisplayDropdown(false);
    if (inputRef.current) {
      inputRef.current.blur(); // Manually remove focus from the input
    }
  };

  const handleFocus = () => {
    setDisplayDropdown(true);
  };

  const handleSelect = (artist) => {
    console.log("selected", selected);
    console.log("artist", artist);
    const updatedSelected = [...selected, artist];
    setSelected(updatedSelected);
    onChange(updatedSelected); // Update the parent component
  };

  const handleXClick = (artistId) => {
    const updatedSelected = selected.filter((artist) => artist.id !== artistId);
    setSelected(updatedSelected);
    onChange(updatedSelected); // Update the parent component
  };

  useEffect(() => {
    if (query.length > 0) {
      fetch(`${baseURL}api/v1/artists/?q=${query}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Log the error message body
            response.json().then((json) => console.error(json));
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          // console.log(data);
          setOptions(data.results);
        });
    }
  }, [token, baseURL, query]);

  return (
    <>
      <div className="col-span-full py-2">
        <label
          htmlFor="artists"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <div
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={handleDropdownMouseLeave}
          className="relative mt-2 mb-2 rounded-md shadow-sm"
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            ref={inputRef}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            name="text"
            id="search"
            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Search artists..."
          />
          {options?.length > 0 && displayDropdown && (
            <div className="absolute w-full flex flex-col justify-start border rounded-md bg-white z-10">
              {options.map((item) => (
                <button
                  key={item.id}
                  onClick={() => handleSelect(item)}
                  className="w-full flex justify-start p-2 ps-4 hover:bg-indigo-100"
                  type="button"
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
        </div>

        {selected?.length > 0 && (
          <div className="w-full flex flex-row justify-start border rounded-md p-2">
            {selected.map((item) => (
              <div
                key={item.id}
                className="flex items-center rounded-md mx-1 bg-indigo-100 hover:bg-red-100"
              >
                <div
                  htmlFor={item.id}
                  className="flex p-1 text-sm rounded-md font-medium text-gray-700 border"
                >
                  {item.name}
                  <button
                    onClick={() => handleXClick(item.id)}
                    className="ml-2"
                    type="button"
                  >
                    <XMarkIcon className="h-5 rounded-md hover:bg-red-300" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
