import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

export default function InputField({
  label,
  name,
  type = "text",
  placeholder,
  value,
  onChange,
  onBlur,
  charLimit = 100,
  error = "",
  required = false,
  setErrors,
}) {
  const handleBlur = (e) => {
    if (required && !e.target.value) {
      // Set specific field error
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field may not be blank.",
      }));
    } else if (onBlur) {
      // Pass the event to the form's onBlur handler if provided
      onBlur(e);
    }
  };

  return (
    <div className="py-2">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={name}
          className={`block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ${
            error
              ? "pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
              : "focus:ring-indigo-600"
          } sm:text-sm sm:leading-6`}
          placeholder={placeholder}
          value={value || ""}
          onChange={onChange}
          onBlur={handleBlur} // Add onBlur event handler
          maxLength={charLimit}
          aria-invalid={error ? "true" : "false"}
          aria-describedby={error ? `${name}-error` : undefined}
          required={required}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}
