import { useOutletContext } from "react-router-dom";

export default function OrganizationIndex() {
  const { organizationViewData } = useOutletContext();

  return (
    <>
      <div className="p-10 justify-center w-1/2">
        <div className="flex items-center justify-start gap-2">
          <h1 className="text-5xl font-bold">
            {organizationViewData?.data && organizationViewData.data.name}
          </h1>
        </div>
        <div className="py-4">
          <p>Welcome to your organization home page! :)</p>
        </div>
      </div>
    </>
  );
}
