import { useState } from "react";
import { Link, useOutletContext, useNavigate } from "react-router-dom";

import ZantipiCat from "../../../assets/images/Zantipi_Cat_no_zzz_screen_res.png";
import PlayButton from "../releases/PlayButton";

export default function ReleaseTile({
  release,
  setLoading,
  isRelease = true,
  showArtist = false,
}) {
  const { audioPlayerViewModel } = useOutletContext();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(audioPlayerViewModel.isPlaying);

  let contextURI = `release:${release.id}`;

  const handleClick = (id) => {
    setLoading(true);
    window.scrollTo(0, 0);
    isRelease ? navigate(`/releases/${id}`) : navigate(`/playlists/${id}`);
  };

  // Add a function to handle play/pause click (Update as per your logic)
  const handlePlayPauseClick = async (e, id) => {
    e.stopPropagation(); // Prevent triggering the navigation
    // Play/pause logic here
    if (isRelease) {
      if (contextURI !== audioPlayerViewModel.contextURI) {
        await audioPlayerViewModel.loadReleaseTracks(id, contextURI, 0, true);
      } else {
        audioPlayerViewModel.togglePlayPause();
      }
    } else {
      if (id !== audioPlayerViewModel.playlistId) {
        await audioPlayerViewModel.loadPlaylistTracks(id, contextURI, 0, true);
      } else {
        audioPlayerViewModel.togglePlayPause();
      }
    }
  };

  const handleArtistClick = (event, artistId) => {
    event.stopPropagation();
    window.scrollTo(0, 0);
    navigate(`/artists/${artistId}`);
  };

  return (
    <>
      <li key={release.id} className="col-span-1 relative">
        <div
          className="relative p-3 w-full flex flex-col items-center space-y-0 cursor-pointer transition duration-300 hover:bg-indigo-50 rounded-lg group"
          onClick={() => handleClick(release.id)}
        >
          <div className="relative w-full">
            {release.artwork ? (
              <img
                className="w-full h-full object-cover rounded-sm"
                src={release.artwork}
                alt={release.name}
              />
            ) : (
              <img
                src={ZantipiCat}
                className="w-full h-60 object-cover rounded-sm border border-gray-200"
                alt={release.name}
              />
            )}
          </div>
          <h3 className="text-sm font-medium text-gray-900 truncate w-full text-left pt-3">
            {release.name}
          </h3>
          {showArtist && (
            <p className="text-sm text-gray-400 truncate w-full text-left">
              {release.artists.map((artist, index) => (
                <span
                  key={`${release.id}-${artist.id}`}
                  className="text-gray-400 truncate w-full text-left hover:underline"
                  onClick={(event) => handleArtistClick(event, artist.id)}
                >
                  {artist.name}
                  {index < release.artists.length - 1 ? ", " : ""}
                </span>
              ))}
            </p>
          )}
          <div className="hidden sm:block absolute bottom-10 right-3 opacity-0 group-hover:opacity-100">
            <PlayButton
              handleClick={(e) => handlePlayPauseClick(e, release.id)}
              isPlaying={audioPlayerViewModel.isPlaying}
              contextURI={contextURI}
              playingContextURI={audioPlayerViewModel.contextURI}
            />
          </div>
        </div>
      </li>
    </>
  );
}
