import React from "react";
import { Droppable } from "react-beautiful-dnd";

import Track from "./Track";

const TrackContainer = ({ tracks, onRemove, onTrackChange }) => {
  return (
    <Droppable droppableId="droppable">
      {(provided) => (
        <>
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="overflow-hidden rounded-md border border-gray-300 bg-white"
          >
            <ul className="divide-y divide-gray-300">
              {tracks.map((track, index) => (
                <Track
                  key={track.id}
                  track={track}
                  index={index}
                  onRemove={onRemove}
                  onTrackChange={onTrackChange}
                  uploadProgress={track.uploadProgress}
                />
              ))}
            </ul>

            {provided.placeholder}
          </div>
        </>
      )}
    </Droppable>
  );
};

export default TrackContainer;
