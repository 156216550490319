export default class ReleaseViewModel {
  constructor(token, releaseId, state, updateState, searchParams) {
    this.releaseId = releaseId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    const queryParams = new URLSearchParams(this.searchParams).toString();
    return `${this.url_begin}${path}?${queryParams}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      //   cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(`api/v1/releases/${this.releaseId}/profile/`, true);
  };

  _fetchInitialReleaseCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchReleaseState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialReleaseCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  updateSearchParams = async (newSearchParams) => {
    this.searchParams = newSearchParams;
    this.state = await this._fetchInitialReleaseCalls();
    this._callUpdateState();
  };

  favoriteRelease = async (isFavorite) => {
    console.log("isFavorite: ", isFavorite);
    return await fetch(
      this._createUrl(`api/v1/releases/${this.releaseId}/toggle-favorite/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({
          is_favorite: isFavorite,
        }),
      }
    )
      .then(async (response) => {
        let data = await response.json();
        this.state.data.release = data.release;
        this._callUpdateState();
      })
      .catch((error) => {
        console.error(`Error (un)favoriting release`, error);
      });
  };

  favoriteReleaseTrack = async (isFavorite, releaseTrackId) => {
    console.log("isFavorite: ", isFavorite);
    return await fetch(
      this._createUrl(
        `api/v1/release-tracks/${releaseTrackId}/toggle-favorite/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({
          is_favorite: isFavorite,
        }),
      }
    )
      .then(async (response) => {
        let data = await response.json();
        this.state.data.tracks = this.state.data.tracks.map((t) => {
          if (t.id === data.track.id) {
            return data.track;
          }
          return t;
        });
        this._callUpdateState();
      })
      .catch((error) => {
        console.error(`Error (un)favoriting release track`, error);
      });
  };
}
