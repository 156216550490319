import { useState, useEffect } from "react";
import {
  NavLink,
  useParams,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import LoadingScreen from "../../components/loading/LoadingScreen";
import { useTracks } from "../../components/contexts/TracksContext";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/solid";
import { ShareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

import PlayButton from "../../components/forFans/releases/PlayButton";
import FavoriteIcon from "../../components/forFans/releases/FavoriteIcon";
import ReleaseList from "../../components/forFans/artists/ReleaseList";
import ReleaseHeader from "../../components/forFans/releases/ReleaseHeader";
import TrackList from "../../components/forFans/tracks/TrackList";
import ReleaseViewModel from "../../models/forFans/releases/releaseViewModel";
import ErrorPage from "../ErrorPage";

export default function ReleaseIndex() {
  const { audioPlayerViewModel } = useOutletContext();
  const token = localStorage.getItem("token");
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);

  // view model
  let { releaseId } = useParams();
  let contextURI = `release:${releaseId}`;
  const [releaseViewData, setReleaseViewData] = useState();
  const [releaseViewModel, setReleaseViewModel] = useState(null);

  useEffect(() => {
    if (audioPlayerViewModel) {
      // Set the initial state
      setIsPlaying(audioPlayerViewModel.isPlaying);

      // Define a function to update state when ViewModel changes
      const handleViewModelChange = () => {
        setIsPlaying(audioPlayerViewModel.isPlaying);
      };

      // Attach the listener to the ViewModel
      audioPlayerViewModel.onStateChange = handleViewModelChange;

      // Clean up the listener when the component unmounts
      return () => {
        audioPlayerViewModel.onStateChange = null;
      };
    }
  }, [audioPlayerViewModel]);

  useEffect(() => {
    setLoading(true);
    const viewModel = new ReleaseViewModel(
      token,
      releaseId,
      releaseViewData,
      (state) => setReleaseViewData(state),
      searchParams
    );
    setReleaseViewModel(viewModel);
  }, [token, releaseId, searchParams]);

  useEffect(() => {
    if (releaseViewModel) {
      releaseViewModel.fetchReleaseState().then(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
    }
  }, [releaseViewModel]);

  const handleClick = async () => {
    if (contextURI !== audioPlayerViewModel.contextURI) {
      audioPlayerViewModel.loadTracks(
        releaseViewData.data.tracks,
        0,
        contextURI,
        true
      );
    } else {
      audioPlayerViewModel.togglePlayPause();
    }
  };

  const handleReleaseFavoriteClick = async () => {
    await releaseViewModel.favoriteRelease(
      !releaseViewData.data.release.is_favorite
    );
  };

  const handleTrackClick = (trackId, trackIndex) => {
    if (contextURI === audioPlayerViewModel.contextURI) {
      if (currentTrackIndex === trackIndex) {
        audioPlayerViewModel.togglePlayPause();
      } else {
        audioPlayerViewModel.changeTrack(trackIndex);
        if (!isPlaying) {
          audioPlayerViewModel.togglePlayPause();
        }
      }
    } else {
      audioPlayerViewModel.loadTracks(
        releaseViewData.data.tracks,
        trackIndex,
        contextURI,
        true
      );
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (!releaseViewData || !releaseViewData.data) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className="mb-20">
        <div className="max-w-7xl mx-auto py-4 px-2 sm:px-6 lg:px-8">
          <div className="px-6 pt-8">
            {releaseViewData?.data?.release &&
              releaseViewData?.data?.artist && (
                <ReleaseHeader
                  data={releaseViewData.data}
                  handleClick={handleClick}
                  isPlaying={isPlaying}
                  contextURI={contextURI}
                  playingContextURI={audioPlayerViewModel.contextURI}
                  isFavorite={releaseViewData.data.release.is_favorite}
                  favoriteRelease={handleReleaseFavoriteClick}
                />
              )}
          </div>

          {releaseViewData?.data?.tracks && (
            <TrackList
              tracks={releaseViewData.data.tracks}
              handleTrackClick={handleTrackClick}
              audioPlayerViewModel={audioPlayerViewModel}
              contextURI={contextURI}
              playingContextURI={audioPlayerViewModel.contextURI}
              currentReleaseId={releaseId}
              isPlaying={isPlaying}
              currentTrackIndex={currentTrackIndex}
              setCurrentTrackIndex={setCurrentTrackIndex}
              releaseId={releaseId}
              favoriteReleaseTrack={async (isFavorite, releaseTrackId) => {
                await releaseViewModel.favoriteReleaseTrack(
                  isFavorite,
                  releaseTrackId
                );
              }}
              showArtist={true}
            />
          )}
          {releaseViewData?.data?.related_releases?.length > 0 && (
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h3 className="text-3xl font-bold text-gray-900 mx-4">
                More by {releaseViewData.data.artist?.name}
              </h3>
              <ReleaseList
                releases={releaseViewData.data.related_releases}
                setLoading={setLoading}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
