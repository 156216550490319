export default class GoogleAdsViewModel {
  constructor(token, organizationId, state, updateState) {
    this.token = token;
    this.organizationId = organizationId;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.site_url = process.env.REACT_APP_SITE_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchGoogleUser = async () => {
    return await this._get(`api/v1/user/google-user/`, false);
  };

  _fetchData = async () => {
    return await this._get(
      `api/v1/organizations/${this.organizationId}/data-sources/`,
      false
    );
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData(), this._fetchGoogleUser()]).then(
      (values) => {
        return {
          data: values[0],
          googleUser: values[1],
        };
      }
    );
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  refreshAssets = async () => {
    try {
      const response = await fetch(
        `${this.url_begin}api/v1/organizations/${this.organizationId}/refresh-gads-assets/`,
        {
          method: "GET",
          headers: this._standardHeaders(),
        }
      );
      if (response.ok) {
        console.log("Refreshed assets");
      } else {
        // Handle error response
        console.error("Error refreshing assets");
      }
    } catch (error) {
      console.error("Error in refreshing assets:", error);
    }
  };

  fetchGadsAdaccountOptions = async () => {
    return await this._get(
      `api/v1/organizations/${this.organizationId}/get-gads-customer-accounts/`,
      false
    )
      .then(async (response) => {
        let options = response;
        this.state.data.gads_accounts_options = options;
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error fetching google ads accounts", error);
      });
  };

  addGadsAdaccount = async (adaccount) => {
    if (
      this.state.data.google_adaccounts.find((item) => item.id === adaccount.id)
    ) {
      return;
    }
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/add-gads-adaccount-to-org/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ adaccount }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);

        // add the adaccount to the list
        this.state.data.google_adaccounts.push(adaccount);

        // update the option with "is_added_to_org" as true
        this.state.data.gads_accounts_options =
          this.state.data.gads_accounts_options.map((item) => {
            if (item.id === adaccount.id) {
              item.is_added_to_org = true;
            }
            return item;
          });

        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error adding google ads account", error);
      });
  };

  removeGadsAdaccount = async (adaccount) => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/remove-gads-adaccount-from-org/`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ adaccount }),
      }
    )
      .then(async (response) => {
        let res = await response.json();
        console.log(res);

        // remove the adaccount from the list
        this.state.data.google_adaccounts =
          this.state.data.google_adaccounts.filter(
            (item) => item.id !== adaccount.id
          );

        // update the option with "is_added_to_org" as false
        if (this.state.data.gads_accounts_options) {
          this.state.data.gads_accounts_options =
            this.state.data.gads_accounts_options.map((item) => {
              if (item.id === adaccount.id) {
                item.is_added_to_org = false;
              }
              return item;
            });
        }

        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error removing google ads account", error);
      });
  };
}
