export default class UserBillingViewModel {
  constructor(token, state, updateState) {
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(`api/v1/user/billing/`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  createCheckout = async (priceArray) => {
    const priceStr = priceArray.map((price) => price.id).join(",");
    return await fetch(
      this._createUrl(`api/v1/stripe/create-checkout-session/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({
          priceIds: priceStr,
          orgId: this.organizationId,
        }),
      }
    )
      .then(async (response) => {
        // TODO - update the state of the subscription on the model (subscription Id or a subscription object)
        let data = await response.json();
        console.log("data: ", data);
        window.location.href = data.url;
        // console.log("this.state: ", this.state);
        // this.state.data.members.unshift(redirectURL);
        // this._callUpdateState();
        // return data;
      })
      .catch((error) => {
        console.error("Error creating checkout session.", error);
      });
  };

  createPortalSession = async () => {
    return await fetch(
      this._createUrl(`api/v1/stripe/create-portal-session/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({
          orgId: this.organizationId,
        }),
      }
    )
      .then(async (response) => {
        // TODO - update the state of the subscription on the model (subscription Id or a subscription object)
        let data = await response.json();
        console.log("data: ", data);
        window.location.href = data.url;
      })
      .catch((error) => {
        console.error("Error creating portal session.", error);
      });
  };
}
