import { useState, useEffect, useContext } from "react";
import {
  Outlet,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import {
  MusicalNoteIcon,
  BanknotesIcon,
  CalendarDaysIcon,
  BriefcaseIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";

// generic
import { UserContext } from "../../../MainLayoutContext";
import Navbar from "../../../../../components/layout/navigation/navbar/Index";
import LoadingScreen from "../../../../../components/loading/LoadingScreen";
import Sidebar from "../../../../../components/layout/navigation/sidebar/Index";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/artists/viewmodel";

const navItems = [
  { name: "Home", href: "", icon: HomeIcon, end: true },
  {
    name: "Releases",
    href: "releases/",
    icon: MusicalNoteIcon,
    current: false,
  },
  {
    name: "Shows",
    href: "shows/",
    icon: CalendarDaysIcon,
    end: false,
  },
  {
    name: "Products",
    href: "products/",
    icon: BriefcaseIcon,
    current: false,
  },
  // {
  //   name: "Account",
  //   href: "account/",
  //   icon: BanknotesIcon,
  //   current: false,
  // },
];

const avatarItems = [{ id: 1, name: "Connect my data", href: "/data/" }];

export default function ArtistRoot() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const { token, appItems } = useOutletContext();
  let { artistId } = useParams();

  const [artist, setArtist] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, artistId, artist, (state) => {
      setArtist(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState().then((state) => {
      setIsLoading(false);
    });
  }, [viewModel, token, artistId]);

  const handleSelect = async (artist) => {
    setIsLoading(true);
    await viewModel.refreshState(artist.id);
    navigate(`/artists/${artist.id}`);
  };

  if (isLoading) {
    <LoadingScreen />;
  }

  return (
    <>
      <Navbar
        user={user}
        navItems={navItems}
        appItems={appItems}
        avatarItems={avatarItems}
        searchOptions={artist?.artists}
        fetchSearchOptions={(query) => viewModel.searchArtists(query)}
        handleSelect={handleSelect}
        searchPlaceholder="Search artists..."
      />

      <div className="flex h-[calc(100vh-4rem)]">
        {/* Sidebar */}
        <aside className="hidden lg:flex flex-grow">
          <Sidebar navItems={navItems} />
        </aside>

        {/* Main Content */}
        <main className="w-full flex-grow overflow-y-auto p-4">
          {/* artist title for mobile */}
          <div className="p-2 mb-2">
            <div className="flex items-center justify-center ">
              <span className="text-gray-400">Artist: {artist?.name}</span>
            </div>
          </div>
          <div className="flex-1 py-20">
            <Outlet context={{ token }} />
          </div>
        </main>
      </div>
    </>
  );
}
