// generic
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../components/layout/tables/TableBody";

// feature specific
import CampaignTableRow from "./CampaignTableRow";

const CampaignsTable = ({
  cols,
  campaigns,
  setSelectedCampaign,
  removeCampaign,
  updateCampaign,
  projectShows,
  projectReleases,
  projectCollections,
  projectPlatforms,
}) => {
  return (
    <>
      <div className="h-96 overflow-y- z-9">
        <TableContainer>
          <TableHeader cols={cols} />
          <TableBody>
            {campaigns?.map((campaign, index) => {
              return (
                <CampaignTableRow
                  key={index}
                  campaign={campaign}
                  setSelectedCampaign={setSelectedCampaign}
                  removeCampaign={removeCampaign}
                  updateCampaign={updateCampaign}
                  projectShows={projectShows}
                  projectReleases={projectReleases}
                  projectCollections={projectCollections}
                  projectPlatforms={projectPlatforms}
                />
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
    </>
  );
};

export default CampaignsTable;
