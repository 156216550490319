import React, { useState, useCallback } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useDropzone } from "react-dropzone";
import { DragDropContext } from "react-beautiful-dnd";

import TrackContainer from "../tracks/TrackContainer";
import PrimaryButton from "../UI/buttons/PrimaryButton";
import { LoadingComponent } from "../loading/LoadingScreen";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TracksUpload = ({ label, tracks, artists, updateReleaseTracks }) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [trackList, setTrackList] = useState(tracks || []);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const newTracks = acceptedFiles.map((file, index) => {
      const tempId = `temp-${Date.now()}-${index}`;

      // Create a new file object with a modified name
      const modifiedFile = new File([file], `${tempId}-${file.name}`, {
        type: file.type,
      });

      // Store the modified file object in the track
      return {
        id: tempId,
        file: modifiedFile, // Store the modified file object here
        originalName: file.name, // Preserve the original file name
        uploadProgress: 0,
      };
    });

    newTracks.forEach(uploadFile);

    setTrackList((prevItems) => [...newTracks, ...prevItems]);
  }, []);

  const uploadFile = (track) => {
    console.log("track", track);
    const formData = new FormData();
    // formData.append("track", track);
    console.log("track.file", track.file);
    formData.append("file", track.file);
    formData.append("filename", track.originalName);
    formData.append("tempId", track.id);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${baseURL}api/v1/tracks/upload/`);

    // Set the authorization header with the token
    xhr.setRequestHeader("Authorization", `Token ${token}`);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        // Update track upload progress
        setTrackList((prevItems) => {
          const newItems = [...prevItems];
          const trackIndex = newItems.findIndex((t) => t.id === track.id);
          if (trackIndex !== -1) {
            newItems[trackIndex] = {
              ...newItems[trackIndex],
              uploadProgress: progress,
            };
          }
          return newItems;
        });
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const response = JSON.parse(xhr.responseText);
        if (xhr.status === 200 && response.track_id && response.temp_id) {
          setTrackList((prevItems) => {
            return prevItems.map((item) =>
              item.id === response.temp_id // Use the temporary ID for matching
                ? {
                    ...item,
                    uploadedId: response.track_id,
                    name: item.originalName, // Revert back to the original name
                  }
                : item
            );
          });
        }
      }
    };

    xhr.send(formData);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const newTracks = reorder(
        trackList,
        result.source.index,
        result.destination.index
      );
      setTrackList(newTracks);
    },
    [trackList]
  );

  const handleTrackChange = (trackId, newTrackData) => {
    console.log("new track data", newTrackData);
    const updatedTracks = trackList.map((track) =>
      track.id === trackId ? { ...track, ...newTrackData } : track
    );
    setTrackList(updatedTracks);
  };

  const handleRemoveTrack = (trackId) => {
    setTrackList((prevItems) =>
      prevItems.filter((item) => item.id !== trackId)
    );
  };

  const handleTracksSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    const submissionData = {
      tracks: trackList.map((track, index) => ({
        ...track,
        file: undefined, // Remove the file object
        order: index + 1, // Add an 'order' field to each track
      })),
    };

    console.log("Submitting:", submissionData);

    const res = await updateReleaseTracks(submissionData);
    if (res) {
      console.log("res", res);
      setIsUpdated(true);
    }
    setIsUpdating(false);
  };

  return (
    <>
      <div className="col-span-full py-2">
        <label
          htmlFor="artists"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <div
          {...getRootProps()}
          className="my-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 cursor-pointer hover:bg-gray-50"
        >
          <input {...getInputProps()} />
          <p className="text-sm leading-6 text-gray-600">
            Drag and drop some tracks here, or click to select tracks
          </p>
        </div>
        {trackList?.length > 0 && console.log("trackList", trackList)}
        {trackList?.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <TrackContainer
              tracks={trackList}
              onTrackChange={handleTrackChange}
              onRemove={handleRemoveTrack}
              artists={artists}
            />
          </DragDropContext>
        )}
      </div>
      <div className="flex justify-start items-center gap-4">
        <PrimaryButton text={"Save tracks"} handleClick={handleTracksSubmit}>
          {isUpdating && <LoadingComponent size={"text-md"} />}
        </PrimaryButton>
        {isUpdated && <CheckIcon className="w-6 h-6 text-green-500" />}
      </div>
    </>
  );
};

export default TracksUpload;
