import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CheckCircleIcon as CheckCircleFilled } from "@heroicons/react/24/solid";
import {
  XCircleIcon,
  CheckCircleIcon as CheckCircleOutline,
} from "@heroicons/react/24/outline";

import TaskListCell from "./TaskListCell";
import SelectFieldStealth from "../../../components/forms/SelectFieldStealth";
import InputDatetimeStealth from "../../../components/forms/InputDatetimeStealth";
import TableRow from "../../../components/layout/tables/TableRow";
import CheckedCircle from "../../../components/UI/buttons/CheckedCircle";

export default function TaskTableRow({
  task,
  selectTask,
  users,
  taskCategories,
  taskStatuses,
  updateTask,
  global,
}) {
  let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isCompleted, setIsCompleted] = useState(task.completed);
  const [formData, setFormData] = useState({
    id: task.id,
    title: task.title,
    project: task.project,
    assigned_to: task.assigned_to,
    category: task.category,
    status: task.status,
    description: task.description,
    due_datetime: task.due_form_display,
  });

  useEffect(() => {
    setFormData({
      id: task.id,
      title: task.title,
      project: task.project,
      assigned_to: task.assigned_to,
      category: task.category,
      status: task.status,
      description: task.description,
      due_datetime: task.due_form_display,
    });
  }, [task]);

  const [showDateInput, setShowDateInput] = useState(false);

  const [newTaskValue, setNewTaskValue] = useState({
    id: task.id,
    title: task.title,
  });

  // hover state for the complete icon
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = async (e) => {
    // Update the formData state with the new selected value
    const updatedFormData = { ...formData, due_datetime: e.target.value };
    setFormData(updatedFormData);

    // Prepare the data for the updateTask function
    const taskData = {
      ...updatedFormData,
      due_datetime: updatedFormData.due_datetime || null,
    };

    // Call the updateTask function with the new data
    const updated = await updateTask(taskData);
    if (updated) {
      console.log("Task updated successfully");
    }
  };

  const handleSelectChange = async (selectedValue, fieldName) => {
    // Update the formData state with the new selected value
    const updatedFormData = { ...formData, [fieldName]: selectedValue };
    setFormData(updatedFormData);

    // Prepare the data for the updateTask function
    const taskData = {
      ...updatedFormData,
      assigned_to_id: updatedFormData.assigned_to?.id,
      category_id: updatedFormData.category?.id,
      status_id: updatedFormData.status?.id,
    };

    // Call the updateTask function with the new data
    const updated = await updateTask(taskData);
    if (updated) {
      console.log("Task updated successfully");
    }
  };

  const handleUpdate = async () => {
    const taskData = {
      ...formData,
      //   assigned_to_id: formData.assigned_to?.id,
      //   category_id: formData.category?.id,
      //   status_id: formData.status?.id,
      due_datetime: formData.due_datetime || null,
    };
    const updated = await updateTask(taskData);
    if (updated) {
      console.log("Task updated successfully");
    }
  };

  const handleTaskCompletion = async (e) => {
    e.stopPropagation();

    // Toggle the completed state
    const newCompletedState = !isCompleted;
    const updatedTaskValue = { ...newTaskValue, completed: newCompletedState };

    setNewTaskValue(updatedTaskValue);

    // Use updatedTaskValue for the update
    const updated = await updateTask(updatedTaskValue);
    if (updated) {
      console.log("Task updated successfully");
      setIsCompleted(newCompletedState);
    }
  };

  const handleMouseEnterDateInput = () => {
    setShowDateInput(true);
  };

  const handleMouseLeaveDateInput = () => {
    setShowDateInput(false);
  };

  const handleProjectClick = (e) => {
    e.stopPropagation();
    // navigate(`/projects/${task.project.id}/tasks/`);
    window.open(`/projects/${task.project.id}/tasks/`, "_blank");
  };

  return (
    <>
      <TableRow isCompleted={isCompleted}>
        <TaskListCell handleClick={() => selectTask(task)}>
          <div className="flex items-center gap-2 w-full group">
            <CheckedCircle
              isCompleted={isCompleted}
              handleClick={handleTaskCompletion}
            />
            <div className="md:hidden w-full my-1 px-2 py-1 bg-transparent">
              {formData.title}
            </div>
            <input
              name="title"
              value={formData.title}
              onClick={(e) => e.stopPropagation()}
              onChange={handleChange}
              onBlur={handleUpdate}
              className="hidden md:block w-1/2 my-1 px-2 py-1 bg-transparent rounded-sm border border-transparent group-hover:border group-hover:border-gray-300 group-hover:bg-white group-hover:cursor-text"
            />
          </div>
        </TaskListCell>
        <TaskListCell
          display={`hidden ${global && "md:table-cell truncate"}`}
          noClick={true}
        >
          {formData.project?.id && (
            <span
              onClick={handleProjectClick}
              className="cursor-pointer hover:underline"
            >
              {formData.project?.name}
            </span>
          )}
        </TaskListCell>
        <TaskListCell display={"hidden sm:table-cell"} noClick={false}>
          {users && (
            <SelectFieldStealth
              options={users}
              selected={formData.assigned_to || ""}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "assigned_to")
              }
              handleBlur={handleUpdate}
            />
          )}
        </TaskListCell>
        <TaskListCell display={"hidden lg:table-cell"} noClick={false}>
          {taskCategories && (
            <SelectFieldStealth
              options={taskCategories}
              selected={formData.category || ""}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "category")
              }
            />
          )}
        </TaskListCell>
        <TaskListCell display={"hidden lg:table-cell"}>
          {taskStatuses && (
            <SelectFieldStealth
              options={taskStatuses}
              selected={formData.status || ""}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "status")
              }
            />
          )}
        </TaskListCell>
        <TaskListCell
          display={"hidden md:table-cell"}
          width={"w-1/4"}
          onMouseEnter={handleMouseEnterDateInput}
          onMouseLeave={handleMouseLeaveDateInput}
        >
          {task.due_datetime &&
            !showDateInput &&
            !task.is_overdue &&
            !task.is_due_soon &&
            task.due_display}
          {task.due_display &&
            !showDateInput &&
            (task.is_overdue ? (
              <span className="text-red-500">{task.due_display}</span>
            ) : null)}
          {task.due_display &&
            !showDateInput &&
            (task.is_due_soon ? (
              <span className="text-yellow-500">{task.due_display}</span>
            ) : null)}

          {showDateInput && (
            <InputDatetimeStealth
              name={"due_datetime"}
              type={"datetime-local"}
              value={formData.due_datetime}
              onChange={handleDateChange}
              onBlur={handleUpdate}
            />
          )}
        </TaskListCell>
      </TableRow>
    </>
  );
}
