import { formatValue, formatLegend } from "../../utils/analyticsUtils";
import { convertToDisplayDate } from "../../utils/dateUtils";

const CustomTooltip = ({
  active,
  payload,
  label,
  colors,
  showPercentage = false,
  isDate = false,
}) => {
  if (active && payload && payload.length) {
    const totalValues = payload.reduce((acc, curr) => acc + curr.value, 0);
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <p className="label font-bold text-gray-600">
          {!label && "Undefined"}
          {isDate ? convertToDisplayDate(label) : label}
        </p>
        {payload.map((entry, index) => (
          <p
            key={index}
            className="intro font-light text-gray-600"
            style={{ color: colors[entry.name] }}
          >
            <span className="font-medium">{`${formatLegend(
              entry.name
            )}: `}</span>
            {`${formatValue(entry.name, entry.value)}`}

            {showPercentage &&
              ` (${((entry.value / totalValues) * 100).toFixed(0)}%)`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
