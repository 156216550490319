import Row from "./Row";

export default function Table({ data }) {
  console.log("data: ", data);
  return (
    <>
      <div>
        {data?.adgroups?.map((adgroup) => (
          <Row key={adgroup.id} adgroup={adgroup} />
        ))}
      </div>
    </>
  );
}
