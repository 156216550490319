export default class CreativesViewModel {
  constructor(token, projectId, state, updateState, searchParams) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      //   cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchAds = async (page = 1, searchQuery = "") => {
    return await this._get(
      `api/v1/projects/${this.projectId}/get-ads/?page=${page}&q=${searchQuery}`,
      true
    );
  };

  _fetchAssetLabels = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/get-asset-labels/`,
      true
    );
  };

  _fetchAdTypes = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/get-ad-types/`,
      true
    );
  };

  _fetchInitialProjectCalls = async () => {
    return Promise.all([
      this._fetchAds(),
      this._fetchAssetLabels(),
      this._fetchAdTypes(),
    ]).then((values) => {
      return {
        ads: values[0],
        assetLabels: values[1],
        adTypes: values[2],
      };
    });
  };

  fetchProjectState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialProjectCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  refreshAds = async (page = 1, searchQuery = "") => {
    this.state.ads = await this._fetchAds(page, searchQuery);
    this._callUpdateState();
  };

  sortData = (data, key, ascending = true) => {
    console.log("data: ", data, "key: ", key, "ascending: ", ascending);

    return data.slice().sort((a, b) => {
      // Use slice() to create a shallow copy and avoid modifying the original array
      // Determine the values to compare, handling special keys "Adgroups" and "audience"
      let aValue, bValue;
      if (key === "Ad") {
        aValue = a.name;
        bValue = b.name;
      } else if (key === "Asset Label") {
        aValue = a.asset_label?.name || "";
        bValue = b.asset_label?.name || "";
      } else if (key === "Ad Type") {
        aValue = a.ad_type?.name || "";
        bValue = b.ad_type?.name || "";
      } else {
        aValue = a[key];
        bValue = b[key];
      }

      // Compare the determined values for sorting
      if (aValue < bValue) {
        return ascending ? -1 : 1;
      }
      if (aValue > bValue) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  };

  sortAds = async (key, ascending = true) => {
    this.state.ads.results = this.sortData(
      this.state.ads.results,
      key,
      ascending
    );
    this._callUpdateState();
  };

  _post = async (path, body) => {
    return await fetch(this._createUrl(path), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createAssetLabel = async (adId, assetLabelName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-asset-label/`,
      {
        ad_id: adId,
        asset_label_name: assetLabelName,
      }
    )
      .then((res) => {
        let assetLabel = res;

        // Add the new audience to the state
        this.state.assetLabels.push(assetLabel);

        // Update the ad with the new audience
        this.state.ads.results = this.state.ads.results.map((ad) => {
          if (ad.id === adId) {
            ad.assetLabel = assetLabel;
          }
          return ad;
        });
        this._callUpdateState();
        return assetLabel;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addAssetLabelToAd = async (adId, assetLabelId) => {
    return await this._post(`api/v1/ads/${adId}/add-asset-label/`, {
      asset_label_id: assetLabelId,
    })
      .then((res) => {
        let ad = res;
        this.state.ads.results = this.state.ads.results.map((a) => {
          if (a.id === ad) {
            a = ad;
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createAdType = async (adId, adTypeName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-ad-type/`,
      {
        ad_id: adId,
        ad_type_name: adTypeName,
      }
    )
      .then((res) => {
        let adType = res;

        // Add the new audience to the state
        this.state.adTypes.push(adType);

        // Update the adgroup with the new audience
        this.state.ads.results = this.state.ads.results.map((ad) => {
          if (ad.id === adId) {
            ad.adType = adType;
          }
          return ad;
        });
        this._callUpdateState();
        return adType;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addAdTypeToAd = async (adId, adTypeId) => {
    return await this._post(`api/v1/ads/${adId}/add-ad-type/`, {
      ad_type_id: adTypeId,
    })
      .then((res) => {
        let ad = res;
        this.state.ads.results = this.state.ads.results.map((a) => {
          if (a.id === ad) {
            a = ad;
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createBulkAssetLabel = async (adIds, assetLabelName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-bulk-asset-label/`,
      {
        ad_ids: adIds,
        asset_label_name: assetLabelName,
      }
    )
      .then((res) => {
        let assetLabel = res;

        // Add the new audience to the state
        this.state.assetLabels.push(assetLabel);

        // Update the adgroup with the new audience
        this.state.ads.results = this.state.ads.results.map((ad) => {
          if (adIds.includes(ad.id)) {
            ad.asset_label = assetLabel;
          }
          return ad;
        });
        this._callUpdateState();
        return assetLabel;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addBulkAssetLabelToAd = async (adIds, assetLabelId) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/add-bulk-asset-label/`,
      {
        ad_ids: adIds,
        asset_label_id: assetLabelId,
      }
    )
      .then((res) => {
        let ads = res;
        this.state.ads.results = this.state.ads.results.map((a) => {
          if (adIds.includes(a.id)) {
            a = ads.find((ad) => ad.id === a.id);
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  createBulkAdType = async (adIds, adTypeName) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/create-bulk-ad-type/`,
      {
        ad_ids: adIds,
        ad_type_name: adTypeName,
      }
    )
      .then((res) => {
        let adType = res;

        // Add the new audience to the state
        this.state.adTypes.push(adType);

        // Update the adgroup with the new audience
        this.state.ads.results = this.state.ads.results.map((ad) => {
          if (adIds.includes(ad.id)) {
            ad.ad_type = adType;
          }
          return ad;
        });
        this._callUpdateState();
        return adType;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addBulkAdTypeToAd = async (adIds, adTypeId) => {
    return await this._post(
      `api/v1/projects/${this.projectId}/add-bulk-ad-type/`,
      {
        ad_ids: adIds,
        ad_type_id: adTypeId,
      }
    )
      .then((res) => {
        let ads = res;
        this.state.ads.results = this.state.ads.results.map((a) => {
          if (adIds.includes(a.id)) {
            a = ads.find((ad) => ad.id === a.id);
          }
          return a;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
}
