import { useState, useEffect } from "react";

// generic
import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";
import InputField from "../../../../components/forms/InputField";
import SelectField from "../../../../components/forms/SelectField";
import { DatePickerWidget } from "../../../../components/forms/DatePickerWidget";
import { formatDate } from "../../../../utils/dateUtils";

export default function ReleaseForm({
  addRelease,
  updateRelease,
  artistId,
  release = null,
  setOpen,
  setReleaseForm,
  releaseTypes,
}) {
  // Initialize form state with either an empty form or the provided release data
  const initialFormData = {
    name: "",
    release_date: null,
    link: "",
    artist_id: artistId,
    // Add more fields as necessary
  };

  const [formData, setFormData] = useState(
    release
      ? {
          ...release,
          release_date: release.release_date ? release.release_date : null,
        }
      : initialFormData
  );

  // Generic handler for form inputs
  const handleChange = (value, name) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    let requestBody = {
      ...formData,
      artist_id: artistId,
      type_id: formData.type ? formData.type.id : null,
      release_date: formData.release_date ? formData.release_date : null,
    };

    const response = release
      ? await updateRelease(requestBody)
      : await addRelease(requestBody);

    if (setOpen) setOpen(false);
    if (setReleaseForm) setReleaseForm(false);
    setFormData(initialFormData); // Reset the form
  };

  // Specialized handler for date changes
  const handleDateChange = (date, name) => handleChange(date, name);

  return (
    <div className="m-1 px-4 py-2 border rounded-md">
      <form onSubmit={handleSubmit}>
        <InputField
          label="Name"
          type="text"
          name="name"
          value={formData.name || ""}
          onChange={(e) => handleChange(e.target.value, e.target.name)}
        />
        <SelectField
          label="Release Type"
          options={releaseTypes}
          selected={formData.type}
          handleChange={(value) => handleChange(value, "type")}
        />
        <DatePickerWidget
          label="Release date"
          name="release_date"
          selected={formData.release_date}
          onChange={(date) => handleDateChange(date, "release_date")}
        />

        <div className="p-2 pt-4 flex justify-between">
          <PrimaryButton text="Save" type="submit" />
        </div>
      </form>
    </div>
  );
}
