import { useOutletContext } from "react-router-dom";

export default function StaffIndex() {
  const { token } = useOutletContext();

  return (
    <>
      <div className="p-10 justify-center w-1/2">
        <div className="flex items-center justify-start gap-2">
          <h1 className="text-5xl font-bold">Home</h1>
        </div>
        <div className="py-4">
          <p>
            Bunch of stuff for staff users in here. That's really just me so far
            hey
          </p>
        </div>
      </div>
    </>
  );
}
