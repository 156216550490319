import { NavLink } from "react-router-dom";

// generic
import Tooltip from "../../../../UI/tooltips/Tooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav({ item, open }) {
  return (
    <li className="group -mx-2 space-y-1">
      <NavLink
        to={item.href}
        className={({ isActive }) =>
          classNames(
            isActive
              ? "bg-gray-50 text-indigo-600"
              : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
            "flex gap-x-3 rounded-md p-4 text-sm leading-6 font-semibold relative"
          )
        }
        end={item.end}
      >
        {(
          { isActive } // You need to render the icon as a React element
        ) => (
          <div className="flex items-center">
            <item.icon
              // Use isActive to conditionally set the icon's className
              className={classNames(
                isActive
                  ? "text-indigo-600"
                  : "text-gray-400 group-hover:text-indigo-600",
                "h-6 w-6 shrink-0"
              )}
              aria-hidden="true"
            />
            {/* Conditionally render the item name */}
            <span className={`ps-2 ${open ? "sm:block" : "hidden"}`}>
              {item.name}
            </span>
            {/* Tooltip */}
            {isActive && <Tooltip text={item.name} />}
          </div>
        )}
      </NavLink>
    </li>
  );
}
