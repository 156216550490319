import TableCell from "../../../../../components/layout/tables/TableCell";
import TableRow from "../../../../../components/layout/tables/TableRow";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

const ShowsModalRow = ({ show, cols, addShowToProject }) => {
  console.log("show: ", show);

  return (
    <TableRow cols={cols}>
      <TableCell>{show.name}</TableCell>
      <TableCell>{show.tour?.name}</TableCell>
      <TableCell>
        {show.is_added_to_project ? (
          "added"
        ) : (
          <PrimaryButton
            text={"Add to project"}
            handleClick={() => addShowToProject(show.id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default ShowsModalRow;
