// generic
import SlideOver from "../../../../components/SlideOver";

// feature specific
import ShowForm from "./ShowForm";

export default function ShowSlideover({
  artistId,
  showId,
  open,
  setOpen,
  selectedShow,
  data,
  addShow,
  updateShow,
  deleteShow,
  createTour,
  addShowToTour,
  showStatuses,
}) {
  console.log("selectedShow: ", selectedShow);
  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        object={selectedShow || null}
        handleDelete={deleteShow}
      >
        <ShowForm
          artistId={artistId}
          show={selectedShow || null}
          setOpen={setOpen}
          tours={data.tours}
          venues={data.venues}
          addShow={addShow}
          updateShow={updateShow}
          createTour={createTour}
          addShowToTour={addShowToTour}
          showStatuses={showStatuses}
        />
      </SlideOver>
    </>
  );
}
