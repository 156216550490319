export default class AudienceViewModel {
  constructor(token, projectId, state, updateState, searchParams) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
    this.searchParams = searchParams;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      //   cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    const queryParams = new URLSearchParams(this.searchParams).toString();
    return await this._get(
      `api/v1/projects/${this.projectId}/analytics/views/audience/?${queryParams}`,
      true
    );
  };

  _fetchInitialProjectCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        data: values[0],
      };
    });
  };

  fetchProjectState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialProjectCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  updateSearchParams = async (newSearchParams) => {
    this.searchParams = newSearchParams;
    this.state = await this._fetchInitialProjectCalls();
    this._callUpdateState();
  };

  sortData = (data, key, ascending = true) => {
    return data.slice().sort((a, b) => {
      // Use slice() to create a shallow copy and avoid modifying the original array
      // Determine the values to compare, handling special keys "Adgroups" and "audience"
      let aValue, bValue;
      if (key === "Adgroup") {
        aValue = a.name;
        bValue = b.name;
      } else if (key === "Audience") {
        aValue = a.audience?.name || ""; // Use optional chaining to safely access name and provide a fallback
        bValue = b.audience?.name || "";
      } else {
        aValue = a[key];
        bValue = b[key];
      }

      // Compare the determined values for sorting
      if (aValue < bValue) {
        return ascending ? -1 : 1;
      }
      if (aValue > bValue) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  };
}
