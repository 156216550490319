import { BarChart, Bar, Tooltip, XAxis } from "recharts";
import CustomTooltip from "../CustomTooltip";

export default function SimplestBarChart({ data, dataKey, format }) {
  return (
    <div style={{ position: "relative" }}>
      <BarChart
        width={300}
        height={200}
        data={data.timeseries}
        // barGap={10}
        // barCategoryGap={5}
        // barSize={5}
      >
        <XAxis hide="true" dataKey={"truncated_date"} />
        {/* <Tooltip content={<CustomTooltip />} /> */}
        <Bar
          dataKey={dataKey}
          fill="#662e91"
          //   stroke="#e34a63"
          //   strokeWidth={2}
          dot={false}
        />
      </BarChart>
      <div
        className="px-4 py-2 border rounded-md shadow-sm bg-white"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span className="text-lg text-gray-700">{format(data.lifetime)}</span>
      </div>
    </div>
  );
}
