export default class ArtistReleasesViewModel {
  constructor(token, artistId, state, updateState) {
    this.artistId = artistId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      `api/v1/artists/${this.artistId}/get-releases/`,
      false
    );
  };

  _fetchTypes = async () => {
    return await this._get(`api/v1/releases/types/`, false);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData(), this._fetchTypes()]).then(
      (values) => {
        return {
          releases: values[0],
          types: values[1],
        };
      }
    );
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  sortReleases = (key) => {
    if (!this.state || !this.state.releases.results) {
      return;
    }

    let sortedReleases;
    const isAscending = this.sortKey === key && !this.isAscending;

    if (key === "name") {
      sortedReleases = [...this.state.releases.results].sort((a, b) => {
        let valueA = a[key].toLowerCase(); // Assuming titles are strings
        let valueB = b[key].toLowerCase();

        if (valueA < valueB) return isAscending ? -1 : 1;
        if (valueA > valueB) return isAscending ? 1 : -1;
        return 0;
      });
    } else if (key === "release_date") {
      sortedReleases = [...this.state.releases.results].sort((a, b) => {
        // Check if either value is an empty string and handle sorting explicitly
        if (
          (a[key] === "" || a[key] === null) &&
          (b[key] !== "" || b[key] !== null)
        ) {
          return isAscending ? 1 : -1; // For ascending, empty strings come first; for descending, they come last.
        } else if (
          (a[key] !== "" || a[key] !== null) &&
          (b[key] === "" || b[key] === null)
        ) {
          return isAscending ? -1 : 1; // Inverse of the above
        } else if (
          (a[key] === "" || a[key] === null) &&
          (b[key] === "" || b[key] === null)
        ) {
          return 0; // Both are empty, keep original order
        }

        // Both have values, compare as dates
        let valueA = new Date(a[key]).getTime();
        let valueB = new Date(b[key]).getTime();
        return isAscending ? valueA - valueB : valueB - valueA;
      });
    } else if (key === "type") {
      sortedReleases = [...this.state.releases.results].sort((a, b) => {
        const A = a.type?.name.toLowerCase() || "";
        const B = b.type?.name.toLowerCase() || "";

        if (A < B) return isAscending ? -1 : 1;
        if (A > B) return isAscending ? 1 : -1;
        return 0;
      });
    }

    this.state.releases.results = sortedReleases;
    this.sortKey = key;
    this.isAscending = isAscending;
    this._callUpdateState();
  };

  fetchMoreReleases = async () => {
    if (!this.state.releases.next) {
      return false;
    }

    const url = this.state.releases.next;
    return await fetch(url, {
      method: "GET",
      headers: this._standardHeaders(),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((res) => {
        this.state.releases.results = this.state.releases.results.concat(
          res.results
        );
        this.state.releases.next = res.next;
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        return false;
      });
  };

  addRelease = async (release) => {
    return await fetch(this._createUrl("api/v1/releases/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(release),
    })
      .then(async (response) => {
        let newRelease = await response.json();
        this.state.releases.results.unshift(newRelease);
        this._callUpdateState();
        return newRelease;
      })
      .catch((error) => {
        console.error("Error updating release", error);
      });
  };

  updateRelease = async (release) => {
    return await fetch(
      this._createUrl(`api/v1/releases/${release.id}/detail/`),
      {
        method: "PATCH",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(release),
      }
    )
      .then(async (response) => {
        let release = await response.json();
        this.state.releases.results = this.state.releases.results.map((r) => {
          if (r.id === release.id) {
            return release;
          }
          return r;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating release", error);
        return false;
      });
  };

  deleteRelease = async (releaseId) => {
    try {
      const response = await fetch(
        this._createUrl(`api/v1/releases/${releaseId}/detail/`),
        {
          method: "DELETE",
          headers: this._standardActionHeaders(),
        }
      );

      if (!response.status === 204) {
        console.log("not ok");
        // If the response is not ok, throw an error
        throw new Error(
          `Failed to delete release, status code: ${response.status}`
        );
      }

      // Remove the task from the local state
      this.state.releases.results = this.state.releases.results.filter(
        (release) => release.id !== releaseId
      );
      this._callUpdateState();
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error deleting release", error);
      return false; // Return false to indicate failure
    }
  };
}
