export default function TableCell({
  handleClick,
  onMouseEnter,
  onMouseLeave,
  display,
  children,
  noClick = false,
  width = "w-1/8",
}) {
  return (
    <td
      className={`h-full border-b border-gray-200 whitespace-nowrap text-sm font-normal ${
        display ? display : "table-cell"
      } ${
        !noClick &&
        "cursor-pointer hover:bg-gray-50 hover:border hover:border-gray-300 hover:p-0 hover:m-0"
      }
        ${width}`}
      onClick={handleClick ? () => handleClick() : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </td>
  );
}
