import { useState } from "react";
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import ArtistTile from "./ArtistTile";

export default function ArtistTileList({ artists, setLoading }) {
  const navigate = useNavigate();

  const handleClick = (artistId) => {
    setLoading(true);
    window.scrollTo(0, 0);
    navigate(`/artists/${artistId}`);
  };

  return (
    <>
      {artists && artists.length > 0 && (
        <div className="">
          <ul className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {artists.map((artist) => (
              <ArtistTile
                key={artist.id}
                artist={artist}
                handleClick={handleClick}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
