import { LoadingComponent } from "../../loading/LoadingScreen";

export default function PrimaryButton({
  handleClick,
  text = "+ Create",
  type = "button",
  disabled = false,
  children,
  widthFull = false,
  isLoading = false,
}) {
  return (
    <button
      type={type}
      onClick={handleClick}
      className={`flex items-center ${
        widthFull ? "justify-center w-full" : "justify-between"
      } gap-1 rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
        disabled
          ? "bg-indigo-300 cursor-default"
          : "bg-indigo-600 hover:bg-indigo-500"
      }`}
      disabled={disabled}
    >
      {text}
      {children}
      {isLoading && <LoadingComponent size="h-5 w-5" />}
    </button>
  );
}
