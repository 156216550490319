export default class AdaccountsViewModel {
  constructor(token, projectId, organizationId, state, updateState) {
    this.projectId = projectId;
    this.organizationId = organizationId;
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (url, cache) => {
    return await fetch(url, {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/adaccounts/?project_id=${this.projectId}`
      ),
      false
    );
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return { adaccounts: values[0] };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      let data = await this._fetchInitialCalls();
      this.state = data;
      this._callUpdateState();
    }
    return this.state;
  };

  refreshState = async (page = "1", searchQuery = "") => {
    const url = this._createUrl(
      `api/v1/organizations/${this.organizationId}/adaccounts/?project_id=${this.projectId}&page=${page}&q=${searchQuery}`
    );

    const response = await this._get(url);
    if (response) {
      this.state.adaccounts = response;
      this._callUpdateState();
    }
  };

  addAdaccountToProject = async (adaccountId) => {
    const response = await fetch(
      this._createUrl(`api/v1/projects/${this.projectId}/add-adaccount/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({
          adaccount_id: adaccountId,
        }),
      }
    );

    if (response.ok) {
      // update the adaccounts array state
      this.state.adaccounts.results = this.state.adaccounts.results.map((a) => {
        if (a.id === adaccountId) {
          a.is_added_to_project = true;
          return a;
        }
        return a;
      });

      this._callUpdateState();
    }
  };
}
