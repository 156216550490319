import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";

const TableHeaderSticky = ({
  name,
  label,
  handleSort,
  sortedHeader,
  sortAscending,
  index,
}) => {
  return (
    <th
      onClick={() => handleSort(name)}
      className={`
      ${index === 0 ? "sticky left-0" : ""}
       bg-white py-3.5 pr-3 text-left font-semibold text-gray-900 cursor-pointer`}
    >
      <div className="flex justify-start items-center gap-1">
        {label}
        {sortedHeader === name && (
          <div className="w-4 h-4">
            {sortAscending ? <BarsArrowDownIcon /> : <BarsArrowUpIcon />}
          </div>
        )}
      </div>
    </th>
  );
};

export default TableHeaderSticky;
