import { useState } from "react";
import { ShareIcon } from "@heroicons/react/24/outline";

import PlayButton from "../releases/PlayButton";
import ArtistMenu from "./ArtistMenu";

export default function ArtistHeader({
  artist,
  handlePlayClick,
  isPlaying,
  contextURI,
  playingContextURI,
  toggleArtistFollow,
  refreshSidebarData,
}) {
  const [isFollowing, setIsFollowing] = useState(artist.is_following);

  const handleClick = async () => {
    await toggleArtistFollow(!isFollowing);
    setIsFollowing(!isFollowing);
    await refreshSidebarData();
  };

  return (
    <>
      <div className="relative h-96 px-2 pb-2">
        {/* Cover image */}
        {artist.cover_pic ? (
          <img
            src={artist.cover_pic}
            className="absolute top-0 left-0 w-full h-full object-cover object-top"
            alt={artist.name}
          />
        ) : (
          <div
            className="absolute top-0 left-0 w-full h-full object-cover object-top bg-gray-400"
            alt={artist.name}
          />
        )}

        {/* Overlay */}
        <div className="relative flex flex-col items-start justify-end h-full sm:px-4 md:px-12 py-2">
          <h1 className="text-8xl font-bold text-white py-1">{artist.name}</h1>

          <div className="flex items-center gap-2">
            <PlayButton
              handleClick={handlePlayClick}
              isPlaying={isPlaying}
              contextURI={contextURI}
              playingContextURI={playingContextURI}
            />

            {/* <button
              onClick={handleClick}
              type="button"
              className="rounded-md text-sm font-semibold text-gray-50 hover:text-gray-300"
            >
              <ShareIcon className="h-8 w-8" aria-hidden="true" />
            </button> */}

            <button
              type="button"
              onClick={handleClick}
              className="rounded-full px-10 py-2 text-lg font-semibold shadow-sm ring-1 ring-inset ring-gray-300 text-white hover:ring-gray-300 hover:border-2"
            >
              {isFollowing ? "Following" : "Follow"}
            </button>
            <ArtistMenu artistId={artist.id} />
          </div>
        </div>
      </div>
    </>
  );
}
