import { useNavigate, useLocation } from "react-router-dom";

// feature specific
import Mobile from "./Mobile";
import Tab from "./Tab";

export default function BudgetTabs({
  tabs,
  selectedCampaigns,
  setSelectedCampaigns,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const isActiveTab = (tabHref) => {
    const currentPath = location.pathname;
    const tabPath = new URL(tabHref, window.location.origin).pathname;
    return currentPath === tabPath;
  };

  const activeTab = tabs.find((tab) => isActiveTab(tab.href));

  return (
    <>
      <div>
        <Mobile tabs={tabs} activeTab={activeTab} navigate={navigate} />
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <Tab
                key={tab.name}
                tab={tab}
                tabIdx={tabIdx}
                isActiveTab={isActiveTab}
                tabsLength={tabs.length}
                selectedCampaigns={selectedCampaigns}
                setSelectedCampaigns={setSelectedCampaigns}
              />
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}
