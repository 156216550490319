import { Link } from "react-router-dom";

import PlayButton from "./PlayButton";
import FavoriteIcon from "./FavoriteIcon";
import ReleaseMenu from "./ReleaseMenu";

export default function ReleaseHeader({
  data,
  handleClick,
  isPlaying,
  contextURI,
  playingContextURI,
  isFavorite,
  favoriteRelease,
}) {
  // Determine if the release name is long
  const isLongName = data.release.name.length > 20;

  return (
    <>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:grid md:grid-cols-[auto_1fr] gap-4 justify-center items-center">
          <div className="w-full flex justify-center items-center md:flex lg:items-start">
            {data?.release?.artwork ? (
              <img
                className="h-80 w-80 md:h-60 md:w-60 rounded-lg bg-gray-300"
                src={data.release.artwork}
                alt={data.release.name}
              />
            ) : (
              <div
                className="h-80 w-80 md:h-60 md:w-60 object-cover rounded-lg bg-gray-300"
                alt={data.release.name}
              />
            )}
          </div>
          <div className="w-80 md:w-4/5 md:mx-4 md:mt-0 md:p-4">
            <p className="text-sm text-gray-400">{data.release.type.name}</p>
            <h1
              className={`font-bold text-gray-800 ${
                isLongName ? "text-xl md:text-3xl" : "text-2xl md:text-6xl"
              }`}
            >
              {data.release.name}
            </h1>
            {data.release.artists && data.release.artists.length > 0 && (
              <div>
                {data.release.artists.map((artist, index) => (
                  <h2
                    key={artist.id}
                    className={`text-gray-600 hover:underline ${
                      isLongName ? "text-sm md:text-2xl" : "text-md md:text-3xl"
                    }`}
                  >
                    <Link to={`/artists/${artist.id}`}>
                      {artist.name}
                      {index < data.release.artists.length - 1 ? ", " : ""}
                    </Link>
                  </h2>
                ))}
              </div>
            )}

            <div className="flex justify-start items-center gap-3 pt-1">
              <PlayButton
                handleClick={handleClick}
                isPlaying={isPlaying}
                contextURI={contextURI}
                playingContextURI={playingContextURI}
              />
              <FavoriteIcon
                isFavorite={isFavorite}
                favoriteRelease={favoriteRelease}
              />
              <ReleaseMenu releaseId={data.release.id} darkStyle={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
