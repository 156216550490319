import { Disclosure } from "@headlessui/react";

// generic
import AppsDropdown from "./AppsDropdown";
import AvatarDropdown from "./AvatarDropdown";
import MobileMenuButton from "./MobileMenuButton";
import SiteLogo from "./SiteLogo";
import SearchDropdown from "./SearchDropdown";
import MobileMenu from "./MobileMenu";

export default function Index({
  user,
  navItems,
  appItems,
  avatarItems,
  searchOptions,
  fetchSearchOptions,
  handleSelect,
  searchPlaceholder,
}) {
  return (
    <>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex justify-start items-center">
                  {navItems && <MobileMenuButton open={open} />}

                  <SiteLogo />
                </div>

                {fetchSearchOptions && (
                  <div className="hidden md:flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                    <div className="w-full max-w-lg lg:max-w-md">
                      <SearchDropdown
                        options={searchOptions}
                        fetchOptions={fetchSearchOptions}
                        handleSelect={handleSelect}
                        placeholder={searchPlaceholder}
                      />
                    </div>
                  </div>
                )}

                <div className="lg:ml-4 flex items-center">
                  {appItems && <AppsDropdown appItems={appItems} />}
                  {avatarItems && (
                    <AvatarDropdown user={user} avatarItems={avatarItems} />
                  )}
                </div>
              </div>
            </div>

            <MobileMenu navItems={navItems} />
          </>
        )}
      </Disclosure>
    </>
  );
}
