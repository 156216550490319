import { XMarkIcon } from "@heroicons/react/24/outline";

export default function BudgetTabs({ selectedLength, setSelectedCampaigns }) {
  const handleClick = () => {
    setSelectedCampaigns([]);
  };

  return (
    <div className="flex justify-between items-center bg-indigo-500 font-light text-gray-50 rounded-full py-1 px-2">
      {selectedLength} selected
      <button
        onClick={handleClick}
        className="my-0 ml-2 text-sm font-semibold text-white hover:text-gray-50 hover:bg-indigo-600 rounded-full p-1"
      >
        <XMarkIcon className="h-4 w-4" />
      </button>
    </div>
  );
}
