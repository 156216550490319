import { useState } from "react";
import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";
import SecondaryButton from "../../../../components/UI/buttons/SecondaryButton";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SubscriptionBoxes({
  prices,
  createCheckout,
  onCancel,
}) {
  const [selectedSubscription, setSelectedSubscription] = useState([]);

  const handleSelectionChange = (price) => {
    if (price.already_purchased || !price.is_available) {
      // Do nothing if the item is already purchased
      return;
    }

    if (selectedSubscription === price) {
      setSelectedSubscription(null);
    } else {
      setSelectedSubscription(price);
    }
  };

  const handleClick = async () => {
    let redirectURL = await createCheckout(selectedSubscription);
  };

  return (
    <>
      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {prices.map((price) => (
          <div
            key={price.id}
            className={classNames(
              "relative flex rounded-lg border p-4 shadow-sm focus:outline-none",
              price.already_purchased
                ? "bg-gray-100 cursor-not-allowed"
                : "cursor-pointer",
              selectedSubscription === price && !price.already_purchased
                ? "border-indigo-600 border-2"
                : "border-gray-200",
              !price.is_available
                ? "bg-gray-100 cursor-not-allowed"
                : "bg-white cursor-pointer"
            )}
            onClick={() => handleSelectionChange(price)}
          >
            <span className="flex flex-1">
              <span className="flex flex-col justify-between">
                <span className="block text-sm font-medium text-gray-900">
                  {price.product.name}
                </span>
                <span className="mt-1 flex items-center text-sm text-gray-500">
                  {price.product.description}
                </span>
                <div className="mt-6 text-lg font-medium text-gray-900">
                  <div className="flex justify-start items-center gap-2">
                    <span className="text-4xl text-indigo-600">
                      ${price.amount}
                    </span>
                    <span className="text-sm text-normal text-gray-400">
                      USD/month
                    </span>
                  </div>
                </div>
              </span>
            </span>
            <CheckCircleIcon
              className={classNames(
                selectedSubscription !== price ||
                  price.already_purchased ||
                  !price.is_available
                  ? "invisible"
                  : "",
                "h-5 w-5 text-indigo-600"
              )}
              aria-hidden="true"
            />
          </div>
        ))}
      </div>
      <div className="flex justify-between py-4">
        <PrimaryButton
          text="Select plan"
          handleClick={handleClick}
          disabled={selectedSubscription.length === 0}
        />
        <SecondaryButton text="Cancel" handleClick={onCancel} />
      </div>
    </>
  );
}
