export default function InputCurrencySelect({
  label,
  name,
  value,
  onChange,
  options,
  onSelectChange,
  error,
  currency,
  lockCurrency,
}) {
  return (
    <div>
      <label
        htmlFor="price"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="text"
          onChange={onChange}
          name={name}
          id={name}
          value={value}
          className={`block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
            error
              ? "pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
              : "focus:ring-indigo-600"
          }`}
          placeholder="0.00"
        />
        {lockCurrency ? (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {/* Assuming the locked currency is displayed using its name. Adjust accordingly. */}
            <span className="text-gray-500 sm:text-sm">{currency?.name}</span>
          </div>
        ) : (
          <div className="absolute mr-2 inset-y-0 right-0 flex items-center">
            <label htmlFor="currency" className="sr-only">
              Currency
            </label>
            <select
              id="currency"
              onChange={onSelectChange}
              name="currency"
              className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            >
              {options.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  selected={option.id === value}
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}
