import { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";

// generic
import LoadingScreen from "../../../../../components/loading/LoadingScreen";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/artists/shows/viewmodel";
import ShowTable from "../../../../../features/forArtists/artists/shows/ShowTable";
import ShowSlideover from "../../../../../features/forArtists/artists/shows/ShowSlideover";

export default function Shows() {
  const { token } = useOutletContext();
  let { artistId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, artistId, data, (state) => {
      setData(state);
    })
  );
  const [open, setOpen] = useState(false);
  const [selectedShow, setSelectedShow] = useState();

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel, token, artistId]);

  const handleShowClick = (show) => {
    setOpen(true);
    setSelectedShow(show);
  };

  if (!data) return <LoadingScreen />;

  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <h1 className="text-5xl font-bold">Shows</h1>
      </div>
      <div className="p-4">
        <div className="">
          <PrimaryButton
            handleClick={() => {
              setOpen(!open);
              setSelectedShow(null);
            }}
          />
        </div>
        <div className="">
          {data?.shows?.results?.length > 0 && (
            <ShowTable
              shows={data.shows}
              selectShow={(show) => handleShowClick(show)}
              updateShow={async (show) => {
                await viewModel.updateShow(show);
              }}
              showStatuses={data.statuses}
              sortShows={(key) => viewModel.sortShows(key)}
              fetchMoreShows={async () => {
                return await viewModel.fetchMoreShows();
              }}
            />
          )}
        </div>
      </div>

      {open && (
        <ShowSlideover
          artistId={artistId}
          showId={selectedShow?.id}
          open={open}
          setOpen={setOpen}
          selectedShow={selectedShow}
          data={data}
          showStatuses={data.statuses}
          addShow={async (show) => {
            return await viewModel.addShow(show);
          }}
          updateShow={async (show) => {
            await viewModel.updateShow(show);
          }}
          createTour={async (tourName) => {
            return await viewModel.createTour(tourName);
          }}
        />
      )}
    </>
  );
}
