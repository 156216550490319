import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/20/solid";

import ViewModel from "../../../../models/forArtists/main/staff/releaseIngestionViewModel";
import ImageUploadTile from "../../../../components/forms/ImageUploadTile";
import InputField from "../../../../components/forms/InputField";
import MultiSelect from "../../../../components/forms/MultiSelect";
import { DatePickerWidget } from "../../../../components/forms/DatePickerWidget";
import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";
import { convertToDate } from "../../../../utils/dateUtils";
import { LoadingComponent } from "../../../../components/loading/LoadingScreen";
import HeadingEditable from "../../../../components/forms/HeadingEditable";
import TracksUpload from "../../../../components/forms/TracksUpload";
import SelectSearch from "../../../../components/forms/SelectSearch";

export default function Ingestion() {
  const { token } = useOutletContext();
  const baseURL = process.env.REACT_APP_API_URL;
  let { releaseId } = useParams();
  const [releaseViewData, setReleaseTaskViewData] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, releaseId, releaseViewData, (state) => {
      setReleaseTaskViewData(state);
    })
  );
  const [formValues, setFormValues] = useState({
    artists: [],
    name: "",
    type: "",
    releaseArtwork: null,
    releaseDate: null,
    tracks: [],
    // ... other form fields as needed
  });

  useEffect(() => {
    viewModel.fetchState().then((fetchedData) => {
      if (fetchedData) {
        // Assuming `fetchedData` has the release data in the format you need
        setFormValues({
          name: fetchedData.data.release.name || "",
          type: fetchedData.data.release.type || "",
          artists: fetchedData.data.release.artists || [],
          releaseArtwork: fetchedData.data.release.artwork || null,
          releaseDate: fetchedData.data.release.release_date
            ? convertToDate(fetchedData.data.release.release_date)
            : null,
        });
      }
    });
  }, [viewModel, token, releaseId]);

  const handleImageUpload = (imageData) => {
    setIsUpdated(false);
    setFormValues({ ...formValues, releaseArtwork: imageData });
  };

  const handleInputChange = (e) => {
    setIsUpdated(false);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleTypeChange = (selectedType) => {
    setIsUpdated(false);
    setFormValues({ ...formValues, type: selectedType });
  };

  const handleArtistChange = (selectedArtists) => {
    setIsUpdated(false);
    setFormValues({ ...formValues, artists: selectedArtists });
  };

  const handleDateChange = (date) => {
    setIsUpdated(false);
    setFormValues({ ...formValues, releaseDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    const submissionData = {
      ...formValues,
      release_date: formValues.releaseDate.toISOString().split("T")[0],
      image_id: formValues.releaseArtwork.imageId,
    };

    const res = await viewModel.updateRelease(submissionData);
    if (res) {
      setIsUpdated(true);
    }
    setIsUpdating(false);
  };

  return (
    <>
      <div className="lg:p-10 justify-center space-y-4">
        <div className="flex items-center justify-start py-4 gap-6">
          <ImageUploadTile
            releaseArtworkUrl={formValues.releaseArtwork}
            onUpload={handleImageUpload}
          />
          <HeadingEditable
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
          />
        </div>
        {releaseViewData?.data?.options?.types && (
          <SelectSearch
            label={"Type"}
            initialOption={formValues.type}
            options={releaseViewData.data.options.types}
            onSelectChange={handleTypeChange}
          />
        )}
        <MultiSelect
          label={"Release Artists"}
          selectedArtists={formValues.artists}
          onChange={handleArtistChange}
        />
        <DatePickerWidget
          label="Release Date"
          name="releaseDate"
          selected={formValues.releaseDate}
          onChange={handleDateChange}
        />

        <div className="flex justify-start items-center gap-4">
          <PrimaryButton text={"Save release"} handleClick={handleSubmit}>
            {isUpdating && <LoadingComponent size={"text-md"} />}
          </PrimaryButton>
          {isUpdated && <CheckIcon className="w-6 h-6 text-green-500" />}
        </div>
      </div>

      <hr />

      <div className="lg:p-10 justify-center space-y-4">
        <h2 className="text-2xl font-bold">Tracks</h2>

        <TracksUpload
          label={"Tracks"}
          tracks={formValues.tracks}
          artists={formValues.artists}
          updateReleaseTracks={viewModel.updateReleaseTracks}
        />
      </div>
    </>
  );
}
