// generic
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import TableRow from "../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../components/layout/tables/TableCell";

export default function Row({ page, setOpenModal, setSelectedPage }) {
  const handleButtonClick = (page) => {
    setOpenModal(true);
    setSelectedPage(page);
  };

  return (
    <>
      <TableRow key={page.id}>
        <TableCell>
          <div className="flex-col">
            <p className="text-sm font-normal leading-6 text-gray-900">
              {page.name}
            </p>
          </div>
        </TableCell>
        <TableCell>
          <SecondaryButton
            text={"remove"}
            handleClick={() => handleButtonClick(page)}
          />
        </TableCell>
      </TableRow>
    </>
  );
}
