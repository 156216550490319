import { useEffect, useState } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import {
  ArrowLeftIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

// generic
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";

const DataGoogle = () => {
  const { token } = useOutletContext();
  const baseURL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get("code");

  useEffect(() => {
    if (code) {
      console.log("code: ", code);
      fetch(`${baseURL}api/v1/google/get-token-from-code/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
          redirectUri: window.location.origin + "/data/google/",
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Error: " + res.status); // or a more specific message based on status
          }
          return res.json();
        })
        .then((res) => {
          console.log(res);
          setIsLoading(false);
          setIsUpdated(true);
          setTimeout(() => {
            setSearchParams({ code: null });
          }, 3000);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setIsLoading(false);
          setIsError(true);
          // Handle the error state here, like showing an error message
        });
    }
  }, []);

  return (
    <>
      <div className="flex justify-start p-8">
        <Link to="/data" className="flex items-center gap-2">
          <ArrowLeftIcon
            className="h-8 w-8 flex-shrink-0 text-gray-800 duration-300 hover:text-indigo-700 hover:ml-2"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-center items-center mt-40 gap-4">
        {isError && (
          <div className="flex justify-center items-center py-6">
            <XMarkIcon className="w-6 h-6 text-red-500" />{" "}
            <span className="text-red-500">
              There was an error. Try going back and re-connecting.
            </span>
          </div>
        )}
        {isLoading && (
          <div className="flex-col justify-center items-center gap-2">
            <div className="text-gray-700">
              Confirming connection and fetching adaccounts. If there's a lot of
              accounts, this can take a while.
            </div>
            <div className="text-gray-500">
              Do not exit or refresh window until this is finished
            </div>
            <LoadingComponent />
          </div>
        )}
        {isUpdated && (
          <div className="flex justify-start items-center gap-2">
            <div className="text-gray-700">
              Completed. Go back to Organization data sources and refresh the
              page to continue setup.
            </div>
            <CheckIcon className="w-6 h-6 text-green-500" />
          </div>
        )}
      </div>
    </>
  );
};

export default DataGoogle;
