const TableRow = ({ children, handleClick, isCompleted = false }) => {
  return (
    <tr
      className={`h-14 ${handleClick && "hover:bg-gray-50 cursor-pointer"} ${
        isCompleted && "bg-gray-50"
      }`}
      onClick={handleClick}
    >
      {children}
    </tr>
  );
};

export default TableRow;
