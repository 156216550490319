// generic
import StackedListItem from "../../../../../components/dataDisplay/lists/StackedListItem";
import StackedListContainer from "../../../../../components/dataDisplay/lists/StackedListContainer";
import Modal from "../../../../../components/UI/modals/Modal";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";
import { useEffect } from "react";

export default function PageModal({
  title,
  isLoading,
  openModal,
  setOpenModal,
  pageOptions,
  addPage,
}) {
  const handleButtonClick = async (page) => {
    await addPage(page);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Modal open={openModal} setOpen={setOpenModal}>
      <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      <StackedListContainer>
        {pageOptions ? (
          pageOptions?.map((item) => (
            <StackedListItem
              key={item.id}
              item={item}
              btnPrimaryText={"+ Add"}
              btnPrimaryAction={handleButtonClick}
            />
          ))
        ) : (
          <div>No pages available.</div>
        )}
      </StackedListContainer>
    </Modal>
  );
}
