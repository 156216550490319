import { useEffect, useState } from "react";

import ViewModel from "../../models/forFans/profile/libraryViewModel";
import ListGroup from "../../components/dataDisplay/lists/ListGroup";
import LoadingScreen from "../../components/loading/LoadingScreen";
import ReleaseList from "../../components/forFans/artists/ReleaseList";

export default function Library() {
  const token = localStorage.getItem("token");
  const [libraryViewData, setLibraryViewData] = useState();
  const [libraryViewModel, setLibraryViewModel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const viewModel = new ViewModel(token, libraryViewData, (state) =>
      setLibraryViewData(state)
    );
    setLibraryViewModel(viewModel);
  }, [token, libraryViewData]);

  useEffect(() => {
    if (!libraryViewData) {
      if (libraryViewModel) {
        libraryViewModel.fetchState();
      }
    }
    setLoading(false);
  }, [libraryViewModel, libraryViewData]);

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <>
      {libraryViewData?.data?.releases && (
        <ReleaseList
          releases={libraryViewData.data.releases}
          showArtist={true}
          setLoading={setLoading}
        />
      )}
    </>
  );
}
