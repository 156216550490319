import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

// generic
import Modal from "../../../../../components/UI/modals/Modal";
import TableBody from "../../../../../components/layout/tables/TableBody";
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/data/adaccounts/viewmodel";
import AdaccountsModalRow from "./AdaccountsModalRow";
import AdaccountsSearch from "./AdaccountsSearch";
import AdaccountsPagination from "./AdaccountsPagination";

const AdaccountsModal = ({ isModalOpen, setIsLoading, handleClosingModal }) => {
  const { token, project } = useOutletContext();
  let { projectId } = useParams();
  let organizationId = project?.organization?.id;

  //   view model
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, organizationId, data, (state) => {
      setData(state);
    })
  );
  // set state with adaccount campaigns
  useEffect(() => {
    viewModel.fetchState();
    setIsLoading(false);
  }, [viewModel, token, projectId]);

  const cols = [
    { id: 1, name: "Adaccount", label: "Name" },
    { id: 2, name: "Platform", label: "Platform" },
    { id: 3, name: "Status", label: "Status" },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  return (
    <Modal open={isModalOpen} setOpen={handleClosingModal} fullWidth={true}>
      <h2 className="text-2xl ps-10 pt-4 py-1 text-gray-700 font-bold">
        Add Adaccounts to Project
      </h2>
      <AdaccountsSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        refreshState={async (page, searchQuery) => {
          await viewModel.refreshState(page, searchQuery);
        }}
        setPage={setPage}
      />
      {/* <div className="h-96 overflow-y-auto"> */}
      <TableContainer>
        <TableHeader cols={cols} />
        <TableBody>
          {data?.adaccounts?.results?.map((adaccount, index) => {
            return (
              <AdaccountsModalRow
                key={index}
                adaccount={adaccount}
                cols={cols}
                addAdaccountToProject={async (adaccountId) => {
                  await viewModel.addAdaccountToProject(adaccountId);
                }}
              />
            );
          })}
        </TableBody>
      </TableContainer>
      {/* </div> */}
      <AdaccountsPagination
        count={data?.count}
        page={page}
        setPage={setPage}
        refreshState={async (page) => {
          await viewModel.refreshState(page, searchQuery);
        }}
      />
    </Modal>
  );
};

export default AdaccountsModal;
