import { useState } from "react";
import { useOutletContext } from "react-router-dom";

// generic
import TableRow from "../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../components/layout/tables/TableCell";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import SelectFieldStealth from "../../../../../components/forms/SelectFieldStealth";

const AdgroupTableRow = ({
  adgroup,
  updateAdgroup,
  projectShows,
  projectReleases,
  projectCollections,
  typeObj,
}) => {
  const [formData, setFormData] = useState({
    project_show: adgroup.project_show,
    project_release: adgroup.project_release,
    project_collection: adgroup.project_collection,
  });

  const handleSelectChange = async (selectedValue, fieldName) => {
    const updatedFormData = { ...formData, [fieldName]: selectedValue };
    setFormData(updatedFormData);

    // Prepare the data for the updateAdgroup function
    const adgroupData = {
      project_show_id: updatedFormData.project_show?.id,
      project_release_id: updatedFormData.project_release?.id,
      project_collection_id: updatedFormData.project_collection?.id,
      project_platform_id: updatedFormData.project_platform?.id,
    };

    // Call the updateAdgroup function with the new data
    const updated = await updateAdgroup(adgroup, adgroupData);
    if (updated) {
      console.log("Adgroup updated successfully");
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{adgroup.name}</TableCell>

        {typeObj === "Show" &&
        (adgroup?.has_campaign_project_show || adgroup?.has_campaign_budget) ? (
          <TableCell display="hidden sm:table-cell" hover={false}>
            {adgroup.project_show?.name} (Set at campaign level)
          </TableCell>
        ) : projectShows ? (
          <TableCell display="hidden sm:table-cell" hover={true}>
            <SelectFieldStealth
              options={projectShows}
              selected={formData.project_show || null}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "project_show")
              }
            />
          </TableCell>
        ) : null}

        {typeObj === "Release" &&
        (adgroup?.has_campaign_project_release ||
          adgroup?.has_campaign_budget) ? (
          <TableCell display="hidden sm:table-cell" hover={false}>
            {adgroup.project_release?.name} (Set at campaign level)
          </TableCell>
        ) : projectReleases ? (
          <TableCell display="hidden sm:table-cell" hover={true}>
            <SelectFieldStealth
              options={projectReleases}
              selected={formData.project_release || null}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "project_release")
              }
            />
          </TableCell>
        ) : null}

        {typeObj === "Collection" &&
        (adgroup?.has_campaign_project_collection ||
          adgroup?.has_campaign_budget) ? (
          <TableCell display="hidden sm:table-cell" hover={false}>
            {adgroup.project_collection?.name} (Set at campaign level)
          </TableCell>
        ) : projectCollections ? (
          <TableCell display="hidden sm:table-cell" hover={true}>
            <SelectFieldStealth
              options={projectCollections}
              selected={formData.project_collection || null}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "project_collection")
              }
            />
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
};

export default AdgroupTableRow;
