import { NavLink, useParams, Outlet, useOutletContext } from "react-router-dom";
import Tabs from "../../../../components/Tabs";

const navigationItems = [
  { name: "Overview", href: "", end: true },
  {
    name: "Timeseries",
    href: "timeseries/",
    end: false,
  },
  { name: "Platform", href: "platform/", end: false },
  { name: "Audience", href: "audience/", end: false },
  { name: "Creative", href: "creative/", end: false },
];

export default function ProjectAnalytics() {
  const { token, project } = useOutletContext();

  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <h1 className="text-5xl font-bold">Analytics</h1>
      </div>
      <div className="flex items-center justify-start gap-2 my-6">
        <div className="flex flex-row py-4 px-1">
          <Tabs tabs={navigationItems} />
        </div>
      </div>

      <Outlet context={{ token, project }} />
    </>
  );
}
