import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Zantipi_Logo from "../../../assets/images/Zantipi_Logo.png";
import ZantipiCat from "../../../assets/images/Zantipi_Cat_no_zzz_screen_res.png";

export default function PasswordReset() {
  const [resetStatus, setResetStatus] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Here we call the backend API to login the user
    fetch(`${baseURL}api/v1/otp/login/verify/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Log the error message body
          response.json().then((json) => console.error(json));
          throw new Error("Something went wrong");
        }
      })
      .then(async (data) => {
        if (data) {
          setResetStatus(true);
          // save to local storage
          localStorage.setItem("token", data.token);
          navigate("/");
        } else {
          // Handle any errors
          console.error("Invalid credentials");
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
      });
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full p-4">
        {/* zantipi logo */}
        <div className="flex flex-col items-center">
          <img
            src={ZantipiCat}
            alt="Zantipi Cat"
            className="w-40 sm:max-w-sm"
          />
          <img className="h-20 w-auto -mt-4" src={Zantipi_Logo} alt="Zantipi" />
        </div>

        {/* sign in box */}
        <div className="w-full sm:max-w-sm border rounded-lg px-6 py-4">
          {resetStatus ? (
            <p>You are successfully verified!</p>
          ) : (
            <>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="otp"
                    className="block mt-3 font-bold leading-6 text-gray-900"
                  >
                    Enter verification code
                  </label>
                  <p className="mb-6 text-sm font-light text-gray-500">
                    For your security, we've sent the login code to your email.
                  </p>

                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      id="otp"
                      name="otp"
                      type="tel"
                      placeholder="######"
                      inputMode="numeric"
                      pattern="[0-9]{6}"
                      autoComplete="one-time-code"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      maxLength="6"
                      style={{ letterSpacing: "4px" }}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Submit code
                  </button>
                </div>
              </form>
              <p className="mt-6 text-sm font-medium text-gray-600">
                Need help?
              </p>
              <p className="text-sm font-light text-gray-500">
                If it doesn't appear in your email, check your spam folder.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}
