import { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import LoadingScreen from "../../components/loading/LoadingScreen";
import ProductViewModel from "../../models/forFans/products/productViewModel";
import ProductHeader from "../../components/forFans/products/ProductHeader";
import ErrorPage from "../ErrorPage";

export default function ProductIndex() {
  const token = localStorage.getItem("token");
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  // view model
  let { productId } = useParams();
  const [productViewData, setProductViewData] = useState();
  const [productViewModel, setProductViewModel] = useState(null);

  useEffect(() => {
    setLoading(true);
    const viewModel = new ProductViewModel(
      token,
      productId,
      productViewData,
      (state) => setProductViewData(state),
      searchParams
    );
    setProductViewModel(viewModel);
  }, [token, productId, searchParams]);

  useEffect(() => {
    if (productViewModel) {
      productViewModel.fetchProductState().then(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
    }
  }, [productViewModel]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!productViewData || !productViewData.data) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className="container-lg mb-20">
        <div className="flex flex-col items-center justify-center">
          {productViewData?.data?.product && (
            <ProductHeader product={productViewData.data.product} />
          )}
        </div>
      </div>
    </>
  );
}
