import TableCell from "../../../../../components/layout/tables/TableCell";
import TableRow from "../../../../../components/layout/tables/TableRow";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

const ReleasesModalRow = ({ release, cols, addReleaseToProject }) => {
  return (
    <TableRow cols={cols}>
      <TableCell>{release.name}</TableCell>
      <TableCell>
        {release.is_added_to_project ? (
          "added"
        ) : (
          <PrimaryButton
            text={"Add to project"}
            handleClick={() => addReleaseToProject(release.id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default ReleasesModalRow;
