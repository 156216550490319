import { useContext, useEffect, useState } from "react";
import { Outlet, useParams, useOutletContext } from "react-router-dom";
import {
  CircleStackIcon,
  UserGroupIcon,
  CreditCardIcon,
  HomeIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

// generic
import LoadingScreen from "../../../../../components/loading/LoadingScreen.jsx";
import Sidebar from "../../../../../components/layout/navigation/sidebar/Index.jsx";
import { UserContext } from "../../../MainLayoutContext.jsx";
// feature specific
import ViewModel from "../../../../../models/forArtists/main/organizations/viewmodel.js";
import Navbar from "../../../../../components/layout/navigation/navbar/Index.jsx";

const navItems = [
  { name: "Home", href: "", icon: HomeIcon, end: true },
  {
    name: "Tasks",
    href: "tasks/",
    icon: CheckCircleIcon,
    end: false,
  },
  // { name: "Analytics", href: "analytics", icon: ChartPieIcon, end: false },
  {
    name: "Users",
    href: "users/",
    icon: UserGroupIcon,
    current: false,
  },
  {
    name: "Data",
    href: "data/",
    icon: CircleStackIcon,
    current: false,
  },
  {
    name: "Billing",
    href: "billing/",
    icon: CreditCardIcon,
    end: false,
  },
];

const avatarItems = [{ id: 1, name: "Connect my data", href: "/data/" }];

export default function OrganizationRoot() {
  const [isLoading, setIsLoading] = useState(true);
  const { token, appItems } = useOutletContext();
  const user = useContext(UserContext);

  let { organizationId } = useParams();
  const [organizationViewData, setOrganizationViewData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, organizationId, organizationViewData, (state) => {
      setOrganizationViewData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token, organizationId]);

  if (isLoading) {
    <LoadingScreen />;
  }

  return (
    <>
      <div className="flex flex-col h-screen">
        <Navbar
          user={user}
          navItems={navItems}
          appItems={appItems}
          avatarItems={avatarItems}
        />

        <div className="flex h-[calc(100vh-4rem)]">
          {/* Sidebar */}
          <aside className="hidden lg:flex flex-grow">
            <Sidebar navItems={navItems} />
          </aside>

          {/* Main Content */}
          <main className="w-full flex-grow overflow-y-auto p-4">
            {/* organization title for mobile */}
            <div className="p-2 mb-2">
              <div className="flex items-center justify-center ">
                <span className="text-gray-400">
                  Organization: {organizationViewData?.data.name}
                </span>
              </div>
            </div>
            <div className="flex-1 py-20">
              <Outlet context={{ token, viewModel, organizationViewData }} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
