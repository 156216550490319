// generic
import TableRow from "../../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../../components/layout/tables/TableCell";
import SelectSearchOrCreate from "../../../../../../components/forms/SelectSearchOrCreate";

export default function ModalRow({
  cols,
  index,
  ad,
  assetLabels,
  adTypes,
  selected,
  handleSelectChange,
  createAssetLabel,
  addAssetLabelToAd,
  createAdType,
  addAdTypeToAd,
}) {
  return (
    <TableRow cols={cols}>
      <TableCell>
        <input
          id={ad.id}
          name={`selected-${index}`}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          checked={selected.includes(ad.id.toString())}
          onChange={(e) => handleSelectChange(e, index)}
        />
      </TableCell>

      <TableCell>{ad.name}</TableCell>
      <TableCell>
        <SelectSearchOrCreate
          initialOption={ad.ad_type}
          options={adTypes}
          createNew={(adTypeName) => {
            createAdType(ad.id, adTypeName);
          }}
          updateObject={(adType) => {
            addAdTypeToAd(ad.id, adType);
          }}
        />
      </TableCell>
      <TableCell>
        <SelectSearchOrCreate
          initialOption={ad.asset_label}
          options={assetLabels}
          createNew={(assetLabelName) => {
            createAssetLabel(ad.id, assetLabelName);
          }}
          updateObject={(assetLabel) => {
            addAssetLabelToAd(ad.id, assetLabel);
          }}
        />
      </TableCell>
    </TableRow>
  );
}
