import React from "react";
import { Link } from "react-router-dom";
import {
  PlayIcon,
  PauseIcon,
  ForwardIcon,
  BackwardIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/20/solid";

const TimeDisplay = React.memo(
  ({ handleTimeSliderChange, currentTime, duration }) => {
    // console.log("TimeDisplay re-rendered");
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
      <>
        <span className="text-xs mr-2">{formatTime(currentTime)}</span>
        <input
          type="range"
          step="any"
          min="0"
          max={duration}
          value={currentTime}
          onChange={(e) => handleTimeSliderChange(e.target.value)}
          className="h-0.5 mx-4 2xl:mx-6 rounded-lg w-full"
        />

        <span className="text-xs ml-2">{formatTime(duration)}</span>
      </>
    );
  }
);

const VolumeSlider = ({ handleVolumeChange, currentVolume }) => {
  return (
    <div className="flex justify-between items-center gap-1 mr-3">
      {currentVolume > 0 ? (
        <SpeakerWaveIcon className="h-5 w-5 text-gray-500" />
      ) : (
        <SpeakerXMarkIcon className="h-5 w-5 text-gray-500" />
      )}

      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={currentVolume}
        onChange={(e) => handleVolumeChange(e.target.value)}
        className="w-20 h-0.5 rounded-lg"
      />
    </div>
  );
};

const AudioPlayer = ({ viewModel }) => {
  return (
    <>
      <div className="fixed bottom-0 w-full bg-gray-100 border p-1 z-50">
        <div className="mx-auto grid grid-cols-2 sm:grid-cols-3 items-center">
          {/* Track info */}
          <div className="flex items-center justify-start">
            {/* artwork */}
            {viewModel?.tracks &&
              viewModel.tracks.length > 0 &&
              viewModel.tracks[viewModel.currentSongIndex]?.artwork && (
                <Link
                  to={`/releases/${
                    viewModel.tracks[viewModel.currentSongIndex].release_id
                  }/`}
                >
                  <img
                    src={viewModel.tracks[viewModel.currentSongIndex].artwork}
                    alt="artwork"
                    className="h-12 pr-2 rounded-sm hover:opacity-40"
                  />
                </Link>
              )}

            {/* track title */}
            {viewModel?.tracks && viewModel.tracks.length > 0 && (
              <div>
                <p className="text-xs font-bold text-gray-700">
                  <Link
                    to={`/releases/${
                      viewModel.tracks[viewModel.currentSongIndex].release_id
                    }/`}
                    className="hover:underline"
                  >
                    {viewModel.tracks[viewModel.currentSongIndex]?.name}
                  </Link>
                </p>
                <div className="text-sm font-light text-gray-500">
                  {viewModel.tracks[viewModel.currentSongIndex]?.artists &&
                    viewModel.tracks[viewModel.currentSongIndex]?.artists.map(
                      (artist, index) => (
                        <Link
                          to={`/artists/${artist.id}`}
                          key={artist.id}
                          className="hover:underline"
                        >
                          {artist.name}
                          {index <
                            viewModel.tracks[viewModel.currentSongIndex].artists
                              .length -
                              1 && ", "}
                        </Link>
                      )
                    )}
                </div>
              </div>
            )}

            {/* no track loaded */}
            {(!viewModel?.tracks || viewModel.tracks.length === 0) && (
              <div className="mx-2">
                <p className="text-xs font-bold text-gray-700">
                  No track loaded
                </p>
                {/* <div className="text-sm font-light text-gray-500">
                  Select a track to play
                </div> */}
              </div>
            )}
          </div>

          {/* Volume control */}
          <div className="hidden sm:flex items-center justify-end sm:order-3">
            <VolumeSlider
              handleVolumeChange={viewModel.handleVolumeChange}
              currentVolume={viewModel.currentVolume}
            />
          </div>

          {/* Play controls */}
          <div className="sm:w-full flex flex-col items-center justify-self-end sm:justify-center pb-1 sm:order-2">
            <div className="flex items-center justify-center">
              <button
                className="hidden sm:block mr-2 px-3 py-2 text-gray-700 rounded hover:bg-gray-400"
                onClick={viewModel.handleBack}
              >
                <BackwardIcon className="h-5 w-5" />
              </button>
              <button
                className="mx-2 px-3 py-2 text-gray-700 rounded hover:bg-gray-400"
                onClick={viewModel.togglePlayPause}
              >
                {viewModel.isPlaying ? (
                  <PauseIcon className="h-5 w-5" />
                ) : (
                  <PlayIcon className="h-5 w-5" />
                )}
              </button>
              <button
                className="hidden sm:block ml-2 px-3 py-2 text-gray-700 rounded hover:bg-gray-400"
                onClick={viewModel.handleNext}
              >
                <ForwardIcon className="h-5 w-5" />
              </button>
            </div>
            <div className="hidden sm:flex items-center pt-1 w-80">
              <TimeDisplay
                handleTimeSliderChange={viewModel.handleTimeSliderChange}
                currentTime={viewModel.currentTime}
                duration={viewModel.duration}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioPlayer;
