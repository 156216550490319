import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/outline";

import FavoriteIcon from "../releases/FavoriteIcon";
import TrackMenu from "./TrackMenu";

export default function Track({
  track,
  index,
  setHoveredTrack,
  handleTrackClick,
  audioPlayerViewModel,
  contextURI,
  playingContextURI,
  isPlaying,
  currentTrackIndex,
  hoveredTrack,
  favoriteReleaseTrack,
  handleArtistClick,
  currentArtistId,
  pageArtistId,
  releaseId,
  currentPlaylistId,
  removePlaylistTrack = null,
  showArtist = true,
}) {
  return (
    <>
      <div
        className="flex justify-between items-center py-1 hover:bg-gray-100 gap-2"
        onMouseEnter={() => setHoveredTrack(track.id)}
        onMouseLeave={() => setHoveredTrack(null)}
        onClick={() => handleTrackClick(track.id, index)}
      >
        <div className="flex-none">
          <div className="flex justify-center items-center w-8 h-8 mx-1 cursor-pointer">
            {contextURI === audioPlayerViewModel.contextURI ? (
              isPlaying && currentTrackIndex === index ? (
                <PauseCircleIcon className="h-6 w6 text-gray-600" />
              ) : hoveredTrack === track.id ? (
                <PlayCircleIcon className="h-6 w-6 text-gray-600" />
              ) : (
                index + 1 || track.track_order_number
              )
            ) : hoveredTrack === track.id ? (
              <PlayCircleIcon className="h-6 w-6 text-gray-600" />
            ) : (
              index + 1 || track.track_order_number
            )}
          </div>
        </div>
        <div className="flex-1 w-64 truncate text-ellipsis">
          <div className="flex justify-start items-center gap-3 sm:gap-4 md:gap-6">
            {releaseId ? null : track.artwork ? (
              <img
                src={track.artwork}
                className="h-10 w-10 rounded-sm"
                alt={`${track.name} artwork`}
              />
            ) : (
              <div className="h-10 w-10 bg-gray-300 rounded-sm"></div>
            )}

            <div className="flex flex-col">
              {audioPlayerViewModel.contextURI === contextURI ? (
                currentTrackIndex === index ? (
                  <div className="sm:w-96 text-indigo-700 font-medium cursor-pointer hover:underline">
                    {track.name}
                  </div>
                ) : (
                  <div className="sm:w-96 text-gray-800 font-medium cursor-pointer hover:underline">
                    {track.name}
                  </div>
                )
              ) : (
                <div className="sm:w-96 text-gray-800 font-medium hover:underline">
                  {track.name}
                </div>
              )}
              <div className="">
                {showArtist
                  ? track.artists &&
                    track.artists.map((artist, idx) => (
                      <span
                        key={artist.id}
                        onClick={(e) => handleArtistClick(artist.id, e)}
                        className="cursor-pointer text-gray-500 hover:underline"
                      >
                        {artist.name}
                        {idx < track.artists.length - 1 ? ", " : ""}
                      </span>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center flex-none w-8">
          <FavoriteIcon
            isFavorite={track.is_favorite}
            favoriteReleaseTrack={favoriteReleaseTrack}
            releaseTrackId={track.id}
          />
        </div>
        <div className="flex-none">
          <div className="hidden md:block w-10">{track.duration}</div>
        </div>
        <div className="flex-none w-6">
          <TrackMenu
            track={track}
            index={index}
            currentPlaylistId={currentPlaylistId}
            removePlaylistTrack={removePlaylistTrack}
          />
        </div>

        {/* <div className="flex flex-grow items-center">
          <div className="flex justify-center items-center w-8 h-8 sm:w-12 sm:h-12 mx-2 cursor-pointer">
            {contextURI === audioPlayerViewModel.contextURI ? (
              isPlaying && currentTrackIndex === index ? (
                <PauseCircleIcon className="h-6 w-6 text-gray-600" />
              ) : hoveredTrack === track.id ? (
                <PlayCircleIcon className="h-6 w-6 text-gray-600" />
              ) : (
                index + 1 || track.track_order_number
              )
            ) : hoveredTrack === track.id ? (
              <PlayCircleIcon className="h-6 w-6 text-gray-600" />
            ) : (
              index + 1 || track.track_order_number
            )}
          </div>
          <div className="flex flex-grow items-center">
            {releaseId ? null : track.artwork ? (
              <img
                src={track.artwork}
                className="h-10 w-10 rounded-sm me-4"
                alt={`${track.name} artwork`}
              />
            ) : (
              <div className="h-10 w-10 bg-gray-300 rounded-sm me-4"></div>
            )}

            <div className="flex flex-col flex-grow mx-2">
              {audioPlayerViewModel.contextURI === contextURI ? (
                currentTrackIndex === index ? (
                  <div className="sm:w-96 mx-2 text-indigo-700 font-medium cursor-pointer hover:underline truncate text-ellipsis">
                    {track.name}
                  </div>
                ) : (
                  <div className="sm:w-96 mx-2 text-gray-800 font-medium cursor-pointer truncate text-ellipsis hover:underline">
                    {track.name}
                  </div>
                )
              ) : (
                <div className="sm:w-96 mx-2 text-gray-800 font-medium truncate text-ellipsis hover:underline">
                  {track.name}
                </div>
              )}
              <div className="mx-2">
                {showArtist
                  ? track.artists &&
                    track.artists.map((artist, idx) => (
                      <span
                        key={artist.id}
                        onClick={(e) => handleArtistClick(artist.id, e)}
                        className="cursor-pointer text-gray-500 hover:underline"
                      >
                        {artist.name}
                        {idx < track.artists.length - 1 ? ", " : ""}
                      </span>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-6 items-center">
          <div className="w-8">
            <FavoriteIcon
              isFavorite={track.is_favorite}
              favoriteReleaseTrack={favoriteReleaseTrack}
              releaseTrackId={track.id}
            />
          </div>
          <div className="hidden sm:block w-16 text-right pr-4">
            {track.duration}
          </div>
          <TrackMenu
            track={track}
            index={index}
            currentPlaylistId={currentPlaylistId}
            removePlaylistTrack={removePlaylistTrack}
          />
        </div> */}
      </div>
    </>
  );
}
