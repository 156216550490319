import { Link, useParams } from "react-router-dom";

export default function Row({ adgroup }) {
  console.log("adgroup: ", adgroup);
  let { projectId } = useParams();

  return (
    <>
      <div>
        <Link
          to={`/projects/${projectId}/ads?adgroup_id=${adgroup.id}`}
          className="hover:text-blue-400"
        >
          {adgroup.name}
        </Link>
      </div>
    </>
  );
}
