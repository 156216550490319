export default class ProjectUsersViewModel {
  constructor(token, projectId, state, updateState) {
    this.projectId = projectId;
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(`api/v1/projects/${this.projectId}/users/`);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return {
        users: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  inviteUser = async (userEmail) => {
    return await fetch(
      this._createUrl(`api/v1/projects/${this.projectId}/add-user/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(userEmail),
      }
    )
      .then(async (response) => {
        let newUser = await response.json();
        this.state.users.unshift(newUser);
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error adding user to organization", error);
      });
  };

  removeUser = async (userId) => {
    return await fetch(
      this._createUrl(
        `api/v1/projects/${this.projectId}/remove-user/${userId}`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
      }
    )
      .then(async (response) => {
        let res = await response.json();

        this.state.users = this.state.users.filter((u) => u.id !== userId);
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error removing user from organization", error);
      });
  };
}
