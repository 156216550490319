import BarChartVertical from "./BarChartVertical";
import BarChartHorizontal from "./BarChartHorizontal";
import { formatColor } from "../../../utils/analyticsUtils";

export default function BarChartDynamic({
  data,
  x_label,
  y_label,
  y1_label = null,
  min,
  max,
  singleYAxis = false,
  stackedLabel = null,
  showPercentage = false,
}) {
  min = min || 0;
  max = max || "auto";
  const domain = [min, max];

  const isSmallDataSet = data?.length < 2;

  const renderChart = () => (
    <>
      {isSmallDataSet ? (
        // Render BarChartHorizontal for all charts if items < 2
        <>
          <div>
            {/* <h2 className="text-xl ps-10 pb-10 text-gray-700 font-bold">
              {title}
            </h2> */}
            {data && (
              <BarChartHorizontal
                data={data}
                x_label={x_label}
                y_label={y_label}
                y1_label={y1_label}
                domain={domain}
                singleYAxis={singleYAxis}
                stackedLabel={stackedLabel}
                showPercentage={showPercentage}
              />
            )}
          </div>
        </>
      ) : (
        // Render BarChartVertical on small screens and BarChartBiaxial on larger screens
        <>
          <div className="md:hidden">
            {/* <h2 className="text-xl ps-10 pb-10 text-gray-700 font-bold">
              {title}
            </h2> */}
            {data && (
              <BarChartVertical
                data={data}
                x_label={x_label}
                y_label={y_label}
                y1_label={y1_label}
                domain={domain}
                bar1Color={formatColor(y_label)}
                bar2Color={formatColor(y1_label)}
                stackedLabel={stackedLabel}
                showPercentage={showPercentage}
              />
            )}
          </div>
          <div className="hidden md:block">
            {/* <h2 className="text-xl ps-10 pb-10 text-gray-700 font-bold">
              {title}
            </h2> */}
            {data && (
              <BarChartHorizontal
                data={data}
                x_label={x_label}
                y_label={y_label}
                y1_label={y1_label}
                domain={domain}
                singleYAxis={singleYAxis}
                stackedLabel={stackedLabel}
                showPercentage={showPercentage}
              />
            )}
          </div>
        </>
      )}
    </>
  );

  return <>{data ? renderChart() : null}</>;
}
