import { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";

// generic
import LoadingScreen from "../../../../../components/loading/LoadingScreen";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/artists/releases/viewmodel";
import ReleaseTable from "../../../../../features/forArtists/artists/releases/ReleaseTable";
import ReleaseSlideover from "../../../../../features/forArtists/artists/releases/ReleaseSlideover";

export default function Index() {
  const { token } = useOutletContext();
  let { artistId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, artistId, data, (state) => {
      setData(state);
    })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState();

  useEffect(() => {
    viewModel.fetchState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token, artistId]);

  const handleReleaseClick = (release) => {
    setOpen(true);
    setSelectedRelease(release);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <h1 className="text-5xl font-bold">Releases</h1>
      </div>
      <div className="p-4">
        <div className="">
          <PrimaryButton
            handleClick={() => {
              setOpen(!open);
              setSelectedRelease(null);
            }}
          />
        </div>
        <div className="">
          {data?.releases?.results?.length > 0 && (
            <ReleaseTable
              releases={data.releases}
              selectRelease={(release) => handleReleaseClick(release)}
              updateRelease={async (release) => {
                await viewModel.updateRelease(release);
              }}
              sortReleases={(key) => viewModel.sortReleases(key)}
              fetchMoreReleases={async () => {
                return await viewModel.fetchMoreReleases();
              }}
            />
          )}
        </div>
      </div>

      {open && (
        <ReleaseSlideover
          artistId={artistId}
          releaseId={selectedRelease?.id}
          open={open}
          setOpen={setOpen}
          selectedRelease={selectedRelease}
          data={data}
          addRelease={async (release) => {
            return await viewModel.addRelease(release);
          }}
          updateRelease={async (release) => {
            await viewModel.updateRelease(release);
          }}
          deleteRelease={async (releaseId) => {
            await viewModel.deleteRelease(releaseId);
          }}
        />
      )}
    </>
  );
}
