export default class ArtistShowsViewModel {
  constructor(token, artistId, state, updateState) {
    this.artistId = artistId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _post = async (path, body) => {
    return await fetch(this._createUrl(path), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("res successful: ", res);
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(`api/v1/artists/${this.artistId}/get-shows/`, false);
  };

  _fetchArtistTours = async () => {
    return await this._get(`api/v1/artists/${this.artistId}/tours/`, false);
  };

  _fetchStatuses = async () => {
    return await this._get(`api/v1/shows/statuses/`, true);
  };

  _fetchVenues = async () => {
    return await this._get(`api/v1/venues/`, true);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([
      this._fetchData(),
      this._fetchArtistTours(),
      this._fetchStatuses(),
      this._fetchVenues(),
    ]).then((values) => {
      return {
        shows: values[0],
        tours: values[1],
        statuses: values[2],
        venues: values[3],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  fetchMoreShows = async () => {
    const url = this.state.shows.next;
    return await fetch(url, {
      method: "GET",
      headers: this._standardHeaders(),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((res) => {
        this.state.shows.results = this.state.shows.results.concat(res.results);
        this.state.shows.next = res.next;
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        return false;
      });
  };

  sortShows = (key) => {
    if (!this.state || !this.state.shows.results) {
      return;
    }

    let sortedShows;
    const isAscending = this.sortKey === key && !this.isAscending;

    if (key === "name") {
      sortedShows = [...this.state.shows.results].sort((a, b) => {
        let valueA = a[key].toLowerCase(); // Assuming titles are strings
        let valueB = b[key].toLowerCase();

        if (valueA < valueB) return isAscending ? -1 : 1;
        if (valueA > valueB) return isAscending ? 1 : -1;
        return 0;
      });
    } else if (key === "show_date") {
      sortedShows = [...this.state.shows.results].sort((a, b) => {
        // Check if either value is an empty string and handle sorting explicitly
        if (
          (a[key] === "" || a[key] === null) &&
          (b[key] !== "" || b[key] !== null)
        ) {
          return isAscending ? 1 : -1; // For ascending, empty strings come first; for descending, they come last.
        } else if (
          (a[key] !== "" || a[key] !== null) &&
          (b[key] === "" || b[key] === null)
        ) {
          return isAscending ? -1 : 1; // Inverse of the above
        } else if (
          (a[key] === "" || a[key] === null) &&
          (b[key] === "" || b[key] === null)
        ) {
          return 0; // Both are empty, keep original order
        }

        // Both have values, compare as dates
        let valueA = new Date(a[key]).getTime();
        let valueB = new Date(b[key]).getTime();
        return isAscending ? valueA - valueB : valueB - valueA;
      });
    } else if (key === "status") {
      sortedShows = [...this.state.shows.results].sort((a, b) => {
        const A = a.status?.name.toLowerCase() || "";
        const B = b.status?.name.toLowerCase() || "";

        if (A < B) return isAscending ? -1 : 1;
        if (A > B) return isAscending ? 1 : -1;
        return 0;
      });
    } else if (key === "tour") {
      sortedShows = [...this.state.shows.results].sort((a, b) => {
        const A = a.tour?.name.toLowerCase() || "";
        const B = b.tour?.name.toLowerCase() || "";

        if (A < B) return isAscending ? -1 : 1;
        if (A > B) return isAscending ? 1 : -1;
        return 0;
      });
    }

    this.state.shows.results = sortedShows;
    this.sortKey = key;
    this.isAscending = isAscending;
    this._callUpdateState();
  };

  addShow = async (show) => {
    return await fetch(this._createUrl("api/v1/shows/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(show),
    })
      .then(async (response) => {
        let newShow = await response.json();
        this.state.shows.results.unshift(newShow);
        this._callUpdateState();
        return newShow;
      })
      .catch((error) => {
        console.error("Error updating show", error);
      });
  };

  updateShow = async (show) => {
    console.log(show);
    return await fetch(this._createUrl(`api/v1/shows/${show.id}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(show),
    })
      .then(async (response) => {
        let show = await response.json();
        this.state.shows.results = this.state.shows.results.map((s) => {
          if (s.id === show.id) {
            return show;
          }
          return s;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error updating show", error);
        return false;
      });
  };

  deleteShow = async (showId) => {
    console.log(showId);
    try {
      const response = await fetch(this._createUrl(`api/v1/shows/${showId}/`), {
        method: "DELETE",
        headers: this._standardActionHeaders(),
      });

      console.log(response);

      if (!response.status === 204) {
        console.log("not ok");
        // If the response is not ok, throw an error
        throw new Error(
          `Failed to delete show, status code: ${response.status}`
        );
      }

      // Remove the show from the local state
      this.state.shows.results = this.state.shows.results.filter(
        (show) => show.id !== showId
      );
      this._callUpdateState();
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error deleting show", error);
      return false; // Return false to indicate failure
    }
  };

  createTour = async (tourName) => {
    return await this._post(`api/v1/artists/${this.artistId}/create-tour/`, {
      tour_name: tourName,
    })
      .then((res) => {
        let tour = res;

        // Add the new tour to the state
        this.state.tours.push(tour);

        this._callUpdateState();
        return tour;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  addShowToTour = async (showId, tourId) => {
    console.log("showId: ", showId, "audienceId: ", tourId);
    return await this._post(`api/v1/shows/${showId}/add-tour/`, {
      tour_id: tourId,
    })
      .then((res) => {
        let show = res;
        this.state.shows.results = this.state.shows.results.map((s) => {
          if (s.id === show) {
            s = show;
          }
          return s;
        });
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
}
