import Row from "./Row";

export default function Table({ data }) {
  console.log("data: ", data);
  return (
    <>
      <div>
        {data?.ads?.map((ad) => (
          <Row key={ad.id} ad={ad} />
        ))}
      </div>
    </>
  );
}
