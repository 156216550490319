import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const TableHeader = ({
  cols,
  handleSort,
  sortedHeader,
  sortAscending,
  divider = true,
}) => {
  return (
    <thead>
      <tr
        className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8`}
      >
        {cols.map((col, index) => (
          <th
            key={col.id}
            className={`${
              col.display ? col.display : ""
            } border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900`}
            onClick={handleSort ? () => handleSort(col.name) : null}
          >
            <div
              className={`group inline-flex ${handleSort && "cursor-pointer"} `}
            >
              {col.label}
              {sortedHeader === col.name && (
                <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                  {sortAscending ? (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
