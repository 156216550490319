import { useEffect } from "react";
import Pagination from "../../../../components/UI/Pagination";

const ProjectsPagination = ({ page, setPage, count, refreshState }) => {
  const itemsPerPage = count < 15 ? count : 15;

  useEffect(() => {
    if (page === 1) return;
    refreshState(page);
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <Pagination
        currentPage={page}
        totalPages={Math.ceil(count / itemsPerPage)}
        onPageChange={handlePageChange}
        totalItems={count}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
};

export default ProjectsPagination;
