import { useEffect, useState } from "react";

// generic
import TaskListCell from "../../tasks/TaskListCell";
import TableRow from "../../../../components/layout/tables/TableRow";

export default function CollectionTableRow({
  collection,
  selectCollection,
  updateCollection,
}) {
  const [formData, setFormData] = useState({
    id: collection.id,
    name: collection.name,
    type: collection.type,
    release_date: collection.release_date_form_display,
  });

  useEffect(() => {
    setFormData({
      id: collection.id,
      name: collection.name,
      type: collection.type,
      release_date: collection.release_date_form_display,
    });
  }, [collection]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = async (e) => {
    // Update the formData state with the new selected value
    const updatedFormData = { ...formData, release_date: e.target.value };
    setFormData(updatedFormData);

    // Prepare the data for the updateTask function
    const collectionData = {
      ...updatedFormData,
      release_date: updatedFormData.release_date || null,
    };

    // Call the updateTask function with the new data
    const updated = await updateCollection(collectionData);
    if (updated) {
      console.log("Collection updated successfully");
    }
  };

  const handleSelectChange = async (selectedValue, fieldName) => {
    // Update the formData state with the new selected value
    const updatedFormData = { ...formData, [fieldName]: selectedValue };
    setFormData(updatedFormData);

    // Prepare the data for the updateCollection function
    const collectionData = {
      ...updatedFormData,
      // assigned_to_id: updatedFormData.assigned_to?.id,
      // category_id: updatedFormData.category?.id,
      status_id: updatedFormData.status?.id,
    };

    // Call the updateCollection function with the new data
    const updated = await updateCollection(collectionData);
    if (updated) {
      console.log("Collection updated successfully");
    }
  };

  const handleUpdate = async () => {
    const collectionData = {
      ...formData,
      //   assigned_to_id: formData.assigned_to?.id,
      //   category_id: formData.category?.id,
      status_id: formData.status?.id,
      // due_datetime: formData.due_datetime || null,
    };
    const updated = await updateCollection(collectionData);
    if (updated) {
      console.log("Collection updated successfully");
    }
  };

  return (
    <>
      <TableRow>
        <TaskListCell handleClick={() => selectCollection(collection)}>
          <div className="flex items-center gap-2 w-full">
            <div className="md:hidden w-full my-1 px-2 py-1 bg-transparent">
              {formData.name}
            </div>
            <input
              name="name"
              value={formData.name}
              onClick={(e) => e.stopPropagation()}
              onChange={handleChange}
              onBlur={handleUpdate}
              className="hidden md:block w-1/2 my-1 px-2 py-1 bg-transparent rounded-sm border border-transparent group-hover:border group-hover:border-gray-300 group-hover:bg-white group-hover:cursor-text"
            />
          </div>
        </TaskListCell>

        <TaskListCell noClick={true} display={"hidden md:table-cell"}>
          {collection.display_release_date}
        </TaskListCell>
      </TableRow>
    </>
  );
}
