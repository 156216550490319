import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

// generic
import TabsNonNav from "../../../../../../components/TabsNonNav";
// feature specific
import ViewModel from "../../../../../../models/forArtists/main/projects/data/viewmodel";
import AdaccountsView from "../../../../../../features/forArtists/projects/data/adaccounts/Index";
import CampaignsView from "../../../../../../features/forArtists/projects/data/campaigns/Index";
import ShowsView from "../../../../../../features/forArtists/projects/data/shows/Index";

export default function Tour({ typeObj }) {
  const { token } = useOutletContext();
  let { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Adaccounts");

  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState();
    setIsLoading(false);
  }, [viewModel, token, projectId]);

  const tabs = [
    {
      name: "Adaccounts",
      component: (
        <AdaccountsView
          adaccounts={data?.project_adaccounts}
          refreshState={async () => {
            await viewModel.refreshState();
          }}
          removeAdaccount={async (projectAdaccountId) => {
            await viewModel.removeAdaccountFromProject(projectAdaccountId);
          }}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ),
    },
    {
      name: "Shows",
      component: (
        <ShowsView
          projectShows={data?.project_shows}
          refreshState={async () => {
            await viewModel.refreshState();
          }}
          removeProjectShow={async (projectShowId) => {
            await viewModel.removeShowFromProject(projectShowId);
          }}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ),
    },
    {
      name: "Campaigns",
      component: (
        <CampaignsView
          typeObj={typeObj}
          campaigns={data?.campaigns}
          adgroups={data?.adgroups || []}
          refreshState={async () => {
            await viewModel.refreshState();
          }}
          fetchAdgroups={async (campaignId) => {
            return await viewModel.fetchAdgroups(campaignId);
          }}
          removeCampaign={async (campaignId) => {
            await viewModel.removeCampaignFromProject(campaignId);
          }}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          updateCampaign={async (campaign, formData) => {
            await viewModel.updateCampaign(campaign, formData);
          }}
          updateAdgroup={async (adgroup, formData) => {
            await viewModel.updateAdgroup(adgroup, formData);
          }}
          projectShows={data?.project_shows}
          projectPlatforms={data?.project_platforms}
        />
      ),
    },
  ];

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col gap-2 py-4">
          <h1 className="text-5xl font-bold">Data</h1>
          <p className="font-light text-gray-500">
            Add your data here to make your dashboards populate with amazing
            insights.
          </p>
        </div>

        <div className="py-4">
          <TabsNonNav
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        <div>
          {tabs
            .filter((tab) => tab.name === selectedTab)
            .map((tab) => (
              <div key={tab.name}>{tab.component}</div>
            ))}
        </div>
      </div>
    </>
  );
}
