import { useState } from "react";
import { Link, useOutletContext, useNavigate } from "react-router-dom";

import ReleaseTile from "./releases/ReleaseTile";
import ArtistTile from "./artists/ArtistTile";
import SmallTile from "./SmallTile";

export default function SidebarContextList({ contexts }) {
  const navigate = useNavigate();

  const handleClick = (id, type) => {
    console.log("id: ", id);
    window.scrollTo(0, 0);
    navigate(`/${type}s/${id}`);
  };

  return (
    <>
      {contexts && contexts.length > 0 && (
        <ul className="flex flex-col">
          {contexts.map((context) => {
            if (context.context_type === "release") {
              return (
                <SmallTile
                  key={context.context_data.id}
                  item={context.context_data}
                  type={context.context_type}
                  onClick={() =>
                    handleClick(context.context_data.id, "release")
                  }
                  // Other props as needed
                />
              );
            } else if (context.context_type === "artist") {
              return (
                <SmallTile
                  key={context.context_data.id}
                  item={context.context_data}
                  type={context.context_type}
                  onClick={() => handleClick(context.context_data.id, "artist")}
                  // Other props as needed
                />
              );
            } else if (context.context_type === "playlist") {
              return (
                <SmallTile
                  key={context.context_data.id}
                  item={context.context_data}
                  type={context.context_type}
                  onClick={() =>
                    handleClick(context.context_data.id, "playlist")
                  }
                  // Other props as needed
                />
              );
            }
          })}
        </ul>
      )}
    </>
  );
}
