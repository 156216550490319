import { useState } from "react";

// generic
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../components/layout/tables/TableBody";
import TableRow from "../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../components/layout/tables/TableCell";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";
// feature specific
import AdaccountsModal from "./AdaccountsModal";

const Index = ({
  adaccounts,
  refreshState,
  removeAdaccount,
  isLoading,
  setIsLoading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cols = [
    { id: 1, name: "Name", label: "Name" },
    { id: 2, name: "Platform", label: "Platform" },
    { id: 3, name: "" },
  ];

  const handleClosingModal = async () => {
    setIsLoading(true);
    setIsModalOpen(false);
    await refreshState();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <PrimaryButton text={"+ Add"} handleClick={() => setIsModalOpen(true)} />

      <div className="h-96 overflow-y-auto">
        <TableContainer>
          <TableHeader cols={cols} />
          <TableBody>
            {adaccounts?.map((adaccount, index) => {
              return (
                <TableRow key={adaccount.id}>
                  <TableCell>{adaccount.name}</TableCell>
                  <TableCell>{adaccount.platform_parent}</TableCell>
                  <TableCell>
                    <SecondaryButton
                      text={"remove"}
                      handleClick={() => {
                        removeAdaccount(adaccount.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
      <AdaccountsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refreshState={refreshState}
        setIsLoading={setIsLoading}
        handleClosingModal={handleClosingModal}
      />
    </>
  );
};

export default Index;
