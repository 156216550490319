export default class OverviewViewModel {
  constructor(token, projectId, state, updateState) {
    this.projectId = projectId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      `api/v1/projects/${this.projectId}/analytics/views/overview/`,
      false
    );
  };

  _fetchInitialProjectCalls = async () => {
    return Promise.all([
      this._fetchData(),
      // this._fetchStatuses(),
      // this._fetchTasks(),
      // this._fetchProject(),
      // this._fetchUsers(),
    ]).then((values) => {
      return {
        data: values[0],
        // projectShows: values[0][1],
        // statuses: values[1],
        // tasks: values[2],
        // project: values[3],
        // users: values[4],
      };
    });
  };

  fetchProjectState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialProjectCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  sortData = (data, key, ascending = true) => {
    return data.slice().sort((a, b) => {
      if (a[key] < b[key]) return ascending ? -1 : 1;
      if (a[key] > b[key]) return ascending ? 1 : -1;
      return 0;
    });
  };
}
