const CustomizedBarLabel = ({
  x,
  y,
  width,
  height,
  value,
  layout,
  format,
  fill = "#662e91",
}) => {
  // Calculate the position for the label
  let xPos;
  let yPos;
  if (layout === "vertical") {
    xPos = x + width + 27;
    yPos = y + height / 2;
  } else {
    xPos = x + width / 2;
    yPos = y - 10;
    if (value === 0) {
      yPos = yPos - 10;
    }
  }

  // Determine the fill color based on the value
  const standardFill = value === 0 ? "gray" : "white";
  const fillColor = fill ? fill : standardFill;

  return (
    <text
      x={xPos}
      y={yPos}
      fill={fill}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {format(value)}
    </text>
  );
};

export default CustomizedBarLabel;
