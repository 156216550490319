export default function TabsNonNav({ tabs, selectedTab, setSelectedTab }) {
  return (
    <>
      <div>
        <nav className="-mb-px flex" aria-label="Tabs">
          {tabs.map((tab) => (
            <div key={tab.name}>
              <button
                key={tab.name}
                onClick={() => setSelectedTab(tab.name)}
                className={
                  selectedTab === tab.name
                    ? "font-bold underline underline-offset-8 pb-4 me-3 sm:me-8 md:me-16"
                    : "font-medium text-gray-400 me-3 sm:me-8 md:me-16"
                }
              >
                {tab.name}
              </button>
            </div>
          ))}
        </nav>
      </div>
    </>
  );
}
