import { useState } from "react";

// generic
import SectionHeading from "../../../../../components/layout/SectionHeading";
import Table from "./Table";
// feature specific
import AdaccountModal from "./AdaccountModal";

export default function Index({
  adaccounts,
  adaccountOptions,
  addAdaccount,
  removeAdaccount,
  fetchAdaccountOptions,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true);
    setOpenModal(true);
    console.log("about to fetch adaccount options");
    await fetchAdaccountOptions();
    setIsLoading(false);
  };

  return (
    <>
      <SectionHeading
        title="Adaccounts"
        buttonText={"+ Add"}
        buttonAction={handleButtonClick}
      />
      <Table adaccounts={adaccounts} removeAdaccount={removeAdaccount} />

      <AdaccountModal
        title="Add Adaccounts"
        isLoading={isLoading}
        openModal={openModal}
        setOpenModal={setOpenModal}
        adaccountOptions={adaccountOptions}
        addAdaccount={addAdaccount}
      />
    </>
  );
}
