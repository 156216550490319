// generic
import SlideOver from "../../../../components/SlideOver";

// feature specific
import CollectionForm from "./CollectionForm";

export default function CollectionSlideover({
  artistId,
  collectionId,
  open,
  setOpen,
  selectedCollection,
  data,
  addCollection,
  updateCollection,
  deleteCollection,
}) {
  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        object={selectedCollection || null}
        objectName="Collection"
        handleDelete={() => deleteCollection(selectedCollection?.id)}
      >
        <CollectionForm
          artistId={artistId}
          collection={selectedCollection || null}
          setOpen={setOpen}
          data={selectedCollection || null}
          addCollection={addCollection}
          updateCollection={updateCollection}
          collectionTypes={data.types}
        />
      </SlideOver>
    </>
  );
}
