import { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import LoadingScreen from "../../components/loading/LoadingScreen";

import PlayButton from "../../components/forFans/releases/PlayButton";
import ReleaseList from "../../components/forFans/artists/ReleaseList";
import PlaylistHeader from "../../components/forFans/playlists/PlaylistHeader";
import TrackList from "../../components/forFans/tracks/TrackList";
import PlaylistViewModel from "../../models/forFans/playlists/playlistViewModel";
import ErrorPage from "../ErrorPage";
import AddPlaylistTracks from "../../components/forFans/playlists/AddPlaylistTracks";
import Modal from "../../components/UI/modals/Modal";
import PlaylistForm from "../../components/forms/PlaylistForm";

export default function ReleaseIndex() {
  const { audioPlayerViewModel } = useOutletContext();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [editOpen, setEditOpen] = useState(false);

  // view model
  let { playlistId } = useParams();
  let contextURI = `playlist:${playlistId}`;
  const [playlistViewData, setPlaylistViewData] = useState();
  const [playlistViewModel, setPlaylistViewModel] = useState(null);

  useEffect(() => {
    if (audioPlayerViewModel) {
      // Set the initial state
      setIsPlaying(audioPlayerViewModel.isPlaying);

      // Define a function to update state when ViewModel changes
      const handleViewModelChange = () => {
        setIsPlaying(audioPlayerViewModel.isPlaying);
      };

      // Attach the listener to the ViewModel
      audioPlayerViewModel.onStateChange = handleViewModelChange;

      // Clean up the listener when the component unmounts
      return () => {
        audioPlayerViewModel.onStateChange = null;
      };
    }
  }, [audioPlayerViewModel]);

  useEffect(() => {
    setLoading(true);
    const viewModel = new PlaylistViewModel(
      token,
      playlistId,
      playlistViewData,
      (state) => setPlaylistViewData(state)
    );
    setPlaylistViewModel(viewModel);
  }, [token, playlistId]);

  useEffect(() => {
    if (playlistViewModel) {
      playlistViewModel.fetchState().then(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
    }
  }, [playlistViewModel]);

  const handleClick = async () => {
    if (contextURI !== audioPlayerViewModel.contextURI) {
      audioPlayerViewModel.loadTracks(
        playlistViewData.data.tracks,
        0,
        contextURI,
        true
      );
    } else {
      audioPlayerViewModel.togglePlayPause();
    }
  };

  const handleReleaseFavoriteClick = async () => {
    await playlistViewModel.favoritePlaylist(
      !playlistViewData.data.playlist.is_favorite
    );
  };

  const handleTrackClick = (trackId, trackIndex) => {
    if (contextURI === audioPlayerViewModel.contextURI) {
      if (currentTrackIndex === trackIndex) {
        audioPlayerViewModel.togglePlayPause();
      } else {
        audioPlayerViewModel.changeTrack(trackIndex);
        if (!isPlaying) {
          audioPlayerViewModel.togglePlayPause();
        }
      }
    } else {
      audioPlayerViewModel.loadTracks(
        playlistViewData.data.tracks,
        trackIndex,
        contextURI
      );
    }
  };

  const handleEditClick = () => {
    setEditOpen(true);
  };

  const handleSubmitPlaylist = async (playlist) => {
    const res = await playlistViewModel.updatePlaylist(playlist);
    if (res) {
      setEditOpen(false);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (!playlistViewData || !playlistViewData.data) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className="container-lg mb-20">
        <div className="px-6 pt-8">
          {playlistViewData?.data?.playlist && (
            <PlaylistHeader
              playlist={playlistViewData.data.playlist}
              handleEditClick={handleEditClick}
              handleClick={handleClick}
              isPlaying={isPlaying}
              contextURI={contextURI}
              playingContextURI={audioPlayerViewModel.contextURI}
              isFavorite={playlistViewData.data.playlist.is_favorite}
              favoriteRelease={handleReleaseFavoriteClick}
            />
          )}
        </div>

        {playlistViewData?.data?.tracks && (
          <TrackList
            tracks={playlistViewData.data.tracks}
            handleTrackClick={handleTrackClick}
            audioPlayerViewModel={audioPlayerViewModel}
            contextURI={contextURI}
            playingContextURI={audioPlayerViewModel.contextURI}
            currentPlaylistId={playlistId}
            isPlaying={isPlaying}
            currentTrackIndex={currentTrackIndex}
            setCurrentTrackIndex={setCurrentTrackIndex}
            playlistId={playlistId}
            showArtist={true}
            favoriteReleaseTrack={async (isFavorite, releaseTrackId) => {
              await playlistViewModel.favoriteReleaseTrack(
                isFavorite,
                releaseTrackId
              );
            }}
            removePlaylistTrack={async (trackId, index) => {
              await playlistViewModel.removePlaylistTrack(trackId, index);
            }}
          />
        )}
        {playlistViewData?.data?.tracks?.length > 0 && (
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <hr />
          </div>
        )}
        <div className="max-w-7xl mx-auto px-6">
          <h3 className="text-lg leading-6 px-6 font-medium text-gray-900">
            Add tracks to this playlist
          </h3>
          <AddPlaylistTracks
            addPlaylistTrack={async (trackId) => {
              await playlistViewModel.addPlaylistTrack(trackId);
            }}
          />
        </div>
      </div>

      {/* UpdateHeaderModal */}
      {playlistViewData?.data?.playlist && (
        <Modal open={editOpen} setOpen={setEditOpen}>
          <PlaylistForm
            playlist={playlistViewData.data.playlist}
            handleSubmitPlaylist={handleSubmitPlaylist}
          />
        </Modal>
      )}
    </>
  );
}
