import { Link } from "react-router-dom";

export default function ReleaseHeader({ show }) {
  return (
    <>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex justify-start items-center">
            {show.poster ? (
              <img
                className="h-80 object-cover rounded-lg bg-gray-300"
                src={show.poster}
                alt={show.name}
              />
            ) : (
              <div
                className="h-80 object-cover rounded-lg bg-gray-300"
                alt={show.name}
              />
            )}
            <div className="self-end mx-8 mt-4 p-4">
              <h1 className="text-7xl font-bold">{show.name}</h1>
              <h2 className="text-3xl text-gray-600 hover:underline">
                <Link to={`/artists/${show.artist}`}>{show.artist_name}</Link>
              </h2>
              <p className="text-gray-400">{show.show_date}</p>
            </div>
          </div>

          <div className="flex justify-end">
            {/* <div className="flex flex-col space-y-2 mx-8 mt-4 p-4">
              <button
                type="button"
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Listen on Apple Music
              </button>
              <button
                type="button"
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Listen on Spotify
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
