import { useState } from "react";

// generic
import { LoadingComponent } from "../../../../components/loading/LoadingScreen";

// feature specific
import ReleaseTableRow from "./ReleaseTableRow";
import TableContainer from "../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../components/layout/tables/TableBody";

export default function ShowTable({
  releases,
  sortReleases,
  selectRelease,
  updateRelease,
  fetchMoreReleases,
}) {
  const [sortedHeader, setSortedHeader] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSort = (key) => {
    let prevKey = sortedHeader;

    sortReleases(key);
    setSortedHeader(key);

    if (prevKey === key) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  const handleScroll = async (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      // if there are more releases to get
      if (releases.count > releases.results.length) {
        // only fetch more tasks if not already loading (avoid duplication)
        if (!isLoading) {
          setIsLoading(true);
          const res = await fetchMoreReleases();
          if (res) {
            setIsLoading(false);
          } else {
            console.log("error getting more releases");
            setIsLoading(false);
          }
        }
      }
    }
  };

  const headerItems = [
    {
      id: 1,
      name: "name",
      label: "Name",
      display: `w-3/4 md:w-1/2`,
    },
    {
      id: 2,
      name: "type",
      label: "Type",
      display: `hidden md:table-cell`,
    },
    {
      id: 3,
      name: "release_date",
      label: "Release date",
      display: `hidden md:table-cell`,
    },
  ];

  return (
    <>
      <TableContainer fixed={true} handleScroll={handleScroll}>
        <TableHeader
          cols={headerItems}
          handleSort={handleSort}
          sortedHeader={sortedHeader}
          sortAscending={sortAscending}
        />
        <TableBody>
          {releases?.results?.length > 0 &&
            releases.results.map((release, index) => {
              return (
                <ReleaseTableRow
                  key={release.id}
                  release={release}
                  selectRelease={selectRelease}
                  updateRelease={updateRelease}
                />
              );
            })}
        </TableBody>
      </TableContainer>
      {isLoading && <LoadingComponent />}
    </>
  );
}
