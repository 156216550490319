export default class OrganizationViewModel {
  constructor(token, organizationId, state, updateState) {
    this.organizationId = organizationId;
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  //   _fetchCategories = async () => {
  //     return await this._get("api/v1/tasks/categories/", true);
  //   };

  _fetchData = async () => {
    return await this._get(
      `api/v1/organizations/${this.organizationId}/`,
      false
    );
  };

  _fetchInitialCalls = async () => {
    return Promise.all([
      this._fetchData(),
      //   this._fetchCategories(),
    ]).then((values) => {
      return {
        data: values[0],
        // categories: values[1],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  inviteUser = async (userEmail) => {
    return await fetch(
      this._createUrl(`api/v1/organizations/${this.organizationId}/add-user/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify(userEmail),
      }
    )
      .then(async (response) => {
        let newOrgUser = await response.json();
        this.state.data.members.unshift(newOrgUser);
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error adding user to organization", error);
      });
  };

  removeUser = async (userId) => {
    return await fetch(
      this._createUrl(
        `api/v1/organizations/${this.organizationId}/remove-user/${userId}`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        // body: JSON.stringify(userEmail),
      }
    )
      .then(async (response) => {
        let user = await response.json();
        this.state.data.members = this.state.data.members.filter(
          (member) => member.id !== userId
        );
        this._callUpdateState();
        return true;
      })
      .catch((error) => {
        console.error("Error removing user to organization", error);
      });
  };
}
