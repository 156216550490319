import { useState, useContext, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import {
  ShareIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { UserContext } from "../../../pages/forFans/FanLayoutContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TrackMenu({
  track,
  index,
  currentPlaylistId = false,
  removePlaylistTrack = null,
}) {
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_API_URL;
  const siteUrl = process.env.REACT_APP_SITE_URL;
  const user = useContext(UserContext);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showShareSubmenu, setShowShareSubmenu] = useState(false);

  const handleMouseEnter = () => setShowSubmenu(true);
  const handleMouseLeave = () => setShowSubmenu(false);
  const handleShareMouseEnter = () => setShowShareSubmenu(true);
  const handleShareMouseLeave = () => setShowShareSubmenu(false);

  const handleClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    console.log("clicked");
  };

  const copyReleaseTrackUrlToClipboard = async (e) => {
    e.stopPropagation();
    const releaseTrackUrl = `${siteUrl}releases/${track.release_id}/`; // Construct the URL
    try {
      await navigator.clipboard.writeText(releaseTrackUrl);
      console.log("URL copied to clipboard"); // Or any other indication to the user
    } catch (err) {
      console.error("Failed to copy URL:", err);
    }
  };

  const handleAddPlaylistClick = (e, trackId, playlistId) => {
    e.stopPropagation(); // Stop event propagation
    console.log("clicked");
    console.log("trackId", trackId);

    if (playlistId) {
      fetch(`${baseUrl}api/v1/playlists/${playlistId}/add-track/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          track_id: trackId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      fetch(`${baseUrl}api/v1/playlists/create/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          track_id: trackId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleRemoveFromPlaylistClick = (e, trackId) => {
    e.stopPropagation(); // Stop event propagation
    console.log("clicked");
    console.log("trackId", trackId);

    removePlaylistTrack(trackId, index);
  };

  return (
    <Menu as="div" className="relative ml-auto w-full">
      <Menu.Button
        onClick={handleClick}
        className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
      >
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  href="/"
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block px-3 py-1 text-sm leading-6 text-gray-900"
                  )}
                >
                  <div className="flex items-center">
                    <PlusIcon className="h-6 w-6 me-1" />
                    <span>Add to playlist</span>
                  </div>
                </div>
                {showSubmenu && (
                  <div className="absolute -left-full top-0 mt-0 ml-0 w-56 bg-white border rounded shadow-lg">
                    {/* Submenu items here */}
                    <Menu.Item>
                      <div
                        onClick={(e) => handleAddPlaylistClick(e, track.id)}
                        className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                      >
                        <div className="flex items-center">
                          <PlusIcon className="h-6 w-6 me-1" />
                          <span>New playlist</span>
                        </div>
                      </div>
                    </Menu.Item>
                    <hr />
                    {user?.playlists?.length > 0 &&
                      user.playlists.map((playlist) => (
                        <Menu.Item key={playlist.id}>
                          <div
                            onClick={(e) =>
                              handleAddPlaylistClick(e, track.id, playlist.id)
                            }
                            className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                          >
                            <div className="flex items-center">
                              <span className="truncate w-40">
                                {playlist.name}
                              </span>
                            </div>
                          </div>
                        </Menu.Item>
                      ))}
                  </div>
                )}
              </div>
            )}
          </Menu.Item>
          {currentPlaylistId ? (
            <Menu.Item>
              <button
                type="button"
                onClick={(e) => handleRemoveFromPlaylistClick(e, track.id)}
                className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
              >
                <div className="flex items-center">
                  <TrashIcon className="h-6 w-6 me-1" />
                  <span>Remove from playlist</span>
                </div>
              </button>
            </Menu.Item>
          ) : null}

          <Menu.Item>
            {({ active }) => (
              <div
                onMouseEnter={handleShareMouseEnter}
                onMouseLeave={handleShareMouseLeave}
              >
                <a
                  href="/"
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block px-3 py-1 text-sm leading-6 text-gray-900"
                  )}
                >
                  <div className="flex items-center">
                    <ShareIcon className="h-5 w-5 me-2" />
                    <span>Share</span>
                  </div>
                </a>
                {showShareSubmenu && (
                  <div className="absolute -left-full bottom-0 mb-0 ml-0 w-56 bg-white border rounded shadow-lg">
                    {/* Submenu items here */}
                    <Menu.Item>
                      <button
                        onClick={(e) => copyReleaseTrackUrlToClipboard(e)}
                        type="button"
                        className="block w-full px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                      >
                        <div className="flex items-center">
                          <DocumentDuplicateIcon className="h-6 w-6 me-1" />
                          <span>Copy link</span>
                        </div>
                      </button>
                    </Menu.Item>
                  </div>
                )}
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
