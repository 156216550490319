// generic
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../components/layout/tables/TableBody";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";

// feature specific
import AdgroupTableRow from "./AdgroupTableRow";

const AdgroupsTable = ({
  adgroups,
  setSelectedCampaign,
  updateAdgroup,
  projectShows,
  projectReleases,
  projectCollections,
  typeObj,
  loadingAdgroups,
}) => {
  const cols = [
    { id: 1, name: "Adgroup", label: "Adgroup" },
    { id: 2, name: typeObj, label: typeObj },
    // { id: 3, name: "", label: "" },
  ];

  if (loadingAdgroups) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div
        onClick={() => setSelectedCampaign(null)}
        className="hover:underline cursor-pointer pt-4"
      >
        Back to Campaigns
      </div>
      <div className="h-96 overflow-y- z-9">
        <TableContainer>
          <TableHeader cols={cols} />
          <TableBody>
            {adgroups?.map((adgroup, index) => {
              return (
                <AdgroupTableRow
                  key={index}
                  adgroup={adgroup}
                  updateAdgroup={updateAdgroup}
                  projectShows={projectShows}
                  projectReleases={projectReleases}
                  projectCollections={projectCollections}
                  typeObj={typeObj}
                />
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
    </>
  );
};

export default AdgroupsTable;
