import { useState } from "react";

// generic
import SectionHeading from "../../../../../components/layout/SectionHeading";
import Table from "./Table";
// feature specific
import PageModal from "./PageModal";

export default function Index({
  pages,
  pageOptions,
  addPage,
  removePage,
  fetchPageOptions,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true);
    setOpenModal(true);
    console.log("about to fetch page options");
    await fetchPageOptions();
    setIsLoading(false);
  };

  return (
    <>
      <SectionHeading
        title="Pages"
        buttonText={"+ Add"}
        buttonAction={handleButtonClick}
      />
      <Table pages={pages} removePage={removePage} />

      <PageModal
        title="Add Pages"
        isLoading={isLoading}
        openModal={openModal}
        setOpenModal={setOpenModal}
        pageOptions={pageOptions}
        addPage={addPage}
      />
    </>
  );
}
