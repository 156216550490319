import { useState } from "react";

// generic
import DeleteModal from "../../../../../components/UI/modals/DeleteModal";
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../components/layout/tables/TableBody";
import Row from "./Row";

const cols = [
  { id: 1, name: "name", label: "Name" },
  { id: 2, name: "" },
];

export default function Table({ pages, removePage }) {
  console.log("pages", pages);

  const [selectedPage, setSelectedPage] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleRemovePage = async () => {
    removePage(selectedPage);
  };

  return (
    <>
      <TableContainer fixedHeight={false}>
        <TableHeader cols={cols} />
        <TableBody>
          {pages?.map((page, index) => {
            return (
              <Row
                key={index}
                page={page}
                setOpenModal={setOpenModal}
                setSelectedPage={setSelectedPage}
              />
            );
          })}
        </TableBody>
      </TableContainer>

      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        onConfirm={handleRemovePage}
        onCancel={() => setOpenModal(false)}
        title={"Remove Page"}
        message={
          "Are you sure you want to remove? This will stop any data being pulled for reports."
        }
        confirmBtnText={"Remove"}
      />
    </>
  );
}
