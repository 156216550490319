export default function AddToBagBtn() {
  return (
    <>
      <button
        type="button"
        className="w-full rounded-md bg-white px-3 py-3 text-sm font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Add to bag
      </button>
    </>
  );
}
