import { useContext } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { UserContext } from "../MainLayoutContext";
import Navbar from "../../../components/layout/navigation/navbar/Index";

const avatarItems = [{ id: 1, name: "Connect my data", href: "/data/" }];

export default function Root() {
  const { token, appItems } = useOutletContext();
  const user = useContext(UserContext);

  return (
    <>
      <Navbar user={user} appItems={appItems} avatarItems={avatarItems} />

      <main>
        <Outlet context={{ token, appItems, user }} />
      </main>
    </>
  );
}
