import SimplestLineChart from "../charts/SimplestLineChart";
import SimplestBarChart from "../charts/SimplestBarChart";
import DonutCompletedScorecard from "./DonutCompletedScorecard";
import { formatValue } from "../../../utils/analyticsUtils";

export default function ScorecardsOverview({
  data,
  projectType,
  hasTicketReporting,
}) {
  return (
    <div>
      <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-center items-center">
            <DonutCompletedScorecard
              data={data.spend}
              format={(value) => formatValue("spend", value)}
            />
          </div>
          <p className="flex justify-center items-center text-gray-500">
            Spent
          </p>
        </div>

        {projectType === "Tour" && hasTicketReporting && (
          <>
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <DonutCompletedScorecard
                  data={data.tickets}
                  format={(value) => formatValue("tickets_sold", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                Tickets sold
              </p>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <SimplestLineChart
                  data={data.cpt}
                  dataKey={"cpt"}
                  format={(value) => formatValue("cpt", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                CPT
              </p>
            </div>
          </>
        )}

        {projectType === "Tour" && !hasTicketReporting && (
          <>
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <SimplestBarChart
                  data={data.clicks}
                  dataKey={"clicks"}
                  format={(value) => formatValue("clicks", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                Clicks
              </p>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <SimplestLineChart
                  data={data.cpc}
                  dataKey={"cpc"}
                  format={(value) => formatValue("cpc", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                CPC
              </p>
            </div>
          </>
        )}

        {projectType === "Release" && (
          <>
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <SimplestBarChart
                  data={data.clicks}
                  dataKey={"clicks"}
                  format={(value) => formatValue("clicks", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                Clicks
              </p>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <SimplestLineChart
                  data={data.cpc}
                  dataKey={"cpc"}
                  format={(value) => formatValue("cpc", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                CPC
              </p>
            </div>
          </>
        )}

        {projectType === "Merch" && (
          <>
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <SimplestBarChart
                  data={data.purchases}
                  dataKey={"purchases"}
                  format={(value) => formatValue("purchases", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                Purchases
              </p>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-center items-center">
                <SimplestLineChart
                  data={data.roas}
                  dataKey={"roas"}
                  format={(value) => formatValue("roas", value)}
                />
              </div>
              <p className="flex justify-center items-center text-gray-500">
                Return on Ad Spend (ROAS)
              </p>
            </div>
          </>
        )}
      </dl>
    </div>
  );
}
