import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

// generic
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton.jsx";
import SubscriptionBoxes from "../../../../../features/forArtists/organizations/billing/SubscriptionBoxes.jsx";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton.jsx";
import EmptyState from "../../../../../components/EmptyState.jsx";
import PageSubHeading from "../../../../../components/PageSubHeading.jsx";
import ListGroup from "../../../../../components/dataDisplay/lists/ListGroup.jsx";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen.jsx";
// feature specific
import ViewModel from "../../../../../models/forArtists/main/organizations/billing/viewmodel.js";

export default function OrganizationBilling() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useOutletContext();
  let { organizationId } = useParams();
  const [showBrowseSubscriptions, setShowBrowseSubscriptions] = useState(false);

  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, organizationId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token, organizationId]);

  const handleAddSubsClick = () => {
    setShowBrowseSubscriptions(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await viewModel.createPortalSession();
    setIsLoading(true);
  };

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <div className="p-10 justify-center">
        <div className="flex items-center justify-start gap-2 py-4">
          <h1 className="text-5xl font-bold">Billing</h1>
        </div>
        <div className="border rounded-md p-6 m-6">
          <PageSubHeading
            title={"Subscriptions"}
            buttonText={"+ Add Subscription"}
            handleClick={handleAddSubsClick}
          />

          {showBrowseSubscriptions ? (
            <SubscriptionBoxes
              label={"Subscriptions"}
              prices={data.data.prices}
              createCheckout={async (price) => {
                return await viewModel.createCheckout(price);
              }}
              onCancel={() => {
                setShowBrowseSubscriptions(false);
              }}
            />
          ) : data?.data ? (
            data?.data?.subscriptions?.length > 0 ? (
              <>
                <ListGroup data={data.data.subscriptions} />
                <form className="p-2" onSubmit={handleSubmit}>
                  <SecondaryButton
                    type={"submit"}
                    text={"Manage subscriptions"}
                  />
                  {/* <button type="submit">Manage billing</button> */}
                </form>
              </>
            ) : (
              <EmptyState buttonDisplay={false} />
            )
          ) : (
            <LoadingComponent />
          )}
        </div>
      </div>
      <br />
      <hr />
    </>
  );
}
