// generic
import TableBody from "../../../../components/layout/tables/TableBody";
import TableContainer from "../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../components/layout/tables/TableHeader";
// feature specific
import ProjectsTableRow from "./ProjectsTableRow";

const ProjectsTable = ({ projects, handleClick }) => {
  const cols = [
    { id: 1, name: "Name", label: "Name" },
    { id: 2, name: "Artist", label: "Artist" },
    { id: 3, name: "Type", label: "Type" },
  ];

  return (
    <TableContainer>
      <TableHeader cols={cols} divider={false} />
      <TableBody>
        {projects.map((project, index) => {
          return (
            <ProjectsTableRow
              key={index}
              project={project}
              cols={cols}
              handleClick={() => handleClick(project.id)}
            />
          );
        })}
      </TableBody>
    </TableContainer>
  );
};

export default ProjectsTable;
