export default class ShowsViewModel {
  constructor(token, projectId, artistId, state, updateState) {
    this.projectId = projectId;
    this.artistId = artistId;
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (url, cache) => {
    return await fetch(url, {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      this._createUrl(
        `api/v1/artists/${this.artistId}/get-shows/?project_id=${this.projectId}`
      ),
      false
    );
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return { shows: values[0] };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      let data = await this._fetchInitialCalls();
      this.state = data;
      this._callUpdateState();
    }
    return this.state;
  };

  refreshState = async (page = "1", searchQuery = "") => {
    const url = this._createUrl(
      `api/v1/artists/${this.artistId}/get-shows/?project_id=${this.projectId}&page=${page}&q=${searchQuery}`
    );

    const response = await this._get(url);
    if (response) {
      this.state.shows = response;
      this._callUpdateState();
    }
  };

  addShowToProject = async (showId) => {
    const response = await fetch(
      this._createUrl(
        `api/v1/projects/${this.projectId}/shows/?show_id=${showId}`
      ),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
      }
    );

    if (response.ok) {
      let res = await response.json();
      let show = res.show;

      // update the shows state
      this.state.shows.results = this.state.shows.results.map((s) => {
        if (s.id === showId) {
          s.is_added_to_project = true;
          return s;
        }
        return s;
      });

      // // update the campaigns state
      // this.state.campaigns.unshift(campaign);
      this._callUpdateState();
    }
  };
}
