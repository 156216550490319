import { PauseCircleIcon, PlayCircleIcon } from "@heroicons/react/24/solid";

import "./PlayButton.css";

export default function PlayButton({
  isPlaying,
  handleClick,
  playingContextURI,
  contextURI,
}) {
  return (
    <>
      <button
        onClick={handleClick}
        type="button"
        className="favorite-icon-button rounded-md text-indigo-600 hover:text-indigo-400"
      >
        {isPlaying ? (
          playingContextURI === contextURI ? (
            <PauseCircleIcon className="favorite-icon h-14 w-14" />
          ) : (
            <PlayCircleIcon className="favorite-icon h-14 w-14" />
          )
        ) : (
          <PlayCircleIcon className="favorite-icon h-14 w-14" />
        )}
      </button>
    </>
  );
}
