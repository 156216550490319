import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function Toast({ isOpenDefault = true }) {
  console.log("isOpenDefault", isOpenDefault);
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-blue-300 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="text-sm leading-6 text-white">
            <div>
              Create an organization and set up billing to exit view only mode.
            </div>
          </p>
          <button
            type="button"
            className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon
              className="h-5 w-5 text-white"
              aria-hidden="true"
              onClick={() => setIsOpen(false)}
            />
          </button>
        </div>
      </div>
    </>
  );
}
