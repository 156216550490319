import { Link, useOutletContext } from "react-router-dom";
import { MusicalNoteIcon } from "@heroicons/react/24/solid";

import Toast from "../../../components/UI/toasts/Toast";

export default function Index() {
  const { appItems, user } = useOutletContext();

  console.log("user", user);

  return (
    <>
      <div className="container mx-auto px-4 py-20">
        <div className="py-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start gap-2">
              <MusicalNoteIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
              <h1 className="text-3xl">Welcome!</h1>
            </div>
          </div>
          <p className="py-2 text-gray-500">
            Select one of the apps below to get started.
          </p>
        </div>
        <div className="py-4">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {appItems.map((item) => (
              <Link
                key={item.id}
                to={item.path}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow hover:shadow-md"
              >
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <item.icon
                    className="h-10 w-10 flex-shrink-0 text-indigo-700"
                    aria-hidden="true"
                  />

                  <div className="flex-1">
                    <div className="flex items-center space-x-3">
                      <h2 className="truncate text-sm font-medium text-gray-900">
                        {item.name}
                      </h2>
                    </div>
                    <p className="text-sm font-light text-gray-400">
                      {item.description}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </ul>
        </div>
        <Toast isOpenDefault={!user.has_active_organization} />
      </div>
    </>
  );
}
