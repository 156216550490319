import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MobileMenu({ navItems }) {
  return (
    <>
      <Disclosure.Panel className="lg:hidden">
        <div className="space-y-1 pb-3 pt-2">
          {navItems?.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                    : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                  "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                )
              }
              end={item.end}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </Disclosure.Panel>
    </>
  );
}
