import React from "react";
import { Draggable } from "react-beautiful-dnd";

import InputField from "../forms/InputField";
import MultiSelect from "../forms/MultiSelect";
import SecondaryButton from "../UI/buttons/SecondaryButton";

const Track = ({ track, index, onRemove, onTrackChange, uploadProgress }) => {
  const handleTrackNameChange = (e) => {
    console.log("track name change", e.target.value);
    onTrackChange(track.id, { name: e.target.value });
  };

  const handleArtistChange = (selectedArtists) => {
    console.log("artist change", selectedArtists);
    onTrackChange(track.id, { artists: selectedArtists });
  };

  return (
    <Draggable key={track.id} draggableId={track.id} index={index}>
      {(provided, snapshot) => (
        <div
          className="group relative"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="flex justify-between px-6 py-2 hover:bg-gray-100">
            <div className="flex">
              <div className="flex justify-center items-center w-8 h-8 sm:w-12 sm:h-12 mx-2">
                {index + 1}
              </div>
              <div className="flex justify-between items-center mx-2">
                <div className="flex gap-2">
                  <InputField
                    onChange={handleTrackNameChange}
                    value={track.name}
                    label="Track Name"
                    type="text"
                  />
                  <MultiSelect
                    label={"Artists"}
                    selectedArtists={track.artists}
                    onChange={handleArtistChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <SecondaryButton
                text={"Remove"}
                handleClick={() => onRemove(track.id)}
              />
            </div>
          </div>
          {/* Progress Bar */}
          <div className="absolute bottom-0 left-0 right-0 bg-gray-300">
            <div
              className="bg-indigo-700"
              style={{
                width: `${uploadProgress}%`,
                // backgroundColor: "green",
                height: "4px",
              }}
            ></div>
          </div>

          {/* Numeric Progress */}
          {uploadProgress < 100 && (
            <div className="absolute bottom-0 right-0 px-2 text-xs text-gray-700">
              {`${Math.round(uploadProgress)}%`}
            </div>
          )}
        </div>
      )}
      {/* <div
        style={{
          width: `${uploadProgress}%`,
          backgroundColor: "green",
          height: "4px",
        }}
      ></div> */}
    </Draggable>
  );
};

export default Track;
