import { useState, useEffect } from "react";
import { useParams, useOutletContext, useSearchParams } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

// generic
import TaskForm from "./TaskForm";
import TasksTable from "../../../components/tasks/TasksTable";
import TaskList from "./TasksTable";
import PrimaryButton from "../../../components/UI/buttons/PrimaryButton";
import SecondaryButton from "../../../components/UI/buttons/SecondaryButton";
import SlideOver from "../../../components/SlideOver";
import ToggleButton from "../../../components/UI/buttons/ToggleButton";
import { LoadingComponent } from "../../../components/loading/LoadingScreen";

// feature specific
import ViewModel from "../../../models/forArtists/main/tasks/detail/viewmodel";
import Header from "./Header";

export default function TaskSlideover({
  open,
  setOpen,
  selectedTaskId,
  deleteTask,
  updateTask,
  isOrgTasks = false,
}) {
  const { token } = useOutletContext();
  let { projectId } = useParams();
  let { organizationId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(
      token,
      projectId,
      organizationId,
      selectedTaskId,
      data,
      (state) => {
        setData(state);
      }
    )
  );

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel]);

  useEffect(() => {
    viewModel.refreshState(selectedTaskId);
  }, [selectedTaskId]);

  console.log("rendering the sidebar data", data);

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        objectName={data?.task?.title}
        handleDelete={deleteTask}
      >
        {data?.task ? (
          <TaskForm
            task={data.task}
            subtasks={data.subtasks}
            updateTask={updateTask}
            addSubtask={async (subtask) => {
              await viewModel.addSubtask(subtask);
            }}
            updateSubtask={async (subtask) => {
              await viewModel.updateSubtask(subtask);
            }}
            removeSubtask={async (subtask) => {
              await viewModel.removeSubtask(subtask);
            }}
            categories={data.categories}
            statuses={data.statuses}
            users={data.users}
            projects={data.projects}
            close={() => setOpen(false)}
            isOrgTasks={isOrgTasks}
          />
        ) : (
          <LoadingComponent />
        )}
      </SlideOver>
    </>
  );
}
