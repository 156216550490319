import { useEffect, useState } from "react";

// generic
import SelectField from "../../../components/forms/SelectField";
import InputField from "../../../components/forms/InputField";
import PrimaryButton from "../../../components/UI/buttons/PrimaryButton";
import InputTextareaField from "../../../components/forms/InputTextareaField";
import InputDatetimeField from "../../../components/forms/InputDatetimeField";
import SelectSearch from "../../../components/forms/SelectSearch";

// feature specific
import SubTasksView from "./subtasks/Index";

const TaskForm = ({
  task,
  subtasks,
  users,
  categories,
  statuses,
  updateTask,
  addSubtask,
  updateSubtask,
  removeSubtask,
  close,
  projects,
  isOrgTasks,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      id: task.id,
      title: task.title || "",
      assigned_to: task.assigned_to || "",
      category: task.category || "",
      project: task.project || "",
      status: task.status || "",
      description: task.description || "",
      due_datetime: task.due_form_display || "",
    });
  }, [task]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedValue, fieldName) => {
    setFormData({ ...formData, [fieldName]: selectedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("formData", formData);

    const taskData = {
      ...formData,
      assigned_to_id: formData.assigned_to.id,
      category_id: formData.category.id,
      status_id: formData.status.id,
      project_id: formData.project.id,
      due_datetime: formData.due_datetime || null,
    };

    const res = await updateTask(taskData);
    if (res) {
      close();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputField
          label={"Task name"}
          name={"title"}
          value={formData.title}
          onChange={handleChange}
        />
        <InputTextareaField
          label={"Description"}
          name={"description"}
          value={formData.description}
          onChange={handleChange}
        />
        {projects && isOrgTasks && (
          <SelectSearch
            label={"Project"}
            initialOption={formData.project}
            options={projects}
            onSelectChange={(selectedValue) =>
              handleSelectChange(selectedValue, "project")
            }
          />
        )}
        {users && (
          <SelectField
            label={"Assigned to"}
            options={users}
            selected={formData.assigned_to || ""}
            handleChange={(selectedValue) =>
              handleSelectChange(selectedValue, "assigned_to")
            }
          />
        )}
        {categories && (
          <SelectField
            label={"Category"}
            options={categories}
            selected={formData.category || categories[0]}
            handleChange={(selectedValue) =>
              handleSelectChange(selectedValue, "category")
            }
          />
        )}
        {statuses && (
          <SelectField
            label={"Status"}
            options={statuses}
            selected={formData.status || statuses[0]}
            handleChange={(selectedValue) =>
              handleSelectChange(selectedValue, "status")
            }
          />
        )}
        <InputDatetimeField
          label={"Due"}
          name={"due_datetime"}
          type={"datetime-local"}
          value={formData.due_datetime}
          onChange={handleChange}
        />
        <SubTasksView
          taskId={task.id}
          subtasks={subtasks}
          addSubtask={addSubtask}
          updateSubtask={updateSubtask}
          removeSubtask={removeSubtask}
        />
        <div className="border border-gray-100 p-6 bg-gray-50">
          <PrimaryButton text="Save" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default TaskForm;
