import { Outlet, NavLink, Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../pages/forFans/FanLayoutContext";
import { useContext, Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import {
  BellIcon,
  FolderIcon,
  PhotoIcon,
  MoonIcon,
  TableCellsIcon,
  RectangleStackIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";

import ZantipiCat from "../../assets/images/Zantipi_Cat_no_zzz_screen_res.png";
import ZantipiLogo from "../../assets/images/Zantipi_Logo_Purple.png";
import SearchArtistsDropdown from "./search/SearchArtistsDropdown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProjectsRoot() {
  const token = localStorage.getItem("token");
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;
  const artistsSiteURL = process.env.REACT_APP_ARTISTS_SITE_URL;

  const handleLogout = (e) => {
    console.log("logout clicked");
    e.preventDefault();

    // Here we call the backend API to logout the user
    fetch(`${baseURL}api/v1/otp/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Log the error message body
          response.json().then((json) => console.error(json));
          throw new Error("Something went wrong");
        }
      })
      .then(async (data) => {
        if (data) {
          // delete token from local storage
          localStorage.removeItem("token");
          console.log("logout successful");
          navigate("/");
        } else {
          // Handle any errors
          console.error("Invalid credentials");
        }
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            {/* main navbar */}
            <div className="mx-auto px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                {/* logo */}
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                      <div className="flex flex-row items-center gap-2">
                        <img
                          src={ZantipiCat}
                          alt="Zantipi Cat"
                          className="w-16"
                        />
                        <img
                          className="h-8 w-auto"
                          src={ZantipiLogo}
                          alt="Zantipi"
                        />
                      </div>
                    </Link>
                  </div>
                </div>

                {/* search bar */}
                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <SearchArtistsDropdown />
                  </div>
                </div>

                {/* Mobile menu button */}
                {/* <div className="flex items-center lg:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div> */}

                <div className="lg:ml-4 flex items-center">
                  {/* Notification icon */}
                  {/* <button
                  type="button"
                  className="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                  {/* library icon */}
                  <Link
                    to="/library/"
                    type="button"
                    className="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View library</span>
                    <HeartIcon className="h-6 w-6" aria-hidden="true" />
                  </Link>

                  {/* location */}
                  {/* <button
                    type="button"
                    className="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">My location</span>
                    <div className="flex">
                      Brooklyn, NY
                      <MapPinIcon className="h-6 w-6 ms-2" aria-hidden="true" />
                    </div>
                  </button> */}

                  {/* Shopping bag */}
                  {/* <button
                    type="button"
                    className="mx-1 relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Shopping bag</span>
                    <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  {user ? (
                    <Menu as="div" className="relative ml-4 flex-shrink-0">
                      <div>
                        <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          {user.profile_picture ? (
                            <img
                              className="h-9 w-9 rounded-full"
                              src={user.profile_picture}
                              alt=""
                            />
                          ) : (
                            <span className="inline-block h-9 w-9 overflow-hidden rounded-full bg-gray-100">
                              <svg
                                className="h-full w-full text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/library/"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Library
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="billing/"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Billing
                              </Link>
                            )}
                          </Menu.Item>
                          <hr />
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href={`${artistsSiteURL}`}
                                rel="noreferrer"
                                target="_blank"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                forArtists
                              </a>
                            )}
                          </Menu.Item>
                          <hr />
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onClick={handleLogout}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <Link
                      to="/login/"
                      className="rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                      Log in
                    </Link>
                  )}
                </div>
              </div>
            </div>

            {/* mobile panel */}
            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                <Disclosure.Button
                  as="a"
                  href="/"
                  className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                >
                  Dashboard
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                >
                  Team
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                >
                  Projects
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                >
                  Calendar
                </Disclosure.Button>
              </div>
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    {user && (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.profile_picture}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="ml-3">
                    {user && (
                      <div className="text-base font-medium text-gray-800">
                        {user.first_name}
                      </div>
                    )}
                    <div className="text-sm font-medium text-gray-500">
                      tom@example.com
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href="/"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Your Profile
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Settings
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
