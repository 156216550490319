import { createBrowserRouter, RouterProvider } from "react-router-dom";

// M4A auth site
import Login from "./pages/forArtists/auth/Login";
import VerifyOTP from "./pages/forArtists/auth/VerifyOTP";
// M Main site
import ErrorPage from "./pages/ErrorPage";
// fans site
import FanLayoutContext from "./pages/forFans/FanLayoutContext";
import FansRoot from "./pages/forFans/FansRoot";
import FansIndex from "./pages/forFans/FansIndex";
import BrowseSearch from "./pages/forFans/BrowseSearch";
import Library from "./pages/forFans/Library";
import LibraryIndex from "./pages/forFans/LibraryIndex";
import LibraryArtists from "./pages/forFans/LibraryArtists";
import LibraryReleases from "./pages/forFans/LibraryReleases";
import LibraryPlaylists from "./pages/forFans/library/LibraryPlaylists";
import LibraryReleaseTracks from "./pages/forFans/LibraryReleaseTracks";
import FanArtistIndex from "./pages/forFans/artists/FanArtistIndex";
import ReleaseIndex from "./pages/forFans/ReleaseIndex";
import PlaylistIndex from "./pages/forFans/PlaylistIndex";
import ShowIndex from "./pages/forFans/ShowIndex";
import ProductIndex from "./pages/forFans/ProductIndex";
import UserBilling from "./pages/forFans/UserBilling";
// fonts
import "./assets/fonts/fonts.css";
import ScrollToTop from "./components/utils/ScrollToTop";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/verify",
    element: <VerifyOTP />,
    errorElement: <ErrorPage />,
  },
  {
    // fan app
    element: <FanLayoutContext />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <FansRoot />,
        children: [
          { index: true, element: <FansIndex /> },
          {
            path: "search/",
            element: <BrowseSearch />,
          },
          {
            path: "library/",
            element: <Library />,
            children: [
              { index: true, element: <LibraryIndex /> },
              { path: "artists/", element: <LibraryArtists /> },
              { path: "releases/", element: <LibraryReleases /> },
              { path: "playlists/", element: <LibraryPlaylists /> },
              { path: "tracks/", element: <LibraryReleaseTracks /> },
            ],
          },
          {
            // fans app: artists
            path: "artists/",
            element: <FansIndex />,
          },
          {
            path: "artists/:artistId/",
            element: <FanArtistIndex />,
          },
          {
            path: "releases/:releaseId/",
            element: <ReleaseIndex />,
          },
          {
            path: "playlists/:playlistId/",
            element: <PlaylistIndex />,
          },
          {
            path: "shows/:showId/",
            element: <ShowIndex />,
          },
          {
            path: "products/:productId/",
            element: <ProductIndex />,
          },
          {
            path: "billing/",
            element: <UserBilling />,
          },
        ],
      },
    ],
  },
  {
    path: "*", // Catch-all route
    element: <ErrorPage />, // Render ErrorPage for unmatched routes
  },
]);

export default function App() {
  return (
    <>
      <RouterProvider router={router}>
        <ScrollToTop />
      </RouterProvider>
    </>
  );
}
