import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  PlayCircleIcon,
  PauseCircleIcon,
  PlusCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

import FavoriteIcon from "../releases/FavoriteIcon";
import Track from "./Track";

export default function TrackList({
  tracks,
  handleTrackClick,
  audioPlayerViewModel,
  contextURI,
  playingContextURI,
  isPlaying,
  currentTrackIndex,
  currentTrackId,
  setCurrentTrackIndex,
  releaseId = null,
  favoriteReleaseTrack,
  currentArtistId = null,
  pageArtistId = null,
  playlistId = null,
  removePlaylistTrack = null,
  showArtist = false,
}) {
  const [hoveredTrack, setHoveredTrack] = useState(null);
  const navigate = useNavigate();

  const handleArtistClick = (artistId, event) => {
    event.stopPropagation();
    navigate(`/artists/${artistId}/`);
  };

  useEffect(() => {
    if (audioPlayerViewModel) {
      // Set the initial state
      setCurrentTrackIndex(audioPlayerViewModel.currentSongIndex);

      // Define a function to update state when ViewModel changes
      const handleViewModelChange = () => {
        setCurrentTrackIndex(audioPlayerViewModel.currentSongIndex);
      };

      // Attach the listener to the ViewModel
      audioPlayerViewModel.onStateChange = handleViewModelChange;

      // Clean up the listener when the component unmounts
      return () => {
        audioPlayerViewModel.onStateChange = null;
      };
    }
  }, [audioPlayerViewModel, setCurrentTrackIndex]);

  return (
    <>
      <div className="max-w-7xl mx-auto py-4 lg:px-8">
        {tracks &&
          tracks.map((track, index) => (
            <Track
              key={`${track.id}-${index}`}
              track={track}
              setHoveredTrack={setHoveredTrack}
              handleTrackClick={handleTrackClick}
              audioPlayerViewModel={audioPlayerViewModel}
              contextURI={contextURI}
              playingContextURI={playingContextURI}
              isPlaying={isPlaying}
              currentTrackIndex={currentTrackIndex}
              hoveredTrack={hoveredTrack}
              index={index}
              releaseId={releaseId}
              favoriteReleaseTrack={favoriteReleaseTrack}
              currentArtistId={currentArtistId}
              pageArtistId={pageArtistId}
              handleArtistClick={handleArtistClick}
              currentPlaylistId={playlistId}
              removePlaylistTrack={removePlaylistTrack}
              showArtist={showArtist}
            />
          ))}
      </div>
    </>
  );
}
