import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import Pagination from "../../../../components/Pagination";
import PageHeading from "../../../../components/layout/PageHeading";
import OrganizationForm from "../../../../components/forms/OrganizationForm";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OrganizationsIndex() {
  const { viewModel, organizationsViewData } = useOutletContext();

  const [formOpen, setFormOpen] = useState(false);
  const navigate = useNavigate();

  // Update for handling changes
  const handleChange = (event) => {
    const newQuery = event.target.value;
    viewModel.updateSearchParameters(newQuery, 1);
  };

  const handleBtnClick = () => {
    setFormOpen(!formOpen);
  };

  // Update for pagination
  const handlePageChange = (newPage) => {
    viewModel.updateSearchParameters(viewModel.query, newPage);
  };

  const handleClick = (organizationId) => {
    navigate(`/organizations/${organizationId}/`);
  };

  return (
    <div className="container mx-auto px-4 py-20">
      <div className="px-4 sm:px-6 lg:px-8">
        <PageHeading
          title="Organizations"
          subtitle={"The full list of your organizations."}
          buttonText="+ Create"
          handleClick={handleBtnClick}
        />

        {formOpen ? (
          <OrganizationForm
            addOrganization={async (organization) => {
              await viewModel.addOrganization(organization);
            }}
            setOpen={setFormOpen}
          />
        ) : null}

        {/* search bar */}
        <div className="flex items-center justify-center py-4">
          <div className="w-full">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                id="search"
                onChange={handleChange}
                name="q"
                className="block w-full rounded-full border-0 bg-white py-2 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Search organizations"
                type="search"
              />
            </div>
          </div>
        </div>

        {/* organizations table */}
        <div className="-mx-4 mt-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {organizationsViewData?.data?.results &&
                organizationsViewData.data.results.map((org, orgIdx) => (
                  <tr
                    key={org.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleClick(org.id)}
                  >
                    <td
                      className={classNames(
                        orgIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                      )}
                    >
                      <div className="flex items-center gap-4 font-medium text-gray-900">
                        {/* {org?.spotify_profile_pic_url ? (
                          <img
                            src={org.spotify_profile_pic_url}
                            alt=""
                            className="h-8 w-8 rounded-full"
                          />
                        ) : (
                          <div className="h-8 w-8 rounded-full bg-gray-100"></div>
                        )} */}

                        {org.name}
                      </div>

                      {orgIdx !== 0 ? (
                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* pagination component */}
        <Pagination
          currentPage={viewModel.currentPage}
          totalPages={Math.ceil(
            viewModel.state?.data?.count / viewModel.pageSize
          )}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
