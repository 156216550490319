import { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";

// generic
import LoadingScreen from "../../../../../components/loading/LoadingScreen";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/artists/products/viewmodel";
import CollectionTable from "../../../../../features/forArtists/artists/collections/CollectionTable";
import CollectionSlideover from "../../../../../features/forArtists/artists/collections/CollectionSlideover";

export default function Index() {
  const { token } = useOutletContext();
  let { artistId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, artistId, data, (state) => {
      setData(state);
    })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState();

  useEffect(() => {
    viewModel.fetchState().then(() => {
      setIsLoading(false);
    });
  }, [viewModel, token, artistId]);

  const handleCollectionClick = (collection) => {
    setOpen(true);
    setSelectedCollection(collection);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <h1 className="text-5xl font-bold">Product Collections</h1>
      </div>
      <div className="p-4">
        <div className="">
          <PrimaryButton
            handleClick={() => {
              setOpen(!open);
              setSelectedCollection(null);
            }}
          />
        </div>
        <div className="">
          {data?.collections?.results?.length > 0 && (
            <CollectionTable
              collections={data.collections}
              selectCollection={(collection) =>
                handleCollectionClick(collection)
              }
              updateCollection={async (collection) => {
                await viewModel.updateCollection(collection);
              }}
              sortCollections={(key) => viewModel.sortCollections(key)}
              fetchMoreCollections={async () => {
                return await viewModel.fetchMoreCollections();
              }}
            />
          )}
        </div>
      </div>

      {open && (
        <CollectionSlideover
          artistId={artistId}
          collectionId={selectedCollection?.id}
          open={open}
          setOpen={setOpen}
          selectedCollection={selectedCollection}
          data={data}
          addCollection={async (collection) => {
            return await viewModel.addCollection(collection);
          }}
          updateCollection={async (collection) => {
            await viewModel.updateCollection(collection);
          }}
          deleteCollection={async (collectionId) => {
            await viewModel.deleteCollection(collectionId);
          }}
        />
      )}
    </>
  );
}
