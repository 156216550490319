export default class CampaignsViewModel {
  constructor(token, projectId, state, updateState) {
    this.projectId = projectId;
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (url, cache) => {
    return await fetch(url, {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchData = async () => {
    return await this._get(
      this._createUrl(
        `api/v1/projects/${this.projectId}/get-adaccount-campaigns/`
      ),
      false
    );
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchData()]).then((values) => {
      return values[0];
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      let data = await this._fetchInitialCalls();
      this.state = data;
      this._callUpdateState();
    }
    return this.state;
  };

  refreshState = async (page = "1", searchQuery = "") => {
    const url = this._createUrl(
      `api/v1/projects/${this.projectId}/get-adaccount-campaigns/?page=${page}&q=${searchQuery}`
    );

    const response = await this._get(url);
    if (response) {
      this.state = response;
      this._callUpdateState();
    }
  };

  addCampaignToProject = async (campaignId) => {
    const response = await fetch(
      this._createUrl(`api/v1/projects/${this.projectId}/add-campaign/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ campaign_id: campaignId }),
      }
    );

    if (response.ok) {
      let res = await response.json();
      let campaign = res.campaign;

      // update the adaccountCampaigns state
      this.state.results = this.state.results.map((c) => {
        if (c.id === campaign.id) {
          return campaign;
        }
        return c;
      });

      // // update the campaigns state
      // this.state.campaigns.unshift(campaign);
      this._callUpdateState();
    }
  };

  removeCampaignFromProject = async (campaignId) => {
    const response = await fetch(
      this._createUrl(`api/v1/projects/${this.projectId}/remove-campaign/`),
      {
        method: "POST",
        headers: this._standardActionHeaders(),
        body: JSON.stringify({ campaign_id: campaignId }),
      }
    );

    if (response.ok) {
      let res = await response.json();
      let campaign = res.campaign;

      // update the adaccountCampaigns state
      this.state.adaccountCampaigns = this.state.adaccountCampaigns.map((c) => {
        if (c.id === campaign.id) {
          return campaign;
        }
        return c;
      });

      // update the campaigns state
      this.state.campaigns = this.state.campaigns.filter(
        (campaign) => campaign.id !== campaignId
      );

      this._callUpdateState();
    }
  };
}
