export default function ArtistTile({ artist, handleClick }) {
  const handleImageClick = () => {
    handleClick(artist.id);
  };

  return (
    <>
      <li key={artist.id} className="col-span-1 relative">
        <div
          className="relative p-3 w-full flex flex-col items-center space-y-0 cursor-pointer transition duration-300 hover:bg-indigo-50 rounded-lg group"
          onClick={handleImageClick}
        >
          {artist.spotify_profile_pic_url ? (
            <img
              className="w-full h-full object-cover rounded-full"
              src={artist.spotify_profile_pic_url}
              alt={artist.name}
            />
          ) : (
            <div
              className="w-full h-full object-cover rounded-lg bg-gray-300"
              alt={artist.name}
            />
          )}
          <h3 className="text-sm font-medium text-gray-900 truncate w-full pt-3 text-center">
            {artist.name}
          </h3>
        </div>
      </li>
    </>
  );
}
