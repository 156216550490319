import { useParams } from "react-router-dom";

// generic
import Breadcrumbs from "../../../../components/layout/navigation/BreadCrumbs";
// feature specific
import ProjectForm from "../../../../features/forArtists/projects/forms/ProjectForm";

export default function ProjectsCreate() {
  let { projectId } = useParams();
  console.log("projectId:", projectId);

  return (
    <>
      {!projectId && (
        <div className="py-2 px-4">
          <Breadcrumbs
            pages={[
              { name: "Projects", href: "/projects" },
              { name: "Create" },
            ]}
            homeLink="/"
          />
        </div>
      )}

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg space-y-4">
          <div className="pt-12">
            <h1 className="text-4xl">
              {projectId ? "Edit" : "Create"} Project
            </h1>
          </div>

          <ProjectForm />
        </div>
      </div>
    </>
  );
}
