import { Outlet } from "react-router-dom";
import NavBar from "../../../components/sales/NavBar";

export default function Root() {
  return (
    <>
      <div className="bg-white">
        <NavBar />
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
}
