import { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { UserContext } from "./FanLayoutContext";
import ViewModel from "../../models/forFans/profile/libraryReleaseTracksViewModel";
import ListGroup from "../../components/dataDisplay/lists/ListGroup";
import LoadingScreen from "../../components/loading/LoadingScreen";
import TrackList from "../../components/forFans/tracks/TrackList";

export default function LibraryReleaseTracks() {
  const token = localStorage.getItem("token");
  const user = useContext(UserContext);
  const { audioPlayerViewModel } = useOutletContext();
  const [releaseTracksViewData, setReleaseTracksViewData] = useState();
  const [libraryViewModel, setLibraryViewModel] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [loading, setLoading] = useState(true);

  let contextURI;
  if (user) {
    let userId = user?.id;
    contextURI = `userTracks:${userId}`;
  }

  useEffect(() => {
    setLoading(true);
    const viewModel = new ViewModel(token, releaseTracksViewData, (state) =>
      setReleaseTracksViewData(state)
    );
    setLibraryViewModel(viewModel);
  }, [token, releaseTracksViewData]);

  useEffect(() => {
    if (!releaseTracksViewData) {
      if (libraryViewModel) {
        libraryViewModel.fetchState();
      }
    }
    setLoading(false);
  }, [libraryViewModel, releaseTracksViewData]);

  useEffect(() => {
    if (audioPlayerViewModel) {
      // Set the initial state
      setIsPlaying(audioPlayerViewModel.isPlaying);

      // Define a function to update state when ViewModel changes
      const handleViewModelChange = () => {
        setIsPlaying(audioPlayerViewModel.isPlaying);
      };

      // Attach the listener to the ViewModel
      audioPlayerViewModel.onStateChange = handleViewModelChange;

      // Clean up the listener when the component unmounts
      return () => {
        audioPlayerViewModel.onStateChange = null;
      };
    }
  }, [audioPlayerViewModel]);

  const handleTrackClick = (trackId, trackIndex) => {
    console.log("contextURI", contextURI);
    console.log(
      "audioPlayerViewModel.contextURI",
      audioPlayerViewModel.contextURI
    );
    if (contextURI === audioPlayerViewModel.contextURI) {
      console.log("same contextURI");
      if (currentTrackIndex === trackIndex) {
        audioPlayerViewModel.togglePlayPause();
      } else {
        audioPlayerViewModel.changeTrack(trackIndex);
        if (!isPlaying) {
          audioPlayerViewModel.togglePlayPause();
        }
      }
    } else {
      console.log("different contextURI");
      audioPlayerViewModel.loadTracks(
        releaseTracksViewData.data.release_tracks,
        trackIndex,
        contextURI,
        true
      );
    }
  };

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <>
      {releaseTracksViewData?.data?.release_tracks && (
        <TrackList
          tracks={releaseTracksViewData.data.release_tracks}
          handleTrackClick={handleTrackClick}
          audioPlayerViewModel={audioPlayerViewModel}
          contextURI={contextURI}
          playingContextURI={audioPlayerViewModel.contextURI}
          isPlaying={isPlaying}
          currentTrackIndex={currentTrackIndex}
          setCurrentTrackIndex={setCurrentTrackIndex}
          // releaseId={releaseId}
          favoriteReleaseTrack={async (isFavorite, releaseTrackId) => {
            await libraryViewModel.favoriteReleaseTrack(
              isFavorite,
              releaseTrackId
            );
          }}
          currentArtistId={audioPlayerViewModel.artistId}
        />
      )}
    </>
  );
}
