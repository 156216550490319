import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

// generic
import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";
import InputField from "../../../../components/forms/InputField";
import SelectField from "../../../../components/forms/SelectField";
import { LoadingComponent } from "../../../../components/loading/LoadingScreen";
import SelectSearch from "../../../../components/forms/SelectSearch";
import InputCurrencySelect from "../../../../components/forms/InputCurrencySelect";

// feature specific
import ViewModel from "../../../../models/forArtists/main/projects/forms/viewmodel";

export default function ProjectForm() {
  const { token, project } = useOutletContext();
  let { projectId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    organization: "",
    name: "",
    type: "",
    artist: "",
    totalBudget: "",
    currency: "",
    // add other fields here
  });

  const [projectCreateViewData, setProjectCreateViewData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectCreateViewData, (state) => {
      setProjectCreateViewData(state);
    })
  );

  useEffect(() => {
    const initForm = async () => {
      const createOptions = await viewModel.fetchState();

      if (projectId) {
        const projectData = await viewModel.fetchProject(projectId);
        console.log("projectData:", projectData);
        setFormData({
          // Populate with project data, converting IDs to objects if necessary
          organization: projectData.organization,
          name: projectData.name,
          type: projectData.type,
          artist: projectData.artist,
          totalBudget: projectData.total_budget.toString(),
          currency: projectData.currency,
        });
      } else {
        // For create mode, prepopulate with default options as before
        setFormData((prevFormData) => ({
          ...prevFormData,
          artist: createOptions.options.artists[0] || "",
          type: createOptions.options.project_types[0] || "",
          organization: createOptions.options.organizations[0] || "",
          currency: createOptions.options.currencies[0] || "",
        }));
      }
      setIsLoading(false);
    };

    initForm();
  }, [viewModel, projectId]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
    if (e.target.name === "totalBudget") {
      setErrors({ ...errors, total_budget: "" });
    }
  };

  const handleOrganizationChange = (selectedItem) => {
    setFormData({ ...formData, organization: selectedItem });
  };

  const handleTypeChange = (selectedItem) => {
    setFormData({ ...formData, type: selectedItem });
  };

  const handleArtistChange = (selectedItem) => {
    setFormData({ ...formData, artist: selectedItem });
  };

  const handleCurrencyChange = (selectedItem) => {
    setFormData({ ...formData, currency: selectedItem });
    setErrors({ ...errors, total_budget: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let result;

    if (projectId) {
      let requestBody = {
        total_budget: formData.totalBudget,
        name: formData.name,
      };
      result = await viewModel.updateProject(projectId, requestBody);
    } else {
      let requestBody = {
        organization_id: formData.organization.id,
        artist_id: formData.artist.id,
        currency_id: formData.currency.id,
        type_id: formData.type.id,
        total_budget: formData.totalBudget,
        name: formData.name,
      };
      result = await viewModel.createProject(requestBody);
    }

    if (result.error) {
      // Directly set the errors state with the returned error object
      // Adjust this if your error structure is different
      setErrors(result.error);
      setIsLoading(false);
    } else {
      console.log("success: ", result);
      setErrors({}); // Clear any existing errors
      setIsLoading(false);
      navigate(`/projects/${result.id}/`);
    }
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="space-y-6">
        <form onSubmit={handleSubmit} className="space-y-2">
          {!projectId && projectCreateViewData?.options?.organizations && (
            <SelectField
              label={"Organization"}
              options={projectCreateViewData?.options?.organizations}
              selected={
                formData.organization ||
                projectCreateViewData?.options?.organizations[0]
              }
              handleChange={handleOrganizationChange}
              addBlankOption={false}
            />
          )}
          <InputField
            label={"Name"}
            name={"name"}
            value={formData.name}
            onChange={handleChange}
            charLimit={50}
            error={errors.name}
            required={true}
            setErrors={setErrors}
          />
          {!projectId && projectCreateViewData?.options?.project_types && (
            <SelectField
              label={"Type"}
              options={projectCreateViewData?.options?.project_types}
              selected={
                formData.type ||
                projectCreateViewData?.options?.project_types[0]
              }
              handleChange={handleTypeChange}
            />
          )}
          {!projectId && projectCreateViewData?.options?.artists && (
            <SelectSearch
              label={"Artist"}
              options={projectCreateViewData?.options?.artists}
              initialOption={
                formData.artist || projectCreateViewData?.options?.artists[0]
              }
              onSelectChange={handleArtistChange}
              showAvatar={true}
            />
          )}
          {projectCreateViewData?.options?.currencies && (
            <InputCurrencySelect
              // input
              label={"Total budget"}
              name={"totalBudget"}
              value={formData.totalBudget}
              onChange={handleChange}
              // select
              options={projectCreateViewData?.options?.currencies}
              onSelectChange={handleCurrencyChange}
              error={errors.total_budget}
              currency={formData.currency}
              lockCurrency={projectId ? true : false}
            />
          )}
          <div className="py-4">
            <PrimaryButton
              text="Save"
              type="submit"
              onClick={handleSubmit}
              widthFull={true}
            />
          </div>
        </form>
      </div>
    </>
  );
}
