import "./LoadingScreen.css"; // Import the CSS file for keyframes animation

const LoadingScreen = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <p className="text-7xl font-medium">
        {/* Loading */}
        <span className="dot dot-1">.</span>
        <span className="dot dot-2">.</span>
        <span className="dot dot-3">.</span>
      </p>
    </div>
  );
};

export default LoadingScreen;

export const LoadingComponent = ({ size = "text-7xl" }) => {
  return (
    <div className="flex items-center justify-center">
      <p className={`${size} font-medium`}>
        {/* Loading */}
        <span className="dot dot-1">.</span>
        <span className="dot dot-2">.</span>
        <span className="dot dot-3">.</span>
      </p>
    </div>
  );
};
