import { useEffect, useState } from "react";

// generic
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";

// feature specific
import CampaignsModal from "./CampaignsModal";
import CampaignsTable from "./CampaignsTable";
import AdgroupsTable from "../adgroups/AdgroupsTable";

const Index = ({
  typeObj,
  campaigns,
  adgroups,
  refreshState,
  fetchAdgroups,
  removeCampaign,
  isLoading,
  setIsLoading,
  updateCampaign,
  updateAdgroup,
  projectShows,
  projectReleases,
  projectCollections,
  projectPlatforms,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loadingAdgroups, setLoadingAdgroups] = useState(false);
  const cols = [
    { id: 1, name: "Campaign", label: "Campaign" },
    { id: 2, name: "Platform", label: "Platform" },
    { id: 3, name: typeObj, label: typeObj },
    { id: 4, name: "", label: "" },
  ];

  useEffect(() => {
    if (selectedCampaign) {
      setLoadingAdgroups(true);
      fetchAdgroups(selectedCampaign.id).then(() => {
        setLoadingAdgroups(false);
      });
    }
  }, [selectedCampaign]);

  const handleClosingModal = async () => {
    setIsLoading(true);
    setIsModalOpen(false);
    await refreshState();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <PrimaryButton text={"+ Add"} handleClick={() => setIsModalOpen(true)} />

      <div className="h-96 overflow-y-auto">
        {selectedCampaign ? (
          <AdgroupsTable
            adgroups={adgroups}
            setSelectedCampaign={setSelectedCampaign}
            updateAdgroup={updateAdgroup}
            projectShows={projectShows}
            projectReleases={projectReleases}
            projectCollections={projectCollections}
            typeObj={typeObj}
            loadingAdgroups={loadingAdgroups}
          />
        ) : (
          <CampaignsTable
            cols={cols}
            campaigns={campaigns}
            setSelectedCampaign={setSelectedCampaign}
            removeCampaign={removeCampaign}
            updateCampaign={updateCampaign}
            projectShows={projectShows}
            projectReleases={projectReleases}
            projectCollections={projectCollections}
            projectPlatforms={projectPlatforms}
          />
        )}
      </div>

      <CampaignsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refreshState={refreshState}
        setIsLoading={setIsLoading}
        handleClosingModal={handleClosingModal}
      />
    </>
  );
};

export default Index;
