import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectFieldStealth({
  options,
  selected,
  setSelected,
  handleChange,
  handleBlur,
}) {
  const onChange = (value) => {
    if (setSelected) {
      setSelected(value);
    }
    if (handleChange) {
      handleChange(value);
    }
  };

  // add an empty option to the beginning of the options array
  // so that the user can clear the selection
  options = [{ id: null, name: "" }, ...options];

  return (
    <>
      <Listbox value={selected || ""} onChange={onChange}>
        {({ open }) => (
          <>
            <div className="relative w-full">
              <Listbox.Button
                // onBlur={handleBlur}
                className="relative w-full cursor-pointer py-1.5 pl-1 pr-4 text-left text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 h-10"
              >
                <span className="h-full w-full truncate flex items-center">
                  {selected ? selected.name : ""}{" "}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 group">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400 opacity-0 group-hover:opacity-100"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-500",
                          "relative cursor-default select-none py-2 pl-3 pr-4"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}
