import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ArtistsApp from "./ArtistsApp";

const root = ReactDOM.createRoot(document.getElementById("root"));

const hostname = window.location.hostname;

let ComponentToRender = App;

// Check if the subdomain is "business"
if (hostname.startsWith("artists.")) {
  ComponentToRender = ArtistsApp;
}

root.render(
  <>
    <ComponentToRender />
  </>
);
