import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

const MenuItem = ({ item }) => {
  return (
    <Menu.Item>
      <div
        onClick={item.handleClick}
        className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 hover:cursor-pointer"
      >
        {item.name}
      </div>
    </Menu.Item>
  );
};

export default MenuItem;
