import { NavLink, useNavigate } from "react-router-dom";

// feature specific
import PillButton from "./PillButton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BudgetTabs({
  tab,
  tabIdx,
  tabsLength,
  isActiveTab,
  selectedCampaigns,
  setSelectedCampaigns,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log("tab.href: ", tab.href);
    // setSelectedCampaigns([]);
    navigate(tab.href);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={classNames(
          isActiveTab(tab.href)
            ? "text-gray-900"
            : "text-gray-500 hover:text-gray-700",
          tabIdx === 0 ? "rounded-l-lg" : "",
          tabIdx === tabsLength - 1 ? "rounded-r-lg" : "",
          "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-left text-sm font-medium hover:bg-gray-50 focus:z-10 hover:cursor-pointer"
        )}
        aria-current={isActiveTab(tab.href) ? "page" : undefined}
      >
        <div className="flex items-center justify-between space-x-3">
          <span>{tab.name}</span>
          {tab.name === "Campaigns" && selectedCampaigns?.length > 0 && (
            <PillButton
              selectedLength={selectedCampaigns.length}
              setSelectedCampaigns={setSelectedCampaigns}
            />
          )}
        </div>

        <span
          aria-hidden="true"
          className={classNames(
            isActiveTab(tab.href) ? "bg-indigo-500" : "bg-transparent",
            "absolute inset-x-0 bottom-0 h-0.5"
          )}
        />
      </div>
    </>
  );
}
