import { useState } from "react";

// generic
import TableContainer from "../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../components/layout/tables/TableBody";
import TableRow from "../../../../../components/layout/tables/TableRow";
import TableCell from "../../../../../components/layout/tables/TableCell";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";
// feature specific
import ShowsModal from "./ShowsModal";

const Index = ({
  projectShows,
  refreshState,
  removeProjectShow,
  isLoading,
  setIsLoading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cols = [
    { id: 1, name: "Name", label: "Name" },
    { id: 2, name: "" },
  ];

  const handleClosingModal = async () => {
    setIsLoading(true);
    setIsModalOpen(false);
    await refreshState();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <PrimaryButton text={"+ Add"} handleClick={() => setIsModalOpen(true)} />

      <TableContainer>
        <TableHeader cols={cols} />
        <TableBody>
          {projectShows?.map((projectShow, index) => {
            return (
              <TableRow key={projectShow.id}>
                <TableCell>{projectShow.name}</TableCell>
                <TableCell>
                  <SecondaryButton
                    text={"remove"}
                    handleClick={() => {
                      removeProjectShow(projectShow.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>

      <ShowsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refreshState={refreshState}
        setIsLoading={setIsLoading}
        handleClosingModal={handleClosingModal}
      />
    </>
  );
};

export default Index;
