import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PrimaryButton from "../../../components/UI/buttons/PrimaryButton";
import SecondaryButton from "../../../components/UI/buttons/SecondaryButton";
import LoadingScreen, {
  LoadingComponent,
} from "../../../components/loading/LoadingScreen";
import Zantipi_Logo from "../../../assets/images/Zantipi_Logo.png";
import ZantipiCat from "../../../assets/images/Zantipi_Cat_no_zzz_screen_res.png";

export default function Login() {
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUpdating(true);

    // Here we call the backend API to login the user
    fetch(`${baseURL}api/v1/otp/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Log the error message body
          response.json().then((json) => console.error(json));
          throw new Error("Something went wrong");
        }
      })
      .then(async (data) => {
        if (data) {
          console.log("successful - check your email for OTP");
          setIsUpdating(false);
          navigate("/verify");
        } else {
          // Handle any errors
          console.error("Invalid credentials");
          setIsUpdating(false);
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
      });
  };

  const handleSignupClick = () => {
    navigate("/register");
  };

  if (isUpdating) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full p-4">
        {/* zantipi logo */}
        <div className="flex flex-col items-center">
          <img
            src={ZantipiCat}
            alt="Zantipi Cat"
            className="w-40 sm:max-w-sm"
          />
          <img className="h-20 w-auto -mt-4" src={Zantipi_Logo} alt="Zantipi" />
        </div>

        {/* sign in box */}
        <div className="w-full sm:max-w-sm border rounded-lg px-6 py-4">
          <h2 className="text-left text-3xl font-medium leading-9 tracking-tight text-gray-900 py-2">
            Sign up / log in
          </h2>
          <p className="text-sm font-light text-gray-500">
            Enter your email below to continue.
          </p>
          <form className="space-y-6 py-2" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="">
                <input
                  onChange={handleChange}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="phoebe@phoebebridgers.com"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex justify-start items-center gap-4">
              <PrimaryButton text={"Continue"} type={"submit"} widthFull={true}>
                {isUpdating && <LoadingComponent size={"text-md"} />}
              </PrimaryButton>
            </div>
          </form>
        </div>

        {/* create account section */}
        {/* <div className="w-full sm:max-w-sm py-4 relative text-center">
          <div className="relative py-2">
            <div className="absolute top-1/2 left-0 w-full border-t border-gray-300" />
            <p className="text-sm font-light text-gray-500 bg-white relative inline-block px-2">
              New to Zantipi?
            </p>
          </div>
          <SecondaryButton
            text={"Create your Zantipi account"}
            handleClick={handleSignupClick}
            widthFull={true}
          />
        </div> */}
      </div>
    </>
  );
}
