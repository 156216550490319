import { useState } from "react";

// generic
import ToggleIcon from "./ToggleIcon";
import Nav from "./nav/Nav";

export default function Index({ navItems }) {
  const [open, setOpen] = useState(true);

  return (
    <div
      className={`hidden lg:flex flex-col h-full overflow-y-auto bg-indigo-600 z-10 ${
        open ? "w-64" : "w-14"
      } `}
    >
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border border-gray-200 bg-white px-4 py-3">
        <Nav navItems={navItems} open={open} />

        <div className="-mx-2 mt-auto pb-10">
          <ToggleIcon open={open} setOpen={setOpen} />
        </div>
      </div>
    </div>
  );
}
