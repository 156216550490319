import { useState, useEffect } from "react";
import { useParams, useOutletContext, useSearchParams } from "react-router-dom";

import TimeseriesViewModel from "../../../../../models/forArtists/main/projects/analytics/timeseriesViewModel";
import SelectBox from "../../../../../components/forms/SelectBox";
import ComboChart from "../../../../../components/analytics/charts/ComboChart";

const date_ranges = [
  { id: 1, text: "All time", value: "lifetime" },
  { id: 2, text: "Last 28 days", value: "last_28_days" },
  { id: 3, text: "Last 7 days", value: "last_7_days" },
];

const date_presets = [
  { id: 1, text: "Daily" },
  { id: 2, text: "Weekly" },
  { id: 3, text: "Monthly" },
];

export default function ProjectAnalyticsTimeseries() {
  // search params
  let [searchParams, setSearchParams] = useSearchParams();
  const [comparisonFields, setComparisonFields] = useState([]);
  const [datePreset, setDatePreset] = useState(date_presets[1]);
  const [dateRange, setDateRange] = useState(date_ranges[0]);
  const [projectShow, setProjectShow] = useState();
  const [projectRelease, setProjectRelease] = useState();
  const [projectCollection, setProjectCollection] = useState();
  const [yLabel, setYLabel] = useState("ctr");
  const [y1Label, setY1Label] = useState("clicks");

  // view model
  const { token, project } = useOutletContext();
  let { projectId } = useParams();
  const [analyticsTimeseriesViewData, setAnalyticsTimeseriesViewData] =
    useState();
  const [timeseriesViewModel, setTimeseriesViewModel] = useState(
    new TimeseriesViewModel(
      token,
      projectId,
      analyticsTimeseriesViewData,
      (state) => {
        setAnalyticsTimeseriesViewData(state);
      },
      searchParams
    )
  );

  useEffect(() => {
    if (project.type_name === "Tour" && project.has_ticket_reporting) {
      setComparisonFields(["tickets_sold"]);
      setY1Label("tickets_sold");
    } else {
      setComparisonFields(["cpc", "clicks"]);
      setYLabel("cpc");
      setY1Label("clicks");
    }
  }, [project]);

  useEffect(() => {
    setSearchParams({
      date_preset: datePreset.text,
      date_range: dateRange.value,
      project_show: projectShow?.id,
      project_release: projectRelease?.id,
      comparison_fields: comparisonFields.join(","),
    });
  }, [
    datePreset,
    dateRange,
    comparisonFields,
    projectShow,
    setSearchParams,
    projectRelease,
  ]);

  useEffect(() => {
    timeseriesViewModel.updateSearchParams(searchParams);
  }, [searchParams, timeseriesViewModel, setSearchParams]);

  useEffect(() => {
    // Check if project_shows data is available and set the initial value
    if (projectShow !== undefined) {
      return;
    }
    if (analyticsTimeseriesViewData?.data?.project_shows?.length > 0) {
      setProjectShow(analyticsTimeseriesViewData.data.project_shows[0]);
    }
  }, [analyticsTimeseriesViewData, projectShow]);

  useEffect(() => {
    // Check if project_releases data is available and set the initial value
    if (projectRelease !== undefined) {
      return;
    }
    if (analyticsTimeseriesViewData?.data?.project_releases?.length > 0) {
      setProjectRelease(analyticsTimeseriesViewData.data.project_releases[0]);
    }
  }, [analyticsTimeseriesViewData, projectRelease]);

  useEffect(() => {
    // Check if project_collections data is available and set the initial value
    if (projectCollection !== undefined) {
      return;
    }
    if (analyticsTimeseriesViewData?.data?.project_collections?.length > 0) {
      setProjectCollection(
        analyticsTimeseriesViewData.data.project_collections[0]
      );
    }
  }, [analyticsTimeseriesViewData, projectCollection]);

  const handleButtonClick = (fields) => {
    setComparisonFields(fields.split(","));
  };

  useEffect(() => {
    if (searchParams.get("comparison_fields")) {
      let fields = searchParams.get("comparison_fields").split(",");

      if (fields.length === 1) {
        if (
          fields[0] === "tickets_sold" ||
          fields[0] === "purchases" ||
          fields[0] === "revenue"
        ) {
          setYLabel(null);
          setY1Label(fields[0]);
          return;
        }
        setYLabel(fields[0]);
        setY1Label(null);
        return;
      }
      setYLabel(fields[0]);
      setY1Label(fields[1]);
    }
  }, [searchParams]);

  // Function to determine if a button should be styled as active
  const isActive = (buttonValue) => {
    return searchParams.get("comparison_fields") === buttonValue;
  };

  return (
    <>
      <div className="rounded-lg my-8">
        {/* filter dropdowns */}
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-full md:w-1/4 ml-auto px-2">
            {projectShow &&
              analyticsTimeseriesViewData?.data?.project_shows?.length > 2 && (
                <SelectBox
                  selectedValue={projectShow}
                  setSelectedValue={setProjectShow}
                  label={""}
                  data={analyticsTimeseriesViewData?.data?.project_shows}
                />
              )}

            {projectRelease &&
              analyticsTimeseriesViewData?.data?.project_releases?.length >
                2 && (
                <SelectBox
                  selectedValue={projectRelease}
                  setSelectedValue={setProjectRelease}
                  label={""}
                  data={analyticsTimeseriesViewData?.data?.project_releases}
                />
              )}

            {projectCollection &&
              analyticsTimeseriesViewData?.data?.project_collections?.length >
                2 && (
                <SelectBox
                  selectedValue={projectCollection}
                  setSelectedValue={setProjectCollection}
                  label={""}
                  data={analyticsTimeseriesViewData?.data?.project_collections}
                />
              )}

            <SelectBox
              selectedValue={dateRange}
              setSelectedValue={setDateRange}
              label={""}
              data={date_ranges}
            />
            <SelectBox
              selectedValue={datePreset}
              setSelectedValue={setDatePreset}
              label={""}
              data={date_presets}
            />
          </div>
        </div>
        {/* chart */}
        {analyticsTimeseriesViewData?.data?.timeseries && (
          <ComboChart
            timeseriesData={analyticsTimeseriesViewData.data.timeseries}
            x_label={"truncated_date"}
            y_label={yLabel}
            y1_label={y1Label}
            min={0}
          />
        )}
      </div>

      {/* comparison buttons */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 md:grid-cols-6">
        {project.type_name === "Tour" && project.has_ticket_reporting && (
          <>
            <button
              onClick={() => handleButtonClick("tickets_sold")}
              className={
                isActive("tickets_sold")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Tickets
            </button>
            <button
              onClick={() => handleButtonClick("spend,tickets_sold")}
              className={
                isActive("spend,tickets_sold")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Spend vs Tickets
            </button>
            <button
              onClick={() => handleButtonClick("cpt,tickets_sold")}
              className={
                isActive("cpt,tickets_sold")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              CPT vs Tickets
            </button>
          </>
        )}

        {project.type_name === "Tour" && !project.has_ticket_reporting && (
          <>
            <button
              onClick={() => handleButtonClick("spend")}
              className={
                isActive("spend")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Spend
            </button>
            <button
              onClick={() => handleButtonClick("spend,clicks")}
              className={
                isActive("spend,clicks")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Spend vs Clicks
            </button>
          </>
        )}

        {project.type_name === "Release" && (
          <>
            <button
              onClick={() => handleButtonClick("spend")}
              className={
                isActive("spend")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Spend
            </button>
            <button
              onClick={() => handleButtonClick("spend,clicks")}
              className={
                isActive("spend,clicks")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Spend vs Clicks
            </button>
          </>
        )}

        {project.type_name === "Merch" && (
          <>
            <button
              onClick={() => handleButtonClick("purchases")}
              className={
                isActive("purchases")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Purchases
            </button>
            <button
              onClick={() => handleButtonClick("spend,purchases")}
              className={
                isActive("spend,purchases")
                  ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              }
            >
              Spend vs Purchases
            </button>
          </>
        )}

        <button
          onClick={() => handleButtonClick("ctr,clicks")}
          className={
            isActive("ctr,clicks")
              ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          }
        >
          CTR vs Clicks
        </button>
        <button
          onClick={() => handleButtonClick("cpc,clicks")}
          className={
            isActive("cpc,clicks")
              ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          }
        >
          CPC vs Clicks
        </button>
        {/* <button
          onClick={() => handleButtonClick("spend,impressions")}
          className={
            isActive("spend,impressions")
              ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          }
        >
          Spend vs Impressions
        </button> */}
        <button
          onClick={() => handleButtonClick("cpm,impressions")}
          className={
            isActive("cpm,impressions")
              ? "rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              : "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          }
        >
          CPM vs Impressions
        </button>
      </div>
    </>
  );
}
