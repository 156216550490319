export default class ProjectFormViewModel {
  constructor(token, state, updateState) {
    // TODO: Put these in a higher state and feed them in as variables
    this.token = token;
    this.url_begin = process.env.REACT_APP_API_URL;
    this.updateState = updateState;
  }

  _callUpdateState = () => {
    this.updateState({ ...this.state });
  };

  _createUrl = (path) => {
    return `${this.url_begin}${path}`;
  };

  _standardHeaders = () => {
    return {
      Authorization: `Token ${this.token}`,
    };
  };

  _standardActionHeaders = () => {
    return {
      ...this._standardHeaders(),
      ...{ "Content-Type": "application/json" },
    };
  };

  _get = async (path, cache) => {
    return await fetch(this._createUrl(path), {
      method: "GET",
      headers: this._standardHeaders(),
      // cache: cache ? "force-cache" : "default",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  _fetchProjectCreateOptions = async () => {
    return await this._get(`api/v1/projects/create-options/`, true);
  };

  _fetchInitialCalls = async () => {
    return Promise.all([this._fetchProjectCreateOptions()]).then((values) => {
      return {
        options: values[0],
      };
    });
  };

  fetchState = async () => {
    if (this.state === undefined) {
      this.state = await this._fetchInitialCalls();
      this._callUpdateState();
    }
    return this.state;
  };

  fetchProject = async (projectId) => {
    return await this._get(`api/v1/projects/${projectId}/`, false);
  };

  createProject = async (project) => {
    return await fetch(this._createUrl("api/v1/projects/"), {
      method: "POST",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(project),
    })
      .then(async (response) => {
        const res = await response.json(); // Parse the response body first
        console.log("response: ", response);
        console.log("res in model: ", res);
        if (!response.ok) {
          // Here we handle a non-OK response by returning an object with an error field
          // containing the parsed error message(s) from the response body
          return { error: res }; // Assuming the server responds with an error object
        }
        return res; // Return the parsed response body for a successful request
      })
      .catch((error) => {
        console.error("Error creating project", error);
        // Return an error structure that can be used to set form errors
        return {
          error: { general: "An error occurred while creating the project." },
        };
      });
  };

  updateProject = async (projectId, project) => {
    return await fetch(this._createUrl(`api/v1/projects/${projectId}/`), {
      method: "PATCH",
      headers: this._standardActionHeaders(),
      body: JSON.stringify(project),
    })
      .then(async (response) => {
        const res = await response.json(); // Parse the response body first
        console.log("response: ", response);
        console.log("res in model: ", res);
        if (!response.ok) {
          // Here we handle a non-OK response by returning an object with an error field
          // containing the parsed error message(s) from the response body
          return { error: res }; // Assuming the server responds with an error object
        }
        return res; // Return the parsed response body for a successful request
      })
      .catch((error) => {
        console.error("Error updating project", error);
        // Return an error structure that can be used to set form errors
        return {
          error: { general: "An error occurred while updating the project." },
        };
      });
  };
}
