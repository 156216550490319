import { useState, useEffect } from "react";
import {
  useParams,
  useOutletContext,
  Link,
  useNavigate,
} from "react-router-dom";

// generic
import TasksTable from "../../../../../components/tasks/TasksTable";
// import Feed from "../../../../../components/UI/feed/Index";

// feature specific
import ViewModel from "../../../../../models/forArtists/main/projects/projectHomeViewModel";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
import ProjectMenu from "../../../../../features/forArtists/projects/home/ProjectMenu";

export default function ProjectIndex() {
  const { token } = useOutletContext();
  let { projectId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, projectId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel, token, projectId]);

  const stats = [
    {
      name: "Total budget",
      stat: `${data?.project?.total_budget?.toLocaleString() || 0}`,
    },
    { name: "Spent", stat: `${data?.project?.spent?.toLocaleString() || 0}` },
    {
      name: "Remaining",
      stat: `${data?.project?.remaining_budget?.toLocaleString() || 0}`,
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-6">
        {/* header */}
        {data?.project && (
          <div className="flex flex-col gap-2 py-4">
            <div className="flex justify-start items-center gap-4">
              <h1 className="text-5xl font-bold">{data?.project?.name}</h1>
              <div>
                <ProjectMenu
                  deleteProject={async () => {
                    return await viewModel.deleteProject(data.project);
                  }}
                />
              </div>
            </div>
            <p className="font-light text-gray-500">
              {data?.project?.artist?.name}
            </p>
          </div>
        )}

        <div className="flex gap-2">
          <SecondaryButton
            text={"Edit"}
            handleClick={() => navigate(`/projects/${data?.project.id}/edit/`)}
          />
        </div>

        {/* budget overview */}

        {data?.project && (
          <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              {stats.map((item) => (
                <div
                  key={item.name}
                  className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                >
                  <dt className="truncate text-sm font-medium text-gray-500">
                    {item.name}
                  </dt>
                  <dd className="mt-1 text-3xl font-medium tracking-tight text-indigo-600">
                    {data?.project?.currency?.symbol}
                    {item.stat}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        )}

        {/* <div>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
            <div className="flex flex-col justify-center items-center py-12 gap-4 rounded-md border border-gray-100">
              <div className="flex justify-start items-center gap-4">
                <h3 className="text-2xl font-medium">Timeline</h3>
              </div>
              <Feed />
            </div>
          </dl>
        </div> */}
      </div>
    </>
  );
}
