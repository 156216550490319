import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

// generic
import { LoadingComponent } from "../../../../../components/loading/LoadingScreen";
// feature specific
import ViewModel from "../../../../../models/forArtists/main/organizations/data/meta/viewmodel.js";
import BusinessManager from "./BusinessManager.jsx";
import Adaccounts from "../adaccounts/Index.jsx";
import Pages from "../pages/Index.jsx";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton.jsx";

export default function Index() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useOutletContext();
  const { organizationId } = useParams();
  const [data, setData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, organizationId, data, (state) => {
      setData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState().then(() => setIsLoading(false));
  }, [viewModel, token]);

  const handleRefreshAssets = async () => {
    setIsLoading(true);
    await viewModel.refreshBusinessManagerAssets();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="">
        {data?.organization && (
          <BusinessManager
            isLoading={isLoading}
            org={data.organization}
            fbUser={data.fb_user}
            options={data.data.meta.business_manager_options}
            updateOrganization={async (org) => {
              return await viewModel.updateOrganization(org);
            }}
            addBusinessManagerToOrg={async (businessManager) => {
              return await viewModel.addBusinessManagerToOrg(businessManager);
            }}
          />
        )}
      </div>

      {data?.fb_user && (
        <div className="pt-8">
          <PrimaryButton
            text="Refresh assets"
            handleClick={handleRefreshAssets}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      )}

      {data?.fb_user && (
        <div className="my-8">
          <Adaccounts
            adaccounts={data.data.meta.adaccounts}
            adaccountOptions={data.data.meta.adaccounts_options}
            addAdaccount={async (adaccount) => {
              return await viewModel.addMetaAdaccount(adaccount);
            }}
            removeAdaccount={async (adaccount) => {
              return await viewModel.removeMetaAdaccount(adaccount);
            }}
            fetchAdaccountOptions={async () => {
              await viewModel.fetchMetaAdaccountOptions();
            }}
          />
        </div>
      )}

      {data?.fb_user && (
        <div className="my-8">
          <Pages
            pages={data.data.meta.pages}
            pageOptions={data.data.meta.page_options}
            addPage={async (page) => {
              return await viewModel.addMetaPage(page);
            }}
            removePage={async (page) => {
              return await viewModel.removeMetaPage(page);
            }}
            fetchPageOptions={async () => {
              await viewModel.fetchMetaPageOptions();
            }}
          />
        </div>
      )}
    </>
  );
}
