import TableCell from "../../../../../components/layout/tables/TableCell";
import TableRow from "../../../../../components/layout/tables/TableRow";
import PrimaryButton from "../../../../../components/UI/buttons/PrimaryButton";

const CampaignsModalRow = ({ campaign, cols, addCampaignToProject }) => {
  return (
    <TableRow cols={cols}>
      <TableCell>{campaign.name}</TableCell>
      <TableCell>{campaign.platform_name}</TableCell>
      <TableCell>
        {campaign.project ? (
          campaign.project?.name
        ) : (
          <PrimaryButton
            text={"Add to project"}
            handleClick={() => addCampaignToProject(campaign.id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default CampaignsModalRow;
