const ChartContainer = ({ title, children }) => {
  return (
    <div className="rounded-lg my-4">
      <h2 className="text-xl ps-10 pt-4 py-1 text-gray-700 font-bold">
        {title}
      </h2>
      {children}
    </div>
  );
};

export default ChartContainer;
