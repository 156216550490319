import { useState } from "react";

import PrimaryButton from "../../../../components/UI/buttons/PrimaryButton";
import InputField from "../../../../components/forms/InputField";

export default function InviteUserForm({ inviteUser, setOpenAddForm }) {
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let requestBody = {
      ...formData,
    };
    let res = await inviteUser(requestBody);
    if (res) {
      setOpenAddForm(false);
      setFormData({
        email: "",
      });
    }
  };

  return (
    <div className="m-1 px-4 py-2 border rounded-md">
      {/* <h2 className="m-4 font-bold text-gray-700">Create a new show</h2> */}
      <div className="py-2">
        <div className="flex items-center justify-between px-2 pt-2 pb-3">
          <h3 className="text-lg font-bold text-gray-700">Invite a user</h3>
          <div className="flex items-center justify-end gap-2">
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={() => setOpenAddForm(false)}
            >
              Cancel
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <InputField
            label="Email"
            type="email"
            name="email"
            value={formData.email || ""}
            onChange={handleChange}
          />
          {/* Include additional input fields here if needed */}
          <div className="p-2 pt-4 flex justify-between">
            <PrimaryButton text="Send invite" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
