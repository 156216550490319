export default function ToggleButton({
  selected,
  handleClick,
  children,
  widthFull = false,
}) {
  return (
    <button
      type="button"
      onClick={handleClick ? handleClick : null}
      className={`flex items-center  rounded-md px-4 py-2 text-sm font-normal shadow-sm ring-1 ring-inset text-gray-500 hover:bg-gray-50 ring-gray-300
      ${selected ? "bg-gray-100" : "bg-white"} ${
        widthFull ? "justify-center w-full" : "justify-between"
      }`}
    >
      {children}
    </button>
  );
}
