import { LineChart, Line, Tooltip, XAxis } from "recharts";
import CustomTooltip from "../CustomTooltip";

export default function SimplestLineChart({ data, dataKey, format }) {
  return (
    <div style={{ position: "relative" }}>
      <LineChart width={300} height={200} data={data.timeseries}>
        <XAxis hide="true" dataKey={"truncated_date"} />
        {/* <Tooltip content={<CustomTooltip />} /> */}
        <Line dataKey={dataKey} stroke="#e34a63" strokeWidth={2} dot={false} />
      </LineChart>
      <div
        className="px-4 py-2 border rounded-md shadow-sm bg-white"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span className="text-lg text-gray-700">{format(data.lifetime)}</span>
      </div>
    </div>
  );
}
