import { useState, useContext, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import {
  ShareIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { UserContext } from "../../../pages/forFans/FanLayoutContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PlaylistMenu({ playlistId, darkStyle = false }) {
  const siteUrl = process.env.REACT_APP_SITE_URL;
  const [showShareSubmenu, setShowShareSubmenu] = useState(false);

  const handleShareMouseEnter = () => setShowShareSubmenu(true);
  const handleShareMouseLeave = () => setShowShareSubmenu(false);

  const handleClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    console.log("clicked");
  };

  const copyPlaylistUrlToClipboard = async () => {
    const artistUrl = `${siteUrl}playlists/${playlistId}/`; // Construct the URL
    try {
      await navigator.clipboard.writeText(artistUrl);
      console.log("URL copied to clipboard"); // Or any other indication to the user
    } catch (err) {
      console.error("Failed to copy URL:", err);
    }
  };

  return (
    <Menu as="div" className="relative ml-auto w-full">
      <Menu.Button
        onClick={handleClick}
        className={`-m-2.5 block p-2.5 ${
          darkStyle
            ? "text-gray-700 hover:text-gray-400"
            : "text-gray-50 hover:text-gray-400"
        }`}
      >
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-0.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            <div
              onMouseEnter={handleShareMouseEnter}
              onMouseLeave={handleShareMouseLeave}
            >
              <div className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50">
                <div className="flex items-center">
                  <ShareIcon className="h-5 w-5 me-2" />
                  <span>Share</span>
                </div>
              </div>
              {showShareSubmenu && (
                <div className="absolute left-full bottom-0 mb-0 ml-0 w-56 bg-white border rounded shadow-lg">
                  {/* Submenu items here */}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={copyPlaylistUrlToClipboard} // Call the function on click
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "px-3 py-1 text-sm leading-6 text-gray-900"
                        )}
                      >
                        <div className="flex items-center">
                          <DocumentDuplicateIcon className="h-6 w-6 me-1" />
                          <span>Copy link</span>
                        </div>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )}
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
