import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import { CheckCircleIcon as CheckCircleFilled } from "@heroicons/react/24/solid";
// import {
//   XCircleIcon,
//   CheckCircleIcon as CheckCircleOutline,
// } from "@heroicons/react/24/outline";

import TaskListCell from "../../tasks/TaskListCell";
import SelectFieldStealth from "../../../../components/forms/SelectFieldStealth";
// import SelectFieldStealth from "../../../components/forms/SelectFieldStealth";
import InputDatetimeStealth from "../../../../components/forms/InputDatetimeStealth";
import TableRow from "../../../../components/layout/tables/TableRow";

export default function ShowTableRow({
  show,
  selectShow,
  showStatuses,
  updateShow,
}) {
  //   let navigate = useNavigate();
  //   const [isHovered, setIsHovered] = useState(false);
  //   const [isCompleted, setIsCompleted] = useState(task.completed);
  const [formData, setFormData] = useState({
    id: show.id,
    name: show.name,
    status: show.status,
    show_date: show.show_date_form_display,
  });

  useEffect(() => {
    setFormData({
      id: show.id,
      name: show.name,
      status: show.status,
      show_date: show.show_date_form_display,
    });
  }, [show]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = async (e) => {
    // Update the formData state with the new selected value
    const updatedFormData = { ...formData, show_date: e.target.value };
    setFormData(updatedFormData);

    // Prepare the data for the updateTask function
    const showData = {
      ...updatedFormData,
      show_date: updatedFormData.show_date || null,
    };

    // Call the updateTask function with the new data
    const updated = await updateShow(showData);
    if (updated) {
      console.log("Show updated successfully");
    }
  };

  const handleSelectChange = async (selectedValue, fieldName) => {
    // Update the formData state with the new selected value
    const updatedFormData = { ...formData, [fieldName]: selectedValue };
    setFormData(updatedFormData);

    // Prepare the data for the updateShow function
    const showData = {
      ...updatedFormData,
      // assigned_to_id: updatedFormData.assigned_to?.id,
      // category_id: updatedFormData.category?.id,
      status_id: updatedFormData.status?.id,
    };

    // Call the updateShow function with the new data
    const updated = await updateShow(showData);
    if (updated) {
      console.log("Show updated successfully");
    }
  };

  const handleUpdate = async () => {
    const showData = {
      ...formData,
      //   assigned_to_id: formData.assigned_to?.id,
      //   category_id: formData.category?.id,
      status_id: formData.status?.id,
      // due_datetime: formData.due_datetime || null,
    };
    const updated = await updateShow(showData);
    if (updated) {
      console.log("Show updated successfully");
    }
  };

  return (
    <>
      <TableRow>
        <TaskListCell handleClick={() => selectShow(show)}>
          <div className="flex items-center gap-2 w-full">
            <div className="md:hidden w-full my-1 px-2 py-1 bg-transparent">
              {formData.name}
            </div>
            <input
              name="name"
              value={formData.name}
              onClick={(e) => e.stopPropagation()}
              onChange={handleChange}
              onBlur={handleUpdate}
              className="hidden md:block w-1/2 my-1 px-2 py-1 bg-transparent rounded-sm border border-transparent group-hover:border group-hover:border-gray-300 group-hover:bg-white group-hover:cursor-text"
            />
          </div>
        </TaskListCell>

        <TaskListCell display={"hidden md:table-cell"}>
          {showStatuses && (
            <SelectFieldStealth
              options={showStatuses}
              selected={formData.status || ""}
              handleChange={(selectedValue) =>
                handleSelectChange(selectedValue, "status")
              }
            />
          )}
        </TaskListCell>

        <TaskListCell noClick={true} display={"hidden md:table-cell truncate"}>
          {show.tour?.name || ""}
        </TaskListCell>

        <TaskListCell noClick={true} display={"hidden md:table-cell"}>
          {show.display_show_date}
        </TaskListCell>
      </TableRow>
    </>
  );
}
