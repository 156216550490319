import { useState, useEffect, useContext } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { UserContext } from "../../MainLayoutContext.jsx";
import ViewModel from "../../../../models/forArtists/main/organizations/organizationsViewModel.js";

export default function OrganizationsIndex() {
  const { token } = useOutletContext();
  const user = useContext(UserContext);
  const userId = user.id;
  const [organizationsViewData, setOrganizationsViewData] = useState();
  const [viewModel, setViewModel] = useState(
    new ViewModel(token, userId, organizationsViewData, (state) => {
      setOrganizationsViewData(state);
    })
  );

  useEffect(() => {
    viewModel.fetchState();
  }, [viewModel, token, userId]);

  return (
    <>
      <Outlet context={{ token, organizationsViewData, viewModel }} />
    </>
  );
}
