import { useContext } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { UserContext } from "./FanLayoutContext";
import Tabs from "../../components/Tabs";

const tabs = [
  { name: "Home", href: "/library/" },
  { name: "Artists", href: "/library/artists/" },
  { name: "Releases", href: "/library/releases/" },
  { name: "Playlists", href: "/library/playlists/" },
  { name: "Tracks", href: "/library/tracks/" },
];

export default function Library() {
  const user = useContext(UserContext);
  const { token, audioPlayerViewModel } = useOutletContext();

  return (
    <>
      <div className="container-lg my-10 mx-10 md:mx-20">
        <div className="flex flex-row items-center justify-start">
          {user && (
            <img
              src={user.profile_picture}
              className="h-40 rounded-full"
              alt={user.first_name}
            />
          )}
          <div className="mx-8">
            <h1 className="text-3xl font-light">{user.first_name}</h1>
            <p className="text-gray-400">Brooklyn, New York</p>
          </div>
        </div>
      </div>
      <div className="container-lg mt-10 mb-20 mx-10 md:mx-20">
        <h1 className="text-4xl font-bold my-2">Library</h1>
        <Tabs tabs={tabs} />
        <div>
          <Outlet context={{ token, audioPlayerViewModel }} />
        </div>
      </div>
    </>
  );
}
