import {
  ResponsiveContainer,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ComposedChart,
} from "recharts";

import {
  formatValue,
  formatLegend,
  formatColor,
  formatAxis,
} from "../../../utils/analyticsUtils";
import CustomTooltip from "../CustomTooltip";
import { convertToDisplayDate } from "../../../utils/dateUtils";

export default function ComboChart({
  timeseriesData,
  x_label,
  y_label,
  y1_label,
  min,
  max,
}) {
  min = min || 0;
  max = max || "dataMax";
  const domain = [min, max];

  const formatYAxisTick = (value) => {
    return `$${value.toLocaleString()}`;
  };
  const formatY1AxisTick = (value) => {
    return `${value.toLocaleString(0)}`;
  };
  return (
    <>
      <div className="relative w-full max-w-full h-[300px]">
        {/* Adjust the max-width and height as needed */}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={timeseriesData}
            margin={{ top: 30, right: 30, bottom: 5, left: 5 }}
          >
            <XAxis
              dataKey={x_label}
              minTickGap={200}
              tickFormatter={convertToDisplayDate}
            />
            <YAxis
              yAxisId="left"
              tickFormatter={(value) => formatAxis(y_label, value)}
              // domain={domain}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              domain={domain}
              tickFormatter={formatY1AxisTick}
            />

            <Bar
              yAxisId="right"
              dataKey={y1_label}
              fill={formatColor(y1_label)}
            />
            <Line
              yAxisId="left"
              // type="monotone"
              dataKey={y_label}
              stroke={formatColor(y_label)}
              dot={false}
              strokeWidth={2}
            />
            <Legend formatter={(value) => formatLegend(value)} />

            <Tooltip
              content={
                <CustomTooltip
                  colors={{
                    [y_label]: formatColor(y_label),
                    [y1_label]: formatColor(y1_label),
                  }}
                  isDate={true}
                />
              }
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
