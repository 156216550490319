// feature specific
import ProjectTasksView from "../../../../../features/forArtists/projects/tasks/Index";

export default function ProjectTasks() {
  return (
    <>
      <div>
        <ProjectTasksView />
      </div>
    </>
  );
}
