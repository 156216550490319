export const formatDate = (dateInput) => {
  if (!dateInput) return null;
  if (dateInput instanceof Date) {
    // Format Date object to 'YYYY-MM-DD' string
    let month = "" + (dateInput.getMonth() + 1),
      day = "" + dateInput.getDate(),
      year = dateInput.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    // Handle invalid input
    console.error("Invalid input for formatDate function:", dateInput);
    return null;
  }
};

export const convertToDate = (dateStr) => {
  // if dateStr is empty, return nothing
  if (!dateStr) return null;
  // Assuming the date string is in 'YYYY-MM-DD' format
  const [year, month, day] = dateStr.split("-");
  // Create a date at midnight in the local timezone
  return new Date(year, month - 1, day);
};

export const convertToDisplayDate = (dateStr) => {
  if (!dateStr) return null;
  const date = convertToDate(dateStr);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const createDateAsUTC = (dateString) => {
  const [year, month, day] = dateString
    .split("-")
    .map((num) => parseInt(num, 10));
  return new Date(Date.UTC(year, month - 1, day)); // Month is 0-indexed in JavaScript Date
};
