import NavItem from "./NavItem";

export default function Nav({ navItems, open }) {
  return (
    <nav className="flex flex-1 flex-col">
      <ul className="hidden sm:flex flex-1 flex-col gap-y-7">
        <li>
          <ul className="-mx-2 space-y-1">
            {navItems.map((item, index) => (
              <NavItem key={index} item={item} open={open} />
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
}
