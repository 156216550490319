import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CustomizedBarLabel from "../CustomBarLabel";
import CustomTooltip from "../CustomTooltip";
import {
  formatValue,
  formatLegend,
  formatColor,
} from "../../../utils/analyticsUtils";

export default function BarChartVertical({
  data,
  x_label,
  y_label,
  y1_label,
  domain,
  stackedLabel = null,
  showPercentage = false,
}) {
  // if data length is above 6, set height to 500
  let height;
  if (data.length < 2) {
    height = 120;
  } else if (data.length < 5) {
    height = 200;
  } else if (data.length < 8) {
    height = 400;
  } else {
    height = 600;
  }

  if (y1_label) {
    height = height * 2;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 5,
            right: 50,
            left: 40,
            bottom: 5,
          }}
          barGap={5}
          barSize={15}
          // barCategoryGap={30}
        >
          <XAxis type="number" domain={domain} hide />
          <YAxis
            dataKey={x_label}
            type="category"
            tickSize={0}
            tickMargin={15}
          />
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                colors={{
                  [y_label]: formatColor(y_label),
                  [y1_label]: formatColor(y1_label),
                  [stackedLabel]: formatColor(stackedLabel),
                }}
                showPercentage={showPercentage}
              />
            }
          />
          <Legend formatter={(value) => formatLegend(value)} />
          <Bar
            dataKey={y_label}
            fill={formatColor(y_label)}
            label={
              stackedLabel ? null : (
                <CustomizedBarLabel
                  layout={"vertical"}
                  format={(value) => formatValue(y_label, value)}
                  fill={formatColor(y_label)}
                />
              )
            }
            stackId={y_label}
          />
          {stackedLabel && (
            <Bar
              dataKey={stackedLabel}
              fill={formatColor(stackedLabel)}
              // label={
              //   <CustomizedBarLabel
              //     layout={"vertical"}
              //     format={(value) => formatValue(stackedLabel, value)}
              //     fill={formatColor(stackedLabel)}
              //   />
              // }
              stackId={y_label}
            />
          )}
          {y1_label && (
            <Bar
              dataKey={y1_label}
              fill={formatColor(y1_label)}
              label={
                <CustomizedBarLabel
                  layout={"vertical"}
                  format={(value) => formatValue(y1_label, value)}
                  fill={formatColor(y1_label)}
                />
              }
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
