import { useState } from "react";

import PrimaryButton from "../UI/buttons/PrimaryButton";
import InputField from "./InputField";
import FormTile from "../images/FormTile";

export default function PlaylistForm({ playlist, handleSubmitPlaylist }) {
  //   console.log("playlist: ", playlist);
  const initialFormData = {
    id: playlist.id,
    name: playlist.name,
    artwork: playlist.artwork,
    // link: "",
    // Add more fields here if necessary, e.g., date, location, etc.
    // date: '',
    // location: '',
    // ...
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (file) => {
    setFormData({ ...formData, image_id: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let requestBody = {
      ...formData,
    };
    // requestBody.artist = playlist.artist;
    //   if (formData.assigned_to)
    //     requestBody.assigned_to = formData.assigned_to.id;
    //   if (formData.category) requestBody.category = formData.category.id;
    //   if (formData.status) requestBody.status = formData.status.id;
    await handleSubmitPlaylist(requestBody);
  };

  return (
    <>
      <div className="pt-2 pb-4">
        <div className="pb-4">
          <h2 className="text-lg font-medium text-gray-600">Update details</h2>
        </div>
        <div className="flex flex-col justify-center items-center items-top gap-4 py-2">
          <FormTile
            imageUrl={formData.artwork ? formData.artwork : playlist?.artwork}
            onImageChange={handleImageChange}
          />

          <div className="w-full flex flex-col jsutify-start px-2">
            <InputField
              label={"Name"}
              name={"name"}
              value={formData.name}
              onChange={handleChange}
            />
            {/* <InputField label={"Description"} /> */}
          </div>
        </div>
        <div className="pt-5">
          <PrimaryButton
            text={"Save"}
            handleClick={handleSubmit}
            widthFull={true}
          />
        </div>
      </div>
    </>
  );
}
