import { useState } from "react";

// generic
import TableContainer from "../../../../../../components/layout/tables/TableContainer";
import TableHeader from "../../../../../../components/layout/tables/TableHeader";
import TableBody from "../../../../../../components/layout/tables/TableBody";

// feature specific
import ModalRow from "./ModalRow";

const cols = [
  { id: 0, name: "", label: "" },
  { id: 1, name: "Adgroup", label: "Adgroup" },
  // { id: 2, name: "Campaign", label: "Campaign" },
  { id: 3, name: "Audience", label: "Audience" },
];

export default function AudienceTable({
  adgroups,
  audiences,
  sortAdgroups,
  selected,
  handleSelectChange,
  createAudience,
  addAudienceToAdgroup,
}) {
  const [sortedHeader, setSortedHeader] = useState("Adgroup");
  const [sortAscending, setSortAscending] = useState(true);

  const handleSort = (key) => {
    let prevKey = sortedHeader;

    setSortedHeader(key);

    if (prevKey === key) {
      sortAdgroups(key, !sortAscending);
      setSortAscending(!sortAscending);
    } else {
      sortAdgroups(key, true);
      setSortAscending(true);
    }
  };

  return (
    <div className="h-96 overflow-y-auto">
      <TableContainer>
        <TableHeader
          cols={cols}
          handleSort={handleSort}
          sortedHeader={sortedHeader}
          sortAscending={sortAscending}
        />
        <TableBody>
          {adgroups?.map((adgroup, index) => {
            return (
              <ModalRow
                key={adgroup.id}
                cols={cols}
                index={index}
                adgroup={adgroup}
                audiences={audiences}
                selected={selected}
                handleSelectChange={handleSelectChange}
                createAudience={createAudience}
                addAudienceToAdgroup={addAudienceToAdgroup}
              />
            );
          })}
        </TableBody>
      </TableContainer>
    </div>
  );
}
