import { useEffect, useState } from "react";
import {
  useOutletContext,
  useParams,
  Outlet,
  useSearchParams,
} from "react-router-dom";

// generic
import SubNavTabs from "../../../../../features/forArtists/projects/adcampaigns/tabs/Index";

export default function Root() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { token, project } = useOutletContext();
  let { projectId } = useParams();

  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  // initial load selectedCampaigns with search params
  useEffect(() => {
    const campaignIds = searchParams.get("selected_campaign_ids");
    if (campaignIds) {
      const parsedIds = campaignIds.split(",");
      setSelectedCampaigns(parsedIds);
    }
  }, []);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(searchParams);

    currentSearchParams.set(
      "selected_campaign_ids",
      selectedCampaigns.join(",")
    );

    setSearchParams(currentSearchParams);
  }, [selectedCampaigns, setSearchParams, searchParams]);

  const tabs = [
    {
      name: "Campaigns",
      href: `/projects/${projectId}/campaigns?${searchParams.toString()}`,
    },
    {
      name: "Adgroups",
      href: `/projects/${projectId}/adgroups?${searchParams.toString()}`,
    },
    {
      name: "Ads",
      href: `/projects/${projectId}/ads?${searchParams.toString()}`,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <h1 className="text-5xl font-bold">Budget</h1>
      </div>
      <div className="py-4">
        <div className="p-4 max-w-4xl">
          <SubNavTabs
            tabs={tabs}
            selectedCampaigns={selectedCampaigns}
            setSelectedCampaigns={setSelectedCampaigns}
          />
        </div>
        <Outlet context={{ token, selectedCampaigns, setSelectedCampaigns }} />
      </div>
    </>
  );
}
