import { useState, useEffect } from "react";
import { useParams, useOutletContext, useSearchParams } from "react-router-dom";

import {
  formatValue,
  displayAscending,
} from "../../../../../utils/analyticsUtils";
import PlatformViewModel from "../../../../../models/forArtists/main/projects/analytics/platformViewModel";
import BarChartHorizontal from "../../../../../components/analytics/charts/BarChartHorizontal";
import ToggleButtonGroup from "../../../../../components/analytics/ToggleButtonGroup";
import StatsTable from "../../../../../components/analytics/StatsTable";
import BarChartDynamic from "../../../../../components/analytics/charts/BarChartDynamic";

export default function ProjectAnalyticsPlatform() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [comparisonField, setComparisonField] = useState("impressions");
  const [activeButton, setActiveButton] = useState("cpm");

  // view model
  const { token, project } = useOutletContext();
  let { projectId } = useParams();
  const [analyticsPlatformViewData, setAnalyticsPlatformViewData] = useState();
  const [platformViewModel, setPlatformViewModel] = useState(
    new PlatformViewModel(
      token,
      projectId,
      analyticsPlatformViewData,
      (state) => {
        setAnalyticsPlatformViewData(state);
      },
      searchParams
    )
  );

  const buttonLabels = [
    "Spend",
    "Impressions",
    "CPM",
    "Clicks",
    "CTR",
    "CPC",
    "Revenue",
    "ROAS",
  ];

  const releaseButtonLabels = [
    "Spend",
    "Impressions",
    "CPM",
    "Clicks",
    "CTR",
    "CPC",
    // "Revenue",
    // "ROAS",
  ];

  useEffect(() => {
    // Update search params to reflect active buttons
    setSearchParams({
      field: activeButton,
    });
  }, [activeButton, setSearchParams]);

  useEffect(() => {
    platformViewModel.updateSearchParams(searchParams);
  }, [searchParams, platformViewModel, setSearchParams]);

  const handleButtonClick = (field) => {
    setActiveButton(field.toLowerCase());
  };

  const columns = [
    { header: "Platform", key: "platform_name" },
    {
      header: "Spend",
      key: "spend",
      formatter: (value) => formatValue("spend", value),
    },
    {
      header: "Impressions",
      key: "impressions",
      formatter: (value) => formatValue("impressions", value),
    },
    {
      header: "CPM",
      key: "cpm",
      formatter: (value) => formatValue("cpm", value),
    },
    {
      header: "Clicks",
      key: "clicks",
      formatter: (value) => formatValue("clicks", value),
    },
    {
      header: "CPC",
      key: "cpc",
      formatter: (value) => formatValue("cpc", value),
    },
    {
      header: "Revenue",
      key: "revenue",
      formatter: (value) => formatValue("revenue", value),
    },
    {
      header: "ROAS",
      key: "roas",
      formatter: (value) => formatValue("roas", value),
    },
  ];

  const releaseColumns = [
    { header: "Platform", key: "platform_name" },
    {
      header: "Spend",
      key: "spend",
      formatter: (value) => formatValue("spend", value),
    },
    {
      header: "Impressions",
      key: "impressions",
      formatter: (value) => formatValue("impressions", value),
    },
    {
      header: "CPM",
      key: "cpm",
      formatter: (value) => formatValue("cpm", value),
    },
    {
      header: "Clicks",
      key: "clicks",
      formatter: (value) => formatValue("clicks", value),
    },
    {
      header: "CPC",
      key: "cpc",
      formatter: (value) => formatValue("cpc", value),
    },
    // {
    //   header: "Revenue",
    //   key: "revenue",
    //   formatter: (value) => formatValue("revenue", value),
    // },
    // {
    //   header: "ROAS",
    //   key: "roas",
    //   formatter: (value) => formatValue("roas", value),
    // },
  ];

  return (
    <>
      {/* comparison buttons */}
      <ToggleButtonGroup
        buttons={
          project.type_name === "Release" ? releaseButtonLabels : buttonLabels
        }
        activeButton={activeButton}
        onToggle={handleButtonClick}
      />
      <div className="py-8">
        {analyticsPlatformViewData?.data?.filtered_qs && (
          <BarChartDynamic
            data={
              analyticsPlatformViewData.data.filtered_qs &&
              platformViewModel.sortData(
                analyticsPlatformViewData.data.filtered_qs,
                activeButton,
                displayAscending(activeButton)
              )
            }
            x_label={"platform_name"}
            y_label={activeButton}
            // y1_label={activeButtons[1]}
          />
        )}
      </div>

      {/* stats table */}
      <div className="py-4">
        {analyticsPlatformViewData?.data?.qs && (
          <StatsTable
            data={analyticsPlatformViewData.data.qs}
            columns={project.type_name === "Release" ? releaseColumns : columns}
            sortData={(key, sortAscending) =>
              platformViewModel.sortData(
                analyticsPlatformViewData?.data?.qs,
                key,
                sortAscending
              )
            }
            footerData={analyticsPlatformViewData.data.totals}
          />
        )}
      </div>
    </>
  );
}
