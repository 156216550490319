// generic
import SlideOver from "../../../../components/SlideOver";

// feature specific
import ReleaseForm from "./ReleaseForm";

export default function ReleaseSlideover({
  artistId,
  releaseId,
  open,
  setOpen,
  selectedRelease,
  data,
  addRelease,
  updateRelease,
  deleteRelease,
}) {
  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        object={selectedRelease || null}
        objectName="Release"
        handleDelete={() => deleteRelease(selectedRelease?.id)}
      >
        <ReleaseForm
          artistId={artistId}
          release={selectedRelease || null}
          setOpen={setOpen}
          data={selectedRelease || null}
          addRelease={addRelease}
          updateRelease={updateRelease}
          releaseTypes={data.types}
        />
      </SlideOver>
    </>
  );
}
