import { useState, useEffect } from "react";
import { useParams, useOutletContext, useSearchParams } from "react-router-dom";

// generic
import ToggleButtonGroup from "../../../../../components/analytics/ToggleButtonGroup";
import BarChartDynamic from "../../../../../components/analytics/charts/BarChartDynamic";
import { displayAscending } from "../../../../../utils/analyticsUtils";
import SecondaryButton from "../../../../../components/UI/buttons/SecondaryButton";
// feature specific
import AudienceViewModel from "../../../../../models/forArtists/main/projects/analytics/audienceViewModel";
import AudienceLabellingModal from "../../../../../features/forArtists/projects/analytics/labelling/audiences/AudienceModal";

export default function ProjectAnalyticsAudience() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeButton, setActiveButton] = useState("cpm");

  const [isModalOpen, setIsModalOpen] = useState(false);

  // view model
  const { token, project } = useOutletContext();
  let { projectId } = useParams();
  const [analyticsAudienceViewData, setAnalyticsAudienceViewData] = useState();
  const [audienceViewModel, setAudienceViewModel] = useState(
    new AudienceViewModel(
      token,
      projectId,
      analyticsAudienceViewData,
      (state) => {
        setAnalyticsAudienceViewData(state);
      },
      searchParams
    )
  );

  const buttonLabels = [
    "Spend",
    "Impressions",
    "CPM",
    "Clicks",
    "CTR",
    "CPC",
    "Revenue",
    "ROAS",
  ];

  const releaseButtonLabels = [
    "Spend",
    "Impressions",
    "CPM",
    "Clicks",
    "CTR",
    "CPC",
    // "Revenue",
    // "ROAS",
  ];

  useEffect(() => {
    // Update search params to reflect active buttons
    setSearchParams({
      field: activeButton,
    });
  }, [activeButton, setSearchParams, isModalOpen]);

  useEffect(() => {
    audienceViewModel.updateSearchParams(searchParams);
  }, [searchParams, audienceViewModel, setSearchParams]);

  const handleButtonClick = (field) => {
    setActiveButton(field.toLowerCase());
  };

  return (
    <>
      {/* data labelling button */}
      <div className="py-2">
        <SecondaryButton text={""} handleClick={() => setIsModalOpen(true)}>
          Label audience data
        </SecondaryButton>
        <p className="text-sm text-gray-500 py-2">
          Add labels to your audience data to group and compare performance.
        </p>
      </div>

      <hr className="py-4" />

      {/* audience */}
      <ToggleButtonGroup
        buttons={
          project.type_name === "Release" ? releaseButtonLabels : buttonLabels
        }
        activeButton={activeButton}
        onToggle={handleButtonClick}
      />

      <div className="grid grid-cols-1 py-4">
        <div className="rounded-lg">
          <h2 className="text-2xl ps-10 py-1 text-gray-700 font-bold">
            Audience
          </h2>
          {analyticsAudienceViewData?.data?.qs_audience && (
            <BarChartDynamic
              // data={analyticsAudienceViewData.data.qs_audience}
              data={
                analyticsAudienceViewData.data.qs_audience &&
                audienceViewModel.sortData(
                  analyticsAudienceViewData.data.qs_audience,
                  activeButton,
                  displayAscending(activeButton)
                )
              }
              x_label={"audience"}
              y_label={activeButton}
              // y1_label={activeButtons[1]}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 py-4">
        <div className="rounded-lg mt-4">
          <h2 className="text-2xl ps-10 pt-4 py-1 text-gray-700 font-bold">
            Age
          </h2>
          {analyticsAudienceViewData?.data?.qs_age && (
            <BarChartDynamic
              data={analyticsAudienceViewData.data.qs_age}
              x_label={"age"}
              y_label={activeButton}
              // y1_label={activeButtons[1]}
            />
          )}
        </div>

        <div className="rounded-lg mt-4 pb-16">
          <h2 className="text-2xl ps-10 pt-4 py-1 text-gray-700 font-bold">
            Gender
          </h2>
          {analyticsAudienceViewData?.data?.qs_gender && (
            <BarChartDynamic
              data={analyticsAudienceViewData.data.qs_gender}
              x_label={"gender_name"}
              y_label={activeButton}
              // y1_label={activeButtons[1]}
            />
          )}
        </div>
      </div>

      <AudienceLabellingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}
