import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

// generic
import PrimaryButton from "../../../components/UI/buttons/PrimaryButton";
import ToggleButton from "../../../components/UI/buttons/ToggleButton";

export default function Header({
  setSearchParams,
  addTask,
  tasksViewType = "project",
}) {
  let { projectId } = useParams();
  let { organizationId } = useParams();
  const [showMyTasks, setShowMyTasks] = useState(false);
  const [hideBacklog, setHideBacklog] = useState(true);
  const [hideCompleted, setHideCompleted] = useState(true);

  // Generalized toggle function
  const handleToggle = (setter) => () => setter((prev) => !prev);

  useEffect(() => {
    setSearchParams({
      show_my_tasks: showMyTasks,
      hide_backlog: hideBacklog,
      hide_completed: hideCompleted,
    });
  }, [showMyTasks, hideBacklog, hideCompleted, setSearchParams]);

  const handleAddTask = async () => {
    const taskData = {
      title: "NEW TASK",
      project_id: projectId || null,
      organization_id: organizationId || null,
    };
    addTask(taskData);
  };

  return (
    <>
      <div>
        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-5xl font-semibold leading-6 text-gray-900 pb-8 sm:pb-0">
            Tasks
          </h3>
          <div className="flex justify-between items-center gap-2">
            <ToggleButton
              selected={showMyTasks}
              handleClick={handleToggle(setShowMyTasks)}
            >
              My tasks
            </ToggleButton>
            <ToggleButton
              selected={hideBacklog}
              handleClick={handleToggle(setHideBacklog)}
            >
              <div className="flex justify-start items-center gap-1">
                <EyeIcon className="h-5 w-5 text-gray-400" />
                Hide backlog
              </div>
            </ToggleButton>
            <ToggleButton
              selected={hideCompleted}
              handleClick={handleToggle(setHideCompleted)}
            >
              <div className="flex justify-start items-center gap-1">
                <EyeIcon className="h-5 w-5 text-gray-400" />
                Hide completed
              </div>
            </ToggleButton>
          </div>
        </div>
        <p className="py-1 text-sm text-gray-400">
          View all tasks for the {tasksViewType}.
        </p>
        <PrimaryButton text={"+ Add task"} handleClick={handleAddTask} />
      </div>
    </>
  );
}
